import { render, staticRenderFns } from "./ModulAsesmen.vue?vue&type=template&id=2425e006&scoped=true&"
import script from "./ModulAsesmen.vue?vue&type=script&lang=js&"
export * from "./ModulAsesmen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2425e006",
  null
  
)

export default component.exports