<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">Asesmen Persalinan</h3>
    <v-row justify="center">
      <v-col cols="12">
        <v-card color="deep-purple lighten-1" dark>
          <v-card-text>
            <v-row class="mt-8 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <v-tabs class="mb-3" centered stacked>
      <v-tab @click="tabKanan = 0">ASESMEN</v-tab>
      <v-tab @click="tabKanan = 1">CATATAN PERSALINAN</v-tab>
      <v-tab @click="tabKanan = 2">LAPORAN PARTUS</v-tab>
    </v-tabs>
    <div :hidden="tabKanan !== 0" style="padding: 10px">
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">1. ANAMNESA</div>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form1"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form1[i] = e)"
            :value="data.form1[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :disabled="v.disabled"
            :label-size="v.labelSize"
            :input-size="v.inputSize"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form2"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form2[i] = e)"
            :value="data.form2[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <table class="table">
        <thead>
          <tr>
            <th rowspan="2">Tgl / Bln</th>
            <th rowspan="2">Tempat</th>
            <th rowspan="2">Usia Kehamilan</th>
            <th rowspan="2">Jenis Persalinan</th>
            <th rowspan="2">Penolong</th>
            <th rowspan="2">Penyulit Kehamilan, Persalinan, Nifas</th>
            <th colspan="3">Anak</th>
          </tr>
          <tr>
            <th>Jenis Kelamin</th>
            <th>BB / PB</th>
            <th>Keadaan</th>
          </tr>
        </thead>
        <tbody>

        </tbody>
      </table>
      <button @click="tambahBaris()" class="btn btn-primary btn-block">
        Tambah Baris
      </button>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">2</div>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col :md="4" :lg="4" :sm="4" cols="12" class="mt-0 mb-0 pt-0 pb-3">
          <sekala-nyeri
            :sync-value="(e) => (data.skala_nyeri = e)"
            :value="data.skala_nyeri"
          />
        </v-col>
        <v-col :md="2" :lg="2" :sm="2" cols="12" class="mt-0 mb-0 pt-0 pb-3">
          <h6>Nutrisi</h6>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in data.form4"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                comp="wtext"
                :sync-value="(e) => (data.form4[i] = e)"
                :value="data.form4[i]"
                vclass="c-text-field"
                :data="null"
                :property="master.form4[i].property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="3" :lg="3" :sm="3" cols="12" class="mt-0 mb-0 pt-0 pb-3">
          <h6>Fungsional</h6>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form5"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form5[i] = e)"
                :value="data.form5[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="3" :lg="3" :sm="3" cols="12" class="mt-0 mb-0 pt-0 pb-3">
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form6"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form6[i] = e)"
                :value="data.form6[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">3. PEMERIKSAAN KEBIDANAN</div>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form7"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form7[i] = e)"
            :value="data.form7[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">4. PEMERIKSAAN PENUNJANG</div>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form8"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form8[i] = e)"
            :value="data.form8[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">5. DIAGNOSA</div>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form9"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form9[i] = e)"
            :value="data.form9[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">
        6. Antisipasi Masalah/Resiko:
      </div>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form10"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form10[i] = e)"
            :value="data.form10[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">
        7. Rencana/Tindakan (Terlampir Pada CPPT)
      </div>
      <div class="font-weight-bold text-center">
        8. Tindakan (Terlampir Pada CPPT)
      </div>
      <div class="font-weight-bold text-center">
        9. Evaluasi (Terlampir Pada CPPT)
      </div>
      <v-divider class="mt-3 mb-3" />
    </div>
    <div :hidden="tabKanan !== 1" style="padding: 10px">
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form11"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form11[i] = e)"
            :value="data.form11[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">KALA I</div>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form12"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form12[i] = e)"
            :value="data.form12[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">KALA II</div>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form13"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form13[i] = e)"
            :value="data.form13[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">Bayi Baru Lahir</div>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form14"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form14[i] = e)"
            :value="data.form14[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">KALA III</div>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form15"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form15[i] = e)"
            :value="data.form15[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <div class="font-weight-bold text-center">KALA IV</div>
      <v-divider class="mt-3 mb-3" />
      <h6>Jam 1</h6>
      <div style="overflow-x: scroll">
        <table class="table table-bordered" width="100%">
          <thead>
            <tr>
              <th>Menit</th>
              <th>Td</th>
              <th>N</th>
              <th>RR</th>
              <th>TFU</th>
              <th>Kontraksi</th>
              <th>Pendarahan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data.form16" :key="'a' + index">
              <td>15' ke {{ index }}</td>
              <td v-for="(item2, index2) in item" :key="'aa' + index2">
                <smart-widget
                  comp="wtext"
                  :sync-value="(e) => (data.form16[index][index2] = e)"
                  :value="data.form16[index][index2]"
                  vclass="c-text-field"
                  :data="null"
                  label=" "
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h6>Jam 2</h6>
      <div style="overflow-x: scroll">
        <table class="table table-bordered" width="100%">
          <thead>
            <tr>
              <th>Menit</th>
              <th>Td</th>
              <th>N</th>
              <th>RR</th>
              <th>TFU</th>
              <th>Kontraksi</th>
              <th>Pendarahan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data.form17" :key="'b' + index">
              <td>30' ke {{ index }}</td>
              <td v-for="(item2, index2) in item" :key="'bb' + index2">
                <smart-widget
                  comp="wtext"
                  :sync-value="(e) => (data.form17[index][index2] = e)"
                  :value="data.form17[index][index2]"
                  vclass="c-text-field"
                  :data="null"
                  label=" "
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div :hidden="tabKanan !== 2" style="padding: 10px">
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form18"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form18[i] = e)"
            :value="data.form18[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form19"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form19[i] = e)"
            :value="data.form19[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form20"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form20[i] = e)"
            :value="data.form20[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form21"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form21[i] = e)"
            :value="data.form21[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" />
      <v-row class="mt-0 mb-0">
        <v-col
          v-for="(v, i) in master.form22"
          :md="v.col ? v.col : 12"
          :lg="v.col ? v.col : 12"
          :sm="v.col ? v.col : 12"
          cols="12"
          class="mt-0 mb-0 pt-0 pb-3"
          :key="i"
        >
          <smart-widget
            :comp="v.widget"
            :sync-value="(e) => (data.form22[i] = e)"
            :value="data.form22[i]"
            vclass="c-text-field"
            :data="v.data"
            :property="v.property"
            :label="v.label ? v.label : i.replaceAll('_', ' ')"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
    </div>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/asesmen-persalinan'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
import SekalaNyeri from '../../components/SekalaNyeri.vue'
export default {
  components: {
    SekalaNyeri,
    DialogConfirm: Dialog,
    SmartWidget
  },
  data  () {
    return {
      ayam: '',
      user: JSON.parse(localStorage.getItem('user')),
      // loadingForm3: true,
      tabKanan: 0,
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          dignosa_medis: '',
          ruangan: ''
        },
        form2: {
          tanggal: '',
          jam: '',
          alasan_mrs: '',
          tanggal_pemulangan: '',
          jam_pemulangan: '',
          estimasi_tanggal_pemulangan: '',
          nama_perawat: ''
        },
        form3: [
          {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: ''
          }
        ],
        form4: {
          p1: '',
          p2: '',
          p3: '',
          p4: ''
        },
        form5: {
          p1: '',
          p2: '',
          p3: '',
          p4: ''
        },
        form6: {
          p1: ''
        },
        form7: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: '',
          p12: '',
          p13: '',
          p14: ''
        },
        form8: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: ''
        },
        form9: {
          p1: ''

        },
        form10: {
          p1: '',
          p2: ''
        },
        form11: {
          p1: '',
          p2: ''
        },
        form12: {
          p1: '',
          p2: '',
          p3: ''
        },
        form13: {
          p1: '',
          p2: '',
          p3: ''
        },
        form14: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: '',
          p11: '',
          p12: '',
          p13: '',
          p14: ''
        },
        form15: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: ''
        },
        form16: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: ''
          },
          4: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: ''
          }
        },
        form17: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: ''
          }
        },
        form18: {},
        form19: {},
        form20: {},
        form21: {},
        form22: {},
        skala_nyeri: ''
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  created  () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
    }
  },
  methods: {
    postSave  () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Persalinan',
        created_by: this.user.employee_id,
        jenis: 'rawat-inap'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    hapusBaris  (index) {
      this.master.form3.splice(index, 1)
      this.data.form3.splice(index, 1)
    },
    addPro  () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    tambahBaris  () {
      this.data.form3.push({
        p1: '',
        p2: '',
        p3: '',
        p4: '',
        p5: '',
        p6: '',
        p7: '',
        p8: '',
        p9: ''
      })
      this.master.form3.push({
        p1: {
          label: ' ',
          widget: 'wdatenormal',
          col: 12,
          data: []
        },
        p2: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p3: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p4: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p5: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p6: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p7: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p8: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p9: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        }
      })
    },
    deletePro  (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien  (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen  (folioId) {
      var type = 'Asesmen Persalinan'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            // this.data = results

            this.data.form1 = results.form1
            this.data.form2 = results.form2
            this.data.form4 = results.form4
            this.data.form5 = results.form5
            this.data.form6 = results.form6
            this.data.form7 = results.form7
            this.data.form8 = results.form8
            this.data.form9 = results.form9
            this.data.form10 = results.form10
            this.data.form11 = results.form11
            this.data.form12 = results.form12
            this.data.form13 = results.form13
            this.data.form14 = results.form14
            this.data.form15 = results.form15
            this.data.form16 = results.form16
            this.data.form17 = results.form17
            this.data.form18 = results.form18
            this.data.form19 = results.form19
            this.data.form20 = results.form20
            this.data.form21 = results.form21
            this.data.form22 = results.form22
            // console.log(this.data)
            // this.data.form1 = results.form1
            // this.data.form2 = results.form2
            // this.data.form4 = results.form4

            // this.data = results
            // this.data.form3 = results.form3
            // var hmm = this
            // setTimeout(function () {
            //   for (var i = 0; i < hmm.data.form3.length; i++) {
            //     for (var j = 1; j <= 9; j++) {
            //       if (j !== 1) {
            //         var str = results.form3[i]['p' + j]
            //         hmm.data.form3[i]['p' + j] = str + ' '
            //         hmm.data.form3[i]['p' + j] = str.substring(0, str.length - 1)
            //       } else {
            //         const lama = hmm.data.form3[i].p1
            //         const ix = i
            //         hmm.data.form3[i].p1 = ''
            //         setTimeout(function () {
            //           hmm.data.form3[ix].p1 = lama
            //         }, 250)
            //         // hmm.data.form3[i].p1 = lama
            //       }
            //     }
            //   }
            // }, 500)
          } else {
            // this.data = results
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style scoped>

.float-group {
  position: fixed;
  bottom: 10%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 50px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.framclass {
  width: 1280px;
  height: 786px;
  border: 0;
  -ms-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.Div1 {
  border: 1px solid #ddd;
  width: 24%;
  background-color: white;
  float: left;
  border: 2px solid #c00;
  margin-right: 5px;
  min-height: 50px;
  position: fixed;
}
</style>
