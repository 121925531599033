<template>
  <v-container fluid class="mt-3">
    <div>
      <h3 class="mb-1 mt-1">Modul Asesmen Keperawatan dan Kebidanan</h3>
      <v-row justify="center">
        <v-col cols="12" lg="4" sm="4" md="4">
          <v-select
            v-model="value"
            :items="items"
            filled
            label="Pilih Modul Asesmen"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Awal Keperawatan Rawat Inap'"
        >
          <FormAsesmenAwalKeperawatanRawatInap />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Asesmen Persalinan'"
        >
          <AsesmenPersalinan />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Awal Keperawatan Rawat Inap Anak'"
        >
          <FormAsesmenAwalKeperawatanRawatInapAnak />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Awal Keperawatan Rawat Inap Neonatus'"
        >
          <FormAsesmenAwalKeperawatanRawatInapNeonatus />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Awal Keperawatan Rawat Inap Icu'"
        >
          <FormAsesmenAwalKeperawatanRawatInapIcu />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="
            value ==
            'Asesmen Lanjutan Resiko Jatuh Anak Dan Intervensi Skala Humpty Dumpty'
          "
        >
          <FormAsesmenLanjutanResikoJatuhAnakDanIntervensiSkalaHumptyDumpty />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Grafik Bayi'"
        >
          <GrafikBayi />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Asesmen Populasi Khusus'"
        >
          <FormAsesmenPopulasiKhusus />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Implementasi Keperawatan'"
        >
          <ImplementasiKeperawatan />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Observasi Khusus'"
        >
          <ObservasiKhusus />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Transafer Pasien'"
        >
          <TransferPasien />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class=""
          md="12"
          v-if="value == 'Asesmen Pemulangan Pasien'"
        >
          <AsesmenPemulanganPasien />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
// import HistoryOrderResep from '@/components/HistoryOrderResep'
// import KedokteranGigi from '@/components/KedokteranGigi'
// import firebase from '@/firebaseInit'
import FormAsesmenAwalKeperawatanRawatInap from './FormAsesmenAwalKeperawatanRawatInap'
import FormAsesmenAwalKeperawatanRawatInapAnak from './FormAsesmenAwalKeperawatanRawatInapAnak'
import FormAsesmenAwalKeperawatanRawatInapNeonatus from './FormAsesmenAwalKeperawatanRawatInapNeonatus'
import FormAsesmenAwalKeperawatanRawatInapIcu from './FormAsesmenAwalKeperawatanRawatInapIcu'
import FormAsesmenLanjutanResikoJatuhAnakDanIntervensiSkalaHumptyDumpty from './FormAsesmenLanjutanResikoJatuhAnakDanIntervensiSkalaHumptyDumpty'
import FormRencanaAsuhanKeperawatanBayiNeonatus from './FormRencanaAsuhanKeperawatanBayiNeonatus'
import FormAsesmenLanjutanResikoJatuhDewasaDanIntervensiSkalaMorse from './FormAsesmenLanjutanResikoJatuhDewasaDanIntervensiSkalaMorse'
import GrafikBayi from './GrafikBayi'
import AsesmenPersalinan from './AsesmenPersalinan.vue'
import FormAsesmenPopulasiKhusus from './FormAsesmenPopulasiKhusus'
import ImplementasiKeperawatan from './ImplementasiKeperawatan'
import ObservasiKhusus from './ObservasiKhusus'
import TransferPasien from './TransferPasien'
import AsesmenPemulanganPasien from './AsesmenPemulanganPasien'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
// import { use } from 'vue/types/umd'
// import { apiKedua } from '../plugins/supports'
// import { apiKedua } from '../plugins/supports'
// import { isEmpty, jsonPrettier } from '@/plugins/supports'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FormAsesmenAwalKeperawatanRawatInap,
    // eslint-disable-next-line vue/no-unused-components
    FormAsesmenAwalKeperawatanRawatInapAnak,
    // eslint-disable-next-line vue/no-unused-components
    FormAsesmenAwalKeperawatanRawatInapNeonatus,
    // eslint-disable-next-line vue/no-unused-components
    FormAsesmenAwalKeperawatanRawatInapIcu,
    // eslint-disable-next-line vue/no-unused-components
    FormAsesmenLanjutanResikoJatuhAnakDanIntervensiSkalaHumptyDumpty,
    // eslint-disable-next-line vue/no-unused-components
    FormAsesmenLanjutanResikoJatuhDewasaDanIntervensiSkalaMorse,
    // eslint-disable-next-line vue/no-unused-components
    FormRencanaAsuhanKeperawatanBayiNeonatus,
    // eslint-disable-next-line vue/no-unused-components
    GrafikBayi,
    // eslint-disable-next-line vue/no-unused-components
    FormAsesmenPopulasiKhusus,
    ImplementasiKeperawatan, // eslint-disable-next-line vue/no-unused-components
    ObservasiKhusus, // eslint-disable-next-line vue/no-unused-components
    TransferPasien, // eslint-disable-next-line vue/no-unused-components
    AsesmenPersalinan, // eslint-disable-next-line vue/no-unused-components
    AsesmenPemulanganPasien // eslint-disable-next-line vue/no-unused-components

    // SmartWidget,
    // SekalaNyeri,
    // DialogConfirm: Dialog,
    // Soap,
  },
  mounted () {},

  data () {
    return {
      items: [
        'Awal Keperawatan Rawat Inap',
        'Awal Keperawatan Rawat Inap Anak',
        'Awal Keperawatan Rawat Inap Neonatus',
        'Awal Keperawatan Rawat Inap Icu',
        'Asesmen Persalinan',
        'Asesmen Populasi Khusus',
        'Asesmen Pemulangan Pasien',
        'Implementasi Keperawatan',
        'Asesmen Lanjutan Resiko Jatuh Anak Dan Intervensi Skala Humpty Dumpty',
        'Asesmen Lanjutan Resiko Jatuh Dewasa Dan Intervensi Skala Morse',
        'Grafik Bayi',
        'Observasi Khusus',
        'Transafer Pasien'
      ],
      value: 'Awal Keperawatan Rawat Inap'
    }
  },
  methods: {}
}
</script>
<style scoped>

</style>
