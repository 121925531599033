export default {
  demografi: {
    nama_pasien: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Nama Pasien'
    },
    no_rekam_medis: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'No. RM'
    },
    tanggal_lahir: {
      widget: 'wdate',
      data: null,
      col: 3,
      label: 'Tanggal Lahir'
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: null,
      col: 4
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 2
    },
    umur: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Umur'
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 6
    }
  },
  form1: {
    label1: {
      label: 'Tanggal',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    rencana_keperawatan_tgl: {
      widget: 'wdate',
      data: [],
      col: 1,
      label: ' '
    }
  },
  form2: {
    label1: {
      label: 'Gangguan pemenuhan kebutuhan nutrisi kurang dari kebutuhan b/d',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    label2: {
      label: 'Data penunjang',
      widget: 'wlabelkiri',
      data: [],
      col: 8
    },
    reaksi_yang_timbul: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        { text: 'Belum sempurnanya reflek menghisap', value: 'Belum sempurnanya reflek menghisap' },
        { text: 'Intoleran terhadap minuman', value: 'Intoleran terhadap minuman' },
        { text: ' ', value: null, field: 'auto' }
      ]
    },
    data_penunjang: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 8
    },
    label3: {
      label: 'Data Subyektif',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    label4: {
      label: 'Data Obyektif',
      widget: 'wlabelkiri',
      data: [],
      col: 8
    },
    data_subyektif: {
      label: ' ',
      widget: 'wradio',
      col: 4,
      data: [
        { text: 'Pasien Umum / Menetek sedikit', value: 'Pasien Umum / Menetek sedikit' },
        { text: ' ', value: null, field: 'auto' }
      ]
    },
    data_obyektif: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Malas Minum', value: 'Malas Minum' },
        { text: 'BB Belum meningkat', value: 'BB Belum meningkat' },
        { text: 'Kulit kering', value: 'Kulit kering' }
      ]
    }
  },
  form3: {
    label0: {
      label: 'NOC /Tujuan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label00: {
      label: 'NIC/Intervensi',
      widget: 'wlabelkiri',
      data: [],
      col: 10
    },
    label1: {
      label: 'Kebutuhan nutrisi terpenuhi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label2: {
      label: 'Mandiri',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    label3: {
      label: 'Kolaborasi',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    kriteria_hasil: {
      label: ' ',
      widget: 'wcheckbox',
      col: 2,
      data: [
        { text: 'tidak terjadi infeksi pada bayi', value: 'tidak terjadi infeksi pada bayi' },
        { text: 'Minum sering', value: 'Minum sering' },
        { text: 'Kulit lembab', value: 'Kulit lembab' },
        { text: ' ', value: null, field: 'auto' }
      ]
    },
    nic_mandiri: {
      label: ' ',
      widget: 'wcheckbox',
      col: 5,
      data: [
        { text: 'Beri minum sedikit-sedikit tapi sering', value: 'Beri minum sedikit-sedikit tapi sering' },
        { text: 'Bila tidak dapat minum dengan menetek ibu/ persiapan pasang sonde', value: 'Bila tidak dapat minum dengan menetek ibu/ persiapan pasang sonde' },
        { text: 'Observasi kembung dan muntah Lakukan retensi setiap akan memberi minum bila bayi memakai sonde dan cata banyak, warna serta konsistensinya', value: 'Observasi kembung dan muntah Lakukan retensi setiap akan memberi minum bila bayi memakai sonde dan cata banyak, warna serta konsistensinya' },
        { text: 'Timbang berat badan setiap hari', value: 'Timbang berat badan setiap hari' }
      ]
    },
    nic_kolaborasi: {
      label: ' ',
      widget: 'wcheckbox',
      col: 5,
      data: [
        { text: 'Bila bayi puasa kolaborasi dengan dokter untuk pemberian infuse', value: 'Bila bayi puasa kolaborasi dengan dokter untuk pemberian infuse' },
        { text: 'Penunjang klinik lain : ', value: null, field: 'auto' }
      ]
    }
  },
  form5: {
    rencana_keperawatan_tgl2: {
      widget: 'wdate',
      data: [],
      col: 4,
      label: 'Tanggal'
    }
  },
  form6: {
    label0: {
      label: 'NOC /Tujuan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label00: {
      label: 'NIC/Intervensi',
      widget: 'wlabelkiri',
      data: [],
      col: 10
    },
    label1: {
      label: 'Resiko tinggi infeksi b/d',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label3: {
      label: 'Data Penunjang',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    label4: {
      label: 'Data Subyektif (Klg Mengatakan)',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label6: {
      label: 'Data Obyektif',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    resiko_tinggi_infeksi: {
      label: ' ',
      widget: 'wcheckbox',
      col: 2,
      data: [
        { text: 'Imaturitas bayi', value: 'Imaturitas bayi' },
        { text: 'Imunologi bayi menurun', value: 'Imunologi bayi menurun' },
        { text: 'Infeksi dari ibu atau tenaga medis', value: 'Infeksi dari ibu atau tenaga medis' },
        { text: ' ', value: null, field: 'auto' }
      ]
    },
    data_penunjang: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 4
    },
    data_klg_mengatakan: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3
    },
    nic_data_obyektif: {
      label: ' ',
      widget: 'wcheckbox',
      col: 3,
      data: [
        { text: 'Pasien TTV dbn', value: 'Pasien TTV dbn' },
        { text: 'Laborat Leuco agak tinggi', value: 'Laborat Leuco agak tinggi' },
        { text: ' ', value: null, field: 'auto' }
      ]
    }
  },
  form7: {
    label1: {
      label: 'Infeksi dapat di cegah - Kriteria',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label2: {
      label: 'Mandiri',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    label3: {
      label: 'Kolaborasi',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    noc_kriteria: {
      label: ' ',
      widget: 'wcheckbox',
      col: 2,
      data: [
        { text: 'Tidak terjadi infeksi pada bayi', value: 'Tidak terjadi infeksi pada bayi' },
        { text: 'Tidak ada tanda-tanda infeksi', value: 'Tidak ada tanda-tanda infeksi' },
        { text: 'Tidak terjadi penularan infeksi', value: 'Tidak terjadi penularan infeksi' }
      ]
    },
    nic_mandiri2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        { text: 'Lakukan cuci tangan sebelum dan sesudah menyentuh bayi', value: 'Lakukan cuci tangan sebelum dan sesudah menyentuh bayi' },
        { text: 'Lakukan teknik septik / steril saat melakukan tindakan pada bayi', value: 'Lakukan teknik septik / steril saat melakukan tindakan pada bayi' },
        { text: 'Berikan lingkungan yang melindungi bayi dari infeksi', value: 'Berikan lingkungan yang melindungi bayi dari infeksi' },
        { text: 'Observasi tanda-tanda vital, lethargi, infeksi dan apnea', value: 'Observasi tanda-tanda vital, lethargi, infeksi dan apnea' },
        { text: 'Jaga kebersihan lingkungan dan kebersihan bayi', value: 'Jaga kebersihan lingkungan dan kebersihan bayi' },
        { text: 'Pertahankan pemenuhan nutrisi adekuat', value: 'Pertahankan pemenuhan nutrisi adekuat' }
      ]
    },
    nic_kolaborasi2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        { text: 'Dengan dokter untuk pemberian antibiotik', value: 'Dengan dokter untuk pemberian antibiotik' },
        { text: 'Dengan laborat untuk pemeriksaan lengkap', value: 'Dengan laborat untuk pemeriksaan lengkap' },
        { text: ' ', value: null, field: 'auto' }
      ]
    }
  },
  form8: {
    rencana_keperawatan_tgl3: {
      widget: 'wdate',
      data: [],
      col: 4,
      label: 'Tanggal'
    }
  },
  form9: {
    label1: {
      label: 'Resiko tinggi terjadinya ketidakefektifan pola nafas b/d',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label2: {
      label: 'Data penunjang',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label3: {
      label: 'Data Subyektif',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label4: {
      label: 'Data Obyektif',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    resiko_tinggi_pola_nafas: {
      label: ' ',
      widget: 'wcheckbox',
      col: 3,
      data: [
        { text: 'Penurunan energy', value: 'Penurunan energy' },
        { text: 'Kelelahan', value: 'Kelelahan' },
        { text: 'Imaturitas', value: 'Imaturitas' },
        { text: 'Sekresi yang kental', value: 'Sekresi yang kental' },
        { text: ' ', value: null, field: 'auto' }
      ]
    },
    data_penunjang: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3
    },
    data_subyektif: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Pasien bila nafas kelihatan terasa berat', value: 'Pasien bila nafas kelihatan terasa berat' }
      ]
    },
    data_obyektif: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Sekret Kental', value: 'Sekret Kental' },
        { text: 'Dispnea', value: 'Dispnea' },
        { text: 'Ronkhi positif', value: 'Ronkhi positif' },
        { text: 'RR meningkat', value: 'RR meningkat' },
        { text: 'Bernafas dengan bantuan otot-otot pernafasan', value: 'Bernafas dengan bantuan otot-otot pernafasan' },
        { text: ' ', value: null, field: 'auto' }
      ]
    }
  },
  form10: {
    label0: {
      label: 'NOC /Tujuan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label00: {
      label: 'NIC/Intervensi',
      widget: 'wlabelkiri',
      data: [],
      col: 10
    },
    label1: {
      label: 'Pola nafas efektif - Kriteria Hasil',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label2: {
      label: 'Mandiri',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    label3: {
      label: 'Kolaborasi',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    kriteria_hasil: {
      label: ' ',
      widget: 'wcheckbox',
      col: 2,
      data: [
        { text: 'Frekwensi, kedalaman dan irama pernafasan normal', value: 'Frekwensi, kedalaman dan irama pernafasan normal' },
        { text: 'Bunyi nafas bersih', value: 'Bunyi nafas bersih' },
        { text: 'Nilai gas darah dalam batas normal', value: 'Nilai gas darah dalam batas normal' },
        { text: ' ', value: null, field: 'auto' }
      ]
    },
    nic_mandiri: {
      label: ' ',
      widget: 'wcheckbox',
      col: 5,
      data: [
        { text: 'Atur posisi bayi dengan leher tengadah (ekstensi) dengan meletakkan handuk dibawah bahu', value: 'Atur posisi bayi dengan leher tengadah (ekstensi) dengan meletakkan handuk dibawah bahu' },
        { text: 'Bila cairan atau lender banyak dalam mulut bayi dimiringkan', value: 'Bila cairan atau lender banyak dalam mulut bayi dimiringkan' },
        { text: 'Bersihkan jalan nafas dengan menghisap lender (cairan ketuban/ meconium)', value: 'Bersihkan jalan nafas dengan menghisap lender (cairan ketuban/ meconium)' },
        { text: 'Observasi tanda-tanda vital, kaji pola nafas, kaji tanda-tanda cyarosis', value: 'Observasi tanda-tanda vital, kaji pola nafas, kaji tanda-tanda cyarosis' },
        { text: 'Ukur saturasi oksigen (SPO2)', value: 'Ukur saturasi oksigen (SPO2)' },
        { text: 'Atur ventilasi ruangan bayi', value: 'Atur ventilasi ruangan bayi' },
        { text: ' ', value: null, field: 'auto' }
      ]
    },
    nic_kolaborasi: {
      label: ' ',
      widget: 'wcheckbox',
      col: 5,
      data: [
        { text: 'Berikan obat sesuai TX dokter :', value: null, field: 'auto' },
        { text: 'Pemberian Oksigen', value: 'Pemberian Oksigen' },
        { text: 'Penunjang klinik lain : ', value: null, field: 'auto' }
      ]
    }
  },
  form11: {
    rencana_keperawatan_tgl4: {
      widget: 'wdate',
      data: [],
      col: 4,
      label: 'Tanggal'
    }
  },
  form12: {
    label1: {
      label: 'Resiko tinggi terjadinya ketidakefektifan termoregulasi b/d',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label2: {
      label: 'Data penunjang',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label3: {
      label: 'Data Subyektif',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label4: {
      label: 'Data Obyektif',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    resiko_tinggi_pola_termoregulasi: {
      label: ' ',
      widget: 'wcheckbox',
      col: 3,
      data: [
        { text: 'Tipis jaringan lemak dibawah kulit', value: 'Tipis jaringan lemak dibawah kulit' },
        { text: 'Mekanisme regulasi suhu imatur', value: 'Mekanisme regulasi suhu imatur' },
        { text: ' ', value: null, field: 'auto' }
      ]
    },
    data_penunjang: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3
    },
    data_subyektif: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Pasien badan nya terasa agak dingin', value: 'Pasien badan nya terasa agak dingin' }
      ]
    },
    data_obyektif: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Suhu tubuh masih normal', value: 'Suhu tubuh masih normal' },
        { text: 'Badan lemas', value: 'Badan lemas' },
        { text: 'Agak pucat', value: 'Agak pucat' },
        { text: 'RR meningkat', value: 'RR meningkat' },
        { text: ' ', value: null, field: 'auto' }
      ]
    }
  },
  form13: {
    label0: {
      label: 'NOC /Tujuan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label00: {
      label: 'NIC/Intervensi',
      widget: 'wlabelkiri',
      data: [],
      col: 10
    },
    label1: {
      label: 'Suhu tubuh bayi dalam batas normal - Kriteria Hasil',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label2: {
      label: 'Mandiri',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    label3: {
      label: 'Kolaborasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label4: {
      label: 'Penunjang klinik lain ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kriteria_hasil: {
      label: ' ',
      widget: 'wcheckbox',
      col: 2,
      data: [
        { text: 'Suhu tubuh 36,5-37 c', value: 'Suhu tubuh 36,5-37 c' },
        { text: 'Akral dan kulit bayi tidak ingin dan tidak tampak pucat', value: 'Akral dan kulit bayi tidak ingin dan tidak tampak pucat' }
      ]
    },
    nic_mandiri: {
      label: ' ',
      widget: 'wcheckbox',
      col: 5,
      data: [
        { text: 'Veru kehangatan pada bayi dengan perawatan di inkubator', value: 'Veru kehangatan pada bayi dengan perawatan di inkubator' },
        { text: 'Hangatkan incubator seperempat jam sebelum bayi dimasukkan', value: 'Hangatkan incubator seperempat jam sebelum bayi dimasukkan' },
        { text: 'Ganti pakaian bayi secepatnya bila basah ', value: 'Ganti pakaian bayi secepatnya bila basah ' },
        { text: 'Berikan ASI pada bayi dengan cara menetek atau dapat diiberikan dengan sendok/pipet', value: 'Berikan ASI pada bayi dengan cara menetek atau dapat diiberikan dengan sendok/pipet' },
        { text: 'Tunda memandikan bayi sampai suhu tubuh stabil', value: 'Tunda memandikan bayi sampai suhu tubuh stabil' },
        { text: 'Monitoring tanda-tanda vital, monitor tanda-tanda hipotermi', value: 'Monitoring tanda-tanda vital, monitor tanda-tanda hipotermi' },
        { text: ' ', value: null, field: 'auto' }
      ]
    },
    nic_kolaborasi: {
      label: ' ',
      widget: 'wcheckbox',
      col: 3,
      data: [
        { text: 'Berikan obat sesuai tx dokter', value: 'Berikan obat sesuai tx dokter' }
      ]
    },
    nic_penunjang_klinik: {
      label: ' ',
      widget: 'wcheckbox',
      col: 2,
      data: [
        { text: 'Laboratorium untuk pemeriksaan serum glukosa', value: null, field: 'auto' }
      ]
    }
  }
}
