export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP',
      disabled: true,

      col: 6
    },
    folio_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'No Reg',
      col: 6
    }
  },
  form1: {
    label1: {
      label: 'Dokter yang Merawat',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    dokter_yang_merawat: {
      label: ' ',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 9
    },
    label2: {
      label: 'Tiba di Ruangan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tiba_diruangan_tanggal: {
      widget: 'wdatenormal',
      data: [],
      col: 4,
      disabled: true,

      label: 'Tanggal'
    },
    tiba_diruangan_jam: {
      label: 'Jam',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3,
      property: { attrs: { suffix: '', type: 'string' } }
    },
    label3: {
      label: 'Pengkajian',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pengkajian_tanggal: {
      widget: 'wdatenormal',
      data: [],
      col: 4,
      disabled: true,

      label: 'Tanggal'
    },
    pengkajian_jam: {
      label: 'Jam',
      widget: 'wtext',
      disabled: true,
      data: [],
      col: 3,
      property: { attrs: { suffix: '', type: 'string' } }
    },
    label5: {
      label: 'Cara Masuk',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    cara_masuk: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Jalan', value: 'jalan' },
        { text: 'Kursi Roda', value: 'kursi_roda' },
        { text: 'Brangkar/Troley*', value: 'brangkar/troley' },
        { text: 'Inkubator', value: 'inkubator' },
        { text: 'Box', value: 'box' }
      ],
      col: 9
    },
    label6: {
      label: 'Asal Pasien',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    asal_pasien: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'IRJ', value: 'irj' },
        { text: 'IGD', value: 'igd' },
        { text: 'Kamar Operasi', value: 'kamar_operasi' },
        { text: 'Kamar Bersalin', value: 'kamar_bersalin' },
        { text: 'Dokter Pribadi', value: 'dokter_pribadi' },
        { text: 'Rujukan', value: 'rujukan' }
      ],
      col: 9
    },
    label7: {
      label: 'Masuk Kasus Trauma',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    masuk_kasus_trauma: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'KLL', value: 'kll' },
        { text: 'KDRT', value: 'kdrt' },
        { text: 'Tidak', value: 'tidak' }
      ],
      col: 9
    },
    label8: {
      label: 'Hasil Pemeriksaan yang Dibawa oleh Keluarga',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    hasil_yang_dibawa: {
      label: ' ',
      widget: 'wcheckbox',
      data: [
        { text: 'Laboratorium', value: 'laboratorium' },
        { text: 'Radiologi', value: 'radiologi' },
        { text: 'Dignostik Lain', value: 'diagnostik_lain' },
        { text: 'Tidak Ada', value: 'tidak_ada' }
      ],
      col: 9
    }
  },
  // form2: {
  //   label1: {
  //     label: 'Pekerjaan Pasien',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   pekerjaan_pasien: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'PNS/TNI/POLRI', value: 'pns/tni/polri' },
  //       { text: 'Swasta', value: 'swasta' },
  //       { text: 'Pelajar/Mahasiswa', value: 'pelajar/mahasiswa' },
  //       { text: 'Pensiun', value: 'pensiun' },
  //       { text: 'IRT', value: 'irt' },
  //       { text: 'Lain-lain', value: null, field: 'auto' }
  //     ]
  //   },
  //   label2: {
  //     label: 'Pekerjaan Penanggung Jawab/OT Pasien',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   pekerjaan_ot_pasien: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'PNS/TNI/POLRI', value: 'pns/tni/polri' },
  //       { text: 'Swasta', value: 'swasta' },
  //       { text: 'Pensiun', value: 'pensiun' },
  //       { text: 'IRT', value: 'irt' },
  //       { text: 'Lain-lain', value: null, field: 'auto' }
  //     ]
  //   },
  //   label3: {
  //     label: '  ',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   pekerjaan_ot_pasien_gaji: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: '< 1 juta', value: '< 1 juta' },
  //       { text: '1 - 2,9 juta', value: '1 - 2,9 juta' },
  //       { text: '3 - 4,9 juta', value: '3 - 4,9 juta' },
  //       { text: '5 - 9,9 juta', value: '5 - 9,9 juta' },
  //       { text: '10 - 14,9 juta', value: '10 - 14,9 juta' },
  //       { text: '15 - 19,9 juta', value: '15 - 19,9 juta' },
  //       { text: '> 20 juta', value: '> 20 juta' }
  //     ]
  //   },
  //   label4: {
  //     label: 'Pendidikan Pasien',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   pendidikan_pasien: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'TK', value: 'TK' },
  //       { text: 'SD', value: 'SD' },
  //       { text: 'SMP', value: 'SMP' },
  //       { text: 'SLTA', value: 'SLTA' },
  //       { text: 'Akademi', value: 'Akademi' },
  //       { text: 'Paska Sarjana', value: 'Paska Sarjana' }
  //     ]
  //   },
  //   label5: {
  //     label: 'Pendidikan Penanggung Jawab/OT/Suami/Istri',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   pendidikan_ot_pasien: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'TK', value: 'TK' },
  //       { text: 'SD', value: 'SD' },
  //       { text: 'SMP', value: 'SMP' },
  //       { text: 'SLTA', value: 'SLTA' },
  //       { text: 'Akademi', value: 'Akademi' },
  //       { text: 'Paska Sarjana', value: 'Paska Sarjana' }
  //     ]
  //   },
  //   label6: {
  //     label: 'Cara Pembayaran',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   cara_pembayaran: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'Pribadi', value: 'Pribadi' },
  //       { text: 'Perusahaan', value: 'Perusahaan' },
  //       { text: 'Asuransi', value: 'Asuransi' },
  //       { text: 'BPJS', value: 'BPJS' },
  //       { text: 'Lain-lain', value: null, field: 'auto' }
  //     ]
  //   },
  //   label7: {
  //     label: 'Cara Pembayaran',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   tinggal_bersama: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'Keluarga', value: 'Keluarga' },
  //       { text: 'Suami/Istri', value: 'Suami/Istri' },
  //       { text: 'Orang Tua', value: 'Orang Tua' },
  //       { text: 'Anak', value: 'Anak' },
  //       { text: 'Teman', value: 'Teman' },
  //       { text: 'Sendiri', value: 'Sendiri' },
  //       { text: 'Panti Asuhan', value: 'Panti Asuhan' }
  //     ]
  //   }
  // },
  // form3: {
  //   agama: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'Islam', value: 'Pribadi' },
  //       { text: 'Protestan', value: 'Protestan' },
  //       { text: 'Katolik', value: 'Katolik' },
  //       { text: 'Hindu', value: 'Hindu' },
  //       { text: 'Budha', value: 'Budha' },
  //       { text: 'Konghucu', value: 'Konghucu' }
  //     ]
  //   }
  // },
  // form4: {
  //   nilai1: {
  //     label: 'Nilai-Nilai Kepercayaan Pasien/Keluarga',
  //     widget: 'wcheckbox',
  //     col: 6,
  //     data: [
  //       {
  //         text: 'Tidak Mau Ditransfusi',
  //         value: 'Tidak Mau Ditransfusi'
  //       },
  //       {
  //         text: 'Tidak Mau Imunisasi',
  //         value: 'Tidak Mau Imunisasi'
  //       },
  //       {
  //         text: 'Tidak Makan Daging/Ikan yang Bersisik',
  //         value: 'Tidak Makan Daging/Ikan yang Bersisik'
  //       }
  //     ]
  //   },
  //   nilai2: {
  //     label: ' ',
  //     widget: 'wcheckbox',
  //     col: 6,
  //     data: [
  //       {
  //         text: 'Tidak Mau Pulang di Hari Tertentu',
  //         value: 'Tidak Mau Pulang di Hari Tertentu'
  //       },
  //       {
  //         text: 'Tidak Boleh Menyusui (ASI)',
  //         value: 'nyeri'
  //       },
  //       {
  //         text: 'lain-lain',
  //         value: null,
  //         field: 'auto'
  //       }
  //     ]
  //   },
  //   label2: {
  //     label: 'Hambatan Belajar',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   hambatan_belajar: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'Tidak Ada', value: 'Tidak Ada' },
  //       { text: 'Pandangan Terbatas', value: 'Pandangan Terbatas' },
  //       { text: 'Keterbatasan Fisik', value: 'Keterbatasan Fisik' },
  //       { text: 'Tidak Bisa Membaca', value: 'Tidak Bisa Membaca' }
  //     ]
  //   },
  //   label3: {
  //     label: 'Hambatan Belajar',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   hambatan_bahasa: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'Ya', value: 'Ya' },
  //       { text: 'Tidak', value: 'Tidak' },
  //       { text: 'Pendengaran Terbatas', value: 'Pendengaran Terbatas' },
  //       { text: 'Hambatan Emosi', value: 'Hambatan Emosi' },
  //       { text: 'Motivasi Buruk', value: 'Motivasi Buruk' }
  //     ]
  //   }
  // },
  form5: {
    label1: {
      label: '1. Keluhan Utama : ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    keluhan_utama: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    label2: {
      label: '2. Riwayat Penyakit Sekarang : ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    riwayat_penyakit_sekarang: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    label3: {
      label: 'Riwayat Penyakit Dahulu(termasuk Riwayat Operasi):',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label3a: {
      label: 'a. Pernah Dirawat :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pernah_dirawat: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label3b: {
      label: 'b. Pernah Operasi/Tindakan :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pernah_operasi_tindakan: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label3c: {
      label: 'c. Masalah Operasi/Pembiusan :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    masalah_operasi_pembiusan: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    }
  },
  form6: {
    label4: {
      label: 'Riwayat Penyakit Keluarga',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_penyakit_keluarga: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label8: {
      label: 'Obat dari Rumah',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    obat_dari_rumah: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, Sebutkan', value: null, field: 'auto' }
      ]
    },
    label9: {
      label:
        'Riwayat Konsumsi Obat Pengencer Darah (Aspirin, Warfarin, Plavix, dll)',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_konsumsi_obat: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, Kapan', value: null, field: 'auto' }
      ]
    },
    label10: {
      label: 'Riwayat Alergi',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_alergi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label11: {
      label: 'Riwayat Menerima Transfusi Darah',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_transfusi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ya, Waktu Terakhir', value: null, field: 'auto' }
      ]
    },
    label12: {
      label: 'Reaksi yang Timbul',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    reaksi_yang_timbul: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, ', value: null, field: 'auto' }
      ]
    },
    label13a: {
      label: 'Golongan Darah',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    golongan_darah: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'O', value: 'O' },
        { text: 'AB', value: 'AB' }
      ]
    },
    label13b: {
      label: 'Rh',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    rh: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Positif', value: 'Positif' },
        { text: 'Negatif', value: 'Negatif' }
      ]
    },
    label14: {
      label: 'Khusus Pasien dengan Riwayat Komoterapi dan Radioterapi',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label14b: {
      label: 'Cara Pemberian',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    cara_pemberian: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Melalui Suntik', value: 'Melalui Suntik' },
        { text: 'Melalui Infus', value: 'Melalui Infus' },
        { text: 'Melalui Oral/Minum', value: 'Melalui Oral/Minum' }
      ]
    },
    label14d: {
      label: 'Efek Samping',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    efek_samping: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Mual', value: 'Mual' },
        { text: 'Muntah', value: 'Muntah' },
        { text: 'Jantung Berdebar', value: 'Jantung Berdebar' },
        { text: 'Pusing', value: 'Pusing' },
        { text: 'Rambut Rontok', value: 'Rambut Rontok' }
      ]
    },
    label15a: {
      label: 'Riwayat Pernikahan',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_pernikahan: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Belum Menikah', value: 'Belum Menikah' },
        { text: 'Menikah, Lama Menikah', value: null, field: 'auto' }
      ]
    },
    label15b: {
      label: 'Pernikahan ke',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    pernikahan_ke: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 2
    }
  },
  form7: {
    label1: {
      label: 'Keadaan Umum:',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    keadaan_umum: {
      label: ' ',
      widget: 'wradio',
      col: 11,
      data: [
        { text: 'Tampak Tidak Sakit', value: 'Tampak Tidak Sakit' },
        { text: 'Tampak Sakit Ringan', value: 'Tampak Sakit Ringan' },
        { text: 'Tampak Sakit Sedang', value: 'Tampak Sakit Sedang' },
        { text: 'Tampak Sakit Berat', value: 'Tampak Sakit Berat' }
      ]
    },
    label2: {
      label: 'Kesadaran:',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    kesadaraan: {
      label: ' ',
      widget: 'wradio',
      col: 11,
      data: [
        { text: 'CM', value: 'CM' },
        { text: 'Apatis', value: 'Apatis' },
        { text: 'Somnolen', value: 'Somnolen' },
        { text: 'Soporos', value: 'Soporos' },
        { text: 'Koma', value: 'Koma' }
      ]
    },
    label3: {
      label: 'GCS:',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    gcs_e: {
      label: ' ',
      widget: 'wtext',
      col: 1,
      data: null,
      property: { attrs: { prefix: 'E', type: 'string' } }
    },
    gcs_m: {
      label: ' ',
      widget: 'wtext',
      col: 1,
      data: '',
      property: { attrs: { prefix: 'M', type: 'string' } }
    },
    gcs_v: {
      label: ' ',
      widget: 'wtext',
      col: 1,
      data: '',
      property: { attrs: { prefix: 'V', type: 'string' } }
    },
    pupil: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: '',
      property: { attrs: { prefix: 'Pupil', suffix: 'mm', type: 'string' } }
    },
    kiri: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: '',
      property: { attrs: { prefix: '/Kiri', suffix: 'mm', type: 'string' } }
    },
    reaksi_cahaya_kanan: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: '',
      property: { attrs: { prefix: 'Reaksi Cahaya : Kanan', type: 'string' } }
    },
    reaksi_cahaya_kiri: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: '',
      property: { attrs: { prefix: '/Kiri', type: 'string' } }
    },
    label4: {
      label: 'Tanda-Tanda Vital:',
      widget: 'wlabelkiri',
      data: '',
      col: 2
    },
    tanda_vital_td: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'TD', suffix: 'mmHg', type: 'string' } }
    },
    tanda_vital_hr: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'hr', suffix: 'x/mnt', type: 'string' } }
    },
    tanda_vital_rr: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'RR', suffix: 'x/mnt', type: 'string' } }
    },
    tanda_vital_t: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'T', suffix: '°C', type: 'string' } }
    },
    tanda_vital_spo2: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'SpO2', suffix: '%', type: 'string' } }
    },
    labelx: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    label5: {
      label: 'Pemeriksaan Atrometrik:',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    pemeriksaan_atrometrik_bb: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'BB', suffix: 'Kg', type: 'string' } }
    },
    pemeriksaan_atrometrik_tb: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'TB', suffix: 'Cm', type: 'string' } }
    },
    pemeriksaan_atrometrik_lk: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'LK', suffix: 'Cm', type: 'string' } }
    },
    pemeriksaan_atrometrik_ld: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'LD', suffix: 'Cm', type: 'string' } }
    },
    pemeriksaan_atrometrik_lp: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'LP', suffix: 'Cm', type: 'string' } }
    },
    labelx2: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    }
  },
  form8: {
    widget: 'wtext',
    property: { attrs: { type: 'string' } },
    data: [
      {
        text: 'Sistem Susunan Saraf Pusat',
        value: 0,
        dataCols: {
          label1: {
            label: 'Kepala :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kepala: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Mikrosefal', value: 'Mikrosefal' },
              { text: 'Asimetri', value: 'Asimetri' },
              { text: 'Hematoma', value: 'Hematoma' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'Ubun-Ubun :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          ubun_ubun: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Datar', value: 'Datar' },
              { text: 'Cekung', value: 'Cekung' },
              { text: 'Menonjol', value: 'Menonjol' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label3: {
            label: 'Wajah :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          wajah: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Asimetris', value: 'Asimetris' },
              { text: "Bell's Palsy", value: "Bell's Palsy" },
              { text: 'Tic Facialis', value: 'Tic Facialis' },
              { text: 'Kelainan Kongenital', value: 'Kelainan Kongenital' }
            ]
          },
          label4: {
            label: 'Leher :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          leher: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Pembesaran Tiroid', value: 'Pembesaran Tiroid' },
              {
                text: 'Pembesaran Vena Ju guralis',
                value: 'Pembesaran Vena Ju guralis'
              },
              { text: 'TAK', value: 'TAK' },
              { text: 'Kaku Kuduk', value: 'Kaku Kuduk' },
              { text: 'Keterbatasan Gerak', value: 'Keterbatasan Gerak' },
              { text: 'Pembesaran KGB', value: 'Pembesaran KGB' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label5: {
            label: 'Kejang :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kejang: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ada, tipe', value: null, field: 'auto' }
            ]
          },
          label6: {
            label: 'Sensorik :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          sensorik: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Sakit/Nyeri', value: 'Sakit/Nyeri' },
              { text: 'Rasa Kebas', value: 'Rasa Kebas' }
            ]
          },
          label7: {
            label: 'Motorik :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          motorik: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Hemiparese', value: 'Hemiparese' },
              { text: 'Tetraparese', value: 'Tetraparese' }
            ]
          }
        }
      },
      {
        text: 'Sistem Penglihatan/Mata',
        value: 0,
        dataCols: {
          label1: {
            label: 'Gangguan Penglihatan :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          gangguan_penglihatan: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Minus', value: 'Minus' },
              { text: 'Plus', value: 'Plus' },
              { text: 'Buta', value: 'Buta' }
            ]
          },
          label2: {
            label: 'Posisi Mata :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          posisi_mata: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Simetris', value: 'Simetris' },
              { text: 'Asimetris', value: 'Asimetris' }
            ]
          },
          label3: {
            label: 'Pupil :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          pupil: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Isokor', value: 'Isokor' },
              { text: 'Anisokor', value: 'Anisokor' }
            ]
          },
          label4: {
            label: 'Kelopak Mata :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kelopak_mata: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Edema', value: 'Edema' },
              { text: 'Cekung', value: 'Cekung' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label5: {
            label: 'Konjungtiva :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          konjungtiva: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Anemis', value: 'Anemis' },
              { text: 'Konjungtivitis', value: 'Konjungtivitis' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label6: {
            label: 'Sklera :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          sklera: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Ikterik', value: 'Ikterik' },
              { text: 'Perdarahan', value: 'Perdarahan' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label7: {
            label: 'Alat Bantu Penglihatan:',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          alat_bantu_penglihatan: {
            label: ' ',
            widget: 'wradio',
            col: 2,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya :', value: 'Ya' }
            ]
          },
          label_alat_bantu_penglihatan_ya: {
            label: 'Jika Ya, :',
            widget: 'wlabelkiri',
            data: [],
            col: 1
          },
          alat_bantu_penglihatan_ya: {
            label: ' ',
            widget: 'wradio',
            col: 7,
            data: [
              { text: 'Mata Palsu', value: 'Mata Palsu' },
              { text: 'Kacamata', value: 'Kacamata' },
              { text: 'Lensa Kontak', value: 'Lensa Kontak' }
            ]
          }
        }
      },
      {
        text: 'Sistem Pendengaran',
        value: 0,
        dataCols: {
          sistem_pendengaran: {
            label: ' ',
            widget: 'wradio',
            col: 12,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Nyeri', value: 'Nyeri' },
              { text: 'Tuli', value: 'Tuli' },
              { text: 'Keluar Cairan', value: 'Keluar Cairan' },
              { text: 'Berdengung', value: 'Berdengung' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'Menggunakan Alat Bantu Dengar :',
            widget: 'wlabelkiri',
            data: [],
            col: 3
          },
          menggunakan_alat_bantu_dengar: {
            label: ' ',
            widget: 'wradio',
            col: 9,
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ]
          }
        }
      },
      {
        text: 'Sistem Penciuman',
        value: 0,
        dataCols: {
          sistem_penciuman: {
            label: ' ',
            widget: 'wradio',
            col: 12,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Asimetris', value: 'Asimetris' },
              { text: 'Polip', value: 'Polip' },
              { text: 'Keluar Cairan', value: 'Keluar Cairan' },
              { text: 'Sinusitis', value: 'Sinusitis' },
              { text: 'Epistaksis', value: 'Epistaksis' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          }
        }
      },
      {
        text: 'Sistem Pernafasan',
        value: 0,
        dataCols: {
          label1: {
            label: 'Pola Nafas :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          pola_nafas: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Normal', value: 'Normal' },
              { text: 'Bradipnea', value: 'Bradipnea' },
              { text: 'Trakipnea', value: 'Trakipnea' },
              { text: 'Apnea', value: 'Apnea' },
              { text: 'Kusmaull', value: 'Kusmaull' }
            ]
          },
          label2: {
            label: 'Retraksi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          retraksi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya', value: 'Ya' }
            ]
          },
          label3: {
            label: 'Nafas Cuping Hidung :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          nafas_cuping_hidung: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya', value: 'Ya' }
            ]
          },
          label4: {
            label: 'Jenis Pernafasan :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          jenis_pernafasan: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Dada', value: 'Dada' },
              { text: 'Perut', value: 'Perut' },
              {
                text: 'Alat Bantu Nafas, Sebutkan :',
                value: null,
                field: 'auto'
              }
            ]
          },
          label5: {
            label: 'Irama Nafas :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          irama_nafas: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Teratur', value: 'Teratur' },
              { text: 'Tidak Teratur', value: 'Tidak Teratur' }
            ]
          },
          label6: {
            label: 'Terpasang WSD :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          terpasang_wsd: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya, Produksi :', value: null, field: 'auto' }
            ]
          },
          label7: {
            label: 'Kesulitan Bernafas :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kesulitan_bernafas: {
            label: ' ',
            widget: 'wradio',
            col: 2,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya :', value: 'Ya' }
            ]
          },
          label_kesulitan_bernafas_ya: {
            label: 'Jika Ya, :',
            widget: 'wlabelkiri',
            data: [],
            col: 1
          },
          kesulitan_bernafas_ya: {
            label: ' ',
            widget: 'wradio',
            col: 7,
            data: [
              { text: 'Dyspneu', value: 'Dyspneu' },
              { text: 'Orthopneu', value: 'Orthopneu' },
              { text: 'Lain-lain :', value: null, field: 'auto' }
            ]
          },
          label8: {
            label: 'Batuk dan Sekresi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          batuk_dan_sekresi: {
            label: ' ',
            widget: 'wradio',
            col: 2,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya :', value: 'Ya' }
            ]
          },
          label_batuk_dan_sekresi_ya: {
            label: 'Jika Ya, :',
            widget: 'wlabelkiri',
            data: [],
            col: 1
          },
          batuk_dan_sekresi_ya: {
            label: ' ',
            widget: 'wradio',
            col: 7,
            data: [
              { text: 'Produktif', value: 'Produktif' },
              { text: 'Non Produktif', value: 'Non Produktif' }
            ]
          },
          label9: {
            label: 'Warna Sputum :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          warna_sputum: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Putih', value: 'Putih' },
              { text: 'Kuning', value: 'Kuning' },
              { text: 'Hijau', value: 'Hijau' },
              { text: 'Merah', value: 'Merah' }
            ]
          },
          label10: {
            label: 'Suara Nafas :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          suara_nafas: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Vesikuler', value: 'Vesikuler' },
              { text: 'Ronchi', value: 'Ronchi' },
              { text: 'Dyspneu', value: 'Dyspneu' },
              { text: 'Wheezing', value: 'Wheezing' },
              { text: 'Rales', value: 'Rales' }
            ]
          },
          label11: {
            label: 'Perkusi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          perkusi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Sonor', value: 'Sonor' },
              { text: 'Hipersonor', value: 'Hipersonor' },
              { text: 'Redup', value: 'Redup' }
            ]
          }
        }
      }
    ]
  },
  form9: {
    widget: 'wtext',
    property: { attrs: { type: 'string' } },
    data: [
      {
        text: 'Sistem Kardiovaskuler/Jantung',
        value: 0,
        dataCols: {
          label1: {
            label: 'Warna Kulit :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          warna_kulit: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Normal', value: 'Normal' },
              { text: 'Kemerahan', value: 'Kemerahan' },
              { text: 'Sianosis', value: 'Sianosis' },
              { text: 'Lain-lain :', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'Clubbing Finger :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          clubbing_finger: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya', value: 'Ya' }
            ]
          },
          label3: {
            label: 'Nyeri Dada :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          nyeri_dada: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya, bagian : ', value: null, field: 'auto' }
            ]
          },
          label4: {
            label: 'Denyut Nadi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          denyut_nadi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Teratur', value: 'Teratur' },
              { text: 'Tidak Teratur', value: 'Tidak Teratur' }
            ]
          },
          label5: {
            label: 'Bunyi Jantung :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          bunyi_jantung: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Normal', value: 'Normal' },
              { text: 'Murmur', value: 'Murmur' },
              { text: 'Gallop', value: 'Gallop' }
            ]
          },
          label6: {
            label: 'Sirkulasi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          sirkulasi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Akral Hangat', value: 'Akral Hangat' },
              { text: 'Akral Dingin', value: 'Akral Dingin' },
              { text: 'Rasa Kebas', value: 'Rasa Kebas' },
              { text: 'Palpitasi', value: 'Palpitasi' },
              { text: 'Edema : ', value: null, field: 'auto' }
            ]
          },
          label7: {
            label: 'Pulsasi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          pulsasi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Kuat', value: 'Kuat' },
              { text: 'Lemah', value: 'Lemah' },
              { text: 'Lain-lain :', value: null, field: 'auto' }
            ]
          },
          label8: {
            label: 'CRT :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          crt: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: '<2 detik', value: '<2 detik' },
              { text: '>2 detik', value: '>2 detik' }
            ]
          }
        }
      },
      {
        text: 'Sistem Pencernaan',
        value: 0,
        dataCols: {
          label1: {
            label: 'Mulut :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          mulut: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Stomatitis', value: 'Stomatitis' },
              { text: 'Mukosa Kering', value: 'Mukosa Kering' },
              { text: 'Kelainan Konginetal', value: 'Kelainan Konginetal' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'Gigi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          gigi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Karies', value: 'Karies' },
              { text: 'Goyang', value: 'Goyang' },
              { text: 'Tambal', value: 'Tambal' },
              { text: 'Gigi Palsu', value: 'Gigi Palsu' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label3: {
            label: 'Lidah :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          lidah: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Bersih', value: 'Bersih' },
              { text: 'Kotor', value: 'Kotor' },
              { text: 'Mukosa Kering', value: 'Mukosa Kering' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label4: {
            label: 'Tenggorokan :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          tenggorokan: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Tidak ada BU', value: 'Tidak ada BU' },
              { text: 'Hiperperistaltik', value: 'Hiperperistaltik' }
            ]
          },
          label5: {
            label: 'Abdomen :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          abdomen: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Atresia Ani', value: 'Atresia Ani' },
              { text: 'Haemoroid', value: 'Haemoroid' },
              { text: 'Hepatomegali', value: 'Hepatomegali' },
              { text: 'Splenomegali', value: 'Splenomegali' }
            ]
          },
          label6: {
            label: 'Nyeri Tekan/Lepas :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          nyeri_tekan_lepas: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya, Lokasi :', value: null, field: 'auto' }
            ]
          },
          label7: {
            label: 'Ada Benjolan/Massa :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          ada_benjolan_massa: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya, Lokasi :', value: null, field: 'auto' }
            ]
          },
          label8: {
            label: 'Peristaltik Usus :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          peristaltik_usus: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Tidak ada BU', value: 'Tidak ada BU' },
              { text: 'Hiperperistaltik', value: 'Hiperperistaltik' },
              { text: 'Lain-lain :', value: null, field: 'auto' }
            ]
          },
          label9: {
            label: 'Anus :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          anus: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Atresia Ani', value: 'Atresia Ani' },
              { text: 'Haemoroid', value: 'Haemoroid' },
              { text: 'Fistula Ani', value: 'Fistula Ani' },
              { text: 'Lain-lain :', value: null, field: 'auto' }
            ]
          },
          label10: {
            label: 'BAB :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          bab: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Konstipasi', value: 'Konstipasi' },
              { text: 'Melena', value: 'Melena' },
              { text: 'Inkontinensia Alvi', value: 'Inkontinensia Alvi' },
              { text: 'Lain-lain :', value: null, field: 'auto' }
            ]
          },
          label11: {
            label: 'Diare :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          diare: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya, Frekuensi :', value: null, field: 'auto' }
            ],
            property: { attrsInput: { suffix: 'x/hari', type: 'string' } }
          }
        }
      },
      {
        text: 'Sistem Genitourinaria',
        value: 0,
        dataCols: {
          label1: {
            label: 'Kebersihan :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kebersihan: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Bersih', value: 'Bersih' },
              { text: 'Kotor', value: 'Kotor' },
              { text: 'Bau', value: 'Bau' },
              { text: 'Lain-lain :', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'Kelainan :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kelainan: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Hipospadia', value: 'Hipospadia' },
              { text: 'Hernia', value: 'Hernia' },
              { text: 'Hidrokel', value: 'Hidrokel' },
              { text: 'Ambigus', value: 'Ambigus' },
              { text: 'Phimosis', value: 'Phimosis' },
              { text: 'Lain-lain :', value: null, field: 'auto' }
            ]
          },
          label3: {
            label: 'BAK :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          bak: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Anuria', value: 'Anuria' },
              { text: 'Disuria', value: 'Disuria' },
              { text: 'Poliuria', value: 'Poliuria' },
              { text: 'Retensio Urin', value: 'Retensio Urin' },
              { text: 'Inkontinensia Urin', value: 'Inkontinensia Urin' },
              { text: 'Hematuria', value: 'Hematuria' }
            ]
          },
          label4: {
            label: 'Palpasi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          palpasi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Ada Kelainan, yaitu :', value: null, field: 'auto' }
            ]
          },
          label5: {
            label: 'Perkusi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          perkusi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Nyeri Ketok, Lokasi :', value: null, field: 'auto' }
            ]
          }
        }
      },
      {
        text: 'Sistem Reproduksi',
        value: 0,
        dataCols: {
          labela: {
            label: 'WANITA',
            widget: 'wlabelkiri',
            data: [],
            col: 12
          },
          label1: {
            label: 'Menarche Umur :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          menarche_umur: {
            label: ' ',
            widget: 'wtext',
            data: [],
            col: 3,
            property: { attrs: { suffix: 'tahun', type: 'string' } }
          },
          label2: {
            label: 'Siklus Haid :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          siklus_haid: {
            label: ' ',
            widget: 'wtext',
            data: [],
            col: 3,
            property: { attrs: { suffix: 'hari', type: 'string' } }
          },
          label3: {
            label: 'Lama Haid :',
            widget: 'wlabelkiri',
            data: [],
            col: 3
          },
          lama_haid: {
            label: ' ',
            widget: 'wtext',
            data: [],
            col: 3,
            property: { attrs: { suffix: 'hari', type: 'string' } }
          },
          label4: {
            label: 'HPHT :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          hpht: {
            label: ' ',
            widget: 'wtext',
            data: [],
            col: 4
          },
          label5: {
            label: 'Gangguan Haid :',
            widget: 'wlabelkiri',
            data: [],
            col: 3
          },
          kebersihan: {
            label: ' ',
            widget: 'wradio',
            col: 9,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Dismenorhea', value: 'Dismenorhea' },
              { text: 'Menometroragi', value: 'Menometroragi' },
              { text: 'Amenorhea', value: 'Amenorhea' },
              { text: 'Lain-lain :', value: null, field: 'auto' }
            ]
          },
          label6: {
            label: 'Penggunaan Alat Kontrasepsi :',
            widget: 'wlabelkiri',
            data: [],
            col: 3
          },
          penggunaan_alat_kontrasepsi: {
            label: ' ',
            widget: 'wradio',
            col: 9,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya, Sebutkan :', value: null, field: 'auto' }
            ]
          },
          label7: {
            label: 'Payudara :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          payudara: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Benjolan', value: 'Benjolan' },
              {
                text: 'Tampak seperti Kulit Jeruk',
                value: 'Tampak seperti Kulit Jeruk'
              },
              { text: 'Lain-lain :', value: null, field: 'auto' }
            ]
          },
          label8: {
            label: 'Puting Susu :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          puting_susu: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Menonjol', value: 'Menonjol' },
              { text: 'Lecet', value: 'Lecet' },
              { text: 'Masuk ke Dalam', value: 'Masuk ke Dalam' },
              { text: 'Keluar ASI/Tidak', value: 'Keluar ASI/Tidak' },
              { text: 'Keluar Darah/Cairan', value: 'Keluar Darah/Cairan' }
            ]
          },
          label9: {
            label: 'Tanda-Tanda Mastitis :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          tanda_tanda_mastitis: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Bengkak', value: 'Bengkak' },
              { text: 'Nyeri', value: 'Nyeri' },
              { text: 'Kemerahan', value: 'Kemerahan' },
              { text: 'Tidak ada', value: 'Tidak ada' }
            ]
          },
          label10: {
            label: 'Uterus :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          uterus: {
            label: 'TFU',
            widget: 'wtext',
            data: [],
            col: 4
          },
          label11: {
            label: 'Kontraksi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kontraksi: {
            label: ' ',
            widget: 'wradio',
            col: 4,
            data: [
              { text: 'Keras', value: 'Keras' },
              { text: 'Lembek', value: 'Lembek' }
            ]
          },
          labelb: {
            label: 'LAKI-LAKI',
            widget: 'wlabelkiri',
            data: [],
            col: 12
          },
          label12: {
            label: 'Sirkumsisi',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          sirkumsisi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya', value: 'Ya' }
            ]
          },
          label13: {
            label: 'Gangguan Prostat',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          gangguan_prostat: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ya', value: 'Ya' },
              { text: 'Lain-lain', value: 'Lain-lain' }
            ]
          }
        }
      },
      {
        text: 'Sistem Integumen',
        value: 0,
        dataCols: {
          label1: {
            label: 'Turgor :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          turgor: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Baik', value: 'Baik' },
              { text: 'Sedang', value: 'Sedang' },
              { text: 'Buruk', value: 'Buruk' }
            ]
          },
          label2: {
            label: 'Warna :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          warna: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Ikterik', value: 'Ikterik' },
              { text: 'Pucat', value: 'Pucat' }
            ]
          },
          label3: {
            label: 'Integritas :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          integritas: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Utuh', value: 'Utuh' },
              { text: 'Dekubitus', value: 'Dekubitus' },
              { text: 'Rash/Ruam', value: 'Rash/Ruam' },
              { text: 'Ptekie', value: 'Ptekie' }
            ]
          }
        }
      },
      {
        text: 'Sistem Muskuloskeletal',
        value: 0,
        dataCols: {
          label1: {
            label: 'Pergerakan Sendi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          pergerakan_sendi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Bebas', value: 'Bebas' },
              { text: 'Terbatas', value: 'Terbatas' }
            ]
          },
          label2: {
            label: 'Kekuatan Otot :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kekuatan_otot: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Baik', value: 'Baik' },
              { text: 'Lemah', value: 'Lemah' },
              { text: 'Tremor', value: 'Tremor' }
            ]
          },
          label3: {
            label: 'Nyeri Sendi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          nyeri_sendi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak Ada', value: 'Tidak Ada' },
              { text: 'Ada, Lokasi :', value: null, field: 'auto' }
            ]
          },
          label4: {
            label: 'Oedema :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          oedema: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak Ada', value: 'Tidak Ada' },
              { text: 'Ada, Lokasi :', value: null, field: 'auto' }
            ]
          },
          label5: {
            label: 'Fraktur :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          fraktur: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak Ada', value: 'Tidak Ada' },
              { text: 'Ada, Lokasi :', value: null, field: 'auto' }
            ]
          },
          label6: {
            label: 'Parese :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          parese: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak Ada', value: 'Tidak Ada' },
              { text: 'Ada, Lokasi :', value: null, field: 'auto' }
            ]
          },
          label7: {
            label: 'Postur Tubuh :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          postur_tubuh: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Normal', value: 'Normal' },
              { text: 'Skoliosis', value: 'Skoliosis' },
              { text: 'Lordosis', value: 'Lordosis' },
              { text: 'Kyphosis', value: 'Kyphosis' }
            ]
          }
        }
      },
      {
        text: 'Sistem Muskuloskeletal',
        value: 0,
        dataCols: {
          label1: {
            label: 'Mata :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          mata: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Exophtalmus', value: 'Exophtalmus' },
              { text: 'Endophtalmus', value: 'Endophtalmus' }
            ]
          },
          label2: {
            label: 'Leher :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          leher: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              {
                text: 'Pembesaran Kelenjer Tiroid',
                value: 'Pembesaran Kelenjer Tiroid'
              }
            ]
          },
          label3: {
            label: 'Ekstremitas :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          ekstremitas: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Tremor', value: 'Tremor' },
              { text: 'Berkeringat', value: 'Berkeringat' }
            ]
          }
        }
      }
    ]
  },
  form10: {
    baris1: {
      nama_obat: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      },
      dosis: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      },
      terakhir_kali: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      }
    },
    baris2: {
      nama_obat: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      },
      dosis: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      },
      terakhir_kali: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      }
    },
    baris3: {
      nama_obat: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      },
      dosis: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      },
      terakhir_kali: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      }
    },
    baris4: {
      nama_obat: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      },
      dosis: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      },
      terakhir_kali: {
        label: ' ',
        widget: 'wtext',
        data: [],
        col: 12
      }
    }
  },
  form11: {
    kondisi_fisik_umum: [
      {
        label: 'Baik',
        value: 4
      },
      {
        label: 'Cukup/Lumayan',
        value: 3
      },
      {
        label: 'Buruk',
        value: 2
      },
      {
        label: 'Sangat Buruk',
        value: 1
      }
    ],
    kesadaraan: [
      {
        label: 'Kompos Mentis',
        value: 4
      },
      {
        label: 'Apatis',
        value: 3
      },
      {
        label: 'Confused',
        value: 2
      },
      {
        label: 'Stupor',
        value: 1
      }
    ],
    tingkat_aktivitas: [
      {
        label: 'Ambulatori',
        value: 4
      },
      {
        label: 'Berjalan Dengan Bantuan',
        value: 3
      },
      {
        label: 'Hanya Bisa Duduk',
        value: 2
      },
      {
        label: 'Hanya Bisa Tiduran',
        value: 1
      }
    ],
    mobilitas: [
      {
        label: 'Bergerak Bebas',
        value: 4
      },
      {
        label: 'Sedikit Terbatas',
        value: 3
      },
      {
        label: 'Sangat Terbatas',
        value: 2
      },
      {
        label: 'Tidak Bisa Bergerak/Imobil',
        value: 1
      }
    ],
    inkontinensia: [
      {
        label: 'Tidak Ada',
        value: 4
      },
      {
        label: 'Kadang-Kadang',
        value: 3
      },
      {
        label: 'Sering Inkontinensia Urin',
        value: 2
      },
      {
        label: 'Inkontinensia Urin Dan Alvi',
        value: 1
      }
    ]
  },
  form12: {
    label1: {
      label: '1. Penglihatan Kontak',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    penglihatan_kontak: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Kabur', value: 'Kabur' },
        { text: 'Kacamata', value: 'Kacamata' },
        { text: 'Lensa', value: 'Lensa' }
      ]
    },
    label2: {
      label: '2. Penciuman',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    penciuman: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Tidak', value: 'Tampak' }
      ]
    },
    label3: {
      label: '3. Pendengaran',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    pendengaran: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Tuli Kanan/Kiri', value: 'Tuli Kanan/Kiri' },
        {
          text: 'Alat Bantu Dengar Kanan/Kiri',
          value: 'Alat Bantu Dengar Kanan/Kiri'
        }
      ]
    }
  },
  form13: {
    kognitif: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Orientasi Penuh', value: 'Orientasi Penuh' },
        { text: 'Pelupa', value: 'Pelupa' },
        { text: 'Bingung', value: 'Bingung' },
        { text: 'Tidak dapat Dimengerti', value: 'Tidak dapat Dimengerti' }
      ]
    }
  },
  form14: {
    label1: {
      label: '1. Aktivitas Sehari-Hari',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    aktivitas_sehari_hari: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Mandiri', value: 'Mandiri' },
        { text: 'Bantuan Minimal', value: 'Bantuan Minimal' },
        { text: 'Bantuan Sebagian', value: 'Bantuan Sebagian' },
        { text: 'Ketergantungan Total', value: 'Ketergantungan Total' }
      ]
    },
    label2: {
      label: '2. Berjalan',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    berjalan: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak Ada Kesulitan', value: 'Tidak Ada Kesulitan' },
        { text: 'Perlu Bantuan', value: 'Perlu Bantuan' },
        { text: 'Sering', value: 'Sering' },
        { text: 'Jatuh', value: 'Jatuh' }
      ]
    },
    label3: {
      label: '3. Riwayat Patah Tulang',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_patah_tulang: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    label4: {
      label: '4. Alat Ambulatory',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    alat_ambulatory: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Walke', value: 'Walke' },
        { text: 'Tongkat', value: 'Tongkat' },
        { text: 'Kursi Roda', value: 'Kursi Roda' }
      ]
    },
    label5: {
      label: '5. Ekstremitas Atas',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    ekstremitas_atas: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'TAK', value: 'TAK' },
        { text: 'Lemah', value: 'Lemah' },
        { text: 'Lain-lain :', value: null, field: 'auto' }
      ]
    },
    label6: {
      label: '6. Ekstremitas Bawah',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    ekstremitas_bawah: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'TAK', value: 'TAK' },
        { text: 'Varises', value: 'Varises' },
        { text: 'Edema', value: 'Edema' },
        { text: 'Tidak Simetris', value: 'Tidak Simetris' },
        { text: 'Lain-lain :', value: null, field: 'auto' }
      ]
    },
    label7: {
      label: '7. Kemampuan Menggenggam',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    kemampuan_menggenggam: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'TAK', value: 'TAK' },
        { text: 'Ada, Sejak :', value: null, field: 'auto' },
        { text: 'Lain-lain :', value: null, field: 'auto' }
      ]
    },
    label8: {
      label: '8. Kemampuan Koordinasi',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    kemampuan_koordinasi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'TAK', value: 'TAK' },
        { text: 'Ada, Masalah', value: 'Ada, Masalah' }
      ]
    }
  },
  form15: {
    bab: [
      {
        label: 'Tak terkendali/tak teratur (perlu pencahar)',
        value: 0
      },
      {
        label: 'Kadang-kadang tak terkendali',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    bak: [
      {
        label: 'Tak terkendali/pakai kateter',
        value: 0
      },
      {
        label: 'Kadang-kadang tak terkendali (1x24 jam)',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    membersihkan_diri: [
      {
        label: 'Butuh pertolongan orang lain',
        value: 0
      },
      {
        label: 'Mandiri',
        value: 1
      }
    ],
    penggunaan_jamban: [
      {
        label: 'Tergantung pertolongan orang lain',
        value: 0
      },
      {
        label:
          'Perlu pertolongan pada beberapa kegiatan,\n' +
          'tetapi dapat mengerjakan sendiri kegiatan\n' +
          'yang lain',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    makan: [
      {
        label: 'Tidak mampu',
        value: 0
      },
      {
        label: 'Perlu ditolong memotong makanan',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    berubah_sikap: [
      {
        label: 'Tidak mampu',
        value: 0
      },
      {
        label: 'Perlu banyak bantuan untuk bisa duduk (2\n' + 'orang)',
        value: 1
      },
      {
        label: 'Bantuan (2 orang)',
        value: 2
      },
      {
        label: 'Mandiri',
        value: 3
      }
    ],
    berpindah: [
      {
        label: 'Tidak mampu',
        value: 0
      },
      {
        label: 'Bisa (pindah) dengan kursi roda',
        value: 1
      },
      {
        label: 'Berjalan dengan bantuan 1 orang',
        value: 2
      },
      {
        label: 'Mandiri',
        value: 3
      }
    ],
    memakai_baju: [
      {
        label: 'Tergantung orang lain',
        value: 0
      },
      {
        label: 'Sebagian dibantu (misalnya: mengancing\n' + 'baju)',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    naik_turun_tangga: [
      {
        label: 'Tidak mampu',
        value: 0
      },
      {
        label: 'Butuh pertolongan',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    mandi: [
      {
        label: 'Tergantung orang lain',
        value: 0
      },
      {
        label: 'Mandiri',
        value: 1
      }
    ]
  },
  form16: {
    label1: {
      label: '1. Kondisi yang Saat Ini Merupakan Resiko Tinggi :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    kondisi_resiko_tinggi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    label2: {
      label: '2. Pengkajian Restain',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    pengkajian_restrain: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        {
          text: 'Tidak Ada Masalah yang Teridentifikasi',
          value: 'Tidak Ada Masalah yang Teridentifikasi'
        },
        {
          text: 'Kondisi Saat Ini yang Merupakan',
          value: 'Kondisi Saat Ini yang Merupakan'
        },
        {
          text: 'Strategi Pelepasan Restrain Terdahulu',
          value: 'Strategi Pelepasan Restrain Terdahulu'
        },
        {
          text: 'Diskusi dengan Keluarga dan Pasien Mengenai Kebijakan Penggunaan Restrain',
          value:
            'Diskusi dengan Keluarga dan Pasien Mengenai Kebijakan Penggunaan Restrain'
        }
      ]
    },
    labelx: {
      label:
        '(Jika Terdapat Alasan Penggunaan Restrain Lihat Form Pengkajian Khusus Restrain)',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label3: {
      label:
        '3. Diskusi dengan Keluarga dan Pasien Mengenai Kebijakan Penggunaan Restrain',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    diskusi_dengan_keluarga: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    }
  },
  form17: {
    riwayat_jatuh: {
      no: 1,
      label: 'Riwayat Jatuh Di Rs < 3 Bulan',
      data: [
        {
          label: 'Ya (25)',
          value: 25
        },
        null,
        {
          label: 'Tidak (0)',
          value: 0
        }
      ]
    },
    diagnosa_sekunder: {
      no: 2,
      label: 'Diagnosa Sekunder (> 2 Diagnosa Medis) Ya',
      data: [
        {
          label: 'Ya (25)',
          value: 25
        },
        null,
        {
          label: 'Tidak (0)',
          value: 0
        }
      ]
    },
    alat_bantu_berjalan: {
      no: 3,
      label: 'Alat Bantu Berjalan :',
      data: [
        {
          label: 'Tongkat/Walker (15)',
          value: 15
        },
        {
          label: 'Mencengkram Furnitur (30)',
          value: 30
        },
        {
          label: 'Tidak (0)',
          value: 0
        }
      ]
    },
    akses_infus: {
      no: 4,
      label: 'IV Akses/Terpasang In fus',
      data: [
        {
          label: 'Ya (20)',
          value: 20
        },
        null,
        {
          label: 'Tidak (0)',
          value: 0
        }
      ]
    },
    cara_berjalan: {
      no: 5,
      label:
        'Cara Berjalan\n' +
        '(Misal : Fraktur, Cedera Extremitas Bawah,\n' +
        'Dm, Hypertensi, Vertigo, Stroke, Dll)',
      data: [
        {
          label: 'Normal (0)',
          value: 0
        },
        {
          label: 'Kelelahan/ Lemah (10)',
          value: 10
        },
        {
          label: 'Keterbatasan/Terganggu (20)',
          value: 20
        }
      ]
    },
    ada_gangguan_sensorik: {
      no: 6,
      label:
        'Apakah Ada Gangguan Sensorik\n' +
        '( Misal Lupa, Buta Gangguan Penglihatan,\n' +
        'Tuli Yang Belum Terkoneksi )',
      data: [
        {
          label: 'Ya (15)',
          value: 15
        },
        null,
        {
          label: 'Tidak (0)',
          value: 0
        }
      ]
    }
  },
  form18: {
    label1: {
      label: '2. Keamanan : Pasang Pengaman Tempat Tidur/Bed Ralls',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    keamanan: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' },
        { text: 'Bel Mudah Dijangkau', value: 'Bel Mudah Dijangkau' }
      ]
    },
    label3: {
      label: '3. Resiko Melarikan Diri :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    resiko_melarikan_diri: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        {
          text: 'Tidak Ada Masalah Teridentifikasi',
          value: 'Tidak Ada Masalah Teridentifikasi'
        },
        { text: 'Resiko Karena', value: 'Resiko Karena' },
        { text: 'Dementia', value: 'Dementia' },
        {
          text: 'Tinggal Dilingkungan Rumah Sakit',
          value: 'Tinggal Dilingkungan Rumah Sakit'
        },
        { text: 'Bingung', value: 'Bingung' },
        { text: 'Gangguan Status Mental', value: 'Gangguan Status Mental' },
        {
          text: 'Menolak Tinggal Dirumah Sakit',
          value: 'Menolak Tinggal Dirumah Sakit'
        },
        {
          text: 'Tinggal Dilingkungan yang Diawasi',
          value: 'Tinggal Dilingkungan yang Diawasi'
        }
      ]
    }
  },
  form19: {
    label1: {
      label: '1. Frekuensi Nyeri *)',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    frekuensi_nyeri: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Jarang Hilang', value: 'Jarang Hilang' },
        { text: 'Timbul Terus Menerus', value: 'Timbul Terus Menerus' }
      ]
    },
    label2: {
      label: '2. Lama Nyeri :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    lama_nyeri: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 8
    },
    label3: {
      label: '3. Menjalar :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    menjalar: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Ke', value: null, field: 'auto' }
      ]
    },
    label4: {
      label: '4. Kualitas Nyeri',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    kualitas_nyeri: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Nyeri Tumpul', value: 'Nyeri Tumpul' },
        { text: 'Nyeri Tajam', value: 'Nyeri Tajam' },
        { text: 'Panas Terbakar', value: 'Panas Terbakar' }
      ]
    },
    label5: {
      label: '5. Faktor Pemicu/Memperberat :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    faktor_pemicu: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 8
    },
    label6: {
      label: '6. Lokasi Nyeri ***) :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    lokasi_nyeri: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 8
    },
    label7: {
      label: '7. Tindak Lanjut *) :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    tindak_lanjut: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Edukasi', value: 'Edukasi' },
        { text: 'Intervensi', value: 'Intervensi' },
        { text: 'Konsul Ke Dokter', value: null, field: 'auto' }
      ]
    },
    label8: {
      label: 'Nyeri Mempengaruhi :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    nyeri_mempengaruhi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidur', value: 'Tidur' },
        { text: 'Aktivitas Fisik', value: 'Aktivitas Fisik' },
        { text: 'Emosi', value: 'Emosi' },
        { text: 'Nafsu Makan', value: 'Nafsu Makan' }
      ]
    }
  },
  form20: {
    label1: {
      label: 'Intake Nutrisi Lewat',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    intake_nutrisi_lewat: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Oral', value: 'Oral' },
        { text: 'NGT', value: 'NGT' },
        { text: 'TPN/PPN', value: 'TPN/PPN' },
        { text: 'Gastrotomy', value: 'Gastrotomy' }
      ]
    }
  },
  form20b: {
    masalah_nutrisi1: {
      label: 'Masalah Yang Berhubungan Dengan Masalah Nutrisi :',
      widget: 'wcheckbox',
      col: 4,
      data: [
        {
          text: 'Mendapat Kemotherapi',
          value: 'Mendapat Kemotherapi'
        },
        {
          text: 'Pasien Operasi Usia > 65 Tahun',
          value: 'Pasien Operasi Usia > 65 Tahun'
        },
        {
          text: 'Malnutrisi',
          value: 'Malnutrisi'
        },
        {
          text: 'Disfagia',
          value: 'Disfagia'
        },
        {
          text: 'Diet Saat Ini',
          value: 'Diet Saat Ini'
        }
      ]
    },
    masalah_nutrisi2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        {
          text: 'Hamil/Menyusui',
          value: 'Hamil/Menyusui'
        },
        {
          text: 'Nausea',
          value: 'Nausea'
        },
        {
          text: 'Obesitas',
          value: 'Obesitas'
        },
        {
          text: 'BB Menurun/Meninggal Dalam 1 bulan Terakhir',
          value: 'BB Menurun/Meninggal Dalam 1 bulan Terakhir'
        },
        {
          text: 'Makanan Kesukaan',
          value: 'Makanan Kesukaan'
        }
      ]
    },
    masalah_nutrisi3: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        {
          text: 'Vomitus',
          value: 'Vomitus'
        },
        {
          text: 'Sulit Menelan',
          value: 'Sulit Menelan'
        }
      ]
    }
  },
  form21: {
    title: 'Dewasa (Berdasarkan MST/ Malnutrition Screening Tools)',
    data: [
      {
        title: 'Apakah Ada Penurunan Berat Badan Yang Tidak Direncanakan?',
        no: 1,
        data: [
          {
            title: 'a. Tidak',
            value: 0
          },
          {
            title: 'b. Tidak Yakin (Ada Tanda Baju Menjadi Lebih Longgar)',
            value: 2
          },
          {
            title: 'c. Jika Ya, Penurunan BB Sebanyak',
            value: null
          },
          {
            title: '1-5 Kg',
            value: 1
          },
          {
            title: '6-10 Kg',
            value: 2
          },
          {
            title: '11-15 Kg',
            value: 3
          },
          {
            title: '>15 Kg',
            value: 4
          },
          {
            title: 'Tidak Tahu Berapa Kg Turunannya',
            value: 2
          }
        ]
      },
      {
        title: 'Apakah Ada Penurunan Nafsu Makan?',
        no: 2,
        data: [
          {
            title: 'a. Tidak',
            value: 0
          },
          {
            title: 'a. Ya',
            value: 1
          }
        ]
      }
    ]
  },
  form22: {
    labelx: {
      label: '1. Pola Istirahat Dan Tidur',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    pola_istirahat_dan_tidur: {
      label: ' ',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Tidak Ada Kelainan', value: 'Tidak Ada Kelainan' },
        { text: 'Gelisah', value: 'Gelisah' },
        { text: 'Sukar Tidur', value: 'Sukar Tidur' },
        { text: 'Tidur Sering Terbangun', value: 'Tidur Sering Terbangun' }
      ]
    },
    label1: {
      label: 'Lama Tidur : ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    lama_tidur: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: []
    },
    label2: {
      label: 'Tidur Siang : ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    tidur_siang: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'Jam', type: 'string' } }
    },
    label3: {
      label: 'Tidur Malam : ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    tidur_malam: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'Jam', type: 'string' } }
    },
    labelx2: {
      label: 'Hal Hal Yang Dapat Membantu Cepat Tidur ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    hal_cepat_tidur: {
      label: ' ',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Minum Susu / Teh', value: 'Minum Susu / Teh' },
        { text: 'Membaca', value: 'Membaca' },
        { text: 'Lampu Dimatikan', value: 'Lampu Dimatikan' },
        { text: 'Mendengarkan Musik', value: 'Mendengarkan Musik' },
        { text: 'Lain Lainnya', value: 'Lain Lainnya' }
      ]
    },
    labelx3: {
      label: '1. Makan Dan Minum',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    labelx4: {
      label: 'Makan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    makan: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'X/Hari', type: 'string' } }
    },
    labelx5: {
      label: 'Jenis',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    jenis_makan: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: []
    },
    labelx6: {
      label: 'Pantangan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    pantangan: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: []
    },
    labelx7: {
      label: 'Diet Khusus',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    diet_khusus: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    labelx8: {
      label: 'Minum',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    minum: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'X/Hari', type: 'string' } }
    },
    labelx9: {
      label: 'Jenis',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    jenis_minum: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: []
    },
    labelx10: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    labelx11: {
      label: '3. Kebersihan Diri :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    labelx12: {
      label: 'Mandi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    mandi: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'X/Hari', type: 'string' } }
    },
    labelx13: {
      label: 'Sekat Gigi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    sekat_gigi: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'X/Hari', type: 'string' } }
    },
    labelx14: {
      label: 'Keramas',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    keramas: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'X/Hari', type: 'string' } }
    },
    labelx15: {
      label: 'Olah Raga',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    olah_raga: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    }
  },
  form23: {
    label1: {
      label: '1. Bicara',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bicara: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Normal', value: 'Normal' },
        {
          text: 'Serangan Awal Gangguan Bicara, Kapan',
          value: null,
          field: 'auto'
        }
      ]
    },
    label2: {
      label: '2. Bahasa Sehari-Hari',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bahasa_sehari_hari: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Indonesia, Aktif/Pasif', value: 'Indonesia, Aktif/Pasif' },
        { text: 'Inggris, Aktif/Pasif', value: 'Inggris, Aktif/Pasif' },
        { text: 'Daerah, Jelaskan', value: null, field: 'auto' },
        { text: 'Lain-Lain, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: '3. Perlu Penerjemah',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    perlu_penerjemah: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Bahasa', value: null, field: 'auto' },
        { text: 'Bahasa Isyarat', value: 'Bahasa Isyarat' }
      ]
    },
    label4: {
      label: '4. Hambatan Belajar',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    hambatan_belajar: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Bahasa', value: 'Bahasa' },
        { text: 'Cemas', value: 'Cemas' },
        { text: 'Menulis', value: 'Menulis' }
      ]
    },
    label5: {
      label: '5. Cara Belajar Yang Disukai',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    cara_belajar: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Audio-Visual/Gambar', value: 'Audio-Visual/Gambar' },
        { text: 'Diskusi', value: 'Diskusi' }
      ]
    },
    label6: {
      label: '6. Tingkat Pendidikan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pendidikan_pasien: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'TK', value: 'TK' },
        { text: 'SD', value: 'SD' },
        { text: 'SMP', value: 'SMP' },
        { text: 'SMA', value: 'SMA' },
        { text: 'Akademi', value: 'Akademi' },
        { text: 'Sarjana', value: 'Sarjana' }
      ]
    },
    label7: {
      label: '7. Potensi Kebutuhan Pelajaran',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    potensi_kebutuhan: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Proses Penyakit', value: 'Proses Penyakit' },
        { text: 'Pengobatan/Tindakan', value: 'Pengobatan/Tindakan' },
        { text: 'Terapi', value: 'Terapi' },
        { text: 'Nutrisi', value: 'Nutrisi' }
      ]
    },
    label8: {
      label: '8. Pasien/Keluarga Menginginkan Informasi Tentang',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    menginginkan_informasi: {
      label: ' ',
      widget: 'wcheckbox',
      col: 5,
      data: [
        { text: 'Penyakit Yang Diderita', value: 'Penyakit Yang Diderita' },
        {
          text: 'Tindakan/Pengobatan Dan Perawatan Yang Diberikan',
          value: 'Tindakan/Pengobatan Dan Perawatan Yang Diberikan'
        },
        {
          text: 'Perubahann Aktifitas Sehari-Hari',
          value: 'Perubahann Aktifitas Sehari-Hari'
        }
      ]
    },
    menginginkan_informasi2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        {
          text: 'Tindakan Pemeriksaan Lanjut',
          value: 'Tindakan Pemeriksaan Lanjut'
        },
        {
          text: 'Perawatan Setelah Dirumah',
          value: 'Perawatan Setelah Dirumah'
        },
        {
          text: 'Perencanaan Diet Dan Menu',
          value: 'Perencanaan Diet Dan Menu'
        }
      ]
    }
  },
  form24: {
    labelx: {
      label: 'Pengetahuan Pasien Dan Keluarga Tentang',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label1: {
      label: '1. Penyakit Yang Diderita',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    penyakit_yang_diderita: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label2: {
      label: '2. Tindakan Pengobatan Dan Perawatan Yang Diberikan',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    tindakan_pengobatan: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label3: {
      label: '3. Perencanaan Diet dan Menu',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    perencanaan_diet: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label4: {
      label: '4. Perubahan Aktifitas Sehari Hari',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    perubahan_aktifitas: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label5: {
      label: '5. Perawatan Setelah Dirumah',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    perawatan_setelah_dirumah: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    }
  },
  form25: {
    respon_psikologis: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        {
          text: 'Takut Terhadap Terapi/Pembedahan/Lingkungan RS',
          value: 'Takut Terhadap Terapi/Pembedahan/Lingkungan RS'
        },
        { text: 'Marah/Tegang', value: 'Marah/Tegang' },
        { text: 'Sedih', value: 'Sedih' },
        { text: 'Gelisah', value: 'Gelisah' },
        { text: 'Menangis', value: 'Menangis' }
      ]
    },
    respon_psikologis2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        { text: 'Mudah Tersinggung', value: 'Mudah Tersinggung' },
        { text: 'Senang', value: 'Senang' },
        { text: 'Cemas', value: 'Cemas' },
        { text: 'Rendah', value: 'Rendah' },
        { text: 'Tidak Mampu Menahan Diri', value: 'Tidak Mampu Menahan Diri' }
      ]
    }
  },
  form26: {
    label1: {
      label: 'Laboratorium',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    laboratorium: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ada,', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label2: {
      label: 'Radiologi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    radiologi: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ada,', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label3: {
      label: 'Lain-lain',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    lain_lain: {
      label: ' ',
      widget: 'wtext',
      col: 9,
      data: []
    }
  },
  form27: {
    label1: {
      label: 'Diet dan Nutrisi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    diet_dan_nutrisi: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label2: {
      label: 'Rehabilitasi Medik',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    rehabilitasi_medik: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: 'Farmasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    farmasi: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label4: {
      label: 'Perawatan Luka',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    perawatan_luka: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label5: {
      label: 'Manajemen Nyeri',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    manajemen_nyeri: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label6: {
      label: 'Lain - lain',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    lain_lain: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    }
  },
  form28: {
    label1: {
      label: 'Pasien dan Keluarga Direncanakan Pulang',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    direncanakan_pulang: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    label2: {
      label: 'Lama Perawatan Rata-Rata',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    lama_perawatan: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'Hari', type: 'string' } }
    },
    label3: {
      label: 'Tanggal Rencana Pulang',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tanggal_rencana_pulang: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: []
    },
    labelx: {
      label: 'Kriteria Discharge Planning :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label4: {
      label: '1. Umur ≥ 65 Tahun',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    umur_65: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label5: {
      label: '2. Keterbatasan Mobilitas',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    keterbatasan_mobilitas: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label6: {
      label: '3. Perawatan/Pengobatan Lanjutan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    lain_lain: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label7: {
      label: '4. Bantuan untuk Melakukan Aktivitas Sehari-Hari',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bantuan_untuk_melakukan_aktifitas_sehari_sehari: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    labelx2: {
      label:
        'Bila Salah Satu Jawaban "Ya" Dari Kriteria Pulang diatas, Maka Akan Dilanjutkan dengan Perencanaan Pulang sbb:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    perencanaan_pulang_sbb: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        {
          text: 'Perawatan Diri ( Mandiri, BAK, BAB)',
          value: 'Perawatan Diri ( Mandiri, BAK, BAB)'
        },
        {
          text: 'Pemantauan Pemberian Obat',
          value: 'Pemantauan Pemberian Obat'
        },
        { text: 'Pemantauan Diet', value: 'Pemantauan Diet' },
        { text: 'Perawatan Luka', value: 'Perawatan Luka' },
        {
          text: 'Bantuan Medis/Perawatan dirumah Home Care',
          value: 'Bantuan Medis/Perawatan dirumah Home Care'
        },
        {
          text: 'Penanganan Kejang /Demam/Diare Saat Dirum',
          value: 'Penanganan Kejang /Demam/Diare Saat Dirum'
        }
      ]
    },
    perencanaan_pulang_sbb2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        {
          text: 'Perawatan Post Nifas/ Post SC',
          value: 'Perawatan Post Nifas/ Post SC'
        },
        { text: 'Perawatan Bayi', value: 'Perawatan Bayi' },
        { text: 'Perawatan Payudara', value: 'Perawatan Payudara' },
        { text: 'Latihan Fisik Lanjutan', value: 'Latihan Fisik Lanjutan' },
        {
          text: 'Bantuan Tenaga Khusus Dirumah',
          value: 'Bantuan Tenaga Khusus Dirumah'
        },
        {
          text: 'Bantuan untuk Melakukan Aktifitas Fisik Kursi Roda, Alat Bantu Jalan',
          value:
            'Bantuan untuk Melakukan Aktifitas Fisik Kursi Roda, Alat Bantu Jalan'
        }
      ]
    }
  }
}
