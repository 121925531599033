export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3,
      disabled: true

    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      col: 3,
      disabled: true

    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Ruangan',
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      disabled: true,

      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'Dokter DPJP',
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    label1: {
      label: 'Dokter yang Merawat',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    dokter_yang_merawat: {
      label: ' ',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 9
    },
    label2: {
      label: 'Tiba di Ruangan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tiba_diruangan_tanggal: {
      widget: 'wdatenormal',
      data: [],
      disabled: true,

      col: 4,
      label: 'Tanggal'
    },
    tiba_diruangan_jam: {
      label: 'Jam',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3,
      property: { attrs: { suffix: '', type: 'string' } }
    },
    label3: {
      label: 'Pengkajian',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pengkajian_tanggal: {
      widget: 'wdatenormal',
      data: [],
      disabled: true,

      col: 4,
      label: 'Tanggal'
    },
    pengkajian_jam: {
      label: 'Jam',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3,
      property: { attrs: { suffix: '', type: 'string' } }
    },
    label5: {
      label: 'Cara Masuk',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    cara_masuk: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Jalan', value: 'jalan' },
        { text: 'Kursi Roda', value: 'kursi_roda' },
        { text: 'Brangkar/Troley*', value: 'brangkar/troley' },
        { text: 'Inkubator', value: 'inkubator' },
        { text: 'Box', value: 'box' }
      ],
      col: 9
    },
    label6: {
      label: 'Asal Pasien',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    asal_pasien: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'IRJ', value: 'irj' },
        { text: 'IGD', value: 'igd' },
        { text: 'Kamar Operasi', value: 'kamar_operasi' },
        { text: 'Kamar Bersalin', value: 'kamar_bersalin' },
        { text: 'Dokter Pribadi', value: 'dokter_pribadi' },
        { text: 'Rujukan', value: 'rujukan' }
      ],
      col: 9
    },
    label7: {
      label: 'Masuk Kasus Trauma',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    masuk_kasus_trauma: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'KLL', value: 'kll' },
        { text: 'KDRT', value: 'kdrt' },
        { text: 'Tidak', value: 'tidak' }
      ],
      col: 9
    },
    label8: {
      label: 'Hasil Pemeriksaan yang Dibawa oleh Keluarga',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    hasil_yang_dibawa: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Laboratorium', value: 'laboratorium' },
        { text: 'Radiologi', value: 'radiologi' },
        { text: 'Dignostik Lain', value: 'diagnostik_lain' },
        { text: 'Tidak Ada', value: 'tidak_ada' }
      ],
      col: 9
    }
  },
  // form2: {
  //   label1: {
  //     label: 'Pekerjaan Pasien',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   pekerjaan_pasien: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'PNS/TNI/POLRI', value: 'pns/tni/polri' },
  //       { text: 'Swasta', value: 'swasta' },
  //       { text: 'Pelajar/Mahasiswa', value: 'pelajar/mahasiswa' },
  //       { text: 'Pensiun', value: 'pensiun' },
  //       { text: 'IRT', value: 'irt' },
  //       { text: 'Lain-lain', value: null, field: 'auto' }
  //     ]
  //   },
  //   label2: {
  //     label: 'Pekerjaan Penanggung Jawab/OT Pasien',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   pekerjaan_ot_pasien: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'PNS/TNI/POLRI', value: 'pns/tni/polri' },
  //       { text: 'Swasta', value: 'swasta' },
  //       { text: 'Pensiun', value: 'pensiun' },
  //       { text: 'IRT', value: 'irt' },
  //       { text: 'Lain-lain', value: null, field: 'auto' }
  //     ]
  //   },
  //   label3: {
  //     label: '  ',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   pekerjaan_ot_pasien_gaji: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: '< 1 juta', value: '< 1 juta' },
  //       { text: '1 - 2,9 juta', value: '1 - 2,9 juta' },
  //       { text: '3 - 4,9 juta', value: '3 - 4,9 juta' },
  //       { text: '5 - 9,9 juta', value: '5 - 9,9 juta' },
  //       { text: '10 - 14,9 juta', value: '10 - 14,9 juta' },
  //       { text: '15 - 19,9 juta', value: '15 - 19,9 juta' },
  //       { text: '> 20 juta', value: '> 20 juta' }
  //     ]
  //   },
  //   label4: {
  //     label: 'Pendidikan Pasien',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   pendidikan_pasien: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'TK', value: 'TK' },
  //       { text: 'SD', value: 'SD' },
  //       { text: 'SMP', value: 'SMP' },
  //       { text: 'SLTA', value: 'SLTA' },
  //       { text: 'Akademi', value: 'Akademi' },
  //       { text: 'Paska Sarjana', value: 'Paska Sarjana' }
  //     ]
  //   },
  //   label5: {
  //     label: 'Pendidikan Penanggung Jawab/OT/Suami/Istri',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   pendidikan_ot_pasien: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'TK', value: 'TK' },
  //       { text: 'SD', value: 'SD' },
  //       { text: 'SMP', value: 'SMP' },
  //       { text: 'SLTA', value: 'SLTA' },
  //       { text: 'Akademi', value: 'Akademi' },
  //       { text: 'Paska Sarjana', value: 'Paska Sarjana' }
  //     ]
  //   },
  //   label6: {
  //     label: 'Cara Pembayaran',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   cara_pembayaran: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'Pribadi', value: 'Pribadi' },
  //       { text: 'Perusahaan', value: 'Perusahaan' },
  //       { text: 'Asuransi', value: 'Asuransi' },
  //       { text: 'BPJS', value: 'BPJS' },
  //       { text: 'Lain-lain', value: null, field: 'auto' }
  //     ]
  //   },
  //   label7: {
  //     label: 'Cara Pembayaran',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   tinggal_bersama: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'Keluarga', value: 'Keluarga' },
  //       { text: 'Suami/Istri', value: 'Suami/Istri' },
  //       { text: 'Orang Tua', value: 'Orang Tua' },
  //       { text: 'Anak', value: 'Anak' },
  //       { text: 'Teman', value: 'Teman' },
  //       { text: 'Sendiri', value: 'Sendiri' },
  //       { text: 'Panti Asuhan', value: 'Panti Asuhan' }
  //     ]
  //   }
  // },
  // form3: {
  //   agama: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'Islam', value: 'Pribadi' },
  //       { text: 'Protestan', value: 'Protestan' },
  //       { text: 'Katolik', value: 'Katolik' },
  //       { text: 'Hindu', value: 'Hindu' },
  //       { text: 'Budha', value: 'Budha' },
  //       { text: 'Konghucu', value: 'Konghucu' }
  //     ]
  //   }
  // },
  // form4: {
  //   nilai1: {
  //     label: 'Nilai-Nilai Kepercayaan Pasien/Keluarga',
  //     widget: 'wcheckbox',
  //     col: 6,
  //     data: [
  //       {
  //         text: 'Tidak Mau Ditransfusi',
  //         value: 'Tidak Mau Ditransfusi'
  //       },
  //       {
  //         text: 'Tidak Mau Imunisasi',
  //         value: 'Tidak Mau Imunisasi'
  //       },
  //       {
  //         text: 'Tidak Makan Daging/Ikan yang Bersisik',
  //         value: 'Tidak Makan Daging/Ikan yang Bersisik'
  //       }
  //     ]
  //   },
  //   nilai2: {
  //     label: ' ',
  //     widget: 'wcheckbox',
  //     col: 6,
  //     data: [
  //       {
  //         text: 'Tidak Mau Pulang di Hari Tertentu',
  //         value: 'Tidak Mau Pulang di Hari Tertentu'
  //       },
  //       {
  //         text: 'Tidak Boleh Menyusui (ASI)',
  //         value: 'nyeri'
  //       },
  //       {
  //         text: 'lain-lain',
  //         value: null,
  //         field: 'auto'
  //       }
  //     ]
  //   },
  //   label2: {
  //     label: 'Hambatan Belajar',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   hambatan_belajar: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'Tidak Ada', value: 'Tidak Ada' },
  //       { text: 'Pandangan Terbatas', value: 'Pandangan Terbatas' },
  //       { text: 'Keterbatasan Fisik', value: 'Keterbatasan Fisik' },
  //       { text: 'Tidak Bisa Membaca', value: 'Tidak Bisa Membaca' }
  //     ]
  //   },
  //   label3: {
  //     label: 'Hambatan Belajar',
  //     widget: 'wlabelkiri',
  //     data: [],
  //     col: 4
  //   },
  //   hambatan_bahasa: {
  //     label: ' ',
  //     widget: 'wradio',
  //     col: 8,
  //     data: [
  //       { text: 'Ya', value: 'Ya' },
  //       { text: 'Tidak', value: 'Tidak' },
  //       { text: 'Pendengaran Terbatas', value: 'Pendengaran Terbatas' },
  //       { text: 'Hambatan Emosi', value: 'Hambatan Emosi' },
  //       { text: 'Motivasi Buruk', value: 'Motivasi Buruk' }
  //     ]
  //   }
  // },
  form5: {
    label1: {
      label: '1. Keluhan Utama : ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    keluhan_utama: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    label2: {
      label: '2. Riwayat Penyakit Sekarang : ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    riwayat_penyakit_sekarang: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    label3: {
      label: 'Riwayat Penyakit Dahulu(termasuk Riwayat Operasi):',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label3a: {
      label: 'a. Pernah Dirawat :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pernah_dirawat: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label3b: {
      label: 'b. Pernah Operasi/Tindakan :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pernah_operasi_tindakan: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label3c: {
      label: 'c. Masalah Operasi/Pembiusan :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    masalah_operasi_pembiusan: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    }
  },
  form6: {
    label4: {
      label: 'Riwayat Penyakit Keluarga',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_penyakit_keluarga: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label5: {
      label: 'Riwayat Konsumsi Obat Penenang',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_konsumsi_obat_penenang: {
      label: ' ',
      widget: 'wradio',
      col: 4,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, jenis : ', value: null, field: 'auto' }
      ]
    },
    riwayat_konsumsi_obat_penenang2: {
      label: 'jumlah / hari',
      widget: 'wtext',
      data: [],
      col: 4
    },
    label6: {
      label: 'Riwayat Merokok',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_merokok: {
      label: ' ',
      widget: 'wradio',
      col: 4,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, jumlah / hari : ', value: null, field: 'auto' }
      ]
    },
    riwayat_merokok2: {
      label: 'lamanya',
      widget: 'wtext',
      data: [],
      col: 4
    },
    label7: {
      label: 'Riwayat Minum Minuman Keras',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_minum_keras: {
      label: ' ',
      widget: 'wradio',
      col: 4,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, jumlah / hari : ', value: null, field: 'auto' }
      ]
    },
    riwayat_minum_keras2: {
      label: 'lamanya',
      widget: 'wtext',
      data: [],
      col: 4
    },
    label8: {
      label: 'Obat dari Rumah',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    obat_dari_rumah: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, Sebutkan', value: null, field: 'auto' }
      ]
    },
    label9: {
      label:
        'Riwayat Konsumsi Obat Pengencer Darah (Aspirin, Warfarin, Plavix, dll)',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_konsumsi_obat: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, Kapan', value: null, field: 'auto' }
      ]
    },
    label10: {
      label: 'Riwayat Alergi',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_alergi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label11: {
      label: 'Riwayat Menerima Transfusi Darah',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_transfusi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ya, Waktu Terakhir', value: null, field: 'auto' }
      ]
    },
    label12: {
      label: 'Reaksi yang Timbul',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    reaksi_yang_timbul: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, ', value: null, field: 'auto' }
      ]
    },
    label13a: {
      label: 'Golongan Darah',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    golongan_darah: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'O', value: 'O' },
        { text: 'AB', value: 'AB' }
      ]
    },
    label13b: {
      label: 'Rh',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    rh: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Positif', value: 'Positif' },
        { text: 'Negatif', value: 'Negatif' }
      ]
    },
    label14: {
      label: 'Khusus Pasien dengan Riwayat Komoterapi dan Radioterapi',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    p1: {
      label: ' ',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ada, Kapan', value: 'Ada, Kapan' }
      ]
    },
    sudah_berapa_kali: {
      label: 'Sudah Berapa Kali',
      widget: 'wtext',
      data: [],
      col: 6,
      property: { attrs: { suffix: ', Terakhir', type: 'number' } }
    },
    label14b: {
      label: 'Cara Pemberian',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    cara_pemberian: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Melalui Suntik', value: 'Melalui Suntik' },
        { text: 'Melalui Infus', value: 'Melalui Infus' },
        { text: 'Melalui Oral/Minum', value: 'Melalui Oral/Minum' }
      ]
    },
    label15a: {
      label: 'Riwayat Radioterapi',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_radioterapi: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Ada, Kapan', value: null, field: 'auto' }
      ]
    },
    berapkali: {
      label: 'Berapa Kali',
      widget: 'wtext',
      data: [],
      col: 5,
      property: { attrs: { suffix: '', type: 'number' } }
    },
    label14d: {
      label: 'Efek Samping',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    efek_samping: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Mual', value: 'Mual' },
        { text: 'Muntah', value: 'Muntah' },
        { text: 'Jantung Berdebar', value: 'Jantung Berdebar' },
        { text: 'Pusing', value: 'Pusing' },
        { text: 'Rambut Rontok', value: 'Rambut Rontok' }
      ]
    }
  },
  form6b: {
    p1: {
      label: 'Riwayat Persalinan, Ditolong lahir secara',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Dokter', value: 'Dokter' },
        { text: 'Spontan', value: 'Spontan' },
        { text: 'Bidan', value: 'Bidan' },
        { text: 'Vacum', value: 'Vacum' },
        { text: 'Dukun', value: 'Dukun' },
        { text: 'SC', value: 'SC' },
        { text: 'Lainnya', value: null, field: 'auto' }
      ]
    },
    p2: {
      label: 'Riwayat Persalinan, Ditolong lahir secara',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Atern', value: 'Atern' },
        { text: 'Prematur', value: 'Prematur' },
        { text: 'Serotinus', value: 'Serotinus' }
      ]
    },
    p3: {
      label: 'Masalah Selama Kehamilan',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p4: {
      label: 'Masalah Neonatus',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Sebutkan', value: null, field: 'auto' }
      ]
    },
    l1: {
      label: 'Kondisi Saat Lahir',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    bbl: {
      label: 'BBL',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { suffix: 'gr', type: 'number' } }
    },
    pb: {
      label: 'PB',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { suffix: 'Cm', type: 'number' } }
    },
    asfiksia: {
      label: 'Asfiksia',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Ringan', value: 'Ringan' },
        { text: 'Sedang', value: 'Sedang' },
        { text: 'Berat', value: 'Berat' }
      ]
    },
    p5: {
      label: 'ASI Sampai Umur',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { suffix: 'Bln', type: 'number' } }
    },
    p6: {
      label: 'Susu Formula Dimulai',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { suffix: 'Bln', type: 'number' } }
    },
    p7: {
      label: 'Makanan Tambahan Dimulai',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { suffix: 'Bln', type: 'number' } }
    },
    p8: {
      label: 'Makanan Padat Dimulai',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { suffix: 'Bln', type: 'number' } }
    }
  },
  form6d: {
    l1: {
      label: 'Status Perkembangan',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    p1: {
      label: 'Berguling',
      widget: 'wtext',
      data: [],
      col: 6,
      property: { attrs: { suffix: 'Bulan (Max 5 Bulan)', type: 'number' } }
    },
    p2: {
      label: 'Duduk',
      widget: 'wtext',
      data: [],
      col: 6,
      property: { attrs: { suffix: 'Bulan (Max 7 Bulan)', type: 'number' } }
    },
    l2: {
      label: 'Usia Anak Berjalan',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    p3: {
      label: 'Berjalan',
      widget: 'wtext',
      data: [],
      col: 6,
      property: { attrs: { suffix: 'Tahun (Max 15 Bulan)', type: 'number' } }
    },
    p4: {
      label: 'Berdiri',
      widget: 'wtext',
      data: [],
      col: 6,
      property: { attrs: { suffix: 'Tahun (Max 14 Bulan)', type: 'number' } }
    },
    p5: {
      label: 'Senyum Kepada Orang Lain Pertama Kali',
      widget: 'wtext',
      data: [],
      col: 6,
      property: { attrs: { suffix: 'Bulan (Normal 3 Bln)', type: 'number' } }
    },
    p6: {
      label: 'Menyebut Mama/Papa Pertama Kali',
      widget: 'wtext',
      data: [],
      col: 6,
      property: { attrs: { suffix: 'Bulan (Max 11 Bln)', type: 'number' } }
    },
    p7: {
      label: 'Berpakaian Tanpa Dibantu',
      widget: 'wtext',
      data: [],
      col: 6,
      property: { attrs: { suffix: 'Tahun (Max 5 Tahun)', type: 'number' } }
    },
    p8: {
      label: 'Menggambar Orang 3 Bagian',
      widget: 'wtext',
      data: [],
      col: 6,
      property: { attrs: { suffix: 'Tahun (Max 5 Tahun)', type: 'number' } }
    }
  },
  form7: {
    label1: {
      label: 'Keadaan Umum:',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    keadaan_umum: {
      label: ' ',
      widget: 'wradio',
      col: 11,
      data: [
        { text: 'Tampak Tidak Sakit', value: 'Tampak Tidak Sakit' },
        { text: 'Tampak Sakit Ringan', value: 'Tampak Sakit Ringan' },
        { text: 'Tampak Sakit Sedang', value: 'Tampak Sakit Sedang' },
        { text: 'Tampak Sakit Berat', value: 'Tampak Sakit Berat' }
      ]
    },
    label4: {
      label: 'Tanda-Tanda Vital:',
      widget: 'wlabelkiri',
      data: '',
      col: 1
    },
    tanda_vital_td: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'TD', suffix: 'mmHg', type: 'number' } }
    },
    tanda_vital_hr: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'hr', suffix: 'x/mnt', type: 'number' } }
    },
    tanda_vital_rr: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'RR', suffix: 'x/mnt', type: 'number' } }
    },
    tanda_vital_t: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'T', suffix: '°C', type: 'number' } }
    },
    tanda_vital_spo2: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'SpO2', suffix: '%', type: 'number' } }
    },
    labelx: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    label5: {
      label: 'Pemeriksaan Atrometrik:',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    pemeriksaan_atrometrik_bb: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'BB', suffix: 'Kg', type: 'number' } }
    },
    pemeriksaan_atrometrik_tb: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'TB', suffix: 'Cm', type: 'number' } }
    },
    pemeriksaan_atrometrik_lk: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'LK', suffix: 'Cm', type: 'number' } }
    },
    pemeriksaan_atrometrik_ld: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'LD', suffix: 'Cm', type: 'number' } }
    },
    pemeriksaan_atrometrik_lp: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'LP', suffix: 'Cm', type: 'number' } }
    },
    labelx2: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    }
  },
  form8: {
    pola_nafas: {
      label: 'Pola Nafas (Irama)',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Teratur', value: 'Teratur' },
        { text: 'Tidak Teratur', value: 'Tidak Teratur' }
      ]
    },
    penggunaan_alat_bantu_pernafasan: {
      label: 'Penggunaan alat bantu pernafasan',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Kanul/NRM.BCPAP/V entilator', value: 'Ya, Kanul/NRM.BCPAP/V entilator' }
      ]
    },
    suara_nafas: {
      label: 'Suara Nafas',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Vesikuler', value: 'Vesikuler' },
        { text: 'Wheezing', value: 'Wheezing' },
        { text: 'Rochi', value: 'Rochi' },
        { text: 'Stridor', value: 'Stridor' },
        { text: 'Lain-lain', value: 'Lain-lain' }
      ]
    },
    sesak_nafas: {
      label: 'Sesak Nafas',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' },
        { text: 'Batuk', value: 'Batuk' },
        { text: 'Pilek', value: 'Pilek' },
        { text: 'Pernafasan cuping hidung', value: 'Pernafasan cuping hidung' }
      ]
    }
  },
  form8b: {
    p1: {
      label: 'Pola Nafas (Irama)',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Reguler', value: 'Reguler' },
        { text: 'Ireguler', value: 'Ireguler' },
        { text: 'Suara S1/S2 Normal', value: 'Suara S1/S2 Normal' }
      ]
    },
    p2: {
      label: 'Penggunaan alat bantu pernafasan',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    p3: {
      label: 'Suara Nafas',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Murmur', value: 'Murmur' },
        { text: 'Gallop', value: 'Gallop' },
        { text: 'Lain-lain', value: 'Lain-lain' }
      ]
    },
    p4: {
      label: 'Sesak Nafas',
      widget: 'wradio',
      col: 8,
      data: [
        { text: '< 3 detik', value: '< 3 detik' },
        { text: '> 3 detik', value: '> 3 detik' }
      ]
    },
    p5: {
      label: 'Sesak Nafas',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Hangat', value: 'Hangat' },
        { text: 'Dingin', value: 'Dingin' },
        { text: 'Lembab', value: 'Lembab' },
        { text: 'Keringat Dingin', value: 'Keringat Dingin' }
      ]
    }
  },
  form9a: {
    p1: {
      label: 'GCS',
      widget: 'wtext',
      data: [],
      col: 3
    },
    p2: {
      label: 'Eye',
      widget: 'wtext',
      data: [],
      col: 3
    },
    p3: {
      label: 'Verbal',
      widget: 'wtext',
      data: [],
      col: 3
    },
    p4: {
      label: 'Motorik',
      widget: 'wtext',
      data: [],
      col: 3
    },
    p5: {
      label: 'Reflek Fisiologis',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Patella', value: 'Patella' },
        { text: 'Biceps', value: 'Biceps' },
        { text: 'Triceps', value: 'Triceps' },
        { text: 'Lain-lain', value: 'Lain-lain' }
      ]
    },
    p6: {
      label: 'Reflek Patologis',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'TAK', value: 'TAK' },
        { text: 'Babinsky', value: 'Babinsky' },
        { text: 'Chaddock', value: 'Chaddock' },
        { text: 'Lain-lain', value: 'Lain-lain' }
      ]
    },
    p7: {
      label: 'Pupil',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Isokor', value: 'Isokor' },
        { text: 'Anisokor', value: 'Anisokor' },
        { text: 'Lain-lain', value: 'Lain-lain' }
      ]
    },
    p8: {
      label: 'Konjungtiva/Sklera',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Anemis', value: 'Anemis' },
        { text: 'Ikterik', value: 'Ikterik' },
        { text: 'Lain-lain', value: 'Lain-lain' }
      ]
    },
    p9: {
      label: 'Gangguan Bentuk',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Caput', value: 'Caput' },
        { text: 'Hidrosefalus', value: 'Hidrosefalus' },
        { text: 'Cephalhematom', value: 'Cephalhematom' }
      ]
    },
    p10: {
      label: 'Gangguan Motorik',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'TAK', value: 'TAK' },
        { text: 'Hemiplegi', value: 'Hemiplegi' },
        { text: 'Paraplegi', value: 'Paraplegi' },
        { text: 'Hemiparese', value: 'Hemiparese' },
        { text: 'Paraparese', value: 'Paraparese' },
        { text: 'Kejang', value: 'Kejang' },
        { text: 'Tremor', value: 'Tremor' }
      ]
    },
    p11: {
      label: 'Gangguan Sensorik',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'TAK', value: 'TAK' },
        { text: 'Kesemutan', value: 'Kesemutan' },
        { text: 'Nyeri, lokasi', value: 'Nyeri, lokasi' }
      ]
    },
    p12: {
      label: 'Gangguan Penglihatan',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    p13: {
      label: 'Gangguan Pendengaran',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    p14: {
      label: 'Gangguan Tidur',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'TAK', value: 'TAK' },
        { text: 'Insomnia', value: 'Insomnia' }
      ]
    },
    p15: {
      label: 'Tidur',
      widget: 'wtext',
      data: [],
      col: 3
    },
    p16: {
      label: 'Jam',
      widget: 'wtext',
      data: [],
      col: 3
    },
    p17: {
      label: ' ',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Ekspresi Kesakitan', value: 'Ekspresi Kesakitan' },
        { text: 'Ekspresi Mengantuk', value: 'Ekspresi Mengantuk' },
        { text: 'Kaku kuduk', value: 'Kaku kuduk' }
      ]
    }
  },
  form9b: {
    p1: {
      label: 'Kebersihan',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Bersih', value: 'Bersih' },
        { text: 'Kotor', value: 'Kotor' }
      ]
    },
    p2: {
      label: 'Urin Output',
      widget: 'wtext',
      col: 3,
      data: [],
      property: { attrs: { suffix: 'cc/Hari', type: 'number' } }
    },
    p3: {
      label: 'Warna',
      widget: 'wtext',
      col: 3,
      data: []
    },
    p4: {
      label: 'Bau',
      widget: 'wtext',
      col: 3,
      data: []
    },
    p5: {
      label: 'Frekuensi',
      widget: 'wtext',
      col: 3,
      data: [],
      property: { attrs: { suffix: 'x/Hari', type: 'number' } }
    },
    p6: {
      label: 'Kandung Kemih',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'TAK', value: 'TAK' },
        { text: 'Membesar', value: 'Membesar' },
        { text: 'Nyeri tekan', value: 'Nyeri tekan' }
      ]
    },
    p7: {
      label: 'Gangguan',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Anuria', value: 'Anuria' },
        { text: 'Oliguria', value: 'Oliguria' },
        { text: 'Retensi', value: 'Retensi' },
        { text: 'Hematuri', value: 'Hematuri' },
        { text: 'Disuria', value: 'Disuria' },
        { text: 'Inkontinensia', value: 'Inkontinensia' },
        { text: 'Kateter', value: 'Kateter' }
      ]
    }
  },
  form9c: {
    l1: {
      label: 'Riwayat ASI dan Pendamping ASI',
      widget: 'wtext',
      col: 12,
      data: []
    },
    p1: {
      label: 'ASI, Sampai Usia',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { suffix: 'Bln/Thn', type: 'number' } }
    },
    p2: {
      label: 'PASI, Susu Formula, Sejak Usia',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { suffix: 'Bln/Thn', type: 'number' } }
    },
    p3: {
      label: 'Alasana pemberian PASI/Susu Formula',
      widget: 'wtext',
      col: 12,
      data: []
    },
    p4: {
      label: 'Faktor anak',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'BBLR', value: 'BBLR' },
        { text: 'Prematur', value: 'Prematur' },
        { text: 'Reflek hisap kurang', value: 'Reflek hisap kurang' },
        { text: 'Kelainan anatomi mulut', value: 'Kelainan anatomi mulut' },
        { text: 'bibir', value: 'bibir' }
      ]
    },
    p5: {
      label: 'Faktor ibu',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Produksi asi kurang', value: 'Produksi asi kurang' },
        { text: 'penyakit ibu', value: 'penyakit ibu' },
        { text: 'masalah puting', value: 'masalah puting' },
        { text: 'ibu bekerja', value: 'ibu bekerja' }
      ]
    },
    p6: {
      label: 'Nafsu makan',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Baik', value: 'Baik' },
        { text: 'Menurun', value: 'Menurun' }
      ]
    },
    p7: {
      label: 'Frekuensi Makan',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { suffix: 'x/Hari', type: 'number' } }
    },
    p8: {
      label: 'Gangguan',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Nausea', value: 'Nausea' },
        { text: 'Vomitus', value: 'Vomitus' },
        { text: 'Hematemesis', value: 'Hematemesis' }
      ]
    },
    p9: {
      label: 'Porsi makan',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Habis', value: 'Habis' },
        { text: 'Tidak, Porsi', value: 'Tidak, Porsi' }
      ]
    },
    p10: {
      label: 'Komposisi Diet',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Nasi', value: 'Nasi' },
        { text: 'Sayur', value: 'Sayur' },
        { text: 'Lauk', value: 'Lauk' },
        { text: 'Buah', value: 'Buah' },
        { text: 'Susu', value: 'Susu' }
      ]
    },
    p11: {
      label: 'Minum',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { suffix: 'cc/Hari', type: 'number' } }
    },
    p12: {
      label: 'Mukosa Mulut',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Lembab', value: 'Lembab' },
        { text: 'Kering', value: 'Kering' },
        { text: 'Stomatitis', value: 'Stomatitis' }
      ]
    },
    p13: {
      label: 'Tenggorokan',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Sulit Menelan', value: 'Sulit Menelan' },
        { text: 'Tonsilitis', value: 'Tonsilitis' },
        { text: 'Hipertiroid', value: 'Hipertiroid' }
      ]
    },
    p14: {
      label: 'Abdomen',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Kembung', value: 'Kembung' },
        { text: 'Ascites', value: 'Ascites' },
        { text: 'Tegang', value: 'Tegang' },
        { text: 'Nyeri Tekan, Lokasi', value: null, field: 'auto' }
      ]
    },
    p15: {
      label: 'Hepatomegali',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    p16: {
      label: 'Splenomegali',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    p17: {
      label: 'BAB',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { suffix: 'x/Hari', type: 'number' } }
    },
    p18: {
      label: 'Konsistensi',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Cair', value: 'Cair' },
        { text: 'Keras', value: 'Keras' }
      ]
    },
    p19: {
      label: 'Gangguan BAB',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Diare', value: 'Diare' },
        { text: 'Melena', value: 'Melena' },
        { text: 'Konstipasi', value: 'Konstipasi' }
      ]
    },
    p20: {
      label: 'Flatus',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    p21: {
      label: 'Bising Usus',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { suffix: 'x/mnt', type: 'number' } }
    }
  },
  form9d: {
    p1: {
      label: 'Pergerakan sendi',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Bebas', value: 'Bebas' },
        { text: 'Terbatas', value: 'Terbatas' }
      ]
    },
    p2: {
      label: 'Aktivitas',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Mandiri', value: 'Mandiri' },
        { text: 'Sebagian dibantu', value: 'Sebagian dibantu' },
        { text: 'Dibantu sepenuhnya', value: 'Dibantu sepenuhnya' }
      ]
    },
    p3: {
      label: 'Kelainan',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Fraktur, Lokasi', value: 'Fraktur, Lokasi' },
        { text: 'Atropi', value: 'Atropi' },
        { text: 'Kontraktur', value: 'Kontraktur' },
        { text: 'Hemiparise', value: 'Hemiparise' }
      ]
    },
    p4: {
      label: 'Warna Kulit',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Pucat', value: 'Pucat' },
        { text: 'Ikterik', value: 'Ikterik' },
        { text: 'Cianosis', value: 'Cianosis' },
        { text: 'Kemerahan', value: 'Kemerahan' }
      ]
    },
    p5: {
      label: 'Turgor Kulit',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Baik', value: 'Baik' },
        { text: 'Sedang Jele', value: 'Sedang Jele' },
        { text: 'k', value: 'k' }
      ]
    },
    p6: {
      label: 'Odema',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Ada, Lokasi', value: null, field: 'auto' },
        { text: 'Tidak Ada', value: 'Tidak Ada' }
      ]
    },
    p7: {
      label: 'Odema',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Gangrene', value: 'Gangrene' },
        { text: 'Dekubitus', value: 'Dekubitus' },
        { text: 'Ptechie', value: 'Ptechie' },
        { text: 'Panas', value: 'Panas' },
        { text: 'Jaundice', value: 'Jaundice' },
        { text: 'Ulkus', value: 'Ulkus' },
        { text: 'Luka Operasi, Jelaskan', value: null, field: 'auto' },
        { text: 'Radang', value: 'Radang' },
        { text: 'Pus', value: 'Pus' },
        { text: 'Dehidrasi', value: 'Dehidrasi' }
      ]
    }
  },
  form11: {
    p1: [
      {
        label: '3-7 Tahun',
        value: 4
      },
      {
        label: '8-13 Tahun',
        value: 3
      },
      {
        label: '>13 Tahun',
        value: 2
      }
    ],
    p2: [
      {
        label: 'Laki-laki',
        value: 2
      },
      {
        label: 'Perempuan',
        value: 1
      }
    ],
    p3: [
      {
        label: 'Kelainan neurologi',
        value: 4
      }
    ],
    p4: [
      {
        label: 'Perubahan dalam oksigen (masalah saluran nafas, dehidrasi, anemia, anoreksia, sinkop/sakit kepala)',
        value: 3
      },
      {
        label: 'Kelainan psikis/prilaku',
        value: 2
      },
      {
        label: 'Diagnosa lain',
        value: 1
      }
    ],
    p5: [
      {
        label: 'Tidak sadar akan keterbatasan',
        value: 3
      },
      {
        label: 'Lupa keterbatasan',
        value: 2
      },
      {
        label: 'Mengetahui keterbatasan',
        value: 1
      }
    ],
    p5b: [
      {
        label: 'Riwayat jatuh dari tempat tidur saat bayi anak',
        value: 4
      },
      {
        label: 'Pasien menggunakan alat bantu atau box/mebel',
        value: 3
      },
      {
        label: 'Pasien berada ditempat tidur',
        value: 2
      },
      {
        label: 'Diluar ruang rawat',
        value: 1
      }
    ],
    p6: [
      {
        label: 'Dalam 24 jam',
        value: 3
      },
      {
        label: 'Dalam 48 jam riwayat jatuh',
        value: 2
      },
      {
        label: '>48 jam',
        value: 1
      }
    ],
    p7: [
      {
        label: 'Bermacam-macam obat yang digunakan: obat sedatif (kecuali pasien PICU yang menggunakan sedasi dan paralisis), hipnotok, barbiturat, fenotiazim, antidepresan, laksansia/diuretika, narkotika',
        value: 3
      },
      {
        label: 'Salah satu dari pengobatan di atas',
        value: 2
      },
      {
        label: 'Pengobatan lain',
        value: 1
      }
    ]
  },
  form11b: {
    p1: {
      label: 'Pasang Pengaman Tempat Tidur/Bed Ralls',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    p2: {
      label: 'Bel Mudah Diajangkaukan',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    p3: {
      label: 'Resiko Melarikan Diri',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Tidak ada masalah teridentifikasi', value: 'Tidak ada masalah teridentifikasi' },
        { text: 'Bingung', value: 'Bingung' },
        { text: 'Resiko Karena,', value: null, field: 'auto' },
        { text: 'Gangguan stutus mental', value: 'Gangguan stutus mental' },
        { text: 'Dementia', value: 'Dementia' },
        { text: 'Menolak tinggal dirumah sakit', value: 'Menolak tinggal dirumah sakit' },
        { text: 'Tinggal dilingkungan Rumah Sakit', value: 'Tinggal dilingkungan Rumah Sakit' },
        { text: 'Tinggal dilingkungan Yang Diawasi', value: 'Tinggal dilingkungan Yang Diawasi' }
      ]
    }
  },
  form12: {
    p1: {
      label: ' ',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Skala FLACC untuk anak 1-3 tahun', value: 'Skala FLACC untuk anak 1-3 tahun' }
      ]
    }
  },
  form12b: {
    p1: [
      {
        label: 'Tersenyum/tidak ada Ekspresi khusus',
        value: 0
      },
      {
        label: 'Terkadang meringis/menarik diri',
        value: 1
      },
      {
        label: 'Sering menngetarkan dagu/mengatupkan rahang',
        value: 2
      }
    ],
    p2: [
      {
        label: 'Gerakan normal/relaksasi',
        value: 0
      },
      {
        label: 'Tidak tenang/tegang',
        value: 1
      },
      {
        label: 'Kaki dibuat menendang/menarik diri',
        value: 2
      }
    ],
    p3: [
      {
        label: 'Tidur, posisi normal, mudah bergerak',
        value: 0
      },
      {
        label: 'Gerakan menggeliat, berguling, kaku',
        value: 1
      },
      {
        label: 'Melengkungkan punggung/kaku/menghentak',
        value: 2
      }
    ],
    p4: [
      {
        label: 'Tidak menangis (bangun/tidur)',
        value: 0
      },
      {
        label: 'Mengerang, merengek-rengek',
        value: 1
      },
      {
        label: 'Menangis terus-menerus/terisak/menjerit',
        value: 2
      }
    ],
    p5: [
      {
        label: 'Bersuara normal/tenang',
        value: 0
      },
      {
        label: 'Tenang bila dipeluk, digendong/diajak bicara',
        value: 1
      }
    ]
  },
  form12c: {
    p1: {
      label: 'Anak Usia 3-8 Tahun Faces Rating Scale (Wong Baker Faces)',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Skala 0 (No Hurt)', value: 'Skala 0 (No Hurt)' },
        { text: 'Skala 2 (Little Bit)', value: 'Skala 2 (Little Bit)' },
        { text: 'Skala 4 (Little More)', value: 'Skala 4 (Little More)' },
        { text: 'Skala 6 (Even More)', value: 'Skala 6 (Even More)' },
        { text: 'Skala 8 (Whole Lot)', value: 'Skala 8 (Whole Lot)' },
        { text: 'Skala 10 (Worst)', value: 'Skala 10 (Worst)' }
      ]
    },
    p2: {
      label: 'Anak > 8 Tahun Numerik rating scale (NRS)',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Skala 0 (Tidak Sakit)', value: 'Skala 0 (Tidak Sakit)' },
        { text: 'Skala 1-3 (Nyeri Ringan)', value: 'Skala 1-3 (Nyeri Ringan)' },
        { text: 'Skala 4-6 (Nyeri Sedang)', value: 'Skala 4-6 (Nyeri Sedang)' },
        { text: 'Skala 7-10 (Nyeri Berat)', value: 'Skala 7-10 (Nyeri Berat)' }
      ]
    }
  },
  form13: {
    p1: {
      label: '1. Provokatif (faktor yang mengurangi)',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Kompes Hangat', value: 'Kompes Hangat' },
        { text: 'Kompers Dingin', value: 'Kompers Dingin' },
        { text: 'Farmakoterapi', value: 'Farmakoterapi' }
      ]
    },
    p2: {
      label: '2. Quality/quantitas nyeri',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Ditusuk', value: 'Ditusuk' },
        { text: 'Dipukul', value: 'Dipukul' },
        { text: 'Berdenyut', value: 'Berdenyut' },
        { text: 'Ditarik', value: 'Ditarik' },
        { text: 'Tajam', value: 'Tajam' }
      ]
    },
    p3: {
      label: '3. Region/radiasi (lokasi nyeri)',
      widget: 'wtext',
      col: 6,
      data: []
    },
    p4: {
      label: '4. Severity/skala (seberapa jauh nyeri)',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Tidak Nyeri', value: 'Tidak Nyeri' },
        { text: 'Ringan', value: 'Ringan' },
        { text: 'Sedang', value: 'Sedang' },
        { text: 'Berat', value: 'Berat' }
      ]
    },
    p5: {
      label: '5. Time (lama nyeri)',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Terus-menerus', value: 'Terus-menerus' },
        { text: 'Hilang timbul', value: 'Hilang timbul' },
        { text: '>30 menit', value: '>30 menit' },
        { text: 'Konsul Ke Dokter', value: 'Konsul Ke Dokter' }
      ]
    },
    p6: {
      label: 'Nyeri Mempengaruhi',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Tidur', value: 'Tidur' },
        { text: 'Aktivitas Fisik', value: 'Aktivitas Fisik' },
        { text: 'Emosi', value: 'Emosi' },
        { text: 'Nafsu Makan', value: 'Nafsu Makan' }
      ]
    }
  },
  form15: {
    bab: [
      {
        label: 'Tak terkendali/tak teratur (perlu pencahar)',
        value: 0
      },
      {
        label: 'Kadang-kadang tak terkendali',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    bak: [
      {
        label: 'Tak terkendali/pakai kateter',
        value: 0
      },
      {
        label: 'Kadang-kadang tak terkendali (1x24 jam)',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    membersihkan_diri: [
      {
        label: 'Butuh pertolongan orang lain',
        value: 0
      },
      {
        label: 'Mandiri',
        value: 1
      }
    ],
    penggunaan_jamban: [
      {
        label: 'Tergantung pertolongan orang lain',
        value: 0
      },
      {
        label:
          'Perlu pertolongan pada beberapa kegiatan,\n' +
          'tetapi dapat mengerjakan sendiri kegiatan\n' +
          'yang lain',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    makan: [
      {
        label: 'Tidak mampu',
        value: 0
      },
      {
        label: 'Perlu ditolong memotong makanan',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    berubah_sikap: [
      {
        label: 'Tidak mampu',
        value: 0
      },
      {
        label: 'Perlu banyak bantuan untuk bisa duduk (2\n' + 'orang)',
        value: 1
      },
      {
        label: 'Bantuan (2 orang)',
        value: 2
      },
      {
        label: 'Mandiri',
        value: 3
      }
    ],
    berpindah: [
      {
        label: 'Tidak mampu',
        value: 0
      },
      {
        label: 'Bisa (pindah) dengan kursi roda',
        value: 1
      },
      {
        label: 'Berjalan dengan bantuan 1 orang',
        value: 2
      },
      {
        label: 'Mandiri',
        value: 3
      }
    ],
    memakai_baju: [
      {
        label: 'Tergantung orang lain',
        value: 0
      },
      {
        label: 'Sebagian dibantu (misalnya: mengancing\n' + 'baju)',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    naik_turun_tangga: [
      {
        label: 'Tidak mampu',
        value: 0
      },
      {
        label: 'Butuh pertolongan',
        value: 1
      },
      {
        label: 'Mandiri',
        value: 2
      }
    ],
    mandi: [
      {
        label: 'Tergantung orang lain',
        value: 0
      },
      {
        label: 'Mandiri',
        value: 1
      }
    ]
  },
  form16: {
    label1: {
      label: 'Intake Nutrisi Lewat',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    intake_nutrisi_lewat: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Oral', value: 'Oral' },
        { text: 'NGT', value: 'NGT' },
        { text: 'TPN/PPN', value: 'TPN/PPN' },
        { text: 'Gastrotomy', value: 'Gastrotomy' }
      ]
    }
  },
  form17: {
    masalah_nutrisi1: {
      label: 'Masalah Yang Berhubungan Dengan Masalah Nutrisi :',
      widget: 'wcheckbox',
      col: 4,
      data: [
        {
          text: 'Mendapat Kemotherapi',
          value: 'Mendapat Kemotherapi'
        },
        {
          text: 'Pasien Operasi Usia > 65 Tahun',
          value: 'Pasien Operasi Usia > 65 Tahun'
        },
        {
          text: 'Malnutrisi',
          value: 'Malnutrisi'
        },
        {
          text: 'Disfagia',
          value: 'Disfagia'
        },
        {
          text: 'Diet Saat Ini',
          value: 'Diet Saat Ini'
        }
      ]
    },
    masalah_nutrisi2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        {
          text: 'Hamil/Menyusui',
          value: 'Hamil/Menyusui'
        },
        {
          text: 'Nausea',
          value: 'Nausea'
        },
        {
          text: 'Obesitas',
          value: 'Obesitas'
        },
        {
          text: 'BB Menurun/Meninggal Dalam 1 bulan Terakhir',
          value: 'BB Menurun/Meninggal Dalam 1 bulan Terakhir'
        },
        {
          text: 'Makanan Kesukaan',
          value: 'Makanan Kesukaan'
        }
      ]
    },
    masalah_nutrisi3: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        {
          text: 'Vomitus',
          value: 'Vomitus'
        },
        {
          text: 'Sulit Menelan',
          value: 'Sulit Menelan'
        }
      ]
    }
  },
  form18: {
    label1: {
      label: '2. Keamanan : Pasang Pengaman Tempat Tidur/Bed Ralls',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    keamanan: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' },
        { text: 'Bel Mudah Dijangkau', value: 'Bel Mudah Dijangkau' }
      ]
    },
    label3: {
      label: '3. Resiko Melarikan Diri :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    resiko_melarikan_diri: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        {
          text: 'Tidak Ada Masalah Teridentifikasi',
          value: 'Tidak Ada Masalah Teridentifikasi'
        },
        { text: 'Resiko Karena', value: 'Resiko Karena' },
        { text: 'Dementia', value: 'Dementia' },
        {
          text: 'Tinggal Dilingkungan Rumah Sakit',
          value: 'Tinggal Dilingkungan Rumah Sakit'
        },
        { text: 'Bingung', value: 'Bingung' },
        { text: 'Gangguan Status Mental', value: 'Gangguan Status Mental' },
        {
          text: 'Menolak Tinggal Dirumah Sakit',
          value: 'Menolak Tinggal Dirumah Sakit'
        },
        {
          text: 'Tinggal Dilingkungan yang Diawasi',
          value: 'Tinggal Dilingkungan yang Diawasi'
        }
      ]
    }
  },
  form19: {
    label1: {
      label: '1. Frekuensi Nyeri *)',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    frekuensi_nyeri: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Jarang Hilang', value: 'Jarang Hilang' },
        { text: 'Timbul Terus Menerus', value: 'Timbul Terus Menerus' }
      ]
    },
    label2: {
      label: '2. Lama Nyeri :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    lama_nyeri: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 8
    },
    label3: {
      label: '3. Menjalar :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    menjalar: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Ke', value: null, field: 'auto' }
      ]
    },
    label4: {
      label: '4. Kualitas Nyeri',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    kualitas_nyeri: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Nyeri Tumpul', value: 'Nyeri Tumpul' },
        { text: 'Nyeri Tajam', value: 'Nyeri Tajam' },
        { text: 'Panas Terbakar', value: 'Panas Terbakar' }
      ]
    },
    label5: {
      label: '5. Faktor Pemicu/Memperberat :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    faktor_pemicu: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 8
    },
    label6: {
      label: '6. Lokasi Nyeri ***) :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    lokasi_nyeri: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 8
    },
    label7: {
      label: '7. Tindak Lanjut *) :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    tindak_lanjut: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Edukasi', value: 'Edukasi' },
        { text: 'Intervensi', value: 'Intervensi' },
        { text: 'Konsul Ke Dokter', value: null, field: 'auto' }
      ]
    },
    label8: {
      label: 'Nyeri Mempengaruhi :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    nyeri_mempengaruhi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidur', value: 'Tidur' },
        { text: 'Aktivitas Fisik', value: 'Aktivitas Fisik' },
        { text: 'Emosi', value: 'Emosi' },
        { text: 'Nafsu Makan', value: 'Nafsu Makan' }
      ]
    }
  },
  form21: {
    title: 'Anak (Berdasarkan Strong)',
    data: [
      {
        title: 'Apakah pasien tampak kurus',
        no: 1,
        data: [
          {
            title: 'a. Tidak',
            value: 0
          },
          {
            title: 'b. Ya',
            value: 1
          }
        ]
      },
      {
        title: 'Apakah terdapat penurunan BB selama satu bulan terakhir? (Berdasarkan penilaian objektif data BB bila ada/penilaian subjektif dari orang tua pasien Atau untuk bayi ≤ 1 Tahun BB tidak naik selama 3 bulan terakhir)',
        no: 2,
        data: [
          {
            title: 'a. Tidak',
            value: 0
          },
          {
            title: 'b. Ya',
            value: 1
          }
        ]
      },
      {
        title: 'Apakah terdapat salah satu dari kondisi berikut? (diare ≥ 5 kali/hari dan atau muntah ≥ 3 kali/hari dalam seminggu terakhir atau asupan makanan berkurang selama 1 minggu terakhir)',
        no: 3,
        data: [
          {
            title: 'a. Tidak',
            value: 0
          },
          {
            title: 'b. Ya',
            value: 1
          }
        ]
      },
      {
        title: 'Apakah terdapat penyakit atau keadaan yang mengakibatkan pasien berisiko mengalami malnutrisi?',
        no: 4,
        data: [
          {
            title: 'a. Tidak',
            value: 0
          },
          {
            title: 'b. Ya',
            value: 1
          }
        ]
      }
    ]
  },
  form22: {
    labelx: {
      label: '1. Pola Istirahat Dan Tidur',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    pola_istirahat_dan_tidur: {
      label: ' ',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Tidak Ada Kelainan', value: 'Tidak Ada Kelainan' },
        { text: 'Gelisah', value: 'Gelisah' },
        { text: 'Sukar Tidur', value: 'Sukar Tidur' },
        { text: 'Tidur Sering Terbangun', value: 'Tidur Sering Terbangun' }
      ]
    },
    label1: {
      label: 'Lama Tidur : ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    lama_tidur: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: []
    },
    label2: {
      label: 'Tidur Siang : ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    tidur_siang: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'Jam', type: 'number' } }
    },
    label3: {
      label: 'Tidur Malam : ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    tidur_malam: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'Jam', type: 'number' } }
    },
    labelx2: {
      label: 'Hal Hal Yang Dapat Membantu Cepat Tidur ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    hal_cepat_tidur: {
      label: ' ',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Minum Susu / Teh', value: 'Minum Susu / Teh' },
        { text: 'Membaca', value: 'Membaca' },
        { text: 'Lampu Dimatikan', value: 'Lampu Dimatikan' },
        { text: 'Mendengarkan Musik', value: 'Mendengarkan Musik' },
        { text: 'Lain Lainnya', value: 'Lain Lainnya' }
      ]
    },
    labelx3: {
      label: '2. Makan Dan Minum',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    labelx4: {
      label: 'Makan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    makan: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'X/Hari', type: 'number' } }
    },
    labelx5: {
      label: 'Jenis',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    jenis_makan: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: []
    },
    labelx6: {
      label: 'Pantangan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    pantangan: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: []
    },
    labelx7: {
      label: 'Diet Khusus',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    diet_khusus: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    labelx8: {
      label: 'Minum',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    minum: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'X/Hari', type: 'number' } }
    },
    labelx9: {
      label: 'Jenis',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    jenis_minum: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: []
    },
    labelx10: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    labelx11: {
      label: '3. Kebersihan Diri :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    labelx12: {
      label: 'Mandi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    mandi: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'X/Hari', type: 'number' } }
    },
    labelx13: {
      label: 'Sekat Gigi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    sekat_gigi: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'X/Hari', type: 'number' } }
    },
    labelx14: {
      label: 'Keramas',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    keramas: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'X/Hari', type: 'number' } }
    },
    labelx15: {
      label: '4. Olah Raga',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    olah_raga: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    }
  },
  form23: {
    label1: {
      label: '1. Bicara',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bicara: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Normal', value: 'Normal' },
        {
          text: 'Serangan Awal Gangguan Bicara, Kapan',
          value: null,
          field: 'auto'
        }
      ]
    },
    label2: {
      label: '2. Bahasa Sehari-Hari',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bahasa_sehari_hari: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Indonesia, Aktif/Pasif', value: 'Indonesia, Aktif/Pasif' },
        { text: 'Inggris, Aktif/Pasif', value: 'Inggris, Aktif/Pasif' },
        { text: 'Daerah, Jelaskan', value: null, field: 'auto' },
        { text: 'Lain-Lain, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: '3. Perlu Penerjemah',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    perlu_penerjemah: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Bahasa', value: null, field: 'auto' },
        { text: 'Bahasa Isyarat', value: 'Bahasa Isyarat' }
      ]
    },
    label4: {
      label: '4. Hambatan Belajar',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    hambatan_belajar: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Bahasa', value: 'Bahasa' },
        { text: 'Cemas', value: 'Cemas' },
        { text: 'Menulis', value: 'Menulis' }
      ]
    },
    label5: {
      label: '5. Cara Belajar Yang Disukai',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    cara_belajar: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Audio-Visual/Gambar', value: 'Audio-Visual/Gambar' },
        { text: 'Diskusi', value: 'Diskusi' }
      ]
    },
    label6: {
      label: '6. Tingkat Pendidikan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pendidikan_pasien: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'TK', value: 'TK' },
        { text: 'SD', value: 'SD' },
        { text: 'SMP', value: 'SMP' },
        { text: 'SMA', value: 'SMA' },
        { text: 'Akademi', value: 'Akademi' },
        { text: 'Sarjana', value: 'Sarjana' }
      ]
    },
    label7: {
      label: '7. Potensi Kebutuhan Pelajaran',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    potensi_kebutuhan: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Proses Penyakit', value: 'Proses Penyakit' },
        { text: 'Pengobatan/Tindakan', value: 'Pengobatan/Tindakan' },
        { text: 'Terapi', value: 'Terapi' },
        { text: 'Nutrisi', value: 'Nutrisi' }
      ]
    },
    label8: {
      label: '8. Pasien/Keluarga Menginginkan Informasi Tentang',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    menginginkan_informasi: {
      label: ' ',
      widget: 'wcheckbox',
      col: 5,
      data: [
        { text: 'Penyakit Yang Diderita', value: 'Penyakit Yang Diderita' },
        {
          text: 'Tindakan/Pengobatan Dan Perawatan Yang Diberikan',
          value: 'Tindakan/Pengobatan Dan Perawatan Yang Diberikan'
        },
        {
          text: 'Perubahann Aktifitas Sehari-Hari',
          value: 'Perubahann Aktifitas Sehari-Hari'
        }
      ]
    },
    menginginkan_informasi2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        {
          text: 'Tindakan Pemeriksaan Lanjut',
          value: 'Tindakan Pemeriksaan Lanjut'
        },
        {
          text: 'Perawatan Setelah Dirumah',
          value: 'Perawatan Setelah Dirumah'
        },
        {
          text: 'Perencanaan Diet Dan Menu',
          value: 'Perencanaan Diet Dan Menu'
        }
      ]
    }
  },
  form24: {
    labelx: {
      label: 'Pengetahuan Pasien Dan Keluarga Tentang',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label1: {
      label: '1. Penyakit Yang Diderita',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    penyakit_yang_diderita: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label2: {
      label: '2. Tindakan Pengobatan Dan Perawatan Yang Diberikan',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    tindakan_pengobatan: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label3: {
      label: '3. Perencanaan Diet dan Menu',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    perencanaan_diet: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label4: {
      label: '4. Perubahan Aktifitas Sehari Hari',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    perubahan_aktifitas: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label5: {
      label: '5. Perawatan Setelah Dirumah',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    perawatan_setelah_dirumah: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    }
  },
  form25: {
    respon_psikologis: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        {
          text: 'Takut Terhadap Terapi/Pembedahan/Lingkungan RS',
          value: 'Takut Terhadap Terapi/Pembedahan/Lingkungan RS'
        },
        { text: 'Marah/Tegang', value: 'Marah/Tegang' },
        { text: 'Sedih', value: 'Sedih' },
        { text: 'Gelisah', value: 'Gelisah' },
        { text: 'Menangis', value: 'Menangis' }
      ]
    },
    respon_psikologis2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        { text: 'Mudah Tersinggung', value: 'Mudah Tersinggung' },
        { text: 'Senang', value: 'Senang' },
        { text: 'Cemas', value: 'Cemas' },
        { text: 'Rendah', value: 'Rendah' },
        { text: 'Tidak Mampu Menahan Diri', value: 'Tidak Mampu Menahan Diri' }
      ]
    }
  },
  form25b: {
    p1: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        {
          text: 'nyeri',
          value: 'nyeri'
        },
        {
          text: 'Keselamatan Pasien / Resiko Jatuh',
          value: 'Keselamatan Pasien / Resiko Jatuh'
        },
        {
          text: 'Penangan Nutrisi',
          value: 'Penangan Nutrisi'
        },
        {
          text: 'Suhu Tubuh',
          value: 'Suhu Tubuh'
        },
        {
          text: 'Jalan Nafas / Perukaran Gas',
          value: 'Jalan Nafas / Perukaran Gas'
        },
        {
          text: 'Perfusi Jaringan',
          value: 'Perfusi Jaringan'
        },
        {
          text: 'Eliminasi',
          value: 'Eliminasi'
        },
        {
          text: 'Intergritas Kulit',
          value: 'Intergritas Kulit'
        }
      ]
    },
    p2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        {
          text: 'Pola Tidur',
          value: 'Pola Tidur'
        },
        {
          text: 'Tumbuh Kembang',
          value: 'Tumbuh Kembang'
        },
        {
          text: 'Cemas',
          value: 'Cemas'
        },
        {
          text: 'Mobilitas / Aktivitas',
          value: 'Mobilitas / Aktivitas'
        },
        {
          text: 'Konflik Peran',
          value: 'Konflik Peran'
        },
        {
          text: 'Perawatan Diri',
          value: 'Perawatan Diri'
        },
        {
          text: 'Pengetahuan',
          value: 'Pengetahuan'
        },
        {
          text: 'Komunikasi',
          value: 'Komunikasi'
        },
        {
          text: 'Keseimbangan Cairan & Elektrolit',
          value: 'Keseimbangan Cairan & Elektrolit'
        }
      ]
    }
  },
  form26: {
    label1: {
      label: 'Laboratorium',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    laboratorium: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ada,', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label2: {
      label: 'Radiologi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    radiologi: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ada,', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label3: {
      label: 'Lain-lain',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    lain_lain: {
      label: ' ',
      widget: 'wtext',
      col: 9,
      data: []
    }
  },
  form27: {
    label1: {
      label: 'Diet dan Nutrisi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    diet_dan_nutrisi: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label2: {
      label: 'Rehabilitasi Medik',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    rehabilitasi_medik: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: 'Farmasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    farmasi: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label4: {
      label: 'Perawatan Luka',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    perawatan_luka: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label5: {
      label: 'Manajemen Nyeri',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    manajemen_nyeri: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label6: {
      label: 'Lain - lain',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    lain_lain: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    }
  },
  form28: {
    label1: {
      label: 'Pasien dan Keluarga Direncanakan Pulang',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    direncanakan_pulang: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    label2: {
      label: '1. Lama Perawatan Rata-Rata',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    lama_perawatan: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { suffix: 'Hari', type: 'number' } }
    },
    label3: {
      label: '2. Tanggal Rencana Pulang',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tanggal_rencana_pulang: {
      label: ' ',
      widget: 'wdatenormal',
      col: 2,
      data: []
    },
    p1: {
      label: '3. Perawatan Lanjutan Yang Diberikan Dirumah',
      widget: 'wcheckbox',
      col: 12,
      data: [
        { text: 'Personal Higiene (Mandi, BAB/BAK)', value: 'Personal Higiene (Mandi, BAB/BAK)' },
        { text: 'Pemberian Minum NGT/OGT/Sendok/Dot Bayi', value: 'Pemberian Minum NGT/OGT/Sendok/Dot Bayi' },
        { text: 'Perawatan Luka', value: 'Perawatan Luka' },
        { text: 'Pemantauan diet', value: 'Pemantauan diet' },
        { text: 'Perawatan Bayi / Anak', value: 'Perawatan Bayi / Anak' },
        { text: 'Latihan Gerak / Exercise', value: 'Latihan Gerak / Exercise' },
        { text: 'Pemberian Obat', value: 'Pemberian Obat' },
        { text: 'Pemeriksaan Laboratorium Lanjutan', value: 'Pemeriksaan Laboratorium Lanjutan' },
        { text: 'Lain-lain', value: null, field: 'auto' },
        { text: 'Penyakit / Diagnosa', value: 'Penyakit / Diagnosa' }
      ]
    },
    p2: {
      label: '4. Bayi/Anak Tinggal Bersama',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'OT Kandung', value: 'OT Kandung' },
        { text: 'Keluarga,', value: null, field: 'auto' }
      ]
    },
    p3: {
      label: '5. Transportasi Yang Digunakan',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Kendaraan Pribadi (Mobil, Beroda Dua Dll)', value: 'Kendaraan Pribadi (Mobil, Beroda Dua Dll)' },
        { text: 'Kendaraan umum', value: 'Kendaraan umum' },
        { text: 'Mobil Ambulance', value: 'Mobil Ambulance' },
        { text: 'Lain-lain', value: 'Lain-lain' }
      ]
    }
  }
}
