<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">Observasi Khusus</h3>
    <v-card color="deep-purple lighten-1" dark>
      <v-card-text>
        <v-row class="mt-8 mb-0">
          <v-col
            v-for="(v, i) in master.demografi"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-1 mb-1 pt-2 pb-1"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.demografi[i] = e)"
              :value="data.demografi[i]"
              vclass="c-text-field"
              :data="v.data"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider class="mt-3 mb-3" />
    <div id="table-scroll" class="table-scroll mt-2">
      <div class="table-wrap">
        <table
          id="responsive-datatable"
          class="table table-bordered table-striped table-md"
          width="100%"
        >
          <thead>
            <tr>
              <th rowspan="2">Tanggal dan Jam</th>
              <th rowspan="2">Tekanan Darah</th>
              <th rowspan="2">Tekanan Nadi</th>
              <th rowspan="2">Suhu Badan</th>
              <th rowspan="2">Pernapasan</th>
              <th rowspan="2">SPO2%</th>
              <th rowspan="2">Skor Nyeri</th>
              <th rowspan="2">GCS</th>
              <th colspan="2">CAIRAN</th>
              <th rowspan="2">Obat - Obatan</th>
              <th rowspan="2">Dosis</th>
              <th colspan="6">Cara Pemberian Obat</th>
              <th rowspan="2">Catatan Perawatan/Bidan</th>
              <th rowspan="2">Ttd. Perawat / Bidan</th>
              <th rowspan="2"></th>
            </tr>
            <tr>
              <th>Input</th>
              <th>Output</th>
              <th>IV</th>
              <th>Oral</th>
              <th>Drip</th>
              <th>Supp</th>
              <th>Per<br />Vagina</th>
              <th>Lain - Lain</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(x, y) in master.form1" :key="y">
              <td v-for="(v, i) in x" :key="i">
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form1[y][i] = e)"
                  :value="data.form1[y][i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </td>
              <td>
                <v-btn
                  @click="hapusBaris(y)"
                  class="mt-2"
                  fab
                  color="error"
                  dense
                  outlined
                  x-small
                  elevation="2"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <br />
    <button @click="tambahBaris()" class="btn btn-primary btn-block">
      Tambah Baris
    </button>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/observasi-khusus'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      ayam: '',
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: [
          {
            tgljam: '',
            td: '',
            n: '',
            s: '',
            rr: '',
            spo2: '',
            skor_nyeri: '',
            gcs: '',
            cairan_input: '',
            cairan_output: '',
            obat_obatan: '',
            dosis: '',
            iv: '',
            oral: '',
            drip: '',
            supp: '',
            per_vagina: '',
            lain_lain: '',
            catatan_perawat: '',
            ttd_perawat: ''
          }
        ]
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
    }
  },
  methods: {
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Populasi Khusus'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
          } else {
          }
        },
        'JSON'
      )
    },
    tambahBaris () {
      this.data.form1.push({
        tgljam: '',
        td: '',
        n: '',
        s: '',
        rr: '',
        spo2: '',
        skor_nyeri: '',
        gcs: '',
        cairan_input: '',
        cairan_output: '',
        obat_obatan: '',
        dosis: '',
        iv: '',
        oral: '',
        drip: '',
        supp: '',
        per_vagina: '',
        lain_lain: '',
        catatan_perawat: '',
        ttd_perawat: ''
      })
      this.master.form1.push({
        tgljam: {
          label: ' ',
          widget: 'wdate',
          col: 12,
          data: []
        },
        td: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        n: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        s: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        rr: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        spo2: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        skor_nyeri: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        gcs: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        cairan_input: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        cairan_output: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        obat_obatan: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        dosis: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        iv: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        oral: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        drip: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        supp: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        per_vagina: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        lain_lain: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        catatan_perawat: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        ttd_perawat: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        }
      })
    },
    hapusBaris (index) {
      this.master.form1.splice(index, 1)
      this.data.form1.splice(index, 1)
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Populasi Khusus'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style>
.table-scroll {
  position: relative;
  max-width: 2790px;
  margin: auto;
  overflow: hidden;
  border: 1px #000;
}

.table-wrap {
  width: 100%;
  overflow: auto;
}

.table-scroll table {
  width: 100%;
  margin: auto;
  border: 1px solid #e8e7e3;

  border-collapse: separate;
  border-spacing: 0;
}

.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  border: 1px solid #e8e7e3;
  /* background: #fff; */
  white-space: nowrap;
  vertical-align: top;
}

.table-scroll thead,
.table-scroll tfoot {
  background: #f9f9f9;
}

.clone {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.clone th,
.clone td {
  visibility: hidden;
}

.clone td,
.clone th {
  border-color: transparent;
}

.clone tbody th {
  visibility: visible;
  color: red;
}

.clone .fixed-side {
  border: 1px solid #e8e7e3;
  background: #eee;
  visibility: visible;
}

.clone thead,
.clone tfoot {
  background: transparent;
}

.float-group {
  position: fixed;
  bottom: 50%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
