<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">ASESMEN LANJUTAN RESIKO JATUH ANAK DAN INTERVENSI SKALA HUMPTY - DUMPTY</h3>
    <v-row justify="center">
      <v-col cols="12">
        <v-card color="deep-purple lighten-1" dark>
          <v-card-text>
            <v-row class="mt-8 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-simple-table class="elevation-1" dense style="font-size: 10px">
          <template v-slot:default>
            <thead>
              <tr>
                <td colspan="21">
                  Penilaian resiko jatuh ini dilakukan ketika pasien masuk, ketika terjadi perubahan kondisi, ketika pindah ke ruangan lain atau setelah terjadi jatuh
                </td>
              </tr>
              <tr>
                <td colspan="2" rowspan="2">
                  <v-row class="mt-0 mb-0">
                    <v-col
                      v-for="(v, i) in master.formkesadaran"
                      :md="v.col ? v.col : 12"
                      :lg="v.col ? v.col : 12"
                      :sm="v.col ? v.col : 12"
                      cols="12"
                      class="mt-0 mb-0 pt-0 pb-3"
                      :key="i"
                    >
                      <smart-widget
                        :comp="v.widget"
                        :sync-value="(e) => (data.formkesadaran[i] = e.value)"
                        vclass="c-text-field"
                        :data="v.data"
                        :property="v.property"
                        :label-size="v.labelSize"
                        :input-size="v.inputSize"
                        :label="v.label ? v.label : i.replaceAll('_', ' ')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                  </v-row>
                </td>
                <td>Tgl</td>
                <td colspan="3" v-for="item in 6" :key="item">
                  <v-row class="mt-0 mb-0">
                    <v-col
                      v-for="(v, i) in master.formtanggal"
                      :md="v.col ? v.col : 12"
                      :lg="v.col ? v.col : 12"
                      :sm="v.col ? v.col : 12"
                      cols="12"
                      class="mt-0 mb-0 pt-0 pb-3"
                      :key="i"
                    >
                      <smart-widget
                        :comp="v.widget"
                        :sync-value="(e) => (data.form12[i] = e.value)"
                        vclass="c-text-field"
                        :data="v.data"
                        :property="v.property"
                        :label-size="v.labelSize"
                        :input-size="v.inputSize"
                        :label="v.label ? v.label : i.replaceAll('_', ' ')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                  </v-row>
                </td>
              </tr>
              <tr>
                <td>Jam</td>
                <td v-for="item in 18" :key="item">
                  <v-row class="mt-0 mb-0">
                    <v-col
                      v-for="(v, i) in master.formwaktu"
                      :md="v.col ? v.col : 12"
                      :lg="v.col ? v.col : 12"
                      :sm="v.col ? v.col : 12"
                      cols="12"
                      class="mt-0 mb-0 pt-0 pb-3"
                      :key="i"
                    >
                      <smart-widget
                        :comp="v.widget"
                        :sync-value="(e) => (data.form12[i] = e.value)"
                        vclass="c-text-field"
                        :data="v.data"
                        :property="v.property"
                        :label-size="v.labelSize"
                        :input-size="v.inputSize"
                        :label="v.label ? v.label : i.replaceAll('_', ' ')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                  </v-row>
                </td>
              </tr>
              <tr>
                <th colspan="2">FAKTOR RESIKO SKALA HUMPTY-DUMPTY</th>
                <th class="text-center">Nilai</th>
                <th class="text-center">P</th><th class="text-center">S</th><th class="text-center">M</th>
                <th class="text-center">P</th><th class="text-center">S</th><th class="text-center">M</th>
                <th class="text-center">P</th><th class="text-center">S</th><th class="text-center">M</th>
                <th class="text-center">P</th><th class="text-center">S</th><th class="text-center">M</th>
                <th class="text-center">P</th><th class="text-center">S</th><th class="text-center">M</th>
                <th class="text-center">P</th><th class="text-center">S</th><th class="text-center">M</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(d, i) in master.form1.p1" :key="i">
              <td v-if="i === 0">Umur</td>
              <td v-else></td>
              <td>{{ d.label }}</td>
              <td>{{d.value}}</td>
              <td v-for="item in 18" :key="item">
                <v-radio-group
                  style="margin-top: 0; margin-bottom: 0"
                  v-model="data.form1.p1[item]"
                >
                  <v-radio :value="d.value" label=""></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr v-for="(d, i) in master.form1.p2" :key="'a'+i">
              <td v-if="i === 0">Jenis Kelamin</td>
              <td v-else></td>
              <td>{{ d.label }}</td>
              <td>{{d.value}}</td>
              <td v-for="item in 18" :key="item">
                <v-radio-group
                  style="margin-top: 0; margin-bottom: 0"
                  v-model="data.form1.p2[item]"
                >
                  <v-radio :value="d.value" label=""></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr
              v-for="(d, i) in master.form1.p3"
              :key="'b'+i"
            >
              <td v-if="i === 0">Diagnosa</td>
              <td v-else></td>
              <td>{{ d.label }}</td>
              <td>{{d.value}}</td>
              <td v-for="item in 18" :key="item">
                <v-radio-group
                  style="margin-top: 0; margin-bottom: 0"
                  v-model="data.form1.p3[item]"
                >
                  <v-radio :value="d.value" label=""></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr v-for="(d, i) in master.form1.p4" :key="'c'+i">
              <td v-if="i === 0">Gangguan Kognitif</td>
              <td v-else></td>
              <td>{{ d.label }}</td>
              <td>{{d.value}}</td>
              <td v-for="item in 18" :key="item">
                <v-radio-group
                  style="margin-top: 0; margin-bottom: 0"
                  v-model="data.form1.p4[item]"
                >
                  <v-radio :value="d.value" label=""></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr v-for="(d, i) in master.form1.p5" :key="'d'+i">
              <td v-if="i === 0">Faktor Lingkungan</td>
              <td v-else></td>
              <td>{{ d.label }}</td>
              <td>{{d.value}}</td>
              <td v-for="item in 18" :key="item">
                <v-radio-group
                  style="margin-top: 0; margin-bottom: 0"
                  v-model="data.form1.p5[item]"
                >
                  <v-radio :value="d.value" label=""></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr v-for="(d, i) in master.form1.p6" :key="'e'+i">
              <td v-if="i === 0">Respon Te</td>
              <td v-else></td>
              <td>{{ d.label }}</td>
              <td>{{d.value}}</td>
              <td v-for="item in 18" :key="item">
                <v-radio-group
                  style="margin-top: 0; margin-bottom: 0"
                  v-model="data.form1.p6[item]"
                >
                  <v-radio :value="d.value" label=""></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr v-for="(d, i) in master.form1.p7" :key="'f'+i">
              <td v-if="i === 0">Inkontinensia</td>
              <td v-else></td>
              <td>{{ d.label }}</td>
              <td>{{d.value}}</td>
              <td v-for="item in 18" :key="item">
                <v-radio-group
                  style="margin-top: 0; margin-bottom: 0"
                  v-model="data.form1.p7[item]"
                >
                  <v-radio :value="d.value" label=""></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <th colspan="2">Total Skor</th>
              <th></th>
              <th class="text-center" v-for="item in 18" :key="item">{{totalresikojatuh(item)}}</th>
            </tr>
            <tr>
              <th colspan="3">RR : Resiko Rendah (0-6)<br>RS : Resiko Sedang (7-11)<br>RT : Resiko Tinggi (≥12)</th>
              <th v-for="item in 18" :key="item">
                <v-row class="mt-0 mb-0">
                  <v-col
                    v-for="(v, i) in master.penilaian_resiko_jatuh"
                    :md="v.col ? v.col : 12"
                    :lg="v.col ? v.col : 12"
                    :sm="v.col ? v.col : 12"
                    cols="12"
                    class="mt-0 mb-0 pt-0 pb-3"
                    :key="i"
                  >
                    <smart-widget
                      :comp="v.widget"
                      :sync-value="(e) => (data.penilaian_resiko_jatuh[item] = e.value)"
                      vclass="c-text-field"
                      :data="v.data"
                      :property="v.property"
                      :label-size="v.labelSize"
                      :input-size="v.inputSize"
                      :label="v.label ? v.label : i.replaceAll('_', ' ')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </th>
            </tr>
            <tr>
              <th colspan="21">
                INTERVENSI PENCEGAHAN RESIKO JATUH BERI CEKLIS, INTERVENSI STANDAR UNTUK SEMUA RESIKO CEKLIS
              </th>
            </tr>
            <tr v-for="(x,y) in master.pencegahan" :key="'ab'+y">
              <td colspan="3">{{x.label}}</td>
              <td v-for="item in 18" :key="y+'-'+item">
                <v-checkbox
                  v-model="data.pencegahan[x.key][item]"
                  label=" "
                  class="mt-2"
                />
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/FormAsesmenLanjutanResikoJatuhAnakDanIntervensiSkalaHumptyDumpty'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      ayam: '',
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        formkesadaran: {},
        form1: {
          p1: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0
          },
          p2: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0
          },
          p3: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0
          },
          p4: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0
          },
          p5: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0
          },
          p6: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0
          },
          p7: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0
          }
        },
        penilaian_resiko_jatuh: {},
        pencegahan: {
          p1: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p2: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p3: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p4: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p5: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p6: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p7: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p8: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p9: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p10: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p11: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p12: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p13: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p14: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p15: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          },
          p16: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: ''
          }
        },
        skor: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
          13: 0,
          14: 0,
          15: 0,
          16: 0,
          17: 0,
          18: 0
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  computed: {
    totalresikojatuh: function () {
      var hmm = this
      var x = this.data.form1
      return (kolom) => {
        const sum = parseInt(x.p1[kolom]) + parseInt(x.p2[kolom]) + parseInt(x.p3[kolom]) + parseInt(x.p4[kolom]) + parseInt(x.p5[kolom]) + parseInt(x.p6[kolom]) + parseInt(x.p7[kolom])
        if (isNaN(sum)) {
          hmm.data.skor[kolom] = 0
          return 0
        } else {
          hmm.data.skor[kolom] = sum
          return sum
        }
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
    }
  },
  methods: {
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Populasi Khusus'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
          } else {
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Populasi Khusus'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style>
.wrap {
  width: 100%;
  height: 690px;
  padding: 0;
  overflow: hidden;
}
.frame {
  width: 1280px;
  height: 926px;
  border: 0;
  -ms-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.float-group {
  position: fixed;
  bottom: 50%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.framclass {
  width: 1280px;
  height: 786px;
  border: 0;
  -ms-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.Div1 {
  border: 1px solid #ddd;
  width: 24%;
  background-color: white;
  float: left;
  border: 2px solid #c00;
  margin-right: 5px;
  min-height: 50px;
  position: fixed;
}
</style>
