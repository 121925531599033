export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form1: [
    {
      tgljam: {
        label: ' ',
        widget: 'wdate',
        col: 12,
        data: []
      },
      td: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      n: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      s: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      rr: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      spo2: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      skor_nyeri: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      gcs: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      cairan_input: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      cairan_output: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      obat_obatan: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      dosis: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      iv: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      oral: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      drip: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      supp: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      per_vagina: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      lain_lain: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      catatan_perawat: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      ttd_perawat: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      }
    }
  ]
}
