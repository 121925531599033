export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      disabled: true,
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      disabled: true,

      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Ruangan',
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      disabled: true,

      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'Dokter DPJP',
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    label1: {
      label: 'Dokter yang Merawat',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    dokter_yang_merawat: {
      label: ' ',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 9
    },
    label2: {
      label: 'Tiba di Ruangan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tiba_diruangan_tanggal: {
      widget: 'wdatenormal',
      data: [],
      disabled: true,

      col: 4,
      label: 'Tanggal'
    },
    tiba_diruangan_jam: {
      label: 'Jam',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3,
      property: { attrs: { suffix: '', type: 'string' } }
    },
    label3: {
      label: 'Pengkajian',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pengkajian_tanggal: {
      widget: 'wdatenormal',
      data: [],
      disabled: true,

      col: 4,
      label: 'Tanggal'
    },
    pengkajian_jam: {
      label: 'Jam',
      disabled: true,

      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { suffix: '', type: 'string' } }
    },
    label5: {
      label: 'Cara Masuk',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    cara_masuk: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Jalan', value: 'jalan' },
        { text: 'Kursi Roda', value: 'kursi_roda' },
        { text: 'Brangkar/Troley*', value: 'brangkar/troley' },
        { text: 'Inkubator', value: 'inkubator' },
        { text: 'Box', value: 'box' }
      ],
      col: 9
    },
    label6: {
      label: 'Asal Pasien',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    asal_pasien: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'IRJ', value: 'irj' },
        { text: 'IGD', value: 'igd' },
        { text: 'Kamar Operasi', value: 'kamar_operasi' },
        { text: 'Kamar Bersalin', value: 'kamar_bersalin' },
        { text: 'Dokter Pribadi', value: 'dokter_pribadi' },
        { text: 'Rujukan', value: 'rujukan' }
      ],
      col: 9
    },
    label7: {
      label: 'Masuk Kasus Trauma',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    masuk_kasus_trauma: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'KLL', value: 'kll' },
        { text: 'KDRT', value: 'kdrt' },
        { text: 'Tidak', value: 'tidak' }
      ],
      col: 9
    },
    label8: {
      label: 'Hasil Pemeriksaan yang Dibawa oleh Keluarga',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    hasil_yang_dibawa: {
      label: ' ',
      widget: 'wcheckbox',
      data: [
        { text: 'Laboratorium', value: 'laboratorium' },
        { text: 'Radiologi', value: 'radiologi' },
        { text: 'Dignostik Lain', value: 'diagnostik_lain' },
        { text: 'Tidak Ada', value: 'tidak_ada' }
      ],
      col: 9
    }
  },
  form2: {
    label1: {
      label: 'Pekerjaan Pasien',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    pekerjaan_pasien: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'PNS/TNI/POLRI', value: 'pns/tni/polri' },
        { text: 'Swasta', value: 'swasta' },
        { text: 'Pelajar/Mahasiswa', value: 'pelajar/mahasiswa' },
        { text: 'Pensiun', value: 'pensiun' },
        { text: 'IRT', value: 'irt' },
        { text: 'Lain-lain', value: null, field: 'auto' }
      ]
    },
    label2: {
      label: 'Pekerjaan Penanggung Jawab/OT Pasien',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    pekerjaan_ot_pasien: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'PNS/TNI/POLRI', value: 'pns/tni/polri' },
        { text: 'Swasta', value: 'swasta' },
        { text: 'Pensiun', value: 'pensiun' },
        { text: 'IRT', value: 'irt' },
        { text: 'Lain-lain', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: '  ',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    pekerjaan_ot_pasien_gaji: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: '< 1 juta', value: '< 1 juta' },
        { text: '1 - 2,9 juta', value: '1 - 2,9 juta' },
        { text: '3 - 4,9 juta', value: '3 - 4,9 juta' },
        { text: '5 - 9,9 juta', value: '5 - 9,9 juta' },
        { text: '10 - 14,9 juta', value: '10 - 14,9 juta' },
        { text: '15 - 19,9 juta', value: '15 - 19,9 juta' },
        { text: '> 20 juta', value: '> 20 juta' }
      ]
    },
    label4: {
      label: 'Pendidikan Pasien',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    pendidikan_pasien: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'TK', value: 'TK' },
        { text: 'SD', value: 'SD' },
        { text: 'SMP', value: 'SMP' },
        { text: 'SLTA', value: 'SLTA' },
        { text: 'Akademi', value: 'Akademi' },
        { text: 'Paska Sarjana', value: 'Paska Sarjana' }
      ]
    },
    label5: {
      label: 'Pendidikan Penanggung Jawab/OT/Suami/Istri',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    pendidikan_ot_pasien: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'TK', value: 'TK' },
        { text: 'SD', value: 'SD' },
        { text: 'SMP', value: 'SMP' },
        { text: 'SLTA', value: 'SLTA' },
        { text: 'Akademi', value: 'Akademi' },
        { text: 'Paska Sarjana', value: 'Paska Sarjana' }
      ]
    },
    label6: {
      label: 'Cara Pembayaran',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    cara_pembayaran: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Pribadi', value: 'Pribadi' },
        { text: 'Perusahaan', value: 'Perusahaan' },
        { text: 'Asuransi', value: 'Asuransi' },
        { text: 'BPJS', value: 'BPJS' },
        { text: 'Lain-lain', value: null, field: 'auto' }
      ]
    },
    label7: {
      label: 'Cara Pembayaran',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    tinggal_bersama: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Keluarga', value: 'Keluarga' },
        { text: 'Suami/Istri', value: 'Suami/Istri' },
        { text: 'Orang Tua', value: 'Orang Tua' },
        { text: 'Anak', value: 'Anak' },
        { text: 'Teman', value: 'Teman' },
        { text: 'Sendiri', value: 'Sendiri' },
        { text: 'Panti Asuhan', value: 'Panti Asuhan' }
      ]
    }
  },
  form3: {
    agama: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Islam', value: 'Pribadi' },
        { text: 'Protestan', value: 'Protestan' },
        { text: 'Katolik', value: 'Katolik' },
        { text: 'Hindu', value: 'Hindu' },
        { text: 'Budha', value: 'Budha' },
        { text: 'Konghucu', value: 'Konghucu' }
      ]
    },
    kebutuhan_privasi_pasien: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Titdak', value: 'Titdak' },
        { text: 'Keinginan Waktu / Tempat Khusus Wawancara dan Tindakan', value: null, field: 'auto' },
        { text: 'Kondisi Penyakit/Pengobatan', value: null, field: 'auto' },
        { text: 'Tidak Menerima Kunjungan,Sebutkan jika ada', value: null, field: 'auto' },
        { text: 'Tidak Mau Dirawat Perawat Laki-laki/Perempuan', value: null, field: 'auto' },
        { text: 'Transportasi', value: 'Transportasi' },
        { text: 'Lainnya', value: null, field: 'auto' }
      ]
    }
  },
  form4: {
    nilai1: {
      label: 'Nilai-Nilai Kepercayaan Pasien/Keluarga',
      widget: 'wcheckbox',
      col: 6,
      data: [
        {
          text: 'Tidak Mau Ditransfusi',
          value: 'Tidak Mau Ditransfusi'
        },
        {
          text: 'Tidak Mau Imunisasi',
          value: 'Tidak Mau Imunisasi'
        },
        {
          text: 'Tidak Makan Daging/Ikan yang Bersisik',
          value: 'Tidak Makan Daging/Ikan yang Bersisik'
        }
      ]
    },
    nilai2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        {
          text: 'Tidak Mau Pulang di Hari Tertentu',
          value: 'Tidak Mau Pulang di Hari Tertentu'
        },
        {
          text: 'Tidak Boleh Menyusui (ASI)',
          value: 'nyeri'
        },
        {
          text: 'lain-lain',
          value: null,
          field: 'auto'
        }
      ]
    },
    label2: {
      label: 'Hambatan Belajar',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    hambatan_belajar: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Pandangan Terbatas', value: 'Pandangan Terbatas' },
        { text: 'Keterbatasan Fisik', value: 'Keterbatasan Fisik' },
        { text: 'Tidak Bisa Membaca', value: 'Tidak Bisa Membaca' }
      ]
    },
    label3: {
      label: 'Hambatan Belajar',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    hambatan_bahasa: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Pendengaran Terbatas', value: 'Pendengaran Terbatas' },
        { text: 'Hambatan Emosi', value: 'Hambatan Emosi' },
        { text: 'Motivasi Buruk', value: 'Motivasi Buruk' }
      ]
    }
  },
  form5: {
    label1: {
      label: '1. Keluhan Utama : ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    keluhan_utama: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    label2: {
      label: '2. Riwayat Penyakit Sekarang : ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    riwayat_penyakit_sekarang: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    label3: {
      label: '3. Riwayat Penyakit Dahulu(termasuk Riwayat Operasi):',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label3a: {
      label: 'a. Pernah Dirawat :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pernah_dirawat: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label3b: {
      label: 'b. Pernah Operasi/Tindakan :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pernah_operasi_tindakan: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label3c: {
      label: 'c. Masalah Operasi/Pembiusan :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    masalah_operasi_pembiusan: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    }
  },
  form6: {
    label1: {
      label: '4. Riwayat Penyakit Keluarga',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_penyakit_keluarga: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ]
    },
    label2: {
      label: '5. Riwayat Konsumsi Obat Penenang',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_konsumsi_obat_penenang: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, jenis', value: null, field: 'auto' }
      ]
    },
    riwayat_konsumsi_obat_jumlah: {
      label: 'Jumlahnya,',
      widget: 'wtext',
      col: 5,
      data: []
    },
    label3: {
      label: '6. Riwayat Konsumsi Obat Penenang',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_merokok: {
      label: ' ',
      widget: 'wradio',
      col: 2,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'jumlah/hari', value: null, field: 'auto' }
      ]
    },
    riwayat_merokok_lama: {
      label: 'lamanya,',
      widget: 'wtext',
      col: 6,
      data: []
    },
    label4: {
      label: '7. Riwayat Minum Minuman Keras',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_minum_keras: {
      label: ' ',
      widget: 'wradio',
      col: 2,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'jumlah/hari', value: null, field: 'auto' }
      ]
    },
    riwayat_minum_keras_lama: {
      label: 'lamanya,',
      widget: 'wtext',
      col: 6,
      data: []
    },
    label8: {
      label: '8. Obat dari Rumah',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    obat_dari_rumah: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, Sebutkan', value: null, field: 'auto' }
      ]
    },
    label9: {
      label: '9. Riwayat Konsumsi Obat Pengencer Darah (Aspirin, Warfarin, Plavix, dll)',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_konsumsi_obat: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, Kapan', value: null, field: 'auto' }
      ]
    },
    label10: {
      label: '10. Riwayat Alergi',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_alergi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', value: null, field: 'auto' }
      ]
    },
    label11: {
      label: '11. Riwayat Menerima Transfusi Darah',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    riwayat_transfusi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ya, Waktu Terakhir', value: null, field: 'auto' }
      ]
    },
    label12: {
      label: '12. Reaksi yang Timbul',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    reaksi_yang_timbul: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, ', value: null, field: 'auto' }
      ]
    },
    label13a: {
      label: '13. Golongan Darah',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    golongan_darah: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'O', value: 'O' },
        { text: 'AB', value: 'AB' }
      ]
    },
    label13b: {
      label: 'Rh',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    rh: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Positif', value: 'Positif' },
        { text: 'Negatif', value: 'Negatif' }
      ]
    },
    label14: {
      label: '14. Khusus Pasien dengan Riwayat Komoterapi dan Radioterapi',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    riwayat_kemoterapi: {
      label: ' ',
      widget: 'wradio',
      col: 2,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ada,kapan ', value: null, field: 'auto' }
      ]
    },
    sudah_berapa_kali: {
      label: 'Sudah Berapa Kali',
      widget: 'wtext',
      data: [],
      col: 2
    },
    kemo_terahir: {
      label: 'Terakhir',
      widget: 'wtext',
      data: [],
      col: 8
    },
    label14b: {
      label: 'b. Cara Pemberian',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    cara_pemberian: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Melalui Suntik', value: 'Melalui Suntik' },
        { text: 'Melalui Infus', value: 'Melalui Infus' },
        { text: 'Melalui Oral/Minum', value: 'Melalui Oral/Minum' }
      ]
    },
    label14c: {
      label: 'c. Riwayat Radioterapi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    riwayat_radioterapi: {
      label: ' ',
      widget: 'wradio',
      col: 2,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada,kapan ', value: null, field: 'auto' }
      ]
    },
    jumlah_radioterapi: {
      label: 'Berapa Kali',
      widget: 'wtext',
      data: [],
      col: 8
    },
    label14d: {
      label: 'd. Efek Samping',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    efek_samping: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Mual', value: 'Mual' },
        { text: 'Muntah', value: 'Muntah' },
        { text: 'Jantung Berdebar', value: 'Jantung Berdebar' },
        { text: 'Pusing', value: 'Pusing' },
        { text: 'Rambut Rontok', value: 'Rambut Rontok' }
      ]
    },
    label15a: {
      label: '15. Riwayat Pernikahan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    riwayat_pernikahan: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Belum Menikah', value: 'Belum Menikah' },
        { text: 'Menikah, Lama Menikah', value: null, field: 'auto' }
      ]
    },
    label15b: {
      label: 'Pernikahan ke',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    pernikahan_ke: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 5
    }
  },
  form7: {
    label1: {
      label: 'Penggunaan Alat:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label2: {
      label: 'ETT:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    ukuran_ett: {
      label: 'Ukuran',
      widget: 'wtext',
      col: 2,
      data: null
    },
    batas_bibir_ett: {
      label: 'Batas bibir',
      widget: 'wtext',
      col: 2,
      data: null
    },
    tanggal_pemasangan_ett: {
      label: 'Tanggal Pemasangan',
      widget: 'wdatenormal',
      col: 6,
      data: null
    },
    label3: {
      label: 'Trakeostomi:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    ukuran_trakeostomi: {
      label: 'Ukuran',
      widget: 'wtext',
      col: 2,
      data: null
    },
    batas_bibir_trakeostomi: {
      label: 'Batas bibir',
      widget: 'wtext',
      col: 2,
      data: null
    },
    tanggal_pemasangan_trakeostomi: {
      label: 'Tanggal Pemasangan',
      widget: 'wdatenormal',
      col: 6,
      data: null
    },
    label4: {
      label: 'Oro Pharengeal:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    ukuran_oro_pharengeal: {
      label: 'Ukuran',
      widget: 'wtext',
      col: 2,
      data: null
    },
    batas_bibir_oro_pharengeal: {
      label: 'Batas bibir',
      widget: 'wtext',
      col: 2,
      data: null
    },
    tanggal_pemasangan_oro_pharengeal: {
      label: 'Tanggal Pemasangan',
      widget: 'wdatenormal',
      col: 6,
      data: null
    },
    label5: {
      label: 'Lainnya:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    alat_lainnya: {
      label: 'Lainnya',
      widget: 'wtext',
      col: 2,
      data: null
    }
  },
  form8: {
    label1: {
      label: 'Jalan Napas:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label2: {
      label: 'Sekret:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    jalan_nafas_sekret: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label3: {
      label: 'Karakteristik Sekret:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    karakteristik_sekret: {
      label: ' ',
      widget: 'wtext',
      col: 9,
      data: null
    },
    label4: {
      label: 'Selang ETT',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label5: {
      label: 'Kebocoran',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    selang_kebocoran: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label6: {
      label: 'Terlipat',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    selang_terlipat: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    }
  },
  form9: {
    label2: {
      label: 'Ventilator:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    ventilator: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label3: {
      label: 'Mode Ventilator:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    mode_ventilator: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Control', value: 'Control' },
        { text: 'SIMV Pressure', value: 'SIMV Pressure' },
        { text: 'SIMV Volume', value: 'SIMV Volume' },
        { text: 'APRV/Biphasic', value: 'APRV/Biphasic' },
        { text: 'CPAP', value: 'CPAP' }
      ]
    },
    label4: {
      label: 'Setting ventilator',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label5: {
      label: 'Tidal Volume',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    setting_venti_tidal_volume: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: null
    },
    label6: {
      label: 'RR',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    setting_venti_rr: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: null
    },
    label7: {
      label: 'I:E Ratio',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    setting_venti_ieratio: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: null
    },
    label8: {
      label: 'PEEP',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    setting_venti_peep: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: null
    },
    label9: {
      label: 'FiO2',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    setting_venti_fio2: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: null
    },
    label10: {
      label: 'PS',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    setting_venti_ps: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: null
    }
  },
  form10: {
    label2: {
      label: 'Terapi oksigen:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label5: {
      label: 'Nasal kanul',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    terapi_oksigen_nasal_kanul: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: [],
      property: { attrs: { prefix: '', suffix: 'ltr/mnt', type: 'string' } }
    },
    label6: {
      label: 'Simple mask',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    terapi_oksigen_simple_mask: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: [],
      property: { attrs: { prefix: '', suffix: 'ltr/mnt', type: 'string' } }
    },
    label7: {
      label: 'RM',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    terapi_oksigen_rm: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: [],
      property: { attrs: { prefix: '', suffix: 'ltr/mnt', type: 'string' } }
    },
    label8: {
      label: 'NRM',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    terapi_oksigen_nrm: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: [],
      property: { attrs: { prefix: '', suffix: 'ltr/mnt', type: 'string' } }
    }
  },
  form11: {
    label2: {
      label: 'Sianosis:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    sianosis: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label3: {
      label: 'Perifer:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    perifer: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Ektermitas', value: 'Ektermitas' },
        { text: 'Telinga', value: 'Telinga' },
        { text: 'Hidung', value: 'Hidung' }
      ]
    },
    label4: {
      label: 'Sentral:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    sentral: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Lidah', value: 'Lidah' },
        { text: 'Bibir', value: 'Bibir' }
      ]
    },
    label5: {
      label: 'RR:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    rr: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: [],
      property: { attrs: { prefix: '', suffix: 'x/mnt', type: 'string' } }
    },
    label6: {
      label: 'Suara nafas :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label6ka: {
      label: 'Ka',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    suara_nafas_ka: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: []
    },
    label6ki: {
      label: 'Ki',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    suara_nafas_ki: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: []
    },
    label7: {
      label: 'Hasil Rontgen Thoraks :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    hasil_rontgen: {
      label: ' ',
      widget: 'wtext',
      col: 8,
      data: []
    }
  },
  form12: {
    label2: {
      label: 'Hasil Lab Status Oksigenisasi',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label5: {
      label: 'AGD Tanggal',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    agd_tgl: {
      label: 'Tanggal Lahir',
      widget: 'wdatenormal',
      data: null,
      col: 4
    },
    agd_jam: {
      label: 'Jam',
      widget: 'wtext',
      data: null,
      col: 5
    },
    label6: {
      label: 'pH',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    status_oksigen_ph: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    },
    label7: {
      label: 'pCO2',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    status_oksigen_pco2: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    },
    label8: {
      label: 'pO2',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    status_oksigen_po2: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    },
    label9: {
      label: 'HCO3',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    status_oksigen_hco3: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    },
    label10: {
      label: 'O2 Saturasi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    status_oksigen_o2_saturasi: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    },
    label11: {
      label: 'BE',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    status_oksigen_be: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    },
    label12: {
      label: 'tCO2',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    status_oksigen_tco2: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    }
  },
  form13: {
    label1: {
      label: 'Auskultasi:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label2: {
      label: 'Bising Jantung:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    auskultasi_bising_jantung: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label3: {
      label: 'Irama Jantung:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    auskultasi_irama_jantung: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Reguler', value: 'Reguler' },
        { text: 'Irreguler', value: 'Irreguler' }
      ]
    },
    label4: {
      label: 'TD :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    auskultasi_td: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: [],
      property: { attrs: { prefix: '', suffix: 'mmHg', type: 'string' } }
    },
    label5: {
      label: 'MAP :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    auskultasi_map: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: [],
      property: { attrs: { prefix: '', suffix: 'mmHg', type: 'string' } }
    },
    label6: {
      label: 'HR :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    auskultasi_hr: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: [],
      property: { attrs: { prefix: '', suffix: 'mmHg', type: 'string' } }
    },
    label7: {
      label: 'Distensi vena jugularis :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    auskultasi_distensi_vena_jugularis: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label8: {
      label: 'JVP :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    auskultasi_jvp: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: [],
      property: { attrs: { prefix: '', suffix: 'cmH2O', type: 'string' } }
    },
    label9: {
      label: 'Terpasang CVP :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    auskultasi_cvp: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya, Lokasi', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    }
  },
  form14: {
    label1: {
      label: 'Palpasi Nadi:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    palpasi_nadi: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak teraba', value: 'Tidak teraba' },
        { text: 'Lemah', value: 'Lemah' },
        { text: 'Kuat', value: 'Kuat' }
      ]
    },
    label3: {
      label: 'Dorsalis Pedis:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    dorsalis_pedis: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak teraba', value: 'Tidak teraba' },
        { text: 'Lemah', value: 'Lemah' },
        { text: 'Kuat', value: 'Kuat' }
      ]
    },
    label4: {
      label: 'Pengisian kapiler:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pengisian_kapiler: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: '<2 detik', value: '<2 detik' },
        { text: '> 2Detik', value: '> 2Detik' }
      ]
    },
    label5: {
      label: 'Edema :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    auskultasi_td: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: [],
      property: { attrs: { prefix: '', suffix: 'mmHg', type: 'string' } }
    },
    label6: {
      label: 'Ekstermitas atas',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label6ka: {
      label: 'Ka',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    ekstrimitas_atas_ka: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: []
    },
    label6ki: {
      label: 'Ki',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    ekstrimitas_atas_ki: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: []
    },
    label7: {
      label: 'Hasil Ekg:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    hasil_ekg: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    }
  },
  form15: {
    label1: {
      label: 'Hasil Lab terkait Fungsi Jantung',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    lab_fungsi_jantung: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label3: {
      label: 'Enzim jantung Tgl',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    enzim_jantung_tgl: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      col: 8
    },
    label4: {
      label: 'CK:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    hasil_lab_ck: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    },
    label5: {
      label: 'CK-MB:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    hasil_lab_ck_mb: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    },
    label6: {
      label: 'Trop-I:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    hasil_lab_trop_i: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    }
  },
  form16: {
    label1: {
      label: 'Kesadaran :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kesadaran: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'CM', value: 'CM' },
        { text: 'Apatis', value: 'Apatis' },
        { text: 'Somnolen', value: 'Somnolen' },
        { text: 'Soporos', value: 'Soporos' },
        { text: 'Koma', value: 'Koma' }
      ]
    },
    label2: {
      label: 'GCS :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    gcs_e: {
      label: 'E',
      widget: 'wtext',
      col: 3,
      data: []
    },
    gcs_m: {
      label: 'M',
      widget: 'wtext',
      col: 3,
      data: []
    },
    gcs_v: {
      label: 'V',
      widget: 'wtext',
      col: 3,
      data: []
    },
    label5: {
      label: 'Mata:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    konjungtiva_anemis: {
      label: 'Konjungtiva Anemis',
      widget: 'wtext',
      col: 5,
      data: []
    },
    sklera_ikterik: {
      label: 'sklera_ikterik',
      widget: 'wtext',
      col: 5,
      data: []
    },
    label6: {
      label: 'Pupil:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    label7: {
      label: 'Ka:',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    pupil_ka: {
      label: ' ',
      widget: 'wtext',
      col: 4,
      data: []
    },
    label8: {
      label: 'Ki:',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    pupil_ki: {
      label: ' ',
      widget: 'wtext',
      col: 4,
      data: []
    },
    label9: {
      label: 'Reaksi Cahaya :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label10: {
      label: 'Ka:',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    reaksi_cahaya_ka: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: []
    },
    label11: {
      label: 'Ki:',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    reaksi_cahaya_ki: {
      label: ' ',
      widget: 'wtext',
      col: 4,
      data: []
    },
    label12: {
      label: 'Tanda tanda vital:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tanda_vital_td: {
      label: 'TD',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: 'mmhg', type: 'string' } }
    },
    tanda_vital_hr: {
      label: 'HR',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: '/mnt', type: 'string' } }
    },
    tanda_vital_rr: {
      label: 'RR',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: '/mnt', type: 'string' } }
    },
    tanda_vital_t: {
      label: 'T',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: 'C', type: 'string' } }
    },
    tanda_vital_spo2: {
      label: 'SpO2',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: '%', type: 'string' } }
    },
    tanda_vital_bb: {
      label: 'BB',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: 'Kg', type: 'string' } }
    },
    tanda_vital_tb: {
      label: 'TB',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: 'Cm', type: 'string' } }
    },
    tanda_vital_imt: {
      label: 'IMT',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: 'kg/m2', type: 'string' } }
    },
    tanda_vital_lk: {
      label: 'LK',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: 'Cm', type: 'string' } }
    },
    tanda_vital_ld: {
      label: 'LD',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: 'Cm', type: 'string' } }
    },
    tanda_vital_bbi: {
      label: 'BBI',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: '', suffix: '', type: 'string' } }
    }
  },
  form17: {
    label13: {
      label: 'Motorik:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label14: {
      label: 'Ka:',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    label15: {
      label: 'Ki:',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    motorik_ka_atas: {
      label: 'Atas',
      widget: 'wtext',
      col: 6,
      data: []
    },
    motorik_ki_atas: {
      label: 'Atas',
      widget: 'wtext',
      col: 6,
      data: []
    },
    motorik_ka_bawah: {
      label: 'Bawah',
      widget: 'wtext',
      col: 6,
      data: []
    },
    motorik_ki_bawah: {
      label: 'Bawah',
      widget: 'wtext',
      col: 6,
      data: []
    }
  },
  form18: {
    label0: {
      label: 'Pengkajian Resiko Jatuh Dewasa (Morse Fall Scale)',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label1: {
      label: '1. Riwayat Jatuh Di Rs <= 3 bulan:',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    riwayat_jatuh_di_rs: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Ya (25)', value: 'Ya (25)' },
        { text: 'Tidak (0)', value: 'Tidak (0)' }
      ],
      col: 7
    },
    label2: {
      label: '2. Diagnosa Sekunder ( >= 2 Diagnosa Medis ):',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    diagnosa_sekunder: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Ya (25)', value: 'Ya (25)' },
        { text: 'Tidak (0)', value: 'Tidak (0)' }
      ],
      col: 7
    },
    label3: {
      label: '3. Alat Bantu Berjalan:',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    alat_bantu_berjalan: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Tongkat /Walker (15)', value: 'Tongkat /Walker (15)' },
        { text: 'Mencekram Furnitur (30)', value: 'Mencekram Furnitur (30)' },
        { text: 'Tidak (0)', value: 'Tidak (0)' }
      ],
      col: 7
    },
    label4: {
      label: '4. IV Akses/ Terpasang Infus:',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    iv_akses_infus: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Ya (20)', value: 'Ya (20)' },
        { text: 'Tidak (0)', value: 'Tidak (0)' }
      ],
      col: 7
    },
    label5: {
      label: '5. Cara Berjalan : (Misal : Fraktur , Cedera, Ekstimitas Bawah, Dm, Hypertensi, Vertigo, Stroke, Dll)',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    cara_berjalan: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Normal (0)', value: 'Normal (0)' },
        { text: 'Kelelahan / Lemah (10)', value: 'Kelelahan / Lemah (10)' },
        { text: 'Keterbatasan / Terganggu (20)', value: 'Keterbatasan / Terganggu (20)' }
      ],
      col: 7
    },
    label6: {
      label: '6. Apakah Ada Gangguan Sensorik :',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    gangguan_sensorik: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Ya (15)', value: 'Ya (15)' },
        { text: 'Tidak (0)', value: 'Tidak (0)' }
      ],
      col: 7
    }
  },
  form19: {
    kondisi_fisik_umum_sn: [
      {
        label: 'Baik',
        value: '4'
      },
      {
        label: 'Cukup/lumayan',
        value: '3'
      },
      {
        label: 'Buruk',
        value: '2'
      },
      {
        label: 'Sangat Buruk',
        value: '1'
      }
    ],
    kesadaran_sn: [
      {
        label: 'Kompos Mentis',
        value: '4'
      },
      {
        label: 'Apatis',
        value: '3'
      },
      {
        label: 'Confused',
        value: '2'
      },
      {
        label: 'Stupor',
        value: '1'
      }
    ],
    tingkat_aktivitas_sn: [
      {
        label: 'Ambulatori',
        value: '4'
      },
      {
        label: 'Berjalan dengan bantuan',
        value: '3'
      },
      {
        label: 'Hanya bisa duduk',
        value: '2'
      },
      {
        label: 'Hanya bisa tiduran',
        value: '1'
      }
    ],
    mobilitas_sn: [
      {
        label: 'Bergerak Bebas',
        value: '4'
      },
      {
        label: 'Sedikit terbatas',
        value: '3'
      },
      {
        label: 'Sangat terbatas',
        value: '2'
      },
      {
        label: 'Tidak bisa bergerak/mobil',
        value: '1'
      }
    ],
    inkontinensia: [
      {
        label: 'Tidak ada',
        value: '4'
      },
      {
        label: 'Kadang-kadang',
        value: '3'
      },
      {
        label: 'Sering inkontinensia urin',
        value: '2'
      },
      {
        label: 'Inkontinensia urin dan alvi',
        value: '1'
      }
    ]
  },
  form20: {
    mengendalikan_rangsanan_defeksi: [
      {
        label: 'Tak terkendali/tak teratur (perlu pencahar)',
        value: '0'
      },
      {
        label: 'Kadang-kadang tak terkendali',
        value: '1'
      },
      {
        label: 'Mandiri',
        value: '2'
      }
    ],
    mengendalikan_rangsanan_berkemih: [
      {
        label: 'Tak terkendali/pakai kateter',
        value: '0'
      },
      {
        label: 'Kadang-kadang tak terkendali (1x24 jam)',
        value: '1'
      },
      {
        label: 'Mandiri',
        value: '2'
      }
    ],
    membersikan_diri: [
      {
        label: 'Butuh pertolongan orang lain',
        value: '0'
      },
      {
        label: 'Mandiri',
        value: '1'
      }
    ],
    penggunaan_jamban: [
      {
        label: 'Perlu pertolongan orang lain',
        value: '0'
      },
      {
        label: 'Perlu pertolongan pada beberapa kegiatan, tetapi dapat mengerjakan sendiri kegiatan yang lain',
        value: '1'
      },
      {
        label: 'Mandiri',
        value: '2'
      }
    ],
    makan: [
      {
        label: 'Tidak mampu',
        value: '0'
      },
      {
        label: 'Perlu ditolong memotong makanan',
        value: '1'
      },
      {
        label: 'Mandiri',
        value: '2'
      }
    ],
    berubah_sikap_berbaring: [
      {
        label: 'Tidak mampu',
        value: '0'
      },
      {
        label: 'Perlu banyak bantuan untuk bisa duduk (2 orang)',
        value: '1'
      },
      {
        label: 'Bantuan (2 Orang)',
        value: '2'
      },
      {
        label: 'Mandiri',
        value: '3'
      }
    ],
    berpindah_berjalan: [
      {
        label: 'Tidak mampu',
        value: '0'
      },
      {
        label: 'Bisa (pindah) dengan kursi roda',
        value: '1'
      },
      {
        label: 'Berjalan dengan bantuan (1 Orang)',
        value: '2'
      },
      {
        label: 'Mandiri',
        value: '3'
      }
    ],
    memakai_baju: [
      {
        label: 'Tergantung orang lain',
        value: '0'
      },
      {
        label: 'Sebagian dibantu (misalnya : mengancing baju)',
        value: '1'
      },
      {
        label: 'Mandiri',
        value: '2'
      }
    ],
    naik_turun_tangga: [
      {
        label: 'Tidak mampu',
        value: '0'
      },
      {
        label: 'Butuh pertolongan',
        value: '1'
      },
      {
        label: 'Mandiri',
        value: '2'
      }
    ],
    mandi: [
      {
        label: 'Tergantung orang lain',
        value: '0'
      },
      {
        label: 'Mandiri',
        value: '1'
      }
    ]
  },
  form21: {
    label0: {
      label: 'PENGAKAJIAN NYERI',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label1: {
      label: '1. Provokatif (faktor yang mengurangi):',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    provokatif: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Kompres Hangat', value: 'Kompres Hangat' },
        { text: 'Kompres Dingin', value: 'Kompres Dingin' },
        { text: 'Farmakoterapi', value: 'Farmakoterapi' }
      ],
      col: 9
    },
    label2: {
      label: '2. Quality/quantitas nyeri:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    quality_quantitas_nyeri: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Ditusuk', value: 'Ditusuk' },
        { text: 'Dipukul', value: 'Dipukul' },
        { text: 'Berdenyut', value: 'Berdenyut' },
        { text: 'Ditarik', value: 'Ditarik' },
        { text: 'Tajam', value: 'Tajam' }
      ],
      col: 9
    },
    label3: {
      label: '3. Region / radiasi (lokasi nyeri):',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    region_radiasi: {
      label: ' ',
      widget: 'wtext',
      col: 9
    },
    label4: {
      label: '4. Severity/skala (seberapa jauh nyeri):',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    severity_skala: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Tidak Nyeri', value: 'Tidak Nyeri' },
        { text: 'Ringan', value: 'Ringan' },
        { text: 'Sedang', value: 'Sedang' },
        { text: 'Berat', value: 'Berat' }
      ],
      col: 9
    },
    label5: {
      label: '5. Time (lama nyeri)',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    time_lama_nyeri: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Terus-menerus', value: 'Terus-menerus' },
        { text: 'Hilang Timbul', value: 'Hilang Timbul' },
        { text: '>30 menit', value: '>30 menit' },
        { text: '<30 menit', value: '<30 menit' }
      ],
      col: 9
    },
    label6: {
      label: 'Konsul Ke Dokter:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    konsul_ke_dokter: {
      label: ' ',
      widget: 'wtext',
      col: 9
    },
    label7: {
      label: 'Nyeri Mempengaruhi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    nyeri_mempengaruhi: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'Tidur', value: 'Tidur' },
        { text: 'Aktivitas Fisik', value: 'Aktivitas Fisik' },
        { text: 'Emosi', value: 'Emosi' },
        { text: 'Nafsu Makan', value: 'Nafsu Makan' }
      ],
      col: 9
    }
  },
  form22: {
    ekspresi_wajah_ccpot: [
      {
        label: 'Tidak tampak kontraksi otot wajah',
        value: '0'
      },
      {
        label: 'Dahi mengkerut, penurunan alis mata,membuka mata atau menangis selama prosedur invasif',
        value: '1'
      },
      {
        label: 'Mengringis (menggigit ETT)',
        value: '2'
      }
    ],
    gerakan_tubuh_ccpot: [
      {
        label: 'Tidak ada pergerakan atau tidak ada gerakan lokasi nyeri',
        value: '0'
      },
      {
        label: 'Gerakan lambat,gerakan hati-hati, menyentuh bagian nyeri',
        value: '1'
      },
      {
        label: 'Menarik ETT, mencoba untuk duduk, mengerakkan tungkai/meronta-ronta,tidak mengikuti perintah,mencoba turun dari tempat tidur',
        value: '2'
      }
    ],
    kepatuhan_pasang_ventilator_ccpot: [
      {
        label: 'Alarm tidak bunyi , ventilasi mudah',
        value: '0'
      },
      {
        label: 'Batuk, alarm aktif tapi berhenti secara spontan',
        value: '1'
      },
      {
        label: 'Tidak singkron, ventilasi tertahan, alarm sering bunti',
        value: '2'
      }
    ],
    vokalisasi_ccpot: [
      {
        label: 'Berbicara dalam bahasa normal atau tidak bersuara sama sekali',
        value: '0'
      },
      {
        label: 'mengehela napas, merintih',
        value: '1'
      },
      {
        label: 'menangis , terisak-isak',
        value: '2'
      }
    ],
    ketegangan_otot_ccpot: [
      {
        label: 'Tidak ada perlawanan pada gerakan pasien',
        value: '0'
      },
      {
        label: 'Ada perlawanan pada gerakan pasif',
        value: '1'
      },
      {
        label: 'Perlawanan kuat pada gerakan pasif atau tidak bisa dilakukan gerakan pasif',
        value: '2'
      }
    ],
    pengunaan_analgetik_ccpot: [
      {
        label: 'Tidak menggunakan analgetik/sedatif',
        value: '0'
      },
      {
        label: 'Menggunakan analgetik/sedatif intermiten',
        value: '1'
      },
      {
        label: 'Menggunakan analgetik/sedatif secara continue',
        value: '2'
      }
    ]
  },
  form23: {
    label0: {
      label: 'INTAKE (sebelumnya)',
      widget: 'wlabelkiri',
      data: [],
      col: 6
    },
    label1: {
      label: 'OUTPUT (sebelumnya):',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label2: {
      label: 'OUTPUT (sebelumnya):',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    label3: {
      label: 'Infus',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    infus: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: 'cc', type: 'string' } }
    },
    label4: {
      label: 'Urine',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    urine: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: 'cc', type: 'string' } }
    },
    label5: {
      label: 'Oral/NGT/OGT',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    oral: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: 'cc', type: 'string' } }
    },
    label6: {
      label: 'IWL',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    iwl: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: 'cc', type: 'string' } }
    },
    label7: {
      label: 'med.Drip',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    med: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: 'cc', type: 'string' } }
    },
    label8: {
      label: 'Drain',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    drain: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: 'cc', type: 'string' } }
    },
    label9: {
      label: 'Balance Cairan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    balance_cairan: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: 'cc', type: 'string' } }
    },
    label10: {
      label: 'Drain kepala',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    drain_kepala: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: 'cc', type: 'string' } }
    }
  },
  form24: {
    label0: {
      label: 'Kateter Urine',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label1: {
      label: 'Terpasang',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    kateter_terpasang: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya, Tanggal', widget: 'wtext', data: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label3: {
      label: 'Jenis',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    kateter_jenis: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Folley', value: 'Folley' },
        { text: 'Kondom', value: 'Kondom' },
        { text: 'Suprapubik', value: 'Suprapubik' }
      ]
    },
    label4: {
      label: 'Karateristik Urine',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label5: {
      label: 'Warna',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    warna_urine: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Bening', value: 'Bening' },
        { text: 'Kuning', value: 'Kuning' },
        { text: 'Merah', value: 'Merah' },
        { text: 'Kecoklatan', value: 'Kecoklatan' }
      ]
    },
    label6: {
      label: 'Pola BAK (Deskripsikan)',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    pola_bak: {
      label: ' ',
      widget: 'wtext',
      col: 9,
      data: null
    }
  },
  form25: {
    label0: {
      label: 'Hasil Lab/Pem . Penunjang Lain Terkait Fungsi Ginjal',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label3: {
      label: 'Elektrolit Tgl',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    elektrolit_tgl: {
      label: ' ',
      widget: 'wdatenormal',
      data: null,
      col: 9
    },
    label4: {
      label: 'Na+',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    na: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: '', type: 'string' } }
    },
    label5: {
      label: 'K+',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    k: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: '', type: 'string' } }
    },
    label6: {
      label: 'Cl',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    cl: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: '', type: 'string' } }
    },
    label7: {
      label: 'Ca2+',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    ca2: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: '', type: 'string' } }
    },
    label8: {
      label: 'P',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: '', type: 'string' } }
    },
    label9: {
      label: 'Mg2+',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    mg2: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: '', type: 'string' } }
    },
    label10: {
      label: 'Cr',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    cr: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: '', type: 'string' } }
    },
    label11: {
      label: 'Ur',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    ur: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: '', suffix: '', type: 'string' } }
    }
  },
  form26: {
    label0: {
      label: 'Karakteristik Feses (warna, konsistensi)',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    karakteristik_feses: {
      label: ' ',
      widget: 'wtext',
      col: 12,
      data: null
    },
    label1: {
      label: 'Pola BAB (Deskripsikan)',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    pola_bab: {
      label: ' ',
      widget: 'wtext',
      col: 12,
      data: null
    },
    label3: {
      label: 'Bising Usus',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bising_usus: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label4: {
      label: 'Asites',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    asites: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label5: {
      label: 'Lingkaran Abdomen',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    lingkaran_abdomen: {
      label: ' ',
      widget: 'wtext',
      col: 9,
      data: null
    },
    label6: {
      label: 'Hemoroid',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    hemoroid: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label7: {
      label: 'Stoma/Kolostomi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    stoma_kolostomi: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya, Lokasi', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    }
  },
  form27: {
    label1: {
      label: 'Nyeri Tekan abdomen / teraba massa :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label1ka: {
      label: 'Ka atas',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    nyeri_abdomen_ka_atas: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: []
    },
    label1ki: {
      label: 'Ki atas',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    nyeri_abdomen_ki_atas: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: []
    },
    label2ka: {
      label: 'Ka bawah',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    nyeri_abdomen_ka_bawah: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: []
    },
    label2ki: {
      label: 'Ki bawah',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    nyeri_abdomen_ki_bawah: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: []
    }
  },
  form28: {
    label1: {
      label: 'Obstetri dan Ginekologi :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label2: {
      label: 'Hamil',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    hamil: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, HPHT', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    hamil_tp: {
      label: 'TP',
      widget: 'wtext',
      col: 2,
      data: null
    },
    hamil_g: {
      label: 'G',
      widget: 'wtext',
      col: 2,
      data: null
    },
    hamil_p: {
      label: 'P',
      widget: 'wtext',
      col: 2,
      data: null
    },
    hamil_a: {
      label: 'A',
      widget: 'wtext',
      col: 2,
      data: null
    },
    hamil_h: {
      label: 'H',
      widget: 'wtext',
      col: 2,
      data: null
    },
    hamil_keluhan: {
      label: 'Keluhan',
      widget: 'wtext',
      col: 2,
      data: null
    }
  },
  form29: {
    penurunan_bb_a: [
      {
        label: 'Tidak',
        value: 0
      },
      {
        label: 'Tidak Yakin (ada tanda baju menjadi lebih longgar)',
        value: 2
      }
    ],
    penurunan_bb_b: [
      {
        label: '1-5 Kg',
        value: 1
      },
      {
        label: '6-10 Kg',
        value: 2
      },
      {
        label: '11-15 Kg',
        value: 3
      },
      {
        label: '>15 Kg',
        value: 4
      },
      {
        label: 'Tidak tahu berapa kg turunnya',
        value: 5
      }
    ],
    penurunan_nafsu_makan: [
      {
        label: 'Tidak',
        value: 0
      },
      {
        label: 'Ya',
        value: 1
      }
    ]
  },
  form30: {
    label0: {
      label: 'Resiko Malnutrisi:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    resiko_malnutrisi: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Nilai 0-1 (Rendah)', value: 'Nilai 0-1 (Rendah)' },
        { text: 'Nilai 2-3 (Sedang)', value: 'Nilai 2-3 (Sedang)' },
        { text: 'Nilai 4-5 (Tinggi)', value: 'Nilai 4-5 (Tinggi)' }
      ]
    },
    label1: {
      label: 'Kebutuhan nutrisi aktual:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kebutuhan_nutrisi_aktual: {
      label: ' ',
      widget: 'wtext',
      col: 9,
      data: null
    }
  },
  form31: {
    label1: {
      label: 'Tugor kulit',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tugor_kulit: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak elastis', value: 'Tidak elastis' },
        { text: 'Elastis', value: 'Elastis' }
      ]
    },
    label2: {
      label: 'Pendarahan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pendarahan: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Gusi', value: 'Gusi' },
        { text: 'Hidung', value: 'Hidung' },
        { text: 'Pethecia', value: 'Pethecia' },
        { text: 'Echimosis', value: 'Echimosis' },
        { text: 'Lainnya', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label3: {
      label: 'Ada luka',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    ada_luka: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Lokasi luka', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label4: {
      label: 'Luka',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    luka: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Bakar', value: 'Bakar' },
        { text: 'Tusuk', value: 'Tusuk' },
        { text: 'Memar/Hematom', value: 'Memar/Hematom' }
      ]
    },
    label5: {
      label: 'Fraktur',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    frakur: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Lokasi fraktur', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label6: {
      label: 'Penggunaan alat bantu',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    penggunaan_alat_bantu: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, nama alat', widget: 'wtext', data: null, field: 'auto' }
      ]
    }
  },
  form32: {
    label0: {
      label: 'Hasil Lab/Pem.Penunjang Lain Terkait Fungsi Abdomen/Nutrisi :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label1: {
      label: 'Tgl:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tgl_labor: {
      label: 'Tanggal',
      widget: 'wdatenormal',
      data: null,
      col: 3
    },
    label2: {
      label: 'Hb:',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    hb: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3
    },
    label3: {
      label: 'Albumin',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    albumin: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3
    },
    label4: {
      label: 'Globumin',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    globumin: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3
    },
    label5: {
      label: 'Protein Total',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    protein_total: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3
    },
    label6: {
      label: 'SGOT',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    sgot: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3
    },
    label7: {
      label: 'SGPT',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    sgpt: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3
    },
    label8: {
      label: 'GDS',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    gds: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3
    },
    label9: {
      label: 'Ur',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    ur: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3
    },
    label10: {
      label: 'Cr',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    cr: {
      label: ' ',
      widget: 'wtext',
      data: null,
      col: 3
    }
  },
  form33: {
    label1: {
      label: '1. Bicara',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bicara: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Seranbgan Awal Gangguan bicara, kapan', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label2: {
      label: '2. Bahasa Sehari-hari',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bahasa_sehari: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Indonesia, Aktif/Pasif', value: 'Indonesia, Aktif/Pasif' },
        { text: 'Inggris, Aktif/Pasif', value: 'Inggris, Aktif/Pasif' },
        { text: 'Daerah, jelaskan :', widget: 'wtext', data: null, field: 'auto' },
        { text: 'Lainnya', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label3: {
      label: '3. Perlu Penerjemah',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    perlu_penerjemah: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Bahasa', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label3a: {
      label: 'Bahasa Isyarat',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bahasa_isyarat: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    label4: {
      label: '4. Hambatan Belajar',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    hambatan_belajar: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Bahasa', value: 'Bahasa' },
        { text: 'Cemas', value: 'Cemas' },
        { text: 'Menulis', value: 'Menulis' }
      ]
    },
    label5: {
      label: '5. Cara Belajar yang Disukai',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    cara_belajar: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Audio-visual/Gambar', value: 'Audio-visual/Gambar' },
        { text: 'Diskusi', value: 'Diskusi' }
      ]
    },
    label6: {
      label: '6. Potensi Kebutuhan Pelajaran',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    potensi_kebutuhan_pelajaran: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Proses penyakit', value: 'Proses penyakit' },
        { text: 'Pengobatan/ tindakan', value: 'Pengobatan/ tindakan' },
        { text: 'Terapi', value: 'Terapi' },
        { text: 'Nutrisi', value: 'Nutrisi' }
      ]
    },
    label7: {
      label: 'Pasien bersedia menerima informasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pasien_menerima_informasi: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    }
  },
  form34: {
    label1: {
      label: '1. Penyakit yang diderita',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    penyakit_diderita: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label2: {
      label: '2. Tindakan Pengobatan dan Perawatan Yang Diberikan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tindakan_pengobatan_diberikan: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label3: {
      label: '3. Perencaan Diet dan Menu',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    perencaan_diet: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label4: {
      label: '4. Perubahan Aktifitas Sehari Hari ',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    perubahan_aktifitas: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    },
    label5: {
      label: '5. Perawatan Setelah Dirumah ',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    perawatan_dirumah: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Kurang', value: 'Kurang' },
        { text: 'Cukup', value: 'Cukup' },
        { text: 'Baik', value: 'Baik' }
      ]
    }
  },
  form35: {
    label1: {
      label: 'Respon Psikologis',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    respon_psikologis: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Takut Terhadap Terapi/Pembedahan/Lingkungan RS', value: 'Takut Terhadap Terapi/Pembedahan/Lingkungan RS' },
        { text: 'Marah/Tegang', value: 'Marah/Tegang' },
        { text: 'Sedih', value: 'Sedih' },
        { text: 'Gelisah', value: 'Gelisah' },
        { text: 'Menangis', value: 'Menangis' },
        { text: 'Mudah Tersinggung ', value: 'Mudah Tersinggung ' },
        { text: 'Senang', value: 'Senang' },
        { text: 'Cemas', value: 'Cemas' },
        { text: 'Rendah', value: 'Rendah' },
        { text: 'Tidak Mampu Menahan Diri', value: 'Tidak Mampu Menahan Diri' }
      ]
    }
  },
  form36: {
    label1: {
      label: 'DAFTAR MASALAH KEPERAWATAN',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    daftar_masalah_keperawatan: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        { text: 'Nyeri', value: 'Nyeri' },
        { text: 'Keselamatan pasien/Resiko jatuh', value: 'Keselamatan pasien/Resiko jatuh' },
        { text: 'Penanganan Nutrisi', value: 'Penanganan Nutrisis' },
        { text: 'Suhu tubuh', value: 'Suhu tubuh' },
        { text: 'Jalan nafas/ Penukaran gas', value: 'Jalan nafas/ Penukaran gas' },
        { text: 'Perfusi jaringan', value: 'Perfusi jaringan' },
        { text: 'Eliminasi', value: 'Eliminasi' },
        { text: 'Intergritas kulit', value: 'Intergritas kulit' }
      ]
    },
    daftar_masalah_keperawatan2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        { text: 'Pola tidur', value: 'Pola tidur' },
        { text: 'Keseimbangan Cairan & Elektrolit', value: 'Keseimbangan Cairan & Elektrolit' },
        { text: 'Cemas', value: 'Cemas' },
        { text: 'Mobilitas/aktivitas', value: 'Mobilitas/aktivitas' },
        { text: 'Konflik peran', value: 'Konflik peran' },
        { text: 'Perawatan diri', value: 'Perawatan diri' },
        { text: 'Pengetahuan', value: 'Pengetahuan' },
        { text: 'Komunikasi', value: 'Komunikasi' }
      ]
    }
  },
  form37: {
    label1: {
      label: 'Diet dan Nutrisi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diet_nutrisi: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label2: {
      label: 'Rehabilitasi medik',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    rehabilitasi_medik: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label3: {
      label: 'Farmasi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    farmasi: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label4: {
      label: 'Perawatan Luka',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    perawatan_luka: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label5: {
      label: 'Manajeman Nyeri',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    manajemen_nyeri: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', widget: 'wtext', data: null, field: 'auto' }
      ]
    },
    label6: {
      label: 'Lain-lain',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    lain_lain: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya,', widget: 'wtext', data: null, field: 'auto' }
      ]
    }
  },
  form38: {
    label1: {
      label: 'Pasien dan Keluarga Direncanakan Pulang',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    pasien_perencanaan_pulang: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    label2: {
      label: 'Lama perawatan rata-rata',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    lama_perawatan: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: [],
      property: { attrs: { prefix: '', suffix: 'Hari', type: 'string' } }
    },
    label3: {
      label: 'Tanggal Rencana Pulang',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tgl_rencana_pulang: {
      label: ' ',
      widget: 'wdatenormal',
      data: null,
      col: 3
    },
    label4: {
      label: 'Kriteria Discharge Planning',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label4a: {
      label: '1. Umur >= 65 Tahun',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    umur_65: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    label4b: {
      label: '2. Keterbatasn Mobilitas',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    keterbatasan_mobilitas: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    label4c: {
      label: '3. Perawatan / Pengobatan Lanjutan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    perawatan_lanjutan: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    label4d: {
      label: '4. Bantuan untuk Melakukan Aktivitas Sehari-hari',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bantuan_untuk_aktivitas: {
      label: ' ',
      widget: 'wradio',
      col: 9,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    }
  },
  form39: {
    label1: {
      label: '5. Bila Salah Satu Jawaban "Ya" Dari Kriteria Pulang diatas, Maka Akan Dilanjutkan dengan Perencanaan Pulang Sbb:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    kriteria_perenncanaan_pulang: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        { text: 'Perawatan Diri (Mandiri, BAK, BAB)', value: 'Perawatan Diri (Mandiri, BAK, BAB)' },
        { text: 'Pemantuan Pemberian Obat', value: 'Pemantuan Pemberian Obat' },
        { text: 'Pemantuan Diet', value: 'Pemantuan Diet' },
        { text: 'Perawatan Luka', value: 'Perawatan Luka' },
        { text: 'Bantuan Medis/Perawatan dirumah Home Care', value: 'Bantuan Medis/Perawatan dirumah Home Care' },
        { text: 'Penanangan Kejang/demam/diare saat Dirumah', value: 'Penanangan Kejang/demam/diare saat Dirumah' },
        { text: 'Bantuan untuk Melakukan Akttifitas Fisik Kursi Roda, Alat Bantu jalan', value: 'Bantuan untuk Melakukan Akttifitas Fisik Kursi Roda, Alat Bantu jalan' }
      ]
    },
    kriteria_perenncanaan_pulang2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 6,
      data: [
        { text: 'Perawatan Post Nifas/post SC', value: 'Perawatan Post Nifas/post SC' },
        { text: 'Perawatan Bayi', value: 'Perawatan Bayi' },
        { text: 'Perawatan Payudara', value: 'Perawatan Payudara' },
        { text: 'Latihan Fisik Lanjutan', value: 'Latihan Fisik Lanjutan' },
        { text: 'Bantuan Tenaga Khusus Dirumah', value: 'Bantuan Tenaga Khusus Dirumah' }
      ]
    }
  }
}
