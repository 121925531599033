<template>
  <v-container fluid>
    <a href="#test0" id="test0"></a>
    <h3 class="mb-2 mt-3">Asesmen Keperawatan Rawat Inap Anak</h3>
    <v-row justify="center">
      <v-col cols="12" lg="10" sm="10" md="10">
        <v-card color="teal darken-1" class="mt-2" dark>
          <v-card-text>
            <v-row class="mt-3 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="12" sm="12" md="12">
        <v-tabs class="mb-3" centered stacked>
          <v-tab @click="tabKanan = 0">Hal 1</v-tab>
          <v-tab @click="tabKanan = 1">Hal 2</v-tab>
          <v-tab @click="tabKanan = 2">Hal 3</v-tab>
          <v-tab @click="tabKanan = 3">Hal 4</v-tab>
          <v-tab @click="tabKanan = 4">Hal 5</v-tab>
          <v-tab @click="tabKanan = 5">Hal 6</v-tab>
          <v-tab @click="tabKanan = 6">Hal 7</v-tab>
        </v-tabs>
        <div :hidden="tabKanan !== 0">
          <v-row class="mt-1 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form1"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form1[i] = e)"
                :value="data.form1[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :disabled="v.disabled"

                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <!--              <v-divider class="mt-3 mb-3" />-->
          <!--              <div class="font-weight-bold text-center">STATUS SOSIAL, EKONOMI, AGAMA, SUKU/BUDAYA, NILAI KEPERCAYAAN DAN KEBUTUHAN PRIVASI DAN EDUKASI</div>-->
          <!--              <v-divider class="mt-3 mb-3" />-->
          <!--              <div class="font-weight-bold mb-4">SOSIAL DAN EKONOMI</div>-->
          <!--              <v-row class="mt-0 mb-0">-->
          <!--                <v-col-->
          <!--                  v-for="(v, i) in master.form2"-->
          <!--                  :md="v.col ? v.col : 12"-->
          <!--                  :lg="v.col ? v.col : 12"-->
          <!--                  :sm="v.col ? v.col : 12"-->
          <!--                  cols="12"-->
          <!--                  class="mt-0 mb-0 pt-0 pb-3"-->
          <!--                  :key="i"-->
          <!--                >-->
          <!--                  <smart-widget-->
          <!--                    :comp="v.widget"-->
          <!--                    :sync-value="(e) => (data.form2[i] = e.value)"-->
          <!--                    vclass="c-text-field"-->
          <!--                    :data="v.data"-->
          <!--                    :property="v.property"-->
          <!--                    :label-size="v.labelSize"-->
          <!--                    :input-size="v.inputSize"-->
          <!--                    :label="v.label ? v.label : i.replaceAll('_', ' ')"-->
          <!--                    :rules="[rules.required]"-->
          <!--                  />-->
          <!--                </v-col>-->
          <!--              </v-row>-->
          <!--              <v-divider class="mt-3 mb-6" />-->
          <!--              <div class="font-weight-bold mb-4">SPIRITUAL (AGAMA)</div>-->
          <!--              <v-row class="mt-0 mb-0">-->
          <!--                <v-col-->
          <!--                  v-for="(v, i) in master.form3"-->
          <!--                  :md="v.col ? v.col : 12"-->
          <!--                  :lg="v.col ? v.col : 12"-->
          <!--                  :sm="v.col ? v.col : 12"-->
          <!--                  cols="12"-->
          <!--                  class="mt-0 mb-0 pt-0 pb-3"-->
          <!--                  :key="i"-->
          <!--                >-->
          <!--                  <smart-widget-->
          <!--                    :comp="v.widget"-->
          <!--                    :sync-value="(e) => (data.form3[i] = e.value)"-->
          <!--                    vclass="c-text-field"-->
          <!--                    :data="v.data"-->
          <!--                    :property="v.property"-->
          <!--                    :label-size="v.labelSize"-->
          <!--                    :input-size="v.inputSize"-->
          <!--                    :label="v.label ? v.label : i.replaceAll('_', ' ')"-->
          <!--                    :rules="[rules.required]"-->
          <!--                  />-->
          <!--                </v-col>-->
          <!--              </v-row>-->
          <!--              <v-divider class="mt-3 mb-6" />-->
          <!--              <div class="font-weight-bold mb-4">SUKU/BUDAYA</div>-->
          <!--              <v-row class="mt-0 mb-0">-->
          <!--                <v-col-->
          <!--                  v-for="(v, i) in master.form4"-->
          <!--                  :md="v.col ? v.col : 12"-->
          <!--                  :lg="v.col ? v.col : 12"-->
          <!--                  :sm="v.col ? v.col : 12"-->
          <!--                  cols="12"-->
          <!--                  class="mt-0 mb-0 pt-0 pb-3"-->
          <!--                  :key="i"-->
          <!--                >-->
          <!--                  <smart-widget-->
          <!--                    :comp="v.widget"-->
          <!--                    :sync-value="(e) => (data.form4[i] = e.value)"-->
          <!--                    vclass="c-text-field"-->
          <!--                    :data="v.data"-->
          <!--                    :property="v.property"-->
          <!--                    :label-size="v.labelSize"-->
          <!--                    :input-size="v.inputSize"-->
          <!--                    :label="v.label ? v.label : i.replaceAll('_', ' ')"-->
          <!--                    :rules="[rules.required]"-->
          <!--                  />-->
          <!--                </v-col>-->
          <!--              </v-row>-->
          <v-divider class="mt-3 mb-6" />
          <div class="font-weight-bold mb-4">ANAMNESIS</div>
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form5"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form5[i] = e)"
                :value="data.form5[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div :hidden="tabKanan !== 1" style="padding: 10px">
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form6"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form6[i] = e)"
                vclass="c-text-field"
                :value="data.form6[i]"
                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">RIWAYAT TUMBUH KEMBANG</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form6b"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form6b[i] = e)"
                vclass="c-text-field"
                :value="data.form6b[i]"
                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <h6>Riwayat Imunisasi</h6>
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
              <tr>
                <th style="font-size: 12px" class="text-left">IMUNISASI</th>
                <th style="font-size: 12px" class="text-center">1</th>
                <th style="font-size: 12px" class="text-center">2</th>
                <th style="font-size: 12px" class="text-center">3</th>
                <th style="font-size: 12px" class="text-center">4</th>
                <th style="font-size: 12px" class="text-center">5</th>
                <th style="font-size: 12px" class="text-center">6</th>
                <th style="font-size: 12px" class="text-left">IMUNISASI</th>
                <th style="font-size: 12px" class="text-center">1</th>
                <th style="font-size: 12px" class="text-center">2</th>
                <th style="font-size: 12px" class="text-center">3</th>
                <th style="font-size: 12px" class="text-center">4</th>
                <th style="font-size: 12px" class="text-center">5</th>
                <th style="font-size: 12px" class="text-center">6</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>BCG</td>
                <td v-for="item in 6" :key="'a'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['bcg'][item] = e)" vclass="c-text-field" :value="data.form6c['bcg'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td>Influenza</td>
                <td v-for="item in 6" :key="'b'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['influenza'][item] = e)" vclass="c-text-field" :value="data.form6c['influenza'][item]"
                    :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
              </tr>
              <tr>
                <td>Hepatits B</td>
                <td v-for="item in 6" :key="'c'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['hepatitisb'][item] = e)" vclass="c-text-field" :value="data.form6c['hepatitisb'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td>Cacar air</td>
                <td v-for="item in 6" :key="'d'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['cacarair'][item] = e)" vclass="c-text-field" :value="data.form6c['cacarair'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
              </tr>
              <tr>
                <td>DPT</td>
                <td v-for="item in 6" :key="'e'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['dpt'][item] = e)" vclass="c-text-field" :value="data.form6c['dpt'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td>MMR</td>
                <td v-for="item in 6" :key="'f'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['mmr'][item] = e)" vclass="c-text-field" :value="data.form6c['mmr'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
              </tr>
              <tr>
                <td>Polio</td>
                <td v-for="item in 6" :key="'g'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['polio'][item] = e)" vclass="c-text-field" :value="data.form6c['polio'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td>Thypoid</td>
                <td v-for="item in 6" :key="'h'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['thypoid'][item] = e)" vclass="c-text-field" :value="data.form6c['thypoid'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
              </tr>
              <tr>
                <td>Campak</td>
                <td v-for="item in 6" :key="'i'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['campak'][item] = e)" vclass="c-text-field" :value="data.form6c['campak'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td>Hepatitis A</td>
                <td v-for="item in 6" :key="'j'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['hepatitisa'][item] = e)" vclass="c-text-field" :value="data.form6c['hepatitisa'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
              </tr>
              <tr>
                <td>HIB</td>
                <td v-for="item in 6" :key="'k'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['hib'][item] = e)" vclass="c-text-field" :value="data.form6c['hib'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td>HPV</td>
                <td v-for="item in 6" :key="'l'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['hpv'][item] = e)" vclass="c-text-field" :value="data.form6c['hpv'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
              </tr>
              <tr>
                <td>IPD</td>
                <td v-for="item in 6" :key="'m'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['ipd'][item] = e)" vclass="c-text-field" :value="data.form6c['ipd'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td>Rotavirus</td>
                <td v-for="item in 6" :key="'n'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['rotavirus'][item] = e)" vclass="c-text-field" :value="data.form6c['rotavirus'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
              </tr>
              <tr>
                <td>
                  <smart-widget comp="wtext" :sync-value="(e) => (data.form6c['lain1text'] = e)" vclass="c-text-field" :value="data.form6c['lain1text']"
                                :data="[]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td v-for="item in 6" :key="'o'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['lain1'][item] = e)" vclass="c-text-field" :value="data.form6c['lain1'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td>
                  <smart-widget comp="wtext" :sync-value="(e) => (data.form6c['lain2text'] = e)" vclass="c-text-field" :value="data.form6c['lain2text']"
                                :data="[]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td v-for="item in 6" :key="'p'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['lain2'][item] = e)" vclass="c-text-field" :value="data.form6c['lain2'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
              </tr>
              <tr>
                <td>
                  <smart-widget comp="wtext" :sync-value="(e) => (data.form6c['lain3text'] = e)" vclass="c-text-field" :value="data.form6c['lain3text']"
                                :data="[]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td v-for="item in 6" :key="'q'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['lain3'][item] = e)" vclass="c-text-field" :value="data.form6c['lain3'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td>
                  <smart-widget comp="wtext" :sync-value="(e) => (data.form6c['lain4text'] = e)" vclass="c-text-field" :value="data.form6c['lain4text']"
                                :data="[]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
                <td v-for="item in 6" :key="'r'+item" class="text-capitalize" style="font-size: 12px">
                  <smart-widget comp="wcheckbox" :sync-value="(e) => (data.form6c['lain4'][item] = e)" vclass="c-text-field" :value="data.form6c['lain4'][item]"
                                :data="[{ text: '', value: '1' }]" :property="[]" :label-size="12" :input-size="null" label="" :rules="[rules.required]"/>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form6d"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :data="v.data"
                :sync-value="(e) => (data.form6d[i] = e)"
                vclass="c-text-field"
                :value="data.form6d[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">PEMERIKSAAN FISIK</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form7"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :data="v.data"
                :sync-value="(e) => (data.form7[i] = e)"
                vclass="c-text-field"
                :value="data.form7[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <!-- {{data.form8}} -->
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">PERNAFASAN (BREATH)</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form8"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :data="v.data"
                :sync-value="(e) => (data.form8[i] = e)"
                vclass="c-text-field"
                :value="data.form8[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">KARDIOVASKULER (BLOOD)</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form8b"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :data="v.data"
                :sync-value="(e) => (data.form8b[i] = e)"
                vclass="c-text-field"
                :value="data.form8b[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div :hidden="tabKanan !== 2" style="padding: 10px">
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">PERSYARAFAN DAN PENGINDERAAN (BRAIN)</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form9a"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :data="v.data"
                :sync-value="(e) => (data.form9a[i] = e)"
                vclass="c-text-field"
                :value="data.form9a[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">PERKEMIHAN (BLADDER)</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form9b"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :data="v.data"
                :sync-value="(e) => (data.form9b[i] = e)"
                vclass="c-text-field"
                :value="data.form9b[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">PENCERNAAN /B5 BOWEL</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form9c"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :data="v.data"
                :sync-value="(e) => (data.form9c[i] = e)"
                vclass="c-text-field"
                :value="data.form9c[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">MUSKULOSKELETAL DAN INTEGUMEN /B6 (BONE)</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form9d"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :data="v.data"
                :sync-value="(e) => (data.form9d[i] = e)"
                vclass="c-text-field"
                :value="data.form9d[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div :hidden="tabKanan !== 3" style="padding: 10px">
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
              <tr>
                <th colspan="2" style="font-size: 16px" class="text-left">
                  FAKTOR RESIKO SKALA HUMPTY-DUMPTY
                </th>
                <th style="font-size: 12px" class="text-center">Skor</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(d, i) in master.form11.p1" :key="i">
                <td v-if="i === 0">Umur</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <!-- {{d.value}} -->
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form11.p1"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form11.p2" :key="'a' + i">
                <td v-if="i === 0">Jenis Kelamin</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form11.p2"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form11.p3" :key="'aa' + i">
                <td v-if="i === 0"></td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form11.p3"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr
                v-for="(d, i) in master.form11.p4"
                :key="'b' + i"
              >
                <td v-if="i === 0">Diagnosa</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form11.p4"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form11.p5" :key="'c' + i">
                <td v-if="i === 0">Gangguan Kognitif</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form11.p5"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form11.p5b" :key="'cc' + i">
                <td v-if="i === 0"></td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form11.p5b"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr
                v-for="(d, i) in master.form11.p6"
                :key="'d' + i"
              >
                <td v-if="i === 0">Respon terhadap operasi/obat penenang/efek anastesi</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form11.p6"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr
                v-for="(d, i) in master.form11.p7"
                :key="'e' + i"
              >
                <td v-if="i === 0">Penggunaan Obat</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form11.p7"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td colspan="2"><center><b>Total Skor</b></center></td>
                <td><b>{{totalresikojatuh}}</b></td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <h6>RR : Resiko Rendah (0-24), RS : Resiko Sedang (25-50), RT : Resiko Tinggi (≥51)</h6>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form11b"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-1 mb-1 pt-2 pb-1"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form11b[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form11b[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">PENGKAJIAN NYERI</div>
          <v-divider class="mt-3 mb-3" />
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
              <tr>
                <th colspan="2" style="font-size: 16px" class="text-left">
                  Pengkajian
                </th>
                <th style="font-size: 12px" class="text-center">Skor</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(d, i) in master.form12b.p1" :key="i">
                <td v-if="i === 0">Wajah</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <!-- {{d.value}} -->
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form12b.p1"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form12b.p2" :key="'a' + i">
                <td v-if="i === 0">Kaki</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form12b.p2"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form12b.p3" :key="'aa' + i">
                <td v-if="i === 0">Aktivitas</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form12b.p3"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr
                v-for="(d, i) in master.form12b.p4"
                :key="'b' + i"
              >
                <td v-if="i === 0">Menangis</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form12b.p4"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form12b.p5" :key="'c' + i">
                <td v-if="i === 0">Bersuara</td>
                <td v-else></td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form12b.p5"
                  >
                    <v-radio
                      :value="d.value.toString()"
                      :label="d.value.toString()"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td colspan="2"><center><b>Total Skor</b></center></td>
                <td><b>{{totalskalaflacc}}</b></td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <h6>Skala 0 = Nyaman, Skala 1-3 = Kurang Nyaman, Skala 4-6 = Nyeri Sedang, Skala 7-10 = Nyeri Berat</h6>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form12c"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-1 mb-1 pt-2 pb-1"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form12c[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form12c[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div :hidden="tabKanan !== 4" style="padding: 10px">
          <h6>Pengkajian nyeri Verbal :</h6>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form13"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-1 mb-1 pt-2 pb-1"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form13[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form13[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
              <tr>
                <th rowspan="2" style="font-size: 12px" class="text-left">
                  No
                </th>
                <th rowspan="2" style="font-size: 12px" class="text-center">
                  Fungsi
                </th>
                <th rowspan="2" style="font-size: 12px" class="text-center">
                  Skor
                </th>
                <th rowspan="2" style="font-size: 12px" class="text-center">
                  Uraian
                </th>
                <th colspan="2" style="font-size: 12px" class="text-center">
                  Uraian
                </th>
              </tr>
              <tr>
                <th style="font-size: 12px" class="text-center">
                  Sebelum Sakit
                </th>
                <th style="font-size: 12px" class="text-center">
                  Saat masuk RS
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(d, i) in master.form15.bab" :key="'a' + i">
                <td v-if="i === 0" :rowspan="master.form15.bab.length">1</td>
                <td v-if="i === 0" :rowspan="master.form15.bab.length">
                  Mengendalikan rangsang defeksi (BAB)
                </td>
                <td>{{ d.value }}</td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.bab1"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.bab2"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form15.bak" :key="'b' + i">
                <td v-if="i === 0" :rowspan="master.form15.bak.length">2</td>
                <td v-if="i === 0" :rowspan="master.form15.bak.length">
                  Mengendalikan rangsangan berkemih (BAK)
                </td>
                <td>{{ d.value }}</td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.bak1"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.bak2"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr
                v-for="(d, i) in master.form15.membersihkan_diri"
                :key="'c' + i"
              >
                <td
                  v-if="i === 0"
                  :rowspan="master.form15.membersihkan_diri.length"
                >
                  3
                </td>
                <td
                  v-if="i === 0"
                  :rowspan="master.form15.membersihkan_diri.length"
                >
                  Membersihkan diri (cuci muka, sisir rambut, sikat)
                </td>
                <td>{{ d.value }}</td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.membersihkan_diri1"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.membersihkan_diri2"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr
                v-for="(d, i) in master.form15.penggunaan_jamban"
                :key="'d' + i"
              >
                <td
                  v-if="i === 0"
                  :rowspan="master.form15.penggunaan_jamban.length"
                >
                  4
                </td>
                <td
                  v-if="i === 0"
                  :rowspan="master.form15.penggunaan_jamban.length"
                >
                  Penggunaan jamban, masuk dan keluar (melepaskan, memakai
                  celana, membersihkan, menyeram)
                </td>
                <td>{{ d.value }}</td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.penggunaan_jamban1"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.penggunaan_jamban2"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form15.makan" :key="'e' + i">
                <td v-if="i === 0" :rowspan="master.form15.makan.length">
                  5
                </td>
                <td v-if="i === 0" :rowspan="master.form15.makan.length">
                  Makan
                </td>
                <td>{{ d.value }}</td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.makan1"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.makan2"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr
                v-for="(d, i) in master.form15.berubah_sikap"
                :key="'f' + i"
              >
                <td
                  v-if="i === 0"
                  :rowspan="master.form15.berubah_sikap.length"
                >
                  6
                </td>
                <td
                  v-if="i === 0"
                  :rowspan="master.form15.berubah_sikap.length"
                >
                  Berubah sikap dari berbaring ke duduk
                </td>
                <td>{{ d.value }}</td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.berubah_sikap1"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.berubah_sikap2"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form15.berpindah" :key="'g' + i">
                <td v-if="i === 0" :rowspan="master.form15.berpindah.length">
                  7
                </td>
                <td v-if="i === 0" :rowspan="master.form15.berpindah.length">
                  Berpindah/berjalan
                </td>
                <td>{{ d.value }}</td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.berpindah1"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.berpindah2"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form15.memakai_baju" :key="'h' + i">
                <td
                  v-if="i === 0"
                  :rowspan="master.form15.memakai_baju.length"
                >
                  8
                </td>
                <td
                  v-if="i === 0"
                  :rowspan="master.form15.memakai_baju.length"
                >
                  Memakai Baju
                </td>
                <td>{{ d.value }}</td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.memakai_baju1"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.memakai_baju2"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr
                v-for="(d, i) in master.form15.naik_turun_tangga"
                :key="'i' + i"
              >
                <td
                  v-if="i === 0"
                  :rowspan="master.form15.naik_turun_tangga.length"
                >
                  9
                </td>
                <td
                  v-if="i === 0"
                  :rowspan="master.form15.naik_turun_tangga.length"
                >
                  Naik turun tangga
                </td>
                <td>{{ d.value }}</td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.naik_turun_tangga1"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.naik_turun_tangga2"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr v-for="(d, i) in master.form15.mandi" :key="'j' + i">
                <td v-if="i === 0" :rowspan="master.form15.mandi.length">
                  10
                </td>
                <td v-if="i === 0" :rowspan="master.form15.mandi.length">
                  Mandi
                </td>
                <td>{{ d.value }}</td>
                <td>{{ d.label }}</td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.mandi1"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-radio-group
                    style="margin-top: 0; margin-bottom: 0"
                    v-model="data.form15.mandi2"
                  >
                    <v-radio :value="d.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th colspan="4" class="text-center">Total Skor</th>
                <th class="text-center">{{ total1 }}</th>
                <th class="text-center">{{ total2 }}</th>
              </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">NUTRISI/GIZI</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form16"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form16[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form16[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-for="(v, i) in master.form17"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form17[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form17[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div :hidden="tabKanan !== 5" style="padding: 10px">
          <h6>Pengkajian Gizi</h6>
          <v-divider class="mt-3 mb-3" />
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
              <tr>
                <th colspan="2" style="font-size: 12px" class="text-left">
                  Anak (Berdasarkan Strong)
                </th>
                <th style="font-size: 12px" class="text-left">Skor</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{master.form21.data[0].no}}</td>
                <td>{{master.form21.data[0].title}}</td>
                <td></td>
              </tr>
              <tr v-for="(v,i) in master.form21.data[0].data" :key="'a'+i">
                <td></td>
                <td>
                  <v-radio-group
                    class="mt-0"
                    v-model="data.form21.p1">
                    <v-radio :label="v.title" :value="v.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>{{v.value}}</td>
              </tr>
              <tr>
                <td>{{master.form21.data[1].no}}</td>
                <td>{{master.form21.data[1].title}}</td>
                <td></td>
              </tr>
              <tr v-for="(v,i) in master.form21.data[1].data" :key="'b'+i">
                <td></td>
                <td>
                  <v-radio-group
                    class="mt-0"
                    v-model="data.form21.p2">
                    <v-radio :label="v.title" :value="v.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>{{v.value}}</td>
              </tr>
              <tr>
                <td>{{master.form21.data[2].no}}</td>
                <td>{{master.form21.data[2].title}}</td>
                <td></td>
              </tr>
              <tr v-for="(v,i) in master.form21.data[2].data" :key="'c'+i">
                <td></td>
                <td>
                  <v-radio-group
                    class="mt-0"
                    v-model="data.form21.p3">
                    <v-radio :label="v.title" :value="v.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>{{v.value}}</td>
              </tr>
              <tr>
                <td>{{master.form21.data[3].no}}</td>
                <td>{{master.form21.data[3].title}}</td>
                <td></td>
              </tr>
              <tr v-for="(v,i) in master.form21.data[3].data" :key="'d'+i">
                <td></td>
                <td>
                  <v-radio-group
                    class="mt-0"
                    v-model="data.form21.p4">
                    <v-radio :label="v.title" :value="v.value.toString()"></v-radio>
                  </v-radio-group>
                </td>
                <td>{{v.value}}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="1"><h6>Resiko Malnutrisi</h6></td>
                <td colspan="2">
                  <h6>{{ data.form21.ket }}</h6>
                  <a hidden>{{ total4 }}</a>
                </td>
              </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">
            POLA AKTIFITAS SEHARI HARI
          </div>
          <v-divider class="mt-3 mb-3" />
          <div class="mt-3">
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form22"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form22[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form22[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </div>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">
            KEBUTUHAN KOMUNIKASI / PENDIDIKAN DAN PENGAJARAN
          </div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form23"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form23[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form23[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">TINGKAT PENGETAHUAN</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form24"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form24[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form24[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">RESPON PSIKOLOGIS</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form25"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form25[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form25[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">DAFTAR MASALAH KEPERAWATAN</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form25b"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form25b[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form25b[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div :hidden="tabKanan !== 6" style="padding: 10px">
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">DATA PENUNJANG</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form26"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form26[i] = e)"
                vclass="c-text-field"
                :value="data.form26[i]"

                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">
            PERENCANAAN PERAWATAN INTERDISIPLIN / REFERAL
          </div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form27"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form27[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form27[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">
            PERENCANAAN PASIEN PULANG (Discharge Planning)
          </div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form28"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form28[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form28[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
// import { use } from 'vue/types/umd'
// import { apiKedua } from '../plugins/supports'
// import { apiKedua } from '../plugins/supports'
// import { isEmpty, jsonPrettier } from '@/plugins/supports'
import Master from '../../data/asesmen-awal-keperawatan-rawat-inap-anak'
import SmartWidget from '@/components/SmartWidget.vue'
import Dialog from '@/components/Dialog'
// import SekalaNyeri from '@/components/SekalaNyeri.vue'
export default {
  components: {
    DialogConfirm: Dialog,
    // SekalaNyeri,
    SmartWidget
    // DialogConfirm: Dialog,
    // Soap,
  },
  data () {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      item_ke: null,
      url_emr: 'http://emr.rs-syafira.com/',
      url: '',
      site: '',
      history: [],
      patient_name: '',
      tabKiri: '',
      tabKanan: 0,
      search: '',
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          dokter_yang_merawat: '',
          tiba_diruangan_tanggal: '',
          tiba_diruangan_jam: '',
          pengkajian_tanggal: '',
          pengkajian_jam: '',
          cara_masuk: '',
          asal_pasien: '',
          masuk_kasus_trauma: '',
          hasil_yang_dibawa: ''
        },
        form5: {
          keluhan_utama: '',
          riwayat_penyakit_sekarang: '',
          pernah_dirawat: '',
          pernah_operasi_tindakan: '',
          masalah_operasi_pembiusan: ''
        },
        form6: {
          riwayat_penyakit_keluarga: '',
          obat_dari_rumah: '',
          riwayat_konsumsi_obat: '',
          riwayat_alergi: '',
          riwayat_transfusi: '',
          reaksi_yang_timbul: '',
          golongan_darah: '',
          rh: '',
          cara_pemberian: '',
          efek_samping: '',
          riwayat_pernikahan: '',
          pernikahan_ke: ''
        },
        form6b: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          l1: '',
          bbl: '',
          pb: '',
          asfiksia: '',
          p5: '',
          p6: '',
          p7: '',
          p8: ''
        },
        form6c: {
          bcg: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          influenza: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          hepatitisb: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          cacarair: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          dpt: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          mmr: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          polio: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          thypoid: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          campak: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          hepatitisa: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          hib: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          hpv: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          ipd: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          rotavirus: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          lain1text: '',
          lain1: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          lain2text: '',
          lain2: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          lain3text: '',
          lain3: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
          lain4text: '',
          lain4: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' }
        },
        form6d: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: ''
        },
        form7: {
          keadaan_umum: '',
          kesadaraan: '',
          gcs_e: '',
          gcs_m: '',
          gcs_v: '',
          pupil: '',
          kiri: '',
          reaksi_cahaya_kanan: '',
          reaksi_cahaya_kiri: '',
          tanda_vital_td: '',
          tanda_vital_hr: '',
          tanda_vital_rr: '',
          tanda_vital_t: '',
          tanda_vital_spo2: '',
          pemeriksaan_atrometrik_bb: '',
          pemeriksaan_atrometrik_tb: '',
          pemeriksaan_atrometrik_lk: '',
          pemeriksaan_atrometrik_ld: '',
          pemeriksaan_atrometrik_lp: ''
        },
        form8: {
          pola_nafas: '',
          penggunaan_alat_bantu_pernafasan: '',
          suara_nafas: '',
          sesak_nafas: ''
        },
        form8b: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: ''
        },
        form9a: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: '',
          p11: '',
          p12: '',
          p13: '',
          p14: '',
          p15: '',
          p16: '',
          p17: ''
        },
        form9b: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: ''
        },
        form9c: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: '',
          p11: '',
          p12: '',
          p13: '',
          p14: '',
          p15: '',
          p16: '',
          p17: '',
          p18: '',
          p19: '',
          p20: '',
          p21: ''
        },
        form9d: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: ''
        },
        form10: {
          baris1: {
            nama_obat: '',
            dosis: '',
            terakhir_kali: ''
          },
          baris2: {
            nama_obat: '',
            dosis: '',
            terakhir_kali: ''
          },
          baris3: {
            nama_obat: '',
            dosis: '',
            terakhir_kali: ''
          },
          baris4: {
            nama_obat: '',
            dosis: '',
            terakhir_kali: ''
          }
        },
        form11: {
          p1: '0',
          p2: '0',
          p3: '0',
          p4: '0',
          p5: '0',
          p5b: '0',
          p6: '0',
          p7: '0'
        },
        form11b: {
          p1: '0',
          p2: '0',
          p3: '0'
        },
        form12: {
          penglihatan_kontak: '-',
          penciuman: '-',
          pendengaran: '-'
        },
        form12b: {
          p1: '0',
          p2: '0',
          p3: '0',
          p4: '0',
          p5: '0'
        },
        form12c: {
          p1: '',
          p2: ''
        },
        form13: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: ''
        },
        form15: {
          bab1: null,
          bab2: null,
          bak1: null,
          bak2: null,
          membersihkan_diri1: null,
          membersihkan_diri2: null,
          penggunaan_jamban1: null,
          penggunaan_jamban2: null,
          makan1: null,
          makan2: null,
          berubah_sikap1: null,
          berubah_sikap2: null,
          berpindah1: null,
          berpindah2: null,
          memakai_baju1: null,
          memakai_baju2: null,
          naik_turun_tangga1: null,
          naik_turun_tangga2: null,
          mandi1: null,
          mandi2: null
        },
        form16: {
          intake_nutrisi_lewat: ''
        },
        form17: {
          masalah_nutrisi1: '',
          masalah_nutrisi2: '',
          masalah_nutrisi3: ''
        },
        form18: {
          keamanan: '',
          resiko_melarikan_diri: ''
        },
        form19: {
          frekuensi_nyeri: '',
          lama_nyeri: '',
          menjalar: '',
          kualitas_nyeri: '',
          faktor_pemicu: '',
          lokasi_nyeri: '',
          tindak_lanjut: '',
          nyeri_mempengaruhi: ''
        },
        form22: {
          pola_istirahat_dan_tidur: '',
          lama_tidur: '',
          tidur_siang: '',
          tidur_malam: '',
          hal_cepat_tidur: '',
          makan: '',
          jenis_makan: '',
          pantangan: '',
          diet_khusus: '',
          minum: '',
          jenis_minum: '',
          mandi: '',
          sekat_gigi: '',
          keramas: '',
          olah_raga: ''
        },
        form23: {
          bicara: '',
          bahasa_sehari_hari: '',
          perlu_penerjemah: '',
          hambatan_belajar: '',
          cara_belajar: '',
          pendidikan_pasien: '',
          potensi_kebutuhan: '',
          menginginkan_informasi: '',
          menginginkan_informasi2: ''
        },
        form24: {
          penyakit_yang_diderita: '',
          tindakan_pengobatan: '',
          perencanaan_diet: '',
          perubahan_aktifitas: '',
          perawatan_setelah_dirumah: ''
        },
        form25: {
          respon_psikologis: '',
          respon_psikologis2: ''
        },
        form25b: {
          p1: '',
          p2: ''
        },
        form26: {
          laboratorium: '',
          radiologi: '',
          lain_lain: ''
        },
        form27: {
          diet_dan_nutrisi: '',
          rehabilitasi_medik: '',
          farmasi: '',
          perawatan_luka: '',
          manajemen_nyeri: '',
          lain_lain: ''
        },
        form28: {
          direncanakan_pulang: '',
          label2: '',
          lama_perawatan: '',
          label3: '',
          tanggal_rencana_pulang: '',
          p1: '',
          p2: '',
          p3: ''
        },
        form21: {
          p1: null,
          p2: null,
          p3: null,
          p4: null,
          sum: '0',
          ket: '-'
        }
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  computed: {
    totalresikojatuh: function () {
      var x = this.data.form11
      const sum = parseInt(x.p1) + parseInt(x.p2) + parseInt(x.p3) + parseInt(x.p4) + parseInt(x.p5) + parseInt(x.p5b) + parseInt(x.p6) + parseInt(x.p7)
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    totalskalaflacc: function () {
      var x = this.data.form12b
      const sum = parseInt(x.p1) + parseInt(x.p2) + parseInt(x.p3) + parseInt(x.p4) + parseInt(x.p5)
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    total1: function () {
      var x = this.data.form15
      const sum =
        parseInt(x.bab1 ? x.bab1 : 0) +
        parseInt(x.bak1 ? x.bak1 : 0) +
        parseInt(x.membersihkan_diri1 ? x.membersihkan_diri1 : 0) +
        parseInt(x.penggunaan_jamban1 ? x.penggunaan_jamban1 : 0) +
        parseInt(x.makan1 ? x.makan1 : 0) +
        parseInt(x.berubah_sikap1 ? x.berubah_sikap1 : 0) +
        parseInt(x.berpindah1 ? x.berpindah1 : 0) +
        parseInt(x.memakai_baju1 ? x.memakai_baju1 : 0) +
        parseInt(x.naik_turun_tangga1 ? x.naik_turun_tangga1 : 0) +
        parseInt(x.mandi1 ? x.mandi1 : 0)
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    total2: function () {
      var x = this.data.form15
      const sum =
        parseInt(x.bab2 ? x.bab2 : 0) +
        parseInt(x.bak2 ? x.bak2 : 0) +
        parseInt(x.membersihkan_diri2 ? x.membersihkan_diri2 : 0) +
        parseInt(x.penggunaan_jamban2 ? x.penggunaan_jamban2 : 0) +
        parseInt(x.makan2 ? x.makan2 : 0) +
        parseInt(x.berubah_sikap2 ? x.berubah_sikap2 : 0) +
        parseInt(x.berpindah2 ? x.berpindah2 : 0) +
        parseInt(x.memakai_baju2 ? x.memakai_baju2 : 0) +
        parseInt(x.naik_turun_tangga2 ? x.naik_turun_tangga2 : 0) +
        parseInt(x.mandi2 ? x.mandi2 : 0)
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    total3: function () {
      var x = this.data.form17
      const sum =
        x.riwayat_jatuh +
        x.diagnosa_sekunder +
        x.alat_bantu_berjalan +
        x.akses_infus +
        x.cara_berjalan +
        x.ada_gangguan_sensorik
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    total4: function () {
      var x = this.data.form21
      const sum =
        parseInt(x.p1 ? x.p1 : 0) +
        parseInt(x.p2 ? x.p2 : 0) +
        parseInt(x.p3 ? x.p3 : 0) +
        parseInt(x.p4 ? x.p4 : 0)
      if (isNaN(sum)) {
        return 0
      } else {
        x.sum = sum
        if (sum <= 0) {
          x.ket = 'Nilai 0 (Rendah)'
        } else if (sum <= 3) {
          x.ket = 'Nilai 1-3 (Sedang)'
        } else {
          x.ket = 'Nilai 4-5 (Tinggi)'
        }
        return sum
      }
    }
  },
  created () {
    if (!this.$route.query.no_rm) {
      errorMsg('PILIH DAHULU PASIENNYA')
      this.$router.push({
        name: 'DataPasienRawatInap'
      })
    }
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      setTimeout(() => {
        this.getAssemen(this.$route.query.folio_id)
      }, 100)
    }
    // setTimeout(() => {
    //   this.getAssemen(this.$route.query.folio_id)
    // }, 100)
  },
  methods: {
    setTab () {
      console.log(this.tabKanan + ' TAB KE BERAPA')
      if (this.tabKanan === 1 || this.tabKanan === 2) {
        this.tabKiri = 2
      } else {
        this.tabKiri = 0
      }
      setTimeout(() => {
        this.getAssemen(this.$route.query.folio_id)
      }, 100)
    },
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        jenis: 'rawat-inap',
        created_by: this.user.employee_id,

        type: 'Asesmen Keperawatan Rawat Inap Anak'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
      // localStorage.setItem('asesmeninap', JSON.stringify(data))
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }

            // this.data.form11 = results.form11
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Keperawatan Rawat Inap Anak'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            // console.log(this.data)
            this.data = results

            // console.log(results)
            // this.data.form7 = results.form7
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
            this.data = results
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style scoped>
.v-input--radio-group .v-input__slot {
  margin-bottom: 0;
}
.v-messages {
  display: none;
}

.float-group {
  position: fixed;
  bottom: 10%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 100px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

</style>
