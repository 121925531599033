<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">Implementasi Keperawatan</h3>
    <v-row justify="center">
      <v-col cols="12">
        <v-card color="deep-purple lighten-1" dark>
          <v-card-text>
            <v-row class="mt-8 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider class="mt-3 mb-3" />
        <v-row class="mt-0 mb-0">
          <div style="overflow-x: scroll">
            <table class="table" border="1">
              <thead>
              <tr>
                <th rowspan="4">MASALAH<br>KEPERAWATAN</th>
                <th rowspan="4">IMPLEMENTASI<br>KEPERAWATAN</th>
                <th colspan="3" v-for="hari in 6" :key="'hari'+hari">
                  <v-row class="mt-0 mb-0">
                    <v-col
                      v-for="(v, i) in master.hari"
                      :md="v.col ? v.col : 12"
                      :lg="v.col ? v.col : 12"
                      :sm="v.col ? v.col : 12"
                      cols="12"
                      class="mt-0 mb-0 pt-0 pb-3"
                      :key="i"
                    >
                      <smart-widget
                        :comp="v.widget"
                        :sync-value="(e) => (data.hari[i] = e)"
                        :value="data.hari[i]"
                        vclass="c-text-field"
                        :data="v.data"
                        :property="v.property"
                        :label="v.label ? v.label : i.replaceAll('_', ' ')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                  </v-row>
                </th>
                <th rowspan="4"></th>
              </tr>
              <tr>
                <th colspan="3" v-for="tanggal in 6" :key="'tanggal'+tanggal">
                  <v-row class="mt-0 mb-0">
                    <v-col
                      v-for="(v, i) in master.tanggal"
                      :md="v.col ? v.col : 12"
                      :lg="v.col ? v.col : 12"
                      :sm="v.col ? v.col : 12"
                      cols="12"
                      class="mt-0 mb-0 pt-0 pb-3"
                      :key="i"
                    >
                      <smart-widget
                        :comp="v.widget"
                        :sync-value="(e) => (data.tanggal[i] = e)"
                        :value="data.tanggal[i]"
                        vclass="c-text-field"
                        :data="v.data"
                        :property="v.property"
                        :label="v.label ? v.label : i.replaceAll('_', ' ')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                  </v-row>
                </th>
              </tr>
              <tr>
                <th class="text-center" colspan="3" v-for="jam in 6" :key="'jam'+jam">Jam Implementasi</th>
              </tr>
              <tr>
                <th class="text-center" v-for="psm in 18" :key="'psm'+psm">
                  {{[1,4,7,10,13,16].includes(psm) ? 'P' : ([2,5,8,11,14,17].includes(psm) ? 'S' : 'M')}}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(x, y) in master.form1" :key="y">
                <td v-for="(v, i) in x" :key="i">
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form1[y][i] = e)"
                    :value="data.form1[y][i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </td>
                <td>
                  <v-btn
                    @click="hapusBaris(y)"
                    class="mt-2"
                    fab
                    color="error"
                    dense
                    outlined
                    x-small
                    elevation="2"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </table><br>
            <button @click="tambahBaris()" class="btn btn-primary btn-block">Tambah Baris</button>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/implementasi-keperawatan'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      ayam: '',
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        hari: {},
        tanggal: {},
        form1: [
          {
            masalah_keperawatan: '',
            implementasi_keperawatan: '',
            p1: '',
            s1: '',
            m1: '',
            p2: '',
            s2: '',
            m2: '',
            p3: '',
            s3: '',
            m3: '',
            p4: '',
            s4: '',
            m4: '',
            p5: '',
            s5: '',
            m5: '',
            p6: '',
            s6: '',
            m6: ''
          }
        ]
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
    }
  },
  methods: {
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Populasi Khusus'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
          } else {
          }
        },
        'JSON'
      )
    },
    tambahBaris () {
      this.data.form1.push({
        masalah_keperawatan: '',
        implementasi_keperawatan: '',
        p1: '',
        s1: '',
        m1: '',
        p2: '',
        s2: '',
        m2: '',
        p3: '',
        s3: '',
        m3: '',
        p4: '',
        s4: '',
        m4: '',
        p5: '',
        s5: '',
        m5: '',
        p6: '',
        s6: '',
        m6: ''
      })
      this.master.form1.push({
        masalah_keperawatan: {
          label: ' ',
          widget: 'wtextarea',
          col: 12,
          data: []
        },
        implementasi_keperawatan: {
          label: ' ',
          widget: 'wtextarea',
          col: 12,
          data: []
        },
        p1: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        s1: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        m1: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p2: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        s2: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        m2: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p3: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        s3: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        m3: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p4: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        s4: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        m4: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p5: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        s5: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        m5: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        p6: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        s6: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        },
        m6: {
          label: ' ',
          widget: 'wtext',
          col: 12,
          data: []
        }
      })
    },
    hapusBaris (index) {
      this.master.form1.splice(index, 1)
      this.data.form1.splice(index, 1)
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Populasi Khusus'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style>

.wrap {
  width: 100%;
  height: 690px;
  padding: 0;
  overflow: hidden;
}
.frame {
  width: 1280px;
  height: 926px;
  border: 0;
  -ms-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.float-group {
  position: fixed;
  bottom: 50%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.framclass {
  width: 1280px;
  height: 786px;
  border: 0;
  -ms-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.Div1 {
  border: 1px solid #ddd;
  width: 24%;
  background-color: white;
  float: left;
  border: 2px solid #c00;
  margin-right: 5px;
  min-height: 50px;
  position: fixed;
}
</style>
