<template>
  <v-container fluid>
    <h3 class="mb-2 mt-3">Asesmen Keperawatan Rawat Inap</h3>
    <div>
      <v-row justify="center">
        <v-col cols="12" lg="10" sm="10" md="10">
          <v-card color="teal darken-1" class="mt-2" dark>
            <v-card-text>
              <v-row class="mt-8 mb-0">
                <v-col
                  v-for="(v, i) in master.demografi"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-1 mb-1 pt-2 pb-1"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.demografi[i] = e)"
                    :value="data.demografi[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :disabled="v.disabled"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="12" sm="12" md="12">
          <v-tabs class="mb-3" centered stacked>
            <v-tab @click="tabKanan = 0">Hal 1</v-tab>
            <v-tab @click="tabKanan = 1">Hal 2</v-tab>
            <v-tab @click="tabKanan = 2">Hal 3</v-tab>
            <v-tab @click="tabKanan = 3">Hal 4</v-tab>
            <v-tab @click="tabKanan = 4">Hal 5</v-tab>
            <v-tab @click="tabKanan = 5">Hal 6</v-tab>
            <v-tab @click="tabKanan = 6">Hal 7</v-tab>
          </v-tabs>
          <div :hidden="tabKanan !== 0">
            <v-row class="mt-1 m-1 mb-0">
              <v-col
                v-for="(v, i) in master.form1"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form1[i] = e)"
                  :value="data.form1[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :disabled="v.disabled"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v?.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <!--              <v-divider class="mt-3 mb-3" />-->
            <!--              <div class="font-weight-bold text-center">STATUS SOSIAL, EKONOMI, AGAMA, SUKU/BUDAYA, NILAI KEPERCAYAAN DAN KEBUTUHAN PRIVASI DAN EDUKASI</div>-->
            <!--              <v-divider class="mt-3 mb-3" />-->
            <!--              <div class="font-weight-bold mb-4">SOSIAL DAN EKONOMI</div>-->
            <!--              <v-row class="mt-0 mb-0">-->
            <!--                <v-col-->
            <!--                  v-for="(v, i) in master.form2"-->
            <!--                  :md="v.col ? v.col : 12"-->
            <!--                  :lg="v.col ? v.col : 12"-->
            <!--                  :sm="v.col ? v.col : 12"-->
            <!--                  cols="12"-->
            <!--                  class="mt-0 mb-0 pt-0 pb-3"-->
            <!--                  :key="i"-->
            <!--                >-->
            <!--                  <smart-widget-->
            <!--                    :comp="v.widget"-->
            <!--                    :sync-value="(e) => (data.form2[i] = e.value)"-->
            <!--                    vclass="c-text-field"-->
            <!--                    :data="v.data"-->
            <!--                    :property="v.property"-->
            <!--                    :label-size="v.labelSize"-->
            <!--                    :input-size="v.inputSize"-->
            <!--                    :label="v.label ? v.label : i.replaceAll('_', ' ')"-->
            <!--                    :rules="[rules.required]"-->
            <!--                  />-->
            <!--                </v-col>-->
            <!--              </v-row>-->
            <!--              <v-divider class="mt-3 mb-6" />-->
            <!--              <div class="font-weight-bold mb-4">SPIRITUAL (AGAMA)</div>-->
            <!--              <v-row class="mt-0 mb-0">-->
            <!--                <v-col-->
            <!--                  v-for="(v, i) in master.form3"-->
            <!--                  :md="v.col ? v.col : 12"-->
            <!--                  :lg="v.col ? v.col : 12"-->
            <!--                  :sm="v.col ? v.col : 12"-->
            <!--                  cols="12"-->
            <!--                  class="mt-0 mb-0 pt-0 pb-3"-->
            <!--                  :key="i"-->
            <!--                >-->
            <!--                  <smart-widget-->
            <!--                    :comp="v.widget"-->
            <!--                    :sync-value="(e) => (data.form3[i] = e.value)"-->
            <!--                    vclass="c-text-field"-->
            <!--                    :data="v.data"-->
            <!--                    :property="v.property"-->
            <!--                    :label-size="v.labelSize"-->
            <!--                    :input-size="v.inputSize"-->
            <!--                    :label="v.label ? v.label : i.replaceAll('_', ' ')"-->
            <!--                    :rules="[rules.required]"-->
            <!--                  />-->
            <!--                </v-col>-->
            <!--              </v-row>-->
            <!--              <v-divider class="mt-3 mb-6" />-->
            <!--              <div class="font-weight-bold mb-4">SUKU/BUDAYA</div>-->
            <!--              <v-row class="mt-0 mb-0">-->
            <!--                <v-col-->
            <!--                  v-for="(v, i) in master.form4"-->
            <!--                  :md="v.col ? v.col : 12"-->
            <!--                  :lg="v.col ? v.col : 12"-->
            <!--                  :sm="v.col ? v.col : 12"-->
            <!--                  cols="12"-->
            <!--                  class="mt-0 mb-0 pt-0 pb-3"-->
            <!--                  :key="i"-->
            <!--                >-->
            <!--                  <smart-widget-->
            <!--                    :comp="v.widget"-->
            <!--                    :sync-value="(e) => (data.form4[i] = e.value)"-->
            <!--                    vclass="c-text-field"-->
            <!--                    :data="v.data"-->
            <!--                    :property="v.property"-->
            <!--                    :label-size="v.labelSize"-->
            <!--                    :input-size="v.inputSize"-->
            <!--                    :label="v.label ? v.label : i.replaceAll('_', ' ')"-->
            <!--                    :rules="[rules.required]"-->
            <!--                  />-->
            <!--                </v-col>-->
            <!--              </v-row>-->
            <v-divider class="mt-3 mb-6" />
            <div class="font-weight-bold mb-4">ANAMNESIS</div>
            <v-row class="mt-0 m-1 mb-0">
              <v-col
                v-for="(v, i) in master.form5"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form5[i] = e)"
                  :value="data.form5[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </div>
          <div :hidden="tabKanan !== 1" style="padding: 10px">
            <v-row class="mt-0 m-1 mb-0">
              <v-col
                v-for="(v, i) in master.form6"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form6[i] = e)"
                  vclass="c-text-field"
                  :value="data.form6[i]"
                  :data="v.data"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">PEMERIKSAAN FISIK</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 m-1 mb-0">
              <v-col
                v-for="(v, i) in master.form7"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :data="v.data"
                  :sync-value="(e) => (data.form7[i] = e)"
                  vclass="c-text-field"
                  :value="data.form7[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <!-- {{data.form8}} -->

            <v-simple-table class="elevation-1" dense style="font-size: 10px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="font-size: 12px" class="text-left">
                      PENGKAJIAN PER-SISTEM/FUNGSI
                    </th>
                    <th style="font-size: 12px" class="text-center">
                      HASIL PEMERIKSAAN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(d, i) in master.form8.data" :key="i">
                    <td
                      class="text-capitalize"
                      style="font-size: 12px"
                      v-bind="
                        typeof d.dataCols === 'undefined'
                          ? {
                              colspan: 4,
                              class: 'text-center font-weight-bold',
                            }
                          : {}
                      "
                    >
                      {{ d.text }}
                    </td>
                    <td class="text-capitalize" style="font-size: 12px">
                      <v-row class="mt-0 mb-0">
                        <v-col
                          v-for="(dcol, j) in d.dataCols"
                          :md="dcol.col ? dcol.col : 12"
                          :lg="dcol.col ? dcol.col : 12"
                          :sm="dcol.col ? dcol.col : 12"
                          cols="12"
                          class="mt-0 mb-0 pt-0 pb-3"
                          :key="j"
                        >
                          <smart-widget
                            :comp="dcol.widget"
                            :sync-value="
                              (e) => (data.form8.data[i]['dataCols'][j] = e)
                            "
                            vclass="c-text-field"
                            :data="dcol.data"
                            :value="data.form8.data[i]['dataCols'][j]"
                            :property="dcol.property"
                            :label-size="dcol.labelSize"
                            :input-size="dcol.inputSize"
                            :label="
                              dcol.label ? dcol.label : i.replaceAll('_', ' ')
                            "
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div :hidden="tabKanan !== 2" style="padding: 10px">
            <v-simple-table class="elevation-1" dense style="font-size: 10px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="font-size: 12px" class="text-left">
                      PENGKAJIAN PER-SISTEM/FUNGSI
                    </th>
                    <th style="font-size: 12px" class="text-center">
                      HASIL PEMERIKSAAN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(d, i) in master.form9.data" :key="i">
                    <td
                      class="text-capitalize"
                      style="font-size: 12px"
                      v-bind="
                        typeof d.dataCols === 'undefined'
                          ? {
                              colspan: 4,
                              class: 'text-center font-weight-bold',
                            }
                          : {}
                      "
                    >
                      {{ d.text }}
                    </td>
                    <td class="text-capitalize" style="font-size: 12px">
                      <v-row class="mt-0 mb-0">
                        <v-col
                          v-for="(dcol, j) in d.dataCols"
                          :md="dcol.col ? dcol.col : 12"
                          :lg="dcol.col ? dcol.col : 12"
                          :sm="dcol.col ? dcol.col : 12"
                          cols="12"
                          class="mt-0 mb-0 pt-0 pb-3"
                          :key="j"
                        >
                          <smart-widget
                            :comp="dcol.widget"
                            :sync-value="
                              (e) => (data.form9.data[i]['dataCols'][j] = e)
                            "
                            vclass="c-text-field"
                            :data="dcol.data"
                            :value="data.form9.data[i]['dataCols'][j]"
                            :property="dcol.property"
                            :label-size="dcol.labelSize"
                            :input-size="dcol.inputSize"
                            :label="
                              dcol.label ? dcol.label : i.replaceAll('_', ' ')
                            "
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <h6 class="mt-2 mb-2">
              Obat-obatan di rumah (daftar obat, dosis/frekuensi, kapan terakhir
              kali dikonsumsi) :
            </h6>
            <v-simple-table class="elevation-1" dense style="font-size: 10px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="font-size: 12px" class="text-left">Nama Obat</th>
                    <th style="font-size: 12px" class="text-center">
                      Dosis/Frekuensi
                    </th>
                    <th style="font-size: 12px" class="text-center">
                      Kapan terakhir kali diberikan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(d, i) in master.form10" :key="i">
                    <td
                      v-for="(dcol, j) in d"
                      :key="j"
                      class="text-capitalize"
                      style="font-size: 12px"
                    >
                      <smart-widget
                        :comp="dcol.widget"
                        :sync-value="(e) => (data.form10[i][j] = e)"
                        vclass="c-text-field"
                        :value="data.form10[i][j]"
                        :data="dcol.data"
                        :property="dcol.property"
                        :label-size="dcol.labelSize"
                        :input-size="dcol.inputSize"
                        :label="
                          dcol.label ? dcol.label : i.replaceAll('_', ' ')
                        "
                        :rules="[rules.required]"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div :hidden="tabKanan !== 3" style="padding: 10px">
            <v-simple-table class="elevation-1" dense style="font-size: 10px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="font-size: 12px" class="text-left">
                      Kondisi Pasien
                    </th>
                    <th style="font-size: 12px" class="text-center">
                      Keterangan
                    </th>
                    <th style="font-size: 12px" class="text-center">Skor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(d, i) in master.form11.kondisi_fisik_umum"
                    :key="i"
                  >
                    <td v-if="i === 0">Kondisi Fisik Umum</td>
                    <td v-else></td>
                    <td>{{ d.label }}</td>
                    <td>
                      <!-- {{d.value}} -->
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form11.kondisi_fisik_umum"
                      >
                        <v-radio
                          :value="d.value.toString()"
                          :label="d.value.toString()"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr v-for="(d, i) in master.form11.kesadaraan" :key="'a' + i">
                    <td v-if="i === 0">Kesadaran</td>
                    <td v-else></td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form11.kesadaraan"
                      >
                        <v-radio
                          :value="d.value.toString()"
                          :label="d.value.toString()"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr
                    v-for="(d, i) in master.form11.tingkat_aktivitas"
                    :key="'b' + i"
                  >
                    <td v-if="i === 0">Tingkat Aktivitas</td>
                    <td v-else></td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form11.tingkat_aktivitas"
                      >
                        <v-radio
                          :value="d.value.toString()"
                          :label="d.value.toString()"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr v-for="(d, i) in master.form11.mobilitas" :key="'c' + i">
                    <td v-if="i === 0">Mobilitas</td>
                    <td v-else></td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form11.mobilitas"
                      >
                        <v-radio
                          :value="d.value.toString()"
                          :label="d.value.toString()"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr
                    v-for="(d, i) in master.form11.inkontinensia"
                    :key="'d' + i"
                  >
                    <td v-if="i === 0">Inkontinensia</td>
                    <td v-else></td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form11.inkontinensia"
                      >
                        <v-radio
                          :value="d.value.toString()"
                          :label="d.value.toString()"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <small
              >Apabila Individu Memiliki Nilai Skala Norton ≤ 14, Maka Dikatakan
              Individu Tersebut Beresiko Untuk Mengalami Ulkus Dekubitus</small
            >
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">PENGKAJIAN FUNGSI</div>
            <v-divider class="mt-3 mb-3" />
            <h6>1. Pengkajian Umum Fungsi</h6>
            <p class="ml-4 mb-0">a. Sensorik</p>
            <div class="ml-8">
              <v-row class="mt-0 mb-0">
                <v-col
                  v-for="(v, i) in master.form12"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-1 mb-1 pt-2 pb-1"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form12[i] = e)"
                    vclass="c-text-field"
                    :data="v.data"
                    :value="data.form12[i]"
                    :property="v.property"
                    :label-size="v.labelSize"
                    :input-size="v.inputSize"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </div>
            <p class="ml-4 mb-0">b. Kongnitif</p>
            <div class="ml-8">
              <v-row class="mt-0 mb-0">
                <v-col
                  v-for="(v, i) in master.form13"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-0 mb-0 pt-0 pb-3"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form13[i] = e)"
                    vclass="c-text-field"
                    :data="v.data"
                    :value="data.form13[i]"
                    :property="v.property"
                    :label-size="v.labelSize"
                    :input-size="v.inputSize"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </div>
            <p class="ml-4 mb-0">c. Motorik</p>
            <div class="ml-8">
              <v-row class="mt-0 mb-0">
                <v-col
                  v-for="(v, i) in master.form14"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-0 mb-0 pt-0 pb-3"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form14[i] = e)"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :value="data.form14[i]"
                    :label-size="v.labelSize"
                    :input-size="v.inputSize"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
          <div :hidden="tabKanan !== 4" style="padding: 10px">
            <v-simple-table class="elevation-1" dense style="font-size: 10px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th rowspan="2" style="font-size: 12px" class="text-left">
                      No
                    </th>
                    <th rowspan="2" style="font-size: 12px" class="text-center">
                      Fungsi
                    </th>
                    <th rowspan="2" style="font-size: 12px" class="text-center">
                      Skor
                    </th>
                    <th rowspan="2" style="font-size: 12px" class="text-center">
                      Uraian
                    </th>
                    <th colspan="2" style="font-size: 12px" class="text-center">
                      Uraian
                    </th>
                  </tr>
                  <tr>
                    <th style="font-size: 12px" class="text-center">
                      Sebelum Sakit
                    </th>
                    <th style="font-size: 12px" class="text-center">
                      Saat masuk RS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(d, i) in master.form15.bab" :key="'a' + i">
                    <td v-if="i === 0" :rowspan="master.form15.bab.length">
                      1
                    </td>
                    <td v-if="i === 0" :rowspan="master.form15.bab.length">
                      Mengendalikan rangsang defeksi (BAB)
                    </td>
                    <td>{{ d.value }}</td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.bab1"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.bab2"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr v-for="(d, i) in master.form15.bak" :key="'b' + i">
                    <td v-if="i === 0" :rowspan="master.form15.bak.length">
                      2
                    </td>
                    <td v-if="i === 0" :rowspan="master.form15.bak.length">
                      Mengendalikan rangsangan berkemih (BAK)
                    </td>
                    <td>{{ d.value }}</td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.bak1"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.bak2"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr
                    v-for="(d, i) in master.form15.membersihkan_diri"
                    :key="'c' + i"
                  >
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.membersihkan_diri.length"
                    >
                      3
                    </td>
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.membersihkan_diri.length"
                    >
                      Membersihkan diri (cuci muka, sisir rambut, sikat)
                    </td>
                    <td>{{ d.value }}</td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.membersihkan_diri1"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.membersihkan_diri2"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr
                    v-for="(d, i) in master.form15.penggunaan_jamban"
                    :key="'d' + i"
                  >
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.penggunaan_jamban.length"
                    >
                      4
                    </td>
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.penggunaan_jamban.length"
                    >
                      Penggunaan jamban, masuk dan keluar (melepaskan, memakai
                      celana, membersihkan, menyeram)
                    </td>
                    <td>{{ d.value }}</td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.penggunaan_jamban1"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.penggunaan_jamban2"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr v-for="(d, i) in master.form15.makan" :key="'e' + i">
                    <td v-if="i === 0" :rowspan="master.form15.makan.length">
                      5
                    </td>
                    <td v-if="i === 0" :rowspan="master.form15.makan.length">
                      Makan
                    </td>
                    <td>{{ d.value }}</td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.makan1"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.makan2"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr
                    v-for="(d, i) in master.form15.berubah_sikap"
                    :key="'f' + i"
                  >
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.berubah_sikap.length"
                    >
                      6
                    </td>
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.berubah_sikap.length"
                    >
                      Berubah sikap dari berbaring ke duduk
                    </td>
                    <td>{{ d.value }}</td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.berubah_sikap1"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.berubah_sikap2"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr v-for="(d, i) in master.form15.berpindah" :key="'g' + i">
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.berpindah.length"
                    >
                      7
                    </td>
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.berpindah.length"
                    >
                      Berpindah/berjalan
                    </td>
                    <td>{{ d.value }}</td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.berpindah1"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.berpindah2"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr
                    v-for="(d, i) in master.form15.memakai_baju"
                    :key="'h' + i"
                  >
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.memakai_baju.length"
                    >
                      8
                    </td>
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.memakai_baju.length"
                    >
                      Memakai Baju
                    </td>
                    <td>{{ d.value }}</td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.memakai_baju1"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.memakai_baju2"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr
                    v-for="(d, i) in master.form15.naik_turun_tangga"
                    :key="'i' + i"
                  >
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.naik_turun_tangga.length"
                    >
                      9
                    </td>
                    <td
                      v-if="i === 0"
                      :rowspan="master.form15.naik_turun_tangga.length"
                    >
                      Naik turun tangga
                    </td>
                    <td>{{ d.value }}</td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.naik_turun_tangga1"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.naik_turun_tangga2"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr v-for="(d, i) in master.form15.mandi" :key="'j' + i">
                    <td v-if="i === 0" :rowspan="master.form15.mandi.length">
                      10
                    </td>
                    <td v-if="i === 0" :rowspan="master.form15.mandi.length">
                      Mandi
                    </td>
                    <td>{{ d.value }}</td>
                    <td>{{ d.label }}</td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.mandi1"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                        style="margin-top: 0; margin-bottom: 0"
                        v-model="data.form15.mandi2"
                      >
                        <v-radio :value="d.value.toString()"></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="4" class="text-center">Total Skor</th>
                    <th class="text-center">{{ total1 }}</th>
                    <th class="text-center">{{ total2 }}</th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">RESIKO JATUH</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form16"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form16[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form16[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <h6 class="mt-3">
              1. Pengkajian Resiko Jatuh Dewasa (Morse Fall Scale)
            </h6>
            <v-simple-table class="elevation-1" dense style="font-size: 10px">
              <template v-slot:default>
                <tbody>
                  <tr v-for="(d, i) in master.form17" :key="i">
                    <td>{{ d.no }}</td>
                    <td>{{ d.label }}</td>
                    <td v-for="(item, x) in d.data" :key="x">
                      <div v-if="item">
                        <v-radio-group
                          style="margin-top: 0; margin-bottom: 0"
                          v-model="data.form17[i]"
                        >
                          <v-radio
                            :value="item.value.toString()"
                            :label="item.label"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="2" class="text-center">Total Skor</th>
                    <th colspan="3" class="text-center">{{ total3 }}</th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </div>
          <div :hidden="tabKanan !== 5" style="padding: 10px">
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form18"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form18[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form18[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">PENGKAJIAN NYERI</div>
            <v-divider class="mt-3 mb-3" />
            <h6>
              Skal Nyeri : Wong Baker Scale Pain Numerik Rating Scale" (Nrm)
              Untuk Dewasa
            </h6>
            <sekala-nyeri
              :sync-value="(e) => (data.skala_nyeri = e)"
              :value="data.skala_nyeri"
            />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form19"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form19[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form19[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">NUTRISI/GIZI</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form20"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form20[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form20[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                v-for="(v, i) in master.form20b"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form20b[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form20b[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <h6>Pengkajian Gizi</h6>
            <v-divider class="mt-3 mb-3" />
            <v-simple-table class="elevation-1" dense style="font-size: 10px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th colspan="2" style="font-size: 12px" class="text-left">
                      Dewasa (Berdasarkan MST/Malnutrition Screening Tools)
                    </th>
                    <th style="font-size: 12px" class="text-left">Skor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      Apakah Ada Penurunan Berat Badan Yang Tidak Direncanakan?
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <v-radio-group
                        class="mt-0"
                        v-model="
                          data.form21.penurunan_berat_badan_tidak_direncanakan
                        "
                      >
                        <v-radio label="a. Tidak" :value="'0'"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <v-radio-group
                        class="mt-0"
                        v-model="
                          data.form21.penurunan_berat_badan_tidak_direncanakan
                        "
                      >
                        <v-radio
                          label="b. Tidak Yakin (Ada Tanda Baju Menjadi Lebih Longgar)"
                          :value="'2.1'"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <h6 class="ml-8">c. Jika Ya, Penurunan BB Sebanyak</h6>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <v-radio-group
                        class="mt-0 ml-6"
                        v-model="
                          data.form21.penurunan_berat_badan_tidak_direncanakan
                        "
                      >
                        <v-radio label="1-5 Kg" :value="1"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <v-radio-group
                        class="mt-0 ml-6"
                        v-model="
                          data.form21.penurunan_berat_badan_tidak_direncanakan
                        "
                      >
                        <v-radio label="6-10 Kg" :value="'2.2'"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <v-radio-group
                        class="mt-0 ml-6"
                        v-model="
                          data.form21.penurunan_berat_badan_tidak_direncanakan
                        "
                      >
                        <v-radio label="11-15 Kg" :value="'3'"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <v-radio-group
                        class="mt-0 ml-6"
                        v-model="
                          data.form21.penurunan_berat_badan_tidak_direncanakan
                        "
                      >
                        <v-radio label=">15 Kg" :value="'4'"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <v-radio-group
                        class="mt-0 ml-6"
                        v-model="
                          data.form21.penurunan_berat_badan_tidak_direncanakan
                        "
                      >
                        <v-radio
                          label="Tidak Tahu Berapa Kg Turunannya"
                          :value="'2.3'"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Apakah Ada Penurunan Nafsu Makan?</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <v-radio-group
                        class="mt-0"
                        v-model="data.form21.penurunan_nafsu_makan"
                      >
                        <v-radio label="a. Tidak" :value="'0'"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <v-radio-group
                        class="mt-0"
                        v-model="data.form21.penurunan_nafsu_makan"
                      >
                        <v-radio label="b. Ya" :value="'1'"></v-radio>
                      </v-radio-group>
                    </td>
                    <td>1</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="1"><h6>Resiko Malnutrisi</h6></td>
                    <td colspan="2">
                      <h6>{{ data.form21.resiko_malnutrisi_ket }}</h6>
                      <a hidden>{{ total4 }}</a>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              POLA AKTIFITAS SEHARI HARI
            </div>
            <v-divider class="mt-3 mb-3" />
            <div class="mt-3">
              <v-row class="mt-0 mb-0">
                <v-col
                  v-for="(v, i) in master.form22"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-0 mb-0 pt-0 pb-3"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form22[i] = e)"
                    vclass="c-text-field"
                    :data="v.data"
                    :value="data.form22[i]"
                    :property="v.property"
                    :label-size="v.labelSize"
                    :input-size="v.inputSize"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
          <div :hidden="tabKanan !== 6" style="padding: 10px">
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              KEBUTUHAN KOMUNIKASI / PENDIDIKAN DAN PENGAJARAN
            </div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form23"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form23[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form23[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">TINGKAT PENGETAHUAN</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form24"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form24[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form24[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">RESPON PSIKOLOGIS</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form25"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form25[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form25[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">DATA PENUNJANG</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form26"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form26[i] = e)"
                  vclass="c-text-field"
                  :value="data.form26[i]"
                  :data="v.data"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              PERENCANAAN PERAWATAN INTERDISIPLIN / REFERAL
            </div>
            <v-divider class="mt-3 mb-3" />
            <!-- <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form27"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
              <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form27[i] = e)"
                  vclass="c-text-field"
                  :value="data.form27[i]"
                  :data="v.data"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row> -->
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              PERENCANAAN PASIEN PULANG (Discharge Planning)
            </div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form28"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form28[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form28[i]"
                  :property="v.property"
                  :label-size="v.labelSize"
                  :input-size="v.inputSize"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <div class="py-2"></div>
      <div class="form-group">
        <div
          class="float-group btn-group-vertical"
          role="group"
          aria-label="Vertical button group"
        >
          <v-btn @click="postSave" color="primary" large class="btn-block">
            Simpan Form
          </v-btn>
          &nbsp;
        </div>
      </div>
      <dialog-confirm
        :show-dialog="dialogShow"
        :negative-button="dialogActionNBtn"
        :positive-button="dialogActionPBtn"
        :disabled-negative-btn="dialogDisableNBtn"
        :disabled-positive-btn="dialogDisablePBtn"
        :progress="dialogProgress"
        :title="dialogTitle"
        :message="dialogMessage"
      />
    </div>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
// import { use } from 'vue/types/umd'
// import { apiKedua } from '../plugins/supports'
// import { apiKedua } from '../plugins/supports'
// import { isEmpty, jsonPrettier } from '@/plugins/supports'
import Master from '../../data/asesmen-awal-keperawatan-rawat-inap'
import SmartWidget from '@/components/SmartWidget.vue'
import Dialog from '@/components/Dialog'
import SekalaNyeri from '@/components/SekalaNyeri.vue'
export default {
  components: {
    DialogConfirm: Dialog,
    SekalaNyeri,
    SmartWidget
    // DialogConfirm: Dialog,
    // Soap,
  },
  data () {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      item_ke: null,
      url_emr: 'http://emr.rs-syafira.com/',
      url: '',
      site: '',
      history: [],
      patient_name: '',
      tabKiri: '',
      tabKanan: 0,
      search: '',
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          dokter_yang_merawat: '',
          tiba_diruangan_tanggal: '',
          tiba_diruangan_jam: '',
          pengkajian_tanggal: '',
          pengkajian_jam: '',
          cara_masuk: '',
          asal_pasien: '',
          masuk_kasus_trauma: '',
          hasil_yang_dibawa: ''
        },
        form5: {
          keluhan_utama: '',
          riwayat_penyakit_sekarang: '',
          pernah_dirawat: '',
          pernah_operasi_tindakan: '',
          masalah_operasi_pembiusan: ''
        },
        form6: {
          riwayat_penyakit_keluarga: '',
          obat_dari_rumah: '',
          riwayat_konsumsi_obat: '',
          riwayat_alergi: '',
          riwayat_transfusi: '',
          reaksi_yang_timbul: '',
          golongan_darah: '',
          rh: '',
          cara_pemberian: '',
          efek_samping: '',
          riwayat_pernikahan: '',
          pernikahan_ke: ''
        },
        form7: {
          keadaan_umum: '',
          kesadaraan: '',
          gcs_e: '',
          gcs_m: '',
          gcs_v: '',
          pupil: '',
          kiri: '',
          reaksi_cahaya_kanan: '',
          reaksi_cahaya_kiri: '',
          tanda_vital_td: '',
          tanda_vital_hr: '',
          tanda_vital_rr: '',
          tanda_vital_t: '',
          tanda_vital_spo2: '',
          pemeriksaan_atrometrik_bb: '',
          pemeriksaan_atrometrik_tb: '',
          pemeriksaan_atrometrik_lk: '',
          pemeriksaan_atrometrik_ld: '',
          pemeriksaan_atrometrik_lp: ''
        },
        form8: {
          data: [
            {
              text: 'Sistem Susunan Saraf Pusat',
              dataCols: {
                kepala: '',
                ubun_ubun: '',
                wajah: '',
                leher: '',
                kejang: '',
                sensorik: '',
                motorik: ''
              }
            },
            {
              text: 'Sistem Penglihatan/Mata',
              dataCols: {
                gangguan_penglihatan: '',
                posisi_mata: '',
                pupil: '',
                kelopak_mata: '',
                konjungtiva: '',
                sklera: '',
                alat_bantu_penglihatan: '',
                label_alat_bantu_penglihatan_ya: '',
                alat_bantu_penglihatan_ya: ''
              }
            },
            {
              text: 'Sistem Pendengaran',
              value: 0,
              dataCols: {
                sistem_pendengaran: '',
                menggunakan_alat_bantu_dengar: ''
              }
            },
            {
              text: 'Sistem Penciuman',
              dataCols: {
                sistem_penciuman: ''
              }
            },
            {
              text: 'Sistem Pernafasan',
              dataCols: {
                pola_nafas: '',
                retraksi: '',
                nafas_cuping_hidung: '',
                jenis_pernafasan: '',
                irama_nafas: '',
                terpasang_wsd: '',
                kesulitan_bernafas: '',
                kesulitan_bernafas_ya: '',
                batuk_dan_sekresi: '',
                batuk_dan_sekresi_ya: '',
                warna_sputum: '',
                suara_nafas: '',
                perkusi: ''
              }
            }
          ]
        },
        form9: {
          data: [
            {
              text: 'Sistem Kardiovaskuler/Jantung',
              dataCols: {
                warna_kulit: '',
                clubbing_finger: '',
                nyeri_dada: '',
                denyut_nadi: '',
                bunyi_jantung: '',
                sirkulasi: '',
                pulsasi: '',
                crt: ''
              }
            },
            {
              text: 'Sistem Pencernaan',
              dataCols: {
                mulut: '',
                gigi: '',
                lidah: '',
                tenggorokan: '',
                abdomen: '',
                nyeri_tekan_lepas: '',
                ada_benjolan_massa: '',
                peristaltik_usus: '',
                anus: '',
                bab: '',
                diare: ''
              }
            },
            {
              text: 'Sistem Genitourinaria',
              dataCols: {
                kebersihan: '',
                kelainan: '',
                bak: '',
                palpasi: '',
                perkusi: ''
              }
            },
            {
              text: 'Sistem Reproduksi',
              dataCols: {
                menarche_umur: '',
                siklus_haid: '',
                lama_haid: '',
                hpht: '',
                kebersihan: '',
                penggunaan_alat_kontrasepsi: '',
                payudara: '',
                puting_susu: '',
                tanda_tanda_mastitis: '',
                uterus: '',
                kontraksi: '',
                sirkumsisi: '',
                gangguan_prostat: ''
              }
            },
            {
              text: 'Sistem Integumen',
              dataCols: {
                turgor: '',
                warna: '',
                integritas: ''
              }
            },
            {
              text: 'Sistem Muskuloskeletal',
              dataCols: {
                pergerakan_sendi: '',
                kekuatan_otot: '',
                nyeri_sendi: '',
                oedema: '',
                fraktur: '',
                parese: '',
                postur_tubuh: ''
              }
            },
            {
              text: 'Sistem Muskuloskeletal',
              dataCols: {
                mata: '',
                leher: '',
                ekstremitas: ''
              }
            }
          ]
        },
        form10: {
          baris1: {
            nama_obat: '',
            dosis: '',
            terakhir_kali: ''
          },
          baris2: {
            nama_obat: '',
            dosis: '',
            terakhir_kali: ''
          },
          baris3: {
            nama_obat: '',
            dosis: '',
            terakhir_kali: ''
          },
          baris4: {
            nama_obat: '',
            dosis: '',
            terakhir_kali: ''
          }
        },
        form11: {
          kondisi_fisik_umum: 0,
          kesadaraan: 0,
          tingkat_aktivitas: 0,
          mobilitas: 0,
          inkontinensia: 0
        },
        form12: {
          penglihatan_kontak: '-',
          penciuman: '-',
          pendengaran: '-'
        },
        form13: {
          kognitif: ''
        },
        form14: {
          aktivitas_sehari_hari: '',
          berjalan: '',
          riwayat_patah_tulang: '',
          alat_ambulatory: '',
          ekstremitas_atas: '',
          ekstremitas_bawah: '',
          kemampuan_menggenggam: '',
          kemampuan_koordinasi: ''
        },
        form15: {
          bab1: null,
          bab2: null,
          bak1: null,
          bak2: null,
          membersihkan_diri1: null,
          membersihkan_diri2: null,
          penggunaan_jamban1: null,
          penggunaan_jamban2: null,
          makan1: null,
          makan2: null,
          berubah_sikap1: null,
          berubah_sikap2: null,
          berpindah1: null,
          berpindah2: null,
          memakai_baju1: null,
          memakai_baju2: null,
          naik_turun_tangga1: null,
          naik_turun_tangga2: null,
          mandi1: null,
          mandi2: null
        },
        form16: {
          kondisi_resiko_tinggi: '',
          pengkajian_restrain: '',
          diskusi_dengan_keluarga: ''
        },
        form17: {
          riwayat_jatuh: null,
          diagnosa_sekunder: null,
          alat_bantu_berjalan: null,
          akses_infus: null,
          cara_berjalan: null,
          ada_gangguan_sensorik: null
        },
        form18: {
          keamanan: '',
          resiko_melarikan_diri: ''
        },
        form19: {
          frekuensi_nyeri: '',
          lama_nyeri: '',
          menjalar: '',
          kualitas_nyeri: '',
          faktor_pemicu: '',
          lokasi_nyeri: '',
          tindak_lanjut: '',
          nyeri_mempengaruhi: ''
        },
        form20: {
          intake_nutrisi_lewat: ''
        },
        form20b: {
          masalah_nutrisi1: '',
          masalah_nutrisi2: '',
          masalah_nutrisi3: ''
        },
        form22: {
          pola_istirahat_dan_tidur: '',
          lama_tidur: '',
          tidur_siang: '',
          tidur_malam: '',
          hal_cepat_tidur: '',
          makan: '',
          jenis_makan: '',
          pantangan: '',
          diet_khusus: '',
          minum: '',
          jenis_minum: '',
          mandi: '',
          sekat_gigi: '',
          keramas: '',
          olah_raga: ''
        },
        form23: {
          bicara: '',
          bahasa_sehari_hari: '',
          perlu_penerjemah: '',
          hambatan_belajar: '',
          cara_belajar: '',
          pendidikan_pasien: '',
          potensi_kebutuhan: '',
          menginginkan_informasi: '',
          menginginkan_informasi2: ''
        },
        form24: {
          penyakit_yang_diderita: '',
          tindakan_pengobatan: '',
          perencanaan_diet: '',
          perubahan_aktifitas: '',
          perawatan_setelah_dirumah: ''
        },
        form25: {
          respon_psikologis: '',
          respon_psikologis2: ''
        },
        form26: {
          laboratorium: '',
          radiologi: '',
          lain_lain: ''
        },
        form27: {
          diet_dan_nutrisi: '',
          rehabilitasi_medik: '',
          farmasi: '',
          perawatan_luka: '',
          manajemen_nyeri: '',
          lain_lain: ''
        },
        form28: {
          // label1: '',
          direncanakan_pulang: '',
          lama_perawatan: '',
          tanggal_rencana_pulang: '',
          umur_65: '',
          keterbatasan_mobilitas: '',
          lain_lain: '',
          bantuan_untuk_melakukan_aktifitas_sehari_sehari: '',
          perencanaan_pulang_sbb: '',
          perencanaan_pulang_sbb2: ''
        },
        form21: {
          penurunan_berat_badan_tidak_direncanakan: null,
          penurunan_nafsu_makan: null,
          resiko_malnutrisi: 0,
          resiko_malnutrisi_ket: '-'
        },
        skala_nyeri: null
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  computed: {
    total1: function () {
      var x = this.data.form15
      const sum =
        x.bab1 +
        x.bak1 +
        x.membersihkan_diri1 +
        x.penggunaan_jamban1 +
        x.makan1 +
        x.berubah_sikap1 +
        x.berpindah1 +
        x.memakai_baju1 +
        x.naik_turun_tangga1 +
        x.mandi1
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    total2: function () {
      var x = this.data.form15
      const sum =
        x.bab2 +
        x.bak2 +
        x.membersihkan_diri2 +
        x.penggunaan_jamban2 +
        x.makan2 +
        x.berubah_sikap2 +
        x.berpindah2 +
        x.memakai_baju2 +
        x.naik_turun_tangga2 +
        x.mandi2
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    total3: function () {
      var x = this.data.form17
      const sum =
        x.riwayat_jatuh +
        x.diagnosa_sekunder +
        x.alat_bantu_berjalan +
        x.akses_infus +
        x.cara_berjalan +
        x.ada_gangguan_sensorik
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    total4: function () {
      var x = this.data.form21
      const sum =
        x.penurunan_nafsu_makan +
        parseInt(x.penurunan_berat_badan_tidak_direncanakan)
      if (isNaN(sum)) {
        return 0
      } else {
        x.resiko_malnutrisi = sum
        if (sum <= 1) {
          x.resiko_malnutrisi_ket = 'Nilai 0-1 (Rendah)'
        } else if (sum <= 3) {
          x.resiko_malnutrisi_ket = 'Nilai 2-3 (Sedang)'
        } else {
          x.resiko_malnutrisi_ket = 'Nilai 4-5 (Tinggi)'
        }
        return sum
      }
    }
  },
  created () {
    if (!this.$route.query.no_rm) {
      errorMsg('PILIH DAHULU PASIENNYA')
      this.$router.push({
        name: 'DataPasienRawatInap'
      })
    }
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      setTimeout(() => {
        this.getAssemen(this.$route.query.folio_id)
      }, 1000)
    }
    // setTimeout(() => {
    //   this.getAssemen(this.$route.query.folio_id)
    // }, 100)
  },
  methods: {
    setTab () {
      console.log(this.tabKanan + ' TAB KE BERAPA')
      if (this.tabKanan === 1 || this.tabKanan === 2) {
        this.tabKiri = 2
      } else {
        this.tabKiri = 0
      }
      setTimeout(() => {
        this.getAssemen(this.$route.query.folio_id)
      }, 1000)
    },
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        created_by: this.user.employee_id,
        jenis: 'rawat-inap',
        type: 'Asesmen Keperawatan Rawat Inap'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
      // localStorage.setItem('asesmeninap', JSON.stringify(data))
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }

            // this.data.form11 = results.form11
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Keperawatan Rawat Inap'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            // console.log(this.data)
            this.data = results

            // console.log(results)
            // this.data.form7 = results.form7
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
            this.data = results
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style scoped>

.float-group {
  position: fixed;
  bottom: 10%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 100px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
