export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      disabled: true,

      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'Dokter DPJP',
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'No Reg',
      col: 6
    }
  },
  form1: {
    label1: {
      label: 'Dokter yang Merawat',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    dokter_yang_merawat: {
      label: ' ',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 9
    },
    label2: {
      label: 'Tiba di Ruangan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tiba_diruangan_tanggal: {
      widget: 'wdatenormal',
      data: [],
      col: 4,
      disabled: true,

      label: 'Tanggal'
    },
    tiba_diruangan_jam: {
      label: 'Jam',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 3,
      property: { attrs: { suffix: '', type: 'string' } }
    },
    label3: {
      label: 'Pengkajian',
      widget: 'wlabelkiri',
      data: [],
      disabled: true,

      col: 3
    },
    pengkajian_tanggal: {
      widget: 'wdatenormal',
      data: [],
      col: 4,
      disabled: true,

      label: 'Tanggal'
    },
    pengkajian_jam: {
      label: 'Jam',
      widget: 'wtext',
      data: [],
      col: 3,
      disabled: true,

      property: { attrs: { suffix: '', type: 'string' } }
    },
    label4: {
      label: 'Pengkajian Diperoleh dari',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    reaksi_yang_timbul: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Pasien', value: 'Pasien' },
        { text: 'Orang Lain, ', value: null, field: 'auto' }
      ]
    },
    label5: {
      label: 'Cara Masuk',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    cara_masuk: {
      label: ' ',
      widget: 'wcheckbox',
      col: 8,
      data: [
        { text: 'IRJ', value: 'IRJ' },
        { text: 'IGD', value: 'IGD' },
        { text: 'Kamar Operasi', value: 'Kamar Operasi' },
        { text: 'Kamar Bersalin', value: 'Kamar Bersalin' },
        { text: 'Dokter Pribadi', value: 'Dokter Pribadi' },
        { text: 'Rujukan, ', value: null, field: 'auto' }
      ]
    },
    label6: {
      label: 'Masuk Kasus Trauma *)',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    cara_masuk_trauma: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'KLL', value: 'KLL' },
        { text: 'KDRT', value: 'KDRT' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label7: {
      label: 'Hasil Pemeriksaan yang Dibawa Oleh Keluarga',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    hasil_pemeriksaan_berkas: {
      label: ' ',
      widget: 'wcheckbox',
      col: 8,
      data: [
        { text: 'Laboratorium', value: 'Laboratorium' },
        { text: 'Radiologi', value: 'Radiologi' },
        { text: 'Diagnostik Lain', value: 'Diagnostik Lain' },
        { text: 'Tidak Ada', value: 'Tidak Ada' }
      ]
    }
  },
  form2: {
    label1: {
      label: 'SOSIAL DAN EKONOMI',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label2: {
      label: '1. Pekerjaan Penanggung Jawab / OT Pasien',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    pekerjaan_penanggung_jawab: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'TK', value: 'TK' },
        { text: 'SD', value: 'SD' },
        { text: 'SMP', value: 'SMP' },
        { text: 'SLTA', value: 'SLTA' },
        { text: 'akademi', value: 'akademi' },
        { text: 'paska sarjana', value: 'paska sarjana' },
        { text: 'lain-lain', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: '2. Penghasilan Penanggung Jawab / OT Pasien',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    penghasilan_penanggung_jawab: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'tidak ada', value: 'tidak ada' },
        { text: '< 1 juta', value: '< 1 juta' },
        { text: '1 - 2,9 juta', value: '1 - 2,9 juta' },
        { text: '3-4,9 juta', value: '3-4,9 juta' },
        { text: '5-9,9 juta', value: '5-9,9 juta' },
        { text: '10-14,9 juta', value: '10-14,9 juta' },
        { text: '15-19,9 juta', value: '15-19,9 juta' },
        { text: '>20 juta', value: '>20 juta' }
      ]
    },
    label4: {
      label: '3. Pendidikan Penanggung Jawab/OT/Suami/Istri',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    pendidikan_penanggung_jawab: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'TK', value: 'TK' },
        { text: 'SD', value: 'SD' },
        { text: 'SMP', value: 'SMP' },
        { text: 'SLTA', value: 'SLTA' },
        { text: 'akademi', value: 'akademi' },
        { text: 'paska sarjana', value: 'paska sarjana' },
        { text: 'lain-lain', value: null, field: 'auto' }
      ]
    },
    label5: {
      label: '4. Tinggal Bersama',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    tinggal_bersama: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'keluarga', value: 'keluarga' },
        { text: 'Suami/Istri', value: 'Suami/Istri' },
        { text: 'Orang tua', value: 'Orang tua' },
        { text: 'Anak', value: 'Anak' },
        { text: 'Teman', value: 'Teman' },
        { text: 'Sendiri', value: 'Sendiri' },
        { text: 'Panti Asuhan', value: 'Panti Asuhan' }
      ]
    }
  },
  form3: {
    label1: {
      label: 'SPIRITUAL (AGAMA)',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    agama: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Islam', value: 'Islam' },
        { text: 'Protestan', value: 'Protestan' },
        { text: 'katolik', value: 'katolik' },
        { text: 'Hindu', value: 'Hindu' },
        { text: 'Budha', value: 'Budha' },
        { text: 'Konghucu', value: 'Konghucu' }
      ]
    },
    label2: {
      label: 'Mengungkapkan Keprihatinan yang Berhubungan dengan Rawat Inap',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    keprihatinan_ranap: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ketidakmampuan Mempertahankan Praktek Spiritual seperti Biasa', value: 'Ketidakmampuan Mempertahankan Praktek Spiritual seperti Biasa' },
        { text: 'Perasaan Negatif tentang sistem Kepercayaan terhadap Spiritual', value: 'Perasaan Negatif tentang sistem Kepercayaan terhadap Spiritual' },
        { text: 'Konflik antara Kepercayaan terhadap Spiritual dengan Ketentuan Sistem Kepercayaan', value: 'Konflik antara Kepercayaan terhadap Spiritual dengan Ketentuan Sistem Kepercayaan' },
        { text: 'Bimbingan rohani', value: 'Bimbingan rohani' },
        { text: 'Lainnya, ', value: null, field: 'auto' }
      ]
    }
  },
  form4: {
    label1: {
      label: 'SUKU / BUDAYA',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label2: {
      label: 'Nilai-nilai Kepercayaan Pasien/Keluarga',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    nilai_kepercayaan: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak Mau Ditransfusi', value: 'Tidak Mau Ditransfusi' },
        { text: 'Tidak Mau Imunisasi', value: 'Tidak Mau Imunisasi' },
        { text: 'Tidak Makan Daging / Ikan yang Bersisik', value: 'Tidak Makan Daging / Ikan yang Bersisik' },
        { text: 'Tidak Mau Pulang di Hari Tertentu', value: 'Tidak Mau Pulang di Hari Tertentu' },
        { text: 'Tidak Boleh Menyusui (ASI)', value: 'Tidak Boleh Menyusui (ASI)' },
        { text: 'Lainnya, ', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: 'SPIRITUAL (AGAMA)',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    keprihatinan_ranap: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ketidakmampuan Mempertahankan Praktek Spiritual seperti Biasa', value: 'Ketidakmampuan Mempertahankan Praktek Spiritual seperti Biasa' },
        { text: 'Perasaan Negatif tentang sistem Kepercayaan terhadap Spiritual', value: 'Perasaan Negatif tentang sistem Kepercayaan terhadap Spiritual' },
        { text: 'Konflik antara Kepercayaan terhadap Spiritual dengan Ketentuan Sistem Kepercayaan', value: 'Konflik antara Kepercayaan terhadap Spiritual dengan Ketentuan Sistem Kepercayaan' },
        { text: 'Bimbingan rohani', value: 'Bimbingan rohani' },
        { text: 'Lainnya, ', value: null, field: 'auto' }
      ]
    },
    label4: {
      label: 'Nilai-nilai Kepercayaan Pasien/Keluarga',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    kebutuhan_privasi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Keinginan Waktu / Tempat Khusus Wawancara dan Tindakan, ', value: null, field: 'auto' },
        { text: 'Kondisi Penyakit / Pengobatan, ', value: null, field: 'auto' },
        { text: 'Tidak Menerima Kunjungan,Sebutkan Jika ada ', value: null, field: 'auto' },
        { text: 'Tidak Mau Dirawat Perawat Laki-laki / Perempuan', value: 'Tidak Mau Dirawat Perawat Laki-laki / Perempuan' },
        { text: 'Transportasi', value: 'Transportasi' },
        { text: 'Lainnya, ', value: null, field: 'auto' }
      ]
    }
  },
  form5: {
    label1: {
      label: 'ANAMNESIS',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label2: {
      label: 'Diagnosa saat Masuk :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    diagnosa_saat_masuk: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3
    },
    label3: {
      label: '1. Keluhan Utama : ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    keluhan_utama: {
      label: ' ',
      widget: 'wtextarea',
      data: [],
      col: 12
    },
    label4: {
      label: '2. Riwayat Penyakit Sekarang : ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    riwayat_penyakit_sekarang: {
      label: ' ',
      widget: 'wtextarea',
      data: null,
      col: 12
    },
    label5: {
      label: '3. Riwayat Obstetrik',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    G: {
      widget: 'wtext',
      data: null,
      col: 2
    },
    P: {
      widget: 'wtext',
      data: null,
      col: 2
    },
    A: {
      widget: 'wtext',
      data: null,
      col: 2
    },
    label6: {
      label: 'Usia Gestasi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    usia_gestasi: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 2,
      property: { attrs: { suffix: 'Mg', type: 'number' } }
    },
    label7: {
      label: '4. Pernah Dirawat :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    pernah_dirawat: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Indikasi Dirawat', value: null, field: 'auto' }
      ]
    },
    label8: {
      label: '5. Status Gizi Ibu:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    status_gizi_ibu: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Baik', value: 'Baik' },
        { text: 'Buruk', value: 'Buruk' },
        { text: 'Lainnya', value: null, field: 'auto' }
      ]
    },
    label9: {
      label: '6. Obat - Obatan Yang Dikonsumsi Selama Kehamilan',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    obat_dikonsumsi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Ada , jenis', value: null, field: 'auto' }
      ]
    }
  },
  form6: {
    label1: {
      label: '7. Kebiasaan Ibu',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kebiasaan_ibu: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Merokok', value: 'Merokok' },
        { text: 'Minum jamu', value: 'Minum jamu' },
        { text: 'Minum beralkohol', value: 'Minum beralkohol' },
        { text: 'Lainnya', value: null, field: 'auto' }
      ]
    },
    label2: {
      label: '8. Riwayat Persalinan Ketuban Volume',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    riwayat_persalinan: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'SC', value: 'SC' },
        { text: 'Spontan Kepala/Bokong', value: 'Spontan Kepala/Bokong' },
        { text: 'VE', value: 'VE' },
        { text: 'FORCEP', value: 'FORCEP' },
        { text: 'Jernih', value: 'Jernih' },
        { text: 'Hijau Encer/Kental', value: 'Hijau Encer/Kental' },
        { text: 'Meconium', value: 'Meconium' },
        { text: 'Darah', value: 'Darah' },
        { text: 'Putih Keruh', value: 'Putih Keruh' },
        { text: 'Lainnya', value: null, field: 'auto' },
        { text: 'Normal', value: 'Normal' },
        { text: 'Oligohudramnion', value: 'Oligohudramnion' },
        { text: 'Polihidramnion', value: 'Polihidramnion' },
        { text: 'APGAR SCORE', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: '9. Antropometri BBL',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    BB: {
      label: 'BB',
      widget: 'wtext',
      data: [],
      col: 1,
      property: { attrs: { suffix: 'gr', type: 'number' } }
    },
    PB: {
      label: 'PB',
      widget: 'wtext',
      data: [],
      col: 2,
      property: { attrs: { suffix: 'cm', type: 'number' } }
    },
    LK: {
      label: 'LK',
      widget: 'wtext',
      data: [],
      col: 2,
      property: { attrs: { suffix: 'cm', type: 'number' } }
    },
    LD: {
      label: 'LD',
      widget: 'wtext',
      data: [],
      col: 2,
      property: { attrs: { suffix: 'cm', type: 'number' } }
    },
    LP: {
      label: 'LP',
      widget: 'wtext',
      data: [],
      col: 2,
      property: { attrs: { suffix: 'cm', type: 'number' } }
    },
    BBS: {
      label: 'BBS',
      widget: 'wtext',
      data: [],
      col: 1,
      property: { attrs: { suffix: 'gr', type: 'number' } }
    },
    label4: {
      label: '10. Riwayat Penyakit Ibu',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    riwayat_penyakit_ibu: {
      label: ' ',
      widget: 'wcheckbox',
      col: 2,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada', value: 'Ada' },
        { text: 'Diabetes', value: 'Diabetes' },
        { text: 'Kangker', value: 'Kangker' }
      ]
    },
    riwayat_penyakit_ibu2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 8,
      data: [
        { text: 'Asma', value: 'Asma' },
        { text: 'Hipertensi', value: 'Hipertensi' },
        { text: 'Jantung', value: 'Jantung' },
        { text: 'Lainnya', value: null, field: 'auto' }
      ]
    },
    label5: {
      label: '11. Riwayat Alergi Obat/Makanan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    riwayat_alergi: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, Sebutkan', value: null, field: 'auto' }
      ]
    },
    label6: {
      label: '12. Riwayat Transfusi Darah',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    riwayat_transfusi_darah: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada, kapan', value: null, field: 'auto' }
      ]
    },
    label6a: {
      label: 'Timbul Reaksi',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    timbul_reaksi_tf_darah: {
      label: ' ',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada , ', value: null, field: 'auto' }
      ]
    },
    label7: {
      label: '13. Riwayat Imunisasi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    riwayat_imunisasi: {
      label: ' ',
      widget: 'wradio',
      col: 5,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'Ada , sebutkan', value: null, field: 'auto' }
      ]
    }
  },
  form7: {
    label1: {
      label: 'PEMERIKSAAN FISIK',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label2: {
      label: '1. Keadaan Umum',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    keadaan_umum: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tampak Tidak Sakit', value: 'Tampak Tidak Sakit' },
        { text: 'Sakit Ringan', value: 'Sakit Ringan' },
        { text: 'Sakit Sedang', value: 'Sakit Sedang' },
        { text: 'Sakit Berat', value: 'Sakit Berat' }
      ]
    },
    label3: {
      label: '2. Kesadaran',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kesadaran: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Alert', value: 'Alert' },
        { text: 'Verbal', value: 'Verbal' },
        { text: 'Pain', value: 'Pain' },
        { text: 'Unrespon', value: 'Unrespon' }
      ]
    },
    label4: {
      label: '3. Tanda Vital',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    S: {
      widget: 'wtext',
      data: null,
      col: 1
    },
    N: {
      widget: 'wtext',
      data: null,
      col: 2
    },
    RR: {
      widget: 'wtext',
      data: null,
      col: 2
    },
    Spo2: {
      widget: 'wtext',
      data: null,
      col: 2
    },
    TD: {
      widget: 'wtext',
      data: null,
      col: 2
    },
    down_score: {
      widget: 'wtext',
      data: null,
      col: 1
    },
    label5: {
      label: '4. Berat Badan Sekarang:',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    pemeriksaan_fisik_bb: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'BB', suffix: 'Kg', type: 'number' } }
    },
    pemeriksaan_fisik_tb: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'TB', suffix: 'Cm', type: 'number' } }
    },
    pemeriksaan_fisik_lk: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'LK', suffix: 'Cm', type: 'number' } }
    },
    pemeriksaan_fisik_ld: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'LD', suffix: 'Cm', type: 'number' } }
    },
    pemeriksaan_fisik_lp: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'LP', suffix: 'Cm', type: 'number' } }
    },
    label6: {
      label: '5. Golongan Darah / Rh (Bayi)',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    golongan_darah_bayi: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'O', value: 'O' },
        { text: 'AB', value: 'AB' }
      ]
    },
    label_rh_bayi: {
      label: 'Rh',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    rh_bayi: {
      label: ' ',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Positif', value: 'Positif' },
        { text: 'Negatif', value: 'Negatif' }
      ]
    },
    label6a: {
      label: 'Golongan Darah / Rh (Ibu)',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    golongan_darah_ibu: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'O', value: 'O' },
        { text: 'AB', value: 'AB' }
      ]
    },
    label_rh_ibu: {
      label: 'Rh',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    rh_ibu: {
      label: ' ',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Positif', value: 'Positif' },
        { text: 'Negatif', value: 'Negatif' }
      ]
    },
    label6b: {
      label: 'Golongan Darah / Rh (Ayah)',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    golongan_darah_ayah: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'O', value: 'O' },
        { text: 'AB', value: 'AB' }
      ]
    },
    label_rh_ayah: {
      label: 'Rh',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    rh_ayah: {
      label: ' ',
      widget: 'wradio',
      col: 6,
      data: [
        { text: 'Positif', value: 'Positif' },
        { text: 'Negatif', value: 'Negatif' }
      ]
    }
  },
  form8: {
    widget: 'wtext',
    property: { attrs: { type: 'number' } },
    data: [
      {
        text: 'Sistem Susunan Saraf Pusat',
        value: 0,
        dataCols: {
          label1: {
            label: 'Gerak Bayi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          gerak_bayi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Aktif', value: 'Aktif' },
              { text: 'Tidak Aktif', value: 'Tidak Aktif' }
            ]
          },
          label2: {
            label: 'UUB :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          uub: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Datar', value: 'Datar' },
              { text: 'Cekung', value: 'Cekung' },
              { text: 'Menonjol', value: 'Menonjol' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label3: {
            label: 'Kejang :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kejang: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak Ada', value: 'Tidak Ada' },
              { text: 'Ada', value: null, field: 'auto' }
            ]
          },
          label4: {
            label: 'Reflek :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          reflek: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Moro', value: 'Moro' },
              { text: 'Menelan', value: 'Menelan' },
              { text: 'Hisap', value: 'Hisap' },
              { text: 'Babinski', value: 'Babinski' },
              { text: 'Rooting', value: 'Rooting' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label5: {
            label: 'Tangis Bayi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          tangis_bayi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Kuat', value: 'Kuat' },
              { text: 'Melengking', value: 'Melengking' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          }
        }
      },
      {
        text: 'Sistem Penglihatan/Mata',
        value: 0,
        dataCols: {
          label1: {
            label: 'Posisi Mata :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          posisi_mata: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Simetris', value: 'Simetris' },
              { text: 'Asimetris', value: 'Asimetris' }
            ]
          },
          label3: {
            label: 'Pupil :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          pupil: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Isokor', value: 'Isokor' },
              { text: 'Anisokor', value: 'Anisokor' }
            ]
          },
          label4: {
            label: 'Kelopak Mata :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kelopak_mata: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak ada kelainan', value: 'Tidak ada kelainan' },
              { text: 'Edema', value: 'Edema' },
              { text: 'Cekung', value: 'Cekung' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label5: {
            label: 'Konjungtiva :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          konjungtiva: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak ada kelainan', value: 'Tidak ada kelainan' },
              { text: 'Anemis', value: 'Anemis' },
              { text: 'Konjungtivitis', value: 'Konjungtivitis' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          },
          label6: {
            label: 'Sklera :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          sklera: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak ada kelainan', value: 'Tidak ada kelainan' },
              { text: 'Ikterik', value: 'Ikterik' },
              { text: 'Perdarahan', value: 'Perdarahan' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          }
        }
      },
      {
        text: 'Sistem Pendengaran',
        value: 0,
        dataCols: {
          sistem_pendengaran: {
            label: ' ',
            widget: 'wradio',
            col: 12,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Asimetris', value: 'Asimetris' },
              { text: 'Serumen', value: 'Serumen' },
              { text: 'Keluar Cairan', value: 'Keluar Cairan' },
              { text: 'Tidak ada lubang telinga', value: 'Tidak ada lubang telinga' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          }
        }
      },
      {
        text: 'Sistem Penciuman',
        value: 0,
        dataCols: {
          sistem_penciuman: {
            label: ' ',
            widget: 'wradio',
            col: 12,
            data: [
              { text: 'TAK', value: 'TAK' },
              { text: 'Asimetris', value: 'Asimetris' },
              { text: 'Keluar Cairan', value: 'Keluar Cairan' },
              { text: 'Lain-lain', value: null, field: 'auto' }
            ]
          }
        }
      },
      {
        text: 'Sistem Pernafasan',
        value: 0,
        dataCols: {
          label1: {
            label: 'Pola Nafas :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          pola_nafas: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Normal', value: null, field: 'auto' },
              { text: 'Bradipnea', value: null, field: 'auto' },
              { text: 'Trakipnea', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'Jenis Pernafasan :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          jenis_pernafasan: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Dada', value: 'Dada' },
              { text: 'Perut', value: 'Perut' },
              {
                text: 'Alat Bantu Nafas, Sebutkan :',
                value: null,
                field: 'auto'
              }
            ]
          },
          label3: {
            label: 'Irama Nafas :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          irama_nafas: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Teratur', value: 'Teratur' },
              { text: 'Tidak Teratur', value: 'Tidak Teratur' }
            ]
          },
          label4: {
            label: 'Retraksi :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          retraksi: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak', value: 'Tidak' },
              { text: 'Ringan', value: 'Ringan' },
              { text: 'Sedang', value: 'Sedang' },
              { text: 'Berat', value: 'Berat' }
            ]
          },
          label5: {
            label: 'Air Entry :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          air_entry: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Udara Masuk', value: 'Udara Masuk' },
              { text: 'Penurunan udara masuk', value: 'Penurunan udara masuk' },
              { text: 'Tidak ada udara masuk', value: 'Tidak ada udara masuk' }
            ]
          },
          label6: {
            label: 'Merintih :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          merintih: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak Ada', value: 'Tidak Ada' },
              { text: 'Terdengar dengan stetoskop', value: 'Terdengar dengan stetoskop' },
              { text: 'Terdengar tanpa stetoskop', value: 'Terdengar tanpa stetoskop' }
            ]
          },
          label7: {
            label: 'Suara Nafas :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          suara_nafas: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Veskuler', value: 'Veskuler' },
              { text: 'Wheezing', value: 'Wheezing' },
              { text: 'Ronchi', value: 'Ronchi' },
              { text: 'Stridor', value: 'Stridor' }
            ]
          }
        }
      },
      {
        text: 'Sistem Pencernaan',
        value: 0,
        dataCols: {
          label1: {
            label: 'Mulut :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          mulut: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TIdak ada kelainan', value: 'TIdak ada kelainan' },
              { text: 'Simestris', value: 'Simestris' },
              { text: 'Asimetris', value: 'Asimetris' },
              { text: 'Mucosa mulut kering', value: 'Mucosa mulut kering' },
              { text: 'Bibir pucat', value: 'Bibir pucat' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'Lidah :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          lidah: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TIdak ada kelainan', value: 'TIdak ada kelainan' },
              { text: 'Kotor', value: 'Kotor' },
              { text: 'Gerakan Asimetris', value: 'Gerakan Asimetris' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label3: {
            label: 'Oesofagus :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          oesofagus: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'TIdak ada kelainan', value: 'TIdak ada kelainan' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label4: {
            label: 'Abdomen :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          abdomen: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Supel', value: 'Supel' },
              { text: 'Asites', value: 'Asites' },
              { text: 'Tegang', value: 'Tegang' },
              { text: 'Bising usus', value: null, field: 'auto' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label5: {
            label: 'Anus :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          anus: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Normal', value: 'Normal' },
              { text: 'Tidak Normal', value: 'Tidak Normal' }
            ]
          },
          label6: {
            label: 'BAB :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          bab: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Normal', value: 'Normal' },
              { text: 'Konstipasi', value: 'Konstipasi' },
              { text: 'Melena', value: 'Melena' },
              { text: 'Colostomy', value: 'Colostomy' },
              { text: 'Diare, frek', value: null, field: 'auto' },
              { text: 'Meconium Pertama, Tgl/jam ', value: null, field: 'auto' }
            ]
          },
          label7: {
            label: 'Warna :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          warna: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Kuning', value: 'Kuning' },
              { text: 'Dempul', value: 'Dempul' },
              { text: 'Coklat', value: 'Coklat' },
              { text: 'Hijau', value: 'Hijau' },
              { text: 'Darah', value: 'Darah' },
              { text: 'Lainya ', value: null, field: 'auto' }
            ]
          }
        }
      },
      {
        text: 'Sistem Genitourinaria',
        value: 0,
        dataCols: {
          label1: {
            label: 'BAK :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          bak: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Normal', value: 'Normal' },
              { text: 'Hematuri', value: 'Hematuri' },
              { text: 'Urin menetes', value: 'Urin menetes' },
              { text: 'Sakit', value: 'Sakit' },
              { text: 'Oliguri', value: 'Oliguri' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'BAK Pertama :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          bak_pertama_tgl: {
            widget: 'wdatenormal',
            data: [],
            col: 4,
            label: 'Tanggal'
          },
          bak_pertama_jam: {
            label: 'Jam',
            widget: 'wtext',
            data: [],
            col: 3,
            property: { attrs: { suffix: '', type: 'number' } }
          },
          warna_bak: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Jernih', value: 'Jernih' },
              { text: 'Kuning', value: 'Kuning' },
              { text: 'Kuning Pekat', value: 'Kuning Pekat' }
            ]
          }
        }
      },
      {
        text: 'Sistem Reproduksi',
        value: 0,
        dataCols: {
          label1: {
            label: 'Laki-laki :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          reproduksi_lk: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Normal', value: 'Normal' },
              { text: 'Hipospadia', value: 'Hipospadia' },
              { text: 'Epispadia', value: 'Epispadia' },
              { text: 'Fimosis', value: 'Fimosis' },
              { text: 'Hidrokel', value: 'Hidrokel' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'Perempuan :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          reproduksi_pr: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Normal', value: 'Normal' },
              { text: 'Keputihan', value: 'Keputihan' },
              { text: 'Vagina Skintag', value: 'Vagina Skintag' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          }
        }
      }
    ]
  },
  form9: {
    widget: 'wtext',
    property: { attrs: { type: 'number' } },
    data: [
      {
        text: 'Sistem Integument',
        value: 0,
        dataCols: {
          label1: {
            label: 'Vernic Kaseosa :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          vernic_kaseosa: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Ada', value: 'Ada' },
              { text: 'Tidak ada', value: 'Tidak ada' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'Lanugo :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          lanugo: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tidak ada', value: 'Tidak ada' },
              { text: 'Banyak', value: 'Banyak' },
              { text: 'Tipis', value: 'Tipis' },
              { text: 'Bercak-bercak tanpa lanugo', value: 'Bercak-bercak tanpa lanugo' },
              { text: 'Sebagian besar tanpa lanugo', value: 'Sebagian besar tanpa lanugo' }
            ]
          },
          label3: {
            label: 'Warna :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          sistem_integument_warna: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Pucat', value: 'Pucat' },
              { text: 'Ikterik', value: 'Ikterik' },
              { text: 'Sianosis', value: 'Sianosis' },
              { text: 'Normal', value: 'Normal' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label4: {
            label: 'Turgor Kulit :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          turgor_kulit: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Baik', value: 'Baik' },
              { text: 'Sedang', value: 'Sedang' },
              { text: 'Buruk', value: 'Buruk' },
              { text: 'Normal', value: 'Normal' },
              { text: 'Rash/Kemerahan', value: 'Rash/Kemerahan' },
              { text: 'Lesi', value: 'Lesi' },
              { text: 'Luka', value: 'Luka' },
              { text: 'Memar', value: 'Memar' },
              { text: 'Ptechie', value: 'Ptechie' },
              { text: 'Bula', value: 'Bula' }
            ]
          },
          label5: {
            label: 'Kriteria Resiko Dekubitus:',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          kriteria_resiko_dekubitus: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Jaringan / Elastisitas kulit kurang', value: 'Jaringan / Elastisitas kulit kurang' },
              { text: 'Immobilisasi', value: 'Immobilisasi' },
              { text: 'Perawatan NICU', value: 'Perawatan NICU' }
            ]
          }
        }
      },
      {
        text: 'Sistem Muskuloskeletal',
        value: 0,
        dataCols: {
          label1: {
            label: 'Lengan:',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          muskuloskeletal_lengan: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Fleksi', value: 'Fleksi' },
              { text: 'Ekstensi', value: 'Ekstensi' },
              { text: 'Pergerakan aktif', value: 'Pergerakan aktif' },
              { text: 'Pergerakan tidak aktif', value: 'Pergerakan tidak aktif' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label2: {
            label: 'Tungkai :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          muskuloskeletal_tungkai: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Fleksi', value: 'Fleksi' },
              { text: 'Ekstensi', value: 'Ekstensi' },
              { text: 'Pergerakan aktif', value: 'Pergerakan aktif' },
              { text: 'Pergerakan tidak aktif', value: 'Pergerakan tidak aktif' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label3: {
            label: 'Rekoil telinga :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          muskuloskeletal_rekoil_telinga: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Rekoil Lambat', value: 'Rekoil Lambat' },
              { text: 'Rekoil Cepat', value: 'Rekoil Cepat' },
              { text: 'Rekoil Segera', value: 'Rekoil Segera' },
              { text: 'Lainnya', value: null, field: 'auto' }
            ]
          },
          label4: {
            label: 'Garis Telapak Kaki :',
            widget: 'wlabelkiri',
            data: [],
            col: 2
          },
          muskuloskeletal_garis_kaki: {
            label: ' ',
            widget: 'wradio',
            col: 10,
            data: [
              { text: 'Tipis', value: 'Tipis' },
              { text: 'Garis transversal anterior', value: 'Garis transversal anterior' },
              { text: 'Garis 2/3 anterior', value: 'Garis 2/3 anterior' },
              { text: 'Seluruh telapak kaki', value: 'Seluruh telapak kaki' }
            ]
          }
        }
      }
    ]
  },
  form10: {
    label1: {
      label: 'Status Psikologis (Orang tua)',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    status_psikologis: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tenang', value: 'Tenang' },
        { text: 'Cemas', value: 'Cemas' },
        { text: 'Sedih', value: 'Sedih' },
        { text: 'Depresi', value: 'Depresi' },
        { text: 'Marah', value: 'Marah' },
        { text: 'Hiperaktif', value: 'Hiperaktif' },
        { text: 'Mengganggu Sekitar', value: 'Mengganggu Sekitar' },
        { text: 'Lainnya', value: null, field: 'auto' }
      ]
    },
    label2: {
      label: 'Kenyamanan / pengkajian nyeri (asesmen nyeri) pada usia 0-1 bulan (NIPS)',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label3: {
      label: 'Nyeri',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    pengkajian_nyeri: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak ada', value: 'Tidak ada' },
        { text: 'ada, < skala nyeri ', value: null, field: 'auto' }
      ]
    },
    label4: {
      label: 'Frekwensi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    pengkajian_frekwensi: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Jarang', value: 'Jarang' },
        { text: 'Hilang Timbul', value: 'Hilang Timbul' },
        { text: 'Terus Menerus', value: 'Terus Menerus' },
        { text: 'Lama nyeri ', value: null, field: 'auto' }
      ]
    }
  },
  form11: {
    ekspresi_wajah_nips: [
      {
        label: 'Santai',
        value: 0
      },
      {
        label: 'Meringis',
        value: 1
      }
    ],
    menangis_nips: [
      {
        label: 'Rileks',
        value: 0
      },
      {
        label: 'Merengek',
        value: 1
      },
      {
        label: 'Menangis Keras',
        value: 2
      }
    ],
    pola_nafas_nips: [
      {
        label: 'Rileks',
        value: 0
      },
      {
        label: 'Perubahan Pola Nafas',
        value: 1
      }
    ],
    lengan_nips: [
      {
        label: 'Tertahan / Rileks',
        value: 0
      },
      {
        label: 'Fleksi / Etensi',
        value: 1
      }
    ],
    kaki_nips: [
      {
        label: 'Tertahan / Rileks',
        value: 0
      },
      {
        label: 'Fleksi / Etensi',
        value: 1
      }
    ],
    keadaan_kedasaran_nips: [
      {
        label: 'Tidur / Bangun',
        value: 0
      },
      {
        label: 'Rewel',
        value: 1
      }
    ]
  },
  form12: {
    nadi_prematur: [
      {
        label: '10 % Dari Basaline',
        value: 0
      },
      {
        label: '11-20% Dari Basaline',
        value: 1
      },
      {
        label: '20% Dari Basaline',
        value: 2
      }
    ],
    saturasi_oksigen_prematur: [
      {
        label: 'Tidak Pakai Oksigen',
        value: 0
      },
      {
        label: 'Oksigen Tambahan',
        value: 1
      }
    ]
  },
  form13: {
    label1: {
      label: 'A. Kebutuhan Komunikasi / Pendidikan Dan Pengajaran Orang Tua',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label2: {
      label: '1. Bicara :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kebutuhan_komunikasi_bicara: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Ada gangguan', value: 'Ada gangguan' },
        { text: 'Menikah, Lama Menikah', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: '2. Bahasa Sehari-hari :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kebutuhan_komunikasi_bahasa_sehari: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 10
    },
    label4: {
      label: '3. Penerjemah :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kebutuhan_komunikasi_penerjemah: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Bahasa', value: null, field: 'auto' }
      ]
    },
    label5: {
      label: 'Bahasa Isyarat :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kebutuhan_komunikasi_bahasa_isyarat: {
      label: ' ',
      widget: 'wradio',
      col: 5,
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label6: {
      label: '4. Masalah Penglijhatan :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kebutuhan_komunikasi_masalah_penglihatan: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Sebutkan', value: null, field: 'auto' }
      ]
    },
    label7: {
      label: 'Pasien Atau Keluarga Menginginkan Informasi Tentang:',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    kebutuhan_komunikasi_informasi: {
      label: ' ',
      widget: 'wradio',
      col: 8,
      data: [
        { text: 'Proses Penyakit', value: 'Proses Penyakit' },
        { text: 'Gizi / Nutrisi', value: 'Gizi / Nutrisi' },
        { text: 'Terapi Atau Obat', value: 'Terapi Atau Obat' },
        { text: 'Peralatan Medis', value: 'Peralatan Medis' },
        { text: 'Tindakan/ Pemeriksaan', value: 'Tindakan/ Pemeriksaan' },
        { text: 'Lainnya', value: null, field: 'auto' }
      ]
    }
  },
  form14: {
    label1: {
      label: 'ASESMEN GIZI / SKRINING GIZI OLEH PERAWAT',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label2: {
      label: 'Minum :',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    asesmen_gizi_minum: {
      label: ' ',
      widget: 'wradio',
      col: 2,
      data: [
        { text: 'ASI', value: 'ASI' },
        { text: 'PASI', value: 'PASI' }
      ]
    },
    label3: {
      label: 'Frekwensi :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    minum_frekwensi: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 3,
      property: { attrs: { prefix: 'X/jam', type: 'string' } }
    },
    minum_frekwensi_cc: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 4,
      property: { attrs: { prefix: 'cc/kal', type: 'string' } }
    },
    label4: {
      label: 'Masalah :',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    gizi_masalah: {
      label: ' ',
      widget: 'wradio',
      col: 11,
      data: [
        { text: 'Ada (Skor 1)', value: 'Ada (Skor 1)' },
        { text: 'Tidak Ada (Skor 0)', value: 'Tidak Ada (Skor 0)' }
      ]
    },
    label5: {
      label: 'Penurunan BB :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    gizi_penurunan_bb: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: '<= 10 % Dari BBL (0)', value: '<= 10 % Dari BBL (0)' },
        { text: '>= 10 % Dari BBL (1)', value: '>= 10 % Dari BBL (1)' }
      ]
    },
    label6: {
      label: 'Penyakit Yang Menyertai Jika Ada Skornya 2 :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    gizi_penyakit: {
      label: ' ',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Sepsis', value: 'Sepsis' },
        { text: 'Jantung', value: 'Jantung' },
        { text: 'BBLR', value: 'BBLR' },
        { text: 'Hipoglikemi', value: 'Hipoglikemi' },
        { text: 'Diare', value: 'Diare' },
        { text: 'Lain-lain', value: null, field: 'auto' }
      ]
    },
    label7: {
      label: 'Total Skor',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label8: {
      label: 'Jika Skor < 2 ',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    diet_yang_diberikan: {
      label: ' ',
      widget: 'wradio',
      col: 11,
      data: [
        { text: 'ASI', value: 'ASI' },
        { text: 'PASI', value: 'PASI' },
        { text: 'Per Oral / NGT', value: 'Per Oral / NGT' }
      ]
    },
    label9: {
      label: 'Jika Skor >=  2 : Asesmen Dilanjutkan Advise Dokter ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    }
  },
  form15: {
    label1: {
      label: 'DAFTAR MASALAH KEPERAWATAN',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    daftar_masalah_keperawatan: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        { text: 'Nyeri', value: 'Nyeri' },
        { text: 'Nutrisi', value: 'Nutrisi' },
        { text: 'Mobilitas/aktifitas', value: 'Mobilitas/aktifitass' },
        { text: 'Pengetahuan/Komunikasi', value: 'Pengetahuan/Komunikasi' },
        { text: 'Infeksi', value: 'Infeksi' }
      ]
    },
    daftar_masalah_keperawatan2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        { text: 'Perfusi Jaringan', value: 'Perfusi Jaringan' },
        { text: 'Suhu Tubuh', value: 'Suhu Tubuh' },
        { text: 'Eliminasi', value: 'Eliminasi' },
        { text: 'Keseimbangan Cairan & Elektrolit', value: 'Keseimbangan Cairan & Elektrolit' },
        { text: 'Lainnya', value: null, field: 'auto' }
      ]
    },
    daftar_masalah_keperawatan3: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        { text: 'Pol', value: 'Pol' },
        { text: 'Peningkatan Bilirubin', value: 'Peningkatan Bilirubin' },
        { text: 'Integritas Kulit', value: 'Integritas Kulit' },
        { text: 'Jalan Nafas/Pertukaran Gas', value: 'Jalan Nafas/Pertukaran Gas' }
      ]
    }
  },
  form16: {
    label0: {
      label: 'PERENCANAAN PERAWATAN INTERDISIPLIN / REFERAL',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label1: {
      label: '1. Diet Dan Nutrisi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    perencanaan_diet_nutrisi: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, ', value: null, field: 'auto' }
      ]
    },
    label2: {
      label: '2. Rehabilitasi Medik',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    perencanaan_rehabilitas_medik: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, ', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: '3. Farmasi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    perencanaan_farmasi: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, ', value: null, field: 'auto' }
      ]
    },
    label4: {
      label: '4. Perawatan Luka',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    perencanaan_perawatan_luka: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, ', value: null, field: 'auto' }
      ]
    },
    label5: {
      label: '5. Manajemen Nyeri',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    perencanaan_manajemen_nyeri: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, ', value: null, field: 'auto' }
      ]
    },
    label6: {
      label: '6. Lain-lain',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    perencanaan_lainnya: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, ', value: null, field: 'auto' }
      ]
    }
  },
  form17: {
    label0: {
      label: 'PERENCANAAN PULANG (DISACHARGE PLANNING)',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label1: {
      label: 'Pasien Dan Keluarga Diberikan Informasi Tentang Perencanaan Pulang',
      widget: 'wlabelkiri',
      data: [],
      col: 5
    },
    informasi_perencanaan_pulang: {
      label: ' ',
      widget: 'wradio',
      col: 7,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya', value: 'Ya' }
      ]
    },
    label2: {
      label: '1. Lama Perawatan Rata-rata ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    lama_rawatan: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    },
    label3: {
      label: '2. Tanggal Perencanaa Pulang ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    tanggal_rencana_pulang: {
      label: 'Tanggal Rencana Pulang',
      widget: 'wdatenormal',
      data: null,
      col: 10
    },
    label4: {
      label: '3. Perawatan Lanjutan Yang Diberikan Dirumah',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    rawatan_lanjutan_dirumah: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        { text: 'Personal Higiene (Mandi, BAB/BAK)', value: 'Personal Higiene (Mandi, BAB/BAK)' },
        { text: 'Perawatan Luka', value: 'Perawatan Luka' },
        { text: 'Perawatan Bayi/Anak', value: 'Perawatan Bayi/Anak' },
        { text: 'Pemberian Obat', value: 'Pemberian Obat' },
        { text: 'Lain-lain', value: 'Lain-lain' },
        { text: '', value: null, field: 'auto' }
      ]
    },
    rawatan_lanjutan_dirumah2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 8,
      data: [
        { text: 'Pemberioan Minum NGT/OGT/Sendok/Bot Bayi', value: 'Pemberioan Minum NGT/OGT/Sendok/Bot Bayi' },
        { text: 'Pemantauan Diet', value: 'Pemantauan Diet' },
        { text: 'Latihan Gerak/Exercise', value: 'Latihan Gerak/Exercise' },
        { text: 'Pemeriksaan Laboratorium Lanjutan', value: 'Pemeriksaan Laboratorium Lanjutan' },
        { text: 'Penyakit /Diagonsa ', value: 'Penyakit /Diagonsa ' },
        { text: '', value: null, field: 'auto' }
      ]
    },
    label5: {
      label: '4. Bayi /Anak Tinggal Bersama',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    bayi_anak_tinggal_bersama: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'OT Kandung', value: 'OT Kandung' },
        { text: 'Keluarga, ', value: null, field: 'auto' }
      ]
    },
    label6: {
      label: '5. Transporsi Yang Digunakan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    transportasi_digunakan: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Kendaraan Pribadi (Mobil, Beroda Dua Dll)', value: 'Kendaraan Pribadi (Mobil, Beroda Dua Dll)' },
        { text: 'Kendaraan', value: 'Kendaraan' },
        { text: 'Umum', value: 'Umum' },
        { text: 'Mobil Ambulance', value: 'Mobil Ambulance' },
        { text: 'Lainnya, ', value: null, field: 'auto' }
      ]
    },
    label7: {
      label: '6. Jika Ada Kriteria Masuk Dalam Pemulangan Kondisi Khusus Dilanjutkan Asesmen Pemulangan Kondisi Khusus',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    kriteria_masuk_pemulangan: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    }
  }
}
