<template>
  <v-container fluid>
    <a href="#test0" id="test0"></a>
    <h3 class="mb-2 mt-3">Asesmen Keperawatan Rawat Inap ICU</h3>
    <v-row justify="center">
      <v-col cols="12" lg="10" sm="10" md="10">
        <v-card color="deep-purple lighten-1" class="mt-2" dark>
          <v-card-text>
            <v-row class="mt-8 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="12" sm="12" md="12">
        <v-tabs class="mb-3" centered stacked>
          <v-tab @click="tabKanan = 0">Hal 1</v-tab>
          <v-tab @click="tabKanan = 1">Hal 2</v-tab>
          <v-tab @click="tabKanan = 2">Hal 3</v-tab>
          <v-tab @click="tabKanan = 3">Hal 4</v-tab>
          <v-tab @click="tabKanan = 4">Hal 5</v-tab>
          <v-tab @click="tabKanan = 5">Hal 6</v-tab>
          <v-tab @click="tabKanan = 6">Hal 7</v-tab>
        </v-tabs>
        <div :hidden="tabKanan !== 0">
          <v-row class="mt-1 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form1"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form1[i] = e)"
                :value="data.form1[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :disabled="v.disabled"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">
            STATUS SOSIAL, EKONOMI, AGAMA, SUKU/BUDAYA, NILAI KEPERCAYAAN DAN
            KEBUTUHAN PRIVASI DAN EDUKASI
          </div>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold mb-4">SOSIAL DAN EKONOMI</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form2"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form2[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6" />
          <div class="font-weight-bold mb-4">SPIRITUAL (AGAMA)</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form3"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form3[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6" />
          <div class="font-weight-bold mb-4">SUKU/BUDAYA</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form4"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form4[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6" />
          <div class="font-weight-bold mb-4">ANAMNESIS</div>
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form5"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form5[i] = e)"
                :value="data.form5[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div :hidden="tabKanan !== 1" style="padding: 10px">
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form6"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form6[i] = e)"
                vclass="c-text-field"
                :value="data.form6[i]"
                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="font-size: 12px" class="text-left">.</th>
                  <th style="font-size: 12px" class="text-left">.</th>
                  <th style="font-size: 12px" class="text-center">.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    width="2%"
                    style="
                      writing-mode: vertical-rl;
                      text-orientation: sideways;
                      text-align: center;
                    "
                  >
                    AIRWAY
                  </td>
                  <td width="50%">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form7"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form7[i] = e)"
                          vclass="c-text-field"
                          :value="data.form7[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                  <td class="text-capitalize" style="font-size: 12px">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form8"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form8[i] = e)"
                          vclass="c-text-field"
                          :value="data.form8[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td
                    rowspan="3"
                    width="2%"
                    style="
                      writing-mode: vertical-rl;
                      text-orientation: sideways;
                      text-align: center;
                    "
                  >
                    BREATHING
                  </td>
                  <td rowspan="2" width="50%">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form9"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form9[i] = e)"
                          vclass="c-text-field"
                          :value="data.form9[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                  <td class="text-capitalize" style="font-size: 12px">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form10"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form10[i] = e)"
                          vclass="c-text-field"
                          :value="data.form10[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td class="text-capitalize" style="font-size: 12px">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form11"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form11[i] = e)"
                          vclass="c-text-field"
                          :value="data.form11[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form12"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form12[i] = e)"
                          vclass="c-text-field"
                          :value="data.form12[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div :hidden="tabKanan !== 2" style="padding: 10px">
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="font-size: 12px" class="text-left">.</th>
                  <th style="font-size: 12px" class="text-left">.</th>
                  <th style="font-size: 12px" class="text-center">.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    rowspan="2"
                    width="2%"
                    style="
                      writing-mode: vertical-rl;
                      text-orientation: sideways;
                      text-align: center;
                    "
                  >
                    CIRCULATION
                  </td>
                  <td width="50%">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form13"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form13[i] = e)"
                          vclass="c-text-field"
                          :value="data.form13[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                  <td class="text-capitalize" style="font-size: 12px">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form14"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form14[i] = e)"
                          vclass="c-text-field"
                          :value="data.form14[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form15"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form15[i] = e)"
                          vclass="c-text-field"
                          :value="data.form15[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td
                    rowspan="2"
                    width="2%"
                    style="
                      writing-mode: vertical-rl;
                      text-orientation: sideways;
                      text-align: center;
                    "
                  >
                    DISABILITY
                  </td>
                  <td width="50%">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form16"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form16[i] = e)"
                          vclass="c-text-field"
                          :value="data.form16[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                  <td
                    class="text-capitalize"
                    style="font-size: 12px; vertical-align: top"
                  >
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form17"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form17[i] = e)"
                          vclass="c-text-field"
                          :value="data.form17[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td width="50%" style="vertical-align: top">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form18"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form18[i] = e)"
                          vclass="c-text-field"
                          :value="data.form18[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                  <td width="50%">
                    <v-row class="mt-0 m-1 mb-0">
                      <h5>Pengkajian Ulkus Dekubitus (Skala Norton)</h5>
                      <!-- {{ data.form19.kondisi_fisik_umum_sn }} -->
                      <v-simple-table
                        width="100%"
                        class="elevation-1"
                        dense
                        style="font-size: 10px"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th style="font-size: 12px" class="text-left">
                                Kondisi Pasien
                              </th>
                              <th style="font-size: 12px" class="text-center">
                                Keterangan
                              </th>
                              <th style="font-size: 12px" class="text-center">
                                Skor
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(d, i) in master.form19
                                .kondisi_fisik_umum_sn"
                              :key="'K' + i"
                            >
                              <td v-if="i === 0">Kondisi Fisik Umum</td>
                              <td v-else></td>
                              <td>{{ d.label }}</td>
                              <td>
                                <v-radio-group
                                  style="margin-top: 0; margin-bottom: 0"
                                  v-model="data.form19.kondisi_fisik_umum_sn"
                                >
                                  <v-radio
                                    :value="d.value.toString()"
                                    :label="d.value.toString()"
                                  ></v-radio>
                                </v-radio-group>
                              </td>
                            </tr>
                            <tr
                              v-for="(d, i) in master.form19.kesadaran_sn"
                              :key="'L' + i"
                            >
                              <td v-if="i === 0">Kesadaran</td>
                              <td v-else></td>
                              <td>{{ d.label }}</td>
                              <td>
                                <v-radio-group
                                  style="margin-top: 0; margin-bottom: 0"
                                  v-model="data.form19.kesadaran_sn"
                                >
                                  <v-radio
                                    :value="d.value"
                                    :label="d.value.toString()"
                                  ></v-radio>
                                </v-radio-group>
                              </td>
                            </tr>
                            <tr
                              v-for="(d, i) in master.form19
                                .tingkat_aktivitas_sn"
                              :key="'M' + i"
                            >
                              <td v-if="i === 0">Tingkat Akrivitas</td>
                              <td v-else></td>
                              <td>{{ d.label }}</td>
                              <td>
                                <v-radio-group
                                  style="margin-top: 0; margin-bottom: 0"
                                  v-model="data.form19.tingkat_aktivitas_sn"
                                >
                                  <v-radio
                                    :value="d.value"
                                    :label="d.value.toString()"
                                  ></v-radio>
                                </v-radio-group>
                              </td>
                            </tr>
                            <tr
                              v-for="(d, i) in master.form19.mobilitas_sn"
                              :key="'N' + i"
                            >
                              <td v-if="i === 0">Mobilitas</td>
                              <td v-else></td>
                              <td>{{ d.label }}</td>
                              <td>
                                <v-radio-group
                                  style="margin-top: 0; margin-bottom: 0"
                                  v-model="data.form19.mobilitas_sn"
                                >
                                  <v-radio
                                    :value="d.value"
                                    :label="d.value.toString()"
                                  ></v-radio>
                                </v-radio-group>
                              </td>
                            </tr>
                            <tr
                              v-for="(d, i) in master.form19.inkontinensia"
                              :key="'O' + i"
                            >
                              <td v-if="i === 0">Inkontinensia</td>
                              <td v-else></td>
                              <td>{{ d.label }}</td>
                              <td>
                                <v-radio-group
                                  style="margin-top: 0; margin-bottom: 0"
                                  v-model="data.form19.inkontinensia"
                                >
                                  <v-radio
                                    :value="d.value"
                                    :label="d.value.toString()"
                                  ></v-radio>
                                </v-radio-group>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div :hidden="tabKanan !== 3" style="padding: 10px">
          <small
            >Pengkajian Fungsional Penilaian Berdasarkan Penilaian Barhel
            Index</small
          >
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="font-size: 12px" class="text-left" rowspan="2">
                    No
                  </th>
                  <th style="font-size: 12px" class="text-left" rowspan="2">
                    Fungsi
                  </th>
                  <th style="font-size: 12px" class="text-center" rowspan="2">
                    Uraian
                  </th>
                  <th style="font-size: 12px" class="text-center" colspan="2">
                    Nilai Skor
                  </th>
                </tr>
                <tr>
                  <th style="font-size: 12px" class="text-center">
                    Sebelum Sakit
                  </th>
                  <th style="font-size: 12px" class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(d, i) in master.form20
                    .mengendalikan_rangsanan_defeksi"
                  :key="'A' + i"
                >
                  <td v-if="i === 0">1</td>
                  <td v-else></td>
                  <td v-if="i === 0">Mengendalikan rangsang defeksi (BAB)</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.mengendalikan_rangsanan_defeksi1"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.mengendalikan_rangsanan_defeksi2"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form20
                    .mengendalikan_rangsanan_berkemih"
                  :key="'B' + i"
                >
                  <td v-if="i === 0">2</td>
                  <td v-else></td>
                  <td v-if="i === 0">
                    Mengendalikan rangsangan berkemih (BAK)
                  </td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.mengendalikan_rangsanan_berkemih1"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.mengendalikan_rangsanan_berkemih2"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form20.membersikan_diri"
                  :key="'C' + i"
                >
                  <td v-if="i === 0">3</td>
                  <td v-else></td>
                  <td v-if="i === 0">
                    Membersihkan diri (cuci muka, sisir rambut, sikat)
                  </td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.membersikan_diri1"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.membersikan_diri2"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form20.penggunaan_jamban"
                  :key="'D' + i"
                >
                  <td v-if="i === 0">4</td>
                  <td v-else></td>
                  <td v-if="i === 0">
                    Penggunaan jamban, masukdan keluar (melepaskan, memakai
                    celana.membersihkan, menyiram)
                  </td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.penggunaan_jamban1"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.penggunaan_jamban2"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr v-for="(d, i) in master.form20.makan" :key="'E' + i">
                  <td v-if="i === 0">5</td>
                  <td v-else></td>
                  <td v-if="i === 0">Makan</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.makan1"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.makan2"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form20.berubah_sikap_berbaring"
                  :key="'F' + i"
                >
                  <td v-if="i === 0">6</td>
                  <td v-else></td>
                  <td v-if="i === 0">Berubah sikap dari berbaring ke duduk</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.berubah_sikap_berbaring1"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.berubah_sikap_berbaring2"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form20.berpindah_berjalan"
                  :key="'G' + i"
                >
                  <td v-if="i === 0">7</td>
                  <td v-else></td>
                  <td v-if="i === 0">Berpindah / berjalan</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.berpindah_berjalan1"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.berpindah_berjalan2"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr v-for="(d, i) in master.form20.memakai_baju" :key="'H' + i">
                  <td v-if="i === 0">8</td>
                  <td v-else></td>
                  <td v-if="i === 0">Memakai baju</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.memakai_baju1"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.memakai_baju2"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form20.naik_turun_tangga"
                  :key="'I' + i"
                >
                  <td v-if="i === 0">9</td>
                  <td v-else></td>
                  <td v-if="i === 0">Naik turun tangga</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.naik_turun_tangga1"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.naik_turun_tangga2"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr v-for="(d, i) in master.form20.mandi" :key="'J' + i">
                  <td v-if="i === 0">10</td>
                  <td v-else></td>
                  <td v-if="i === 0">Mandi</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.mandi1"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form20.mandi2"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="3" class="text-center">Total Skor</th>
                  <th class="text-center">{{ total1 }}</th>
                  <th class="text-center">{{ total2 }}</th>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form21"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form21[i] = e)"
                vclass="c-text-field"
                :value="data.form21[i]"
                :data="v.data"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div :hidden="tabKanan !== 4" style="padding: 10px">
          <small>Pengkajian Nyeri Non Verbal</small>
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="font-size: 12px" class="text-left">No</th>
                  <th style="font-size: 12px" class="text-left">Indikator</th>
                  <th style="font-size: 12px" class="text-center">
                    Deskription
                  </th>
                  <th style="font-size: 12px" class="text-center">
                    Nilai Skor
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(d, i) in master.form22.ekspresi_wajah_ccpot"
                  :key="'AA' + i"
                >
                  <td v-if="i === 0">1</td>
                  <td v-else></td>
                  <td v-if="i === 0">Ekspresi Wajah</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form22.ekspresi_wajah_ccpot"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form22.gerakan_tubuh_ccpot"
                  :key="'BA' + i"
                >
                  <td v-if="i === 0">2</td>
                  <td v-else></td>
                  <td v-if="i === 0">Gerakan Tubuh</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form22.gerakan_tubuh_ccpot"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form22
                    .kepatuhan_pasang_ventilator_ccpot"
                  :key="'CA' + i"
                >
                  <td v-if="i === 0">3 (A)</td>
                  <td v-else></td>
                  <td v-if="i === 0">
                    Kepatuhan terhadap pemasangan ventilator (pasien terpasang
                    intubasi)
                  </td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form22.kepatuhan_pasang_ventilator_ccpot"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form22.vokalisasi_ccpot"
                  :key="'DA' + i"
                >
                  <td v-if="i === 0">3 (B)</td>
                  <td v-else></td>
                  <td v-if="i === 0">
                    Vokalisasi (pasien tidak terpasang intubasi)
                  </td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form22.vokalisasi_ccpot"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form22.ketegangan_otot_ccpot"
                  :key="'DB' + i"
                >
                  <td v-if="i === 0">4</td>
                  <td v-else></td>
                  <td v-if="i === 0">Ketegangan Otot</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form22.ketegangan_otot_ccpot"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form22.ketegangan_otot_ccpot"
                  :key="'EA' + i"
                >
                  <td v-if="i === 0">5</td>
                  <td v-else></td>
                  <td v-if="i === 0">Ketegangan Otot</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form22.ketegangan_otot_ccpot"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form22.pengunaan_analgetik_ccpot"
                  :key="'FA' + i"
                >
                  <td v-if="i === 0">5</td>
                  <td v-else></td>
                  <td v-if="i === 0">Ketegangan Otot</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>

                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form22.pengunaan_analgetik_ccpot"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">URINE</div>
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="font-size: 12px" class="text-left">.</th>
                  <th style="font-size: 12px" class="text-left">.</th>
                  <th style="font-size: 12px" class="text-center">.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    rowspan="2"
                    width="2%"
                    style="
                      writing-mode: vertical-rl;
                      text-orientation: sideways;
                      text-align: center;
                    "
                  >
                    ELIMINATION
                  </td>
                  <td width="50%">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form23"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form23[i] = e)"
                          vclass="c-text-field"
                          :value="data.form23[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                  <td class="text-capitalize" style="font-size: 12px">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form24"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form24[i] = e)"
                          vclass="c-text-field"
                          :value="data.form24[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td width="50%" style="vertical-align: top">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form25"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form25[i] = e)"
                          vclass="c-text-field"
                          :value="data.form25[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">BOWEL</div>
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="font-size: 12px" class="text-left">.</th>
                  <th style="font-size: 12px" class="text-left">.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="50%">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form26"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form26[i] = e)"
                          vclass="c-text-field"
                          :value="data.form26[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                  <td
                    class="text-capitalize"
                    style="font-size: 12px; vertical-align: top"
                  >
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form27"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form27[i] = e)"
                          vclass="c-text-field"
                          :value="data.form27[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div :hidden="tabKanan !== 5" style="padding: 10px">
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="font-size: 12px" class="text-left">.</th>
                  <th style="font-size: 12px" class="text-left">.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="50%" style="font-size: 12px; vertical-align: top">
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form28"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="'f28' + i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form28[i] = e)"
                          vclass="c-text-field"
                          :value="data.form28[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                  <td
                    class="text-capitalize"
                    style="font-size: 12px; vertical-align: top"
                    rowspan="2"
                  >
                    <v-row class="mt-0 m-1 mb-0">
                      <v-col
                        v-for="(v, i) in master.form31"
                        :md="v.col ? v.col : 12"
                        :lg="v.col ? v.col : 12"
                        :sm="v.col ? v.col : 12"
                        cols="12"
                        class="mt-0 mb-0 pt-0 pb-3"
                        :key="'f28' + i"
                      >
                        <smart-widget
                          :comp="v.widget"
                          :sync-value="(e) => (data.form31[i] = e)"
                          vclass="c-text-field"
                          :value="data.form31[i]"
                          :data="v.data"
                          :property="v.property"
                          :label-size="v.labelSize"
                          :input-size="v.inputSize"
                          :label="v.label ? v.label : i.replaceAll('_', ' ')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">Pengkajian Gizi</div>
          <v-simple-table
            width="100%"
            class="elevation-1"
            dense
            style="font-size: 10px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="font-size: 12px" class="text-left">.</th>
                  <th style="font-size: 12px" class="text-center">.</th>
                  <th style="font-size: 12px" class="text-center">Skor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3">
                    1. Apakah ada penurunan berat badan yang tidak direncanakan
                    ?
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form29.penurunan_bb_a"
                  :key="'pbb' + i"
                >
                  <td v-if="i === 0"></td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form29.penurunan_bb_a"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form29.penurunan_bb_b"
                  :key="'pbb2' + i"
                >
                  <td v-if="i === 0">Penurunan BB Sebanyak</td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form29.penurunan_bb_b"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr
                  v-for="(d, i) in master.form29.penurunan_nafsu_makan"
                  :key="'pbb3' + i"
                >
                  <td v-if="i === 0">
                    2. Apakah ada penurunan berat badan yang tidak direncanakan
                    ?
                  </td>
                  <td v-else></td>
                  <td>{{ d.label }}</td>
                  <td>
                    <v-radio-group
                      style="margin-top: 0; margin-bottom: 0"
                      v-model="data.form29.penurunan_nafsu_makan"
                    >
                      <v-radio
                        :value="d.value"
                        :label="d.value.toString()"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form30"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form30[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form30[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form32"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form32[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form32[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <div class="font-weight-bold text-center">
            KEBUTUHAN KOMUNIKASI / PENDIDIKAN DAN PENGAJARAN
          </div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form33"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form33[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form33[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <div class="font-weight-bold text-center">TINGKAT PENGETAHUAN</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form34"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form34[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form34[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <div class="font-weight-bold text-center">RESPON PSIKOLOGIS</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form35"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form35[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form35[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div :hidden="tabKanan !== 6" style="padding: 10px">
          <div class="font-weight-bold text-center">
            Daftar masalah keperawatan
          </div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form36"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'mk' + i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form36[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form36[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <div class="font-weight-bold text-center">
            PERENCANAAN PERAWATAN INTERDISIPLIN / REFERAL
          </div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form37"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'ppr' + i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form37[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :value="data.form37[i]"

                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <div class="font-weight-bold text-center">
            PERENCANAAN PASIEN PULANG (Discharge Planning)
          </div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form38"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'ppp' + i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form38[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :value="data.form38[i]"

                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-for="(v, i) in master.form39"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'ppp2' + i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form39[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.form39[i]"

                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" medium class="btn-block">
          Simpan
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
// import { use } from 'vue/types/umd'
// import { apiKedua } from '../plugins/supports'
// import { apiKedua } from '../plugins/supports'
// import { isEmpty, jsonPrettier } from '@/plugins/supports'
import Master from '../../data/asesmen-awal-keperawatan-rawat-inap-icu'
import SmartWidget from '@/components/SmartWidget.vue'
import Dialog from '@/components/Dialog'
// import SekalaNyeri from '@/components/SekalaNyeri.vue'
export default {
  components: {
    DialogConfirm: Dialog,
    // SekalaNyeri,
    SmartWidget
    // DialogConfirm: Dialog,
    // Soap,
  },
  data () {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      item_ke: null,
      url_emr: 'http://emr.rs-syafira.com/',
      url: '',
      site: '',
      history: [],
      patient_name: '',
      tabKiri: '',
      tabKanan: 0,
      search: '',
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          dokter_yang_merawat: '',
          tiba_diruangan_tanggal: '',
          tiba_diruangan_jam: '',
          pengkajian_tanggal: '',
          pengkajian_jam: '',
          cara_masuk: '',
          asal_pasien: '',
          masuk_kasus_trauma: '',
          hasil_yang_dibawa: ''
        },
        form5: {
          keluhan_utama: '',
          riwayat_penyakit_sekarang: '',
          pernah_dirawat: '',
          pernah_operasi_tindakan: '',
          masalah_operasi_pembiusan: ''
        },
        form6: {
          riwayat_penyakit_keluarga: '',
          obat_dari_rumah: '',
          riwayat_konsumsi_obat: '',
          riwayat_alergi: '',
          riwayat_transfusi: '',
          reaksi_yang_timbul: '',
          golongan_darah: '',
          rh: '',
          cara_pemberian: '',
          efek_samping: '',
          riwayat_pernikahan: '',
          pernikahan_ke: ''
        },
        form7: {
          ukuran_ett: '',
          batas_bibir_ett: '',
          tanggal_pemasangan_ett: '',
          ukuran_trakeostomi: '',
          batas_bibir_trakeostomi: '',
          tanggal_pemasangan_trakeostomi: '',
          ukuran_oro_pharengeal: '',
          batas_bibir_oro_pharengeal: '',
          tanggal_pemasangan_oro_pharengeal: '',
          alat_lainnya: ''
        },
        form8: {
          jalan_nafas_sekret: '',
          karakteristik_sekret: '',
          selang_kebocoran: '',
          selang_terlipat: ''
        },
        form9: {
          ventilator: '',
          mode_ventilator: '',
          setting_venti_tidal_volume: '',
          setting_venti_rr: '',
          setting_venti_ieratio: '',
          setting_venti_peep: '',
          setting_venti_fio2: '',
          setting_venti_ps: ''
        },
        form10: {
          terapi_oksigen_nasal_kanul: '',
          terapi_oksigen_naterapi_oksigen_simple_masksal_kanul: '',
          terapi_oksigen_rm: '',
          terapi_oksigen_nrm: ''
        },
        form11: {
          sianosis: '',
          perifer: '',
          sentral: '',
          rr: '',
          suara_nafas_ka: '',
          suara_nafas_ki: '',
          hasil_rontgen: ''
        },
        form12: {
          agd_tgl: '',
          agd_jam: '',
          status_oksigen_ph: '',
          status_oksigen_pco2: '',
          status_oksigen_po2: '',
          status_oksigen_hco3: '',
          status_oksigen_o2_saturasi: '',
          status_oksigen_be: '',
          status_oksigen_tco2: ''
        },
        form13: {
          auskultasi_bising_jantung: '',
          auskultasi_irama_jantung: '',
          auskultasi_td: '',
          auskultasi_map: '',
          auskultasi_hr: '',
          auskultasi_distensi_vena_jugularis: '',
          auskultasi_jvp: '',
          auskultasi_cvp: ''
        },
        form14: {
          palpasi_nadi: '',
          dorsalis_pedis: '',
          pengisian_kapiler: '',
          auskultasi_td: '',
          ekstrimitas_atas_ka: '',
          ekstrimitas_atas_ki: '',
          hasil_ekg: ''
        },
        form15: {
          lab_fungsi_jantung: '',
          enzim_jantung_tgl: '',
          hasil_lab_ck: '',
          lab_funhasil_lab_ck_mbgsi_jantung: '',
          hasil_lab_trop_i: ''
        },
        form16: {
          kesadaran: '',
          gcs_e: '',
          gcs_m: '',
          gcs_v: '',
          konjungtiva_anemis: '',
          sklera_ikterik: '',
          pupil_ka: '',
          pupil_ki: '',
          reaksi_cahaya_ka: '',
          reaksi_cahaya_ki: '',
          tanda_vital_td: '',
          tanda_vital_hr: '',
          tanda_vital_rr: '',
          tanda_vital_t: '',
          tanda_vital_spo2: '',
          tanda_vital_bb: '',
          tanda_vital_tb: '',
          tanda_vital_imt: '',
          tanda_vital_lk: '',
          tanda_vital_ld: '',
          tanda_vital_bbi: ''
        },
        form17: {
          motorik_ka_atas: '',
          motorik_ka_bawah: '',
          motorik_ki_atas: '',
          motorik_ki_bawah: ''
        },
        form18: {
          riwayat_jatuh_di_rs: '',
          diagnosa_sekunder: '',
          alat_bantu_berjalan: '',
          iv_akses_infus: '',
          cara_berjalan: '',
          gangguan_sensorik: ''
        },
        form19: {
          kondisi_fisik_umum_sn: 4,
          kesadaran_sn: 4,
          tingkat_aktivitas_sn: 4,
          mobilitas_sn: 4,
          inkontinensia: 4
        },
        form20: {
          mengendalikan_rangsanan_defeksi1: 2,
          mengendalikan_rangsanan_defeksi2: 2,
          mengendalikan_rangsanan_berkemih1: 2,
          mengendalikan_rangsanan_berkemih2: 2,
          membersikan_diri1: 1,
          membersikan_diri2: 1,
          penggunaan_jamban1: 2,
          penggunaan_jamban2: 2,
          makan1: 2,
          makan2: 2,
          berubah_sikap_berbaring1: 3,
          berubah_sikap_berbaring2: 3,
          berpindah_berjalan1: 3,
          berpindah_berjalan2: 3,
          memakai_baju1: 2,
          memakai_baju2: 2,
          naik_turun_tangga1: 2,
          naik_turun_tangga2: 2,
          mandi1: 1,
          mandi2: 1
        },
        form21: {
          provokatif: '',
          quality_quantitas_nyeri: '',
          region_radiasi: '',
          severity_skala: '',
          time_lama_nyeri: '',
          konsul_ke_dokter: '',
          nyeri_mempengaruhi: ''
        },
        form22: {
          ekspresi_wajah_ccpot: 0,
          gerakan_tubuh_ccpot: 0,
          kepatuhan_pasang_ventilator_ccpot: 0,
          vokalisasi_ccpot: 0,
          ketegangan_otot_ccpot: 0,
          pengunaan_analgetik_ccpot: 0
        },
        form23: {
          infus: '',
          urine: '',
          oral: '',
          iwl: '',
          med: '',
          drain: '',
          balance_cairan: '',
          drain_kepala: ''
        },
        form24: {
          kateter_terpasang: '',
          kateter_jenis: '',
          warna_urine: '',
          pola_bak: ''
        },
        form25: {
          elektrolit_tgl: '',
          na: '',
          k: '',
          cl: '',
          ca2: '',
          p: '',
          mg2: '',
          cr: '',
          ur: ''
        },
        form26: {
          karakteristik_feses: '',
          pola_bab: '',
          bising_usus: '',
          asites: '',
          lingkaran_abdomen: '',
          hemoroid: '',
          stoma_kolostomi: ''
        },
        form27: {
          nyeri_abdomen_ka_atas: '',
          nyeri_abdomen_ki_atas: '',
          nyeri_abdomen_ka_bawah: '',
          nyeri_abdomen_ki_bawah: ''
        },
        form28: {
          hamil: '',
          hamil_tp: '',
          hamil_g: '',
          hamil_p: '',
          hamil_a: '',
          hamil_h: '',
          hamil_keluhan: ''
        },
        form29: {
          penurunan_bb_a: 0,
          penurunan_bb_b: '',
          penurunan_nafsu_makan: 0
        },
        form30: {
          resiko_malnutrisi: '',
          kebutuhan_nutrisi_aktual: ''
        },
        form31: {
          tugor_kulit: '',
          pendarahan: '',
          ada_luka: '',
          luka: '',
          frakur: '',
          penggunaan_alat_bantu: ''
        },
        form32: {
          tgl_labor: '',
          hb: '',
          albumin: '',
          globumin: '',
          protein_total: '',
          sgot: '',
          sgpt: '',
          gds: '',
          ur: '',
          cr: ''
        },
        form33: {
          bicara: '',
          bahasa_sehari: '',
          perlu_penerjemah: '',
          bahasa_isyarat: '',
          hambatan_belajar: '',
          cara_belajar: '',
          potensi_kebutuhan_pelajaran: '',
          pasien_menerima_informasi: ''
        },
        form34: {
          penyakit_diderita: '',
          tindakan_pengobatan_diberikan: '',
          perencaan_diet: '',
          perubahan_aktifitas: '',
          perawatan_dirumah: ''
        },
        form35: {
          respon_psikologis: ''
        },
        form36: {
          daftar_masalah_keperawatan: '',
          daftar_masalah_keperawatan2: ''
        },
        form37: {
          diet_nutrisi: '',
          rehabilitasi_medik: '',
          farmasi: '',
          perawatan_luka: '',
          manajemen_nyeri: '',
          lain_lain: ''
        },
        form38: {
          pasien_perencanaan_pulang: '',
          lama_perawatan: '',
          tgl_rencana_pulang: '',
          umur_65: '',
          keterbatasan_mobilitas: '',
          perawatan_lanjutan: '',
          bantuan_untuk_aktivitas: ''
        },
        form39: {
          kriteria_perenncanaan_pulang: '',
          kriteria_perenncanaan_pulang2: ''
        },
        skala_nyeri: null
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  computed: {
    total1: function () {
      var x = this.data.form20
      const sum =
        parseInt(x.mengendalikan_rangsanan_defeksi1) +
        parseInt(x.mengendalikan_rangsanan_berkemih1) +
        parseInt(x.membersikan_diri1) +
        parseInt(x.penggunaan_jamban1) +
        parseInt(x.makan1) +
        parseInt(x.berubah_sikap_berbaring1) +
        parseInt(x.berpindah_berjalan1) +
        parseInt(x.memakai_baju1) +
        parseInt(x.naik_turun_tangga1) +
        parseInt(x.mandi1)
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    total2: function () {
      var x = this.data.form20
      const sum =
        parseInt(x.mengendalikan_rangsanan_defeksi2) +
        parseInt(x.mengendalikan_rangsanan_berkemih2) +
        parseInt(x.membersikan_diri2) +
        parseInt(x.penggunaan_jamban2) +
        parseInt(x.makan2) +
        parseInt(x.berubah_sikap_berbaring2) +
        parseInt(x.berpindah_berjalan2) +
        parseInt(x.memakai_baju2) +
        parseInt(x.naik_turun_tangga2) +
        parseInt(x.mandi2)
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    total3: function () {
      var x = this.data.form17
      const sum =
        x.riwayat_jatuh +
        x.diagnosa_sekunder +
        x.alat_bantu_berjalan +
        x.akses_infus +
        x.cara_berjalan +
        x.ada_gangguan_sensorik
      if (isNaN(sum)) {
        return 0
      } else {
        return sum
      }
    },
    total4: function () {
      var x = this.data.form21
      const sum =
        x.penurunan_nafsu_makan +
        parseInt(x.penurunan_berat_badan_tidak_direncanakan)
      if (isNaN(sum)) {
        return 0
      } else {
        x.resiko_malnutrisi = sum
        if (sum <= 1) {
          x.resiko_malnutrisi_ket = 'Nilai 0-1 (Rendah)'
        } else if (sum <= 3) {
          x.resiko_malnutrisi_ket = 'Nilai 2-3 (Sedang)'
        } else {
          x.resiko_malnutrisi_ket = 'Nilai 4-5 (Tinggi)'
        }
        return sum
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      setTimeout(() => {
        this.getAssemen(this.$route.query.folio_id)
      }, 100)
    }
    setTimeout(() => {
      this.getAssemen(this.$route.query.folio_id)
    }, 100)
  },
  methods: {
    setTab () {
      console.log(this.tabKanan + ' TAB KE BERAPA')
      if (this.tabKanan === 1 || this.tabKanan === 2) {
        this.tabKiri = 2
      } else {
        this.tabKiri = 0
      }
      setTimeout(() => {
        // this.getAssemen(this.$route.query.folio_id)
      }, 100)
    },
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        created_by: this.user.employee_id,
        jenis: 'rawat-inap',
        type: 'Asesmen Keperawatan Rawat Inap ICU'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
      // localStorage.setItem('asesmeninap', JSON.stringify(data))
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Keperawatan Rawat Inap ICU'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            // console.log(this.data)
            this.data = results

            // console.log(results)
            // this.data.form7 = results.form7
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
            this.data = results
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style>
.v-input--radio-group .v-input__slot {
  margin-bottom: 0;
}
.v-messages {
  display: none;
}
.float-group {
  position: fixed;
  bottom: 50%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 100px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
