export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    gangguan_penglihatan: {
      label: 'Ganguan Penglihatan',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    gangguan_pendengaran: {
      label: 'Ganguan Pendengaran',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    gangguan_berkemih: {
      label: 'Ganguan Berkemih',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    gangguan_daya_ingat: {
      label: 'Ganguan Daya Ingat',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    gangguan_bicara: {
      label: 'Ganguan Bicara',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    }
  },
  form2: {
    mengetahui: {
      label: 'Mengetahui penyakit pada saat ini',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    sumber_informasi: {
      widget: 'wradio',
      data: [
        { text: 'Dokter', value: 'Dokter' },
        { text: 'Perawat', value: 'Perawat' },
        { text: 'Keluarga', value: 'Keluarga' },
        {
          text: 'Lain 2',
          value: null,
          field: 'auto'
        }
      ],
      col: 6
    },
    menerima_info: {
      widget: 'wradiowithoption',
      label: 'Menerima informasi dalam waktu pengobatan',
      data: [
        {
          text: 'Ya, ',
          value: 'YA',
          field: 'auto',
          option: ['Minggu', 'Bulan', 'Tahun'],
          default: 'Minggu',
          valueOption: 'YA'
        },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6
    },
    melakukan_pemeriksaan_rutin: {
      widget: 'wradio',
      data: [
        {
          text: 'Ya, di',
          value: null,
          field: 'auto'
        },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6
    },
    cara_penularan: {
      widget: 'wradio',
      data: [
        { text: 'Airbone', value: 'airbone' },
        { text: 'Droplet', value: 'droplet' },
        { text: 'Kontak_langsung', value: 'kontak_langsung' },
        { text: 'Cairan Tubuh', value: 'cairan_tubuh' }
      ],
      col: 6
    },
    riwayat_penyerta: {
      label: 'Riwayat Penyerta',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    }
  },
  form3: {
    mengetahui: {
      label: 'Pasien mengetahui penyakit pada saat ini',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    sumber_informasi: {
      widget: 'wradio',
      data: [
        { text: 'Dokter', value: 'Dokter' },
        { text: 'Perawat', value: 'Perawat' },
        { text: 'Keluarga', value: 'Keluarga' },
        {
          text: 'Lain 2',
          value: null,
          field: 'auto'
        }
      ],
      col: 6
    },
    melakukan_pemeriksaan_rutin: {
      widget: 'wradio',
      data: [
        {
          text: 'Ya, di',
          value: null,
          field: 'auto'
        },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6
    },
    riwayat_penyerta: {
      label: 'Riwayat Penyerta',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    }
  },
  form4: {
    kjq1: {
      label: 'Pernah mengalami gangguan jiwa sebelumnya',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq2: {
      label: 'Riwayat pengobobatan sebelumnya',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq3: {
      label: 'Adakah anggota keluarga menderita gangguan jiwa serupa',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq4: {
      label: 'Apakah pasien dapat merawat dirinya sendiri',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq5: {
      label: 'Apakah pasien dapat berkomunikasi dengan baik',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq6: {
      label: 'Apakah bicara pasien dapat dipahami oleh dokter atau perawat',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq7: {
      label: 'Apakah resiko mencederai diri sendiri',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq8: {
      label: 'Apakah resiko mencederai orang lain',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq9: {
      label: 'Apakah pasien dapat memahami instruksi dokter atau perawat',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    }
  },
  form5: {
    kjq1: {
      label: 'Apakah anda mengalami kekerasan/ penganiyaan',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq2: {
      label: 'Jenis kekerasan/ penganiyaan apa yang dialami',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq3: {
      label: 'Sudah berapa lama mengalami kekerasan/ penganiyaan',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq4: {
      label: 'Seberapa sering anda mengalami kekerasan/ penganiyaan',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq5: {
      label: 'Siapa yang melakukan kekerasan penganiyaan',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    kjq6: {
      label: 'Apakah anda memerlukan pendampingan',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    }
  }
}
