export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      disabled: true,

      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'Dokter DPJP',
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'No Reg',
      col: 6
    }

  },
  form1: {
    p1: {
      widget: 'wdatenormal',
      data: null,
      col: 3,
      label: 'Masuk Kamar Bersalin, Tanggal:'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Masuk Kamar Bersalin, Jam:'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Keluhan Utama:'
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Tanda-Tanda Persalinan:'
    },
    p5: {
      widget: 'wdatenormal',
      data: null,
      col: 3,
      label: 'Mules/Kontraksi Mulai, Tanggal:'
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Mules/Kontraksi Mulai, Jam:'
    },
    p7: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ada, Jam :', value: null, field: 'auto' }
      ],
      col: 6,
      label: 'Keluar Darah/Lendir/Air Ketuban'
    }
  },
  form2: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'HPHT'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Taksiran Partus'
    },
    p3: {
      widget: 'wtext',
      data: null,
      property: { attrs: { suffix: 'kali', type: 'number' } },
      col: 3,
      label: 'Perkawinan'
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Lamanya'
    },
    p5: {
      widget: 'wradio',
      data: [
        { text: 'Bidan', value: 'Bidan' },
        { text: 'di Dokter', value: 'di Dokter' },
        { text: 'Teratur', value: 'Teratur' },
        { text: 'Tidak Teratur', value: 'Tidak Teratur' },
        { text: 'Berapa (kali)', value: null, field: 'auto' }
      ],
      col: 6,
      label: 'Pemeriksaan Antenata'
    },
    p6: {
      widget: 'wradio',
      data: [
        { text: 'Ada, Jelaskan,', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Penyakit Selama Kehamilan'
    },
    p7: {
      widget: 'wradio',
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Jenis Operasi', value: 'Jenis Operasi' },
        { text: 'Tahun', value: null, field: 'auto' },
        { text: 'Tempat', value: null, field: 'auto' }
      ],
      col: 6,
      label: 'Riwayat Operasi'
    },
    p8: {
      widget: 'wradio',
      data: [
        { text: 'Ada, Jelaskan,', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Komplikasi Selama Kehamilan Sebelumnya'
    },
    p9: {
      widget: 'wradio',
      data: [
        { text: 'Ada, berapa kali,', value: null, field: 'auto' },
        { text: 'Tidak Pernah', value: 'Tidak Pernah' }
      ],
      col: 6,
      label: 'Riwayat Imunisasi TT'
    },
    p10: {
      widget: 'wradio',
      data: [
        { text: 'Obat-obatan yang diminum', value: 'Obat-obatan yang diminum' },
        { text: 'Vitamin', value: 'Vitamin' },
        { text: 'Jamu-jamuan', value: 'Tidak' },
        { text: 'Merokok', value: 'Merokok' }
      ],
      col: 6,
      label: 'Kebiasaan Ibu Waktu Hamil'
    },
    p11: {
      widget: 'wradio',
      data: [
        { text: 'Ada, Sebutkan,', value: null, field: 'auto' },
        { text: 'Tidak ada', value: 'Tidak ada' }
      ],
      col: 6,
      label: 'Riwayat Imunisasi TT'
    },
    p12: {
      widget: 'wradio',
      data: [
        { text: 'Ya, Kapan?', value: null, field: 'auto' },
        { text: 'Timbul Reaksi', value: null, field: 'auto' }
      ],
      col: 6,
      label: 'Riwayat Tranfusi Darah'
    },
    p13: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Riwayat Kahamilan (G)'
    },
    p14: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Riwayat Kahamilan (P)'
    },
    p15: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Riwayat Kahamilan (A)'
    },
    p16: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Riwayat Kahamilan (H)'
    }
  },
  form3: [
    {
      p1: {
        label: ' ',
        widget: 'wdatenormal',
        col: 12,
        data: []
      },
      p2: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p3: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p4: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p5: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p6: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p7: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p8: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p9: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      }
    }
  ],
  skala_nyeri: '',
  form4: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: 'BB'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: 'TB'
    },
    p3: {
      widget: 'wtext',
      data: null,
      property: { attrs: { suffix: 'kg/m2', type: 'number' } },
      col: 12,
      label: 'IMT'
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: 'LK'
    }
  },
  form5: {
    p1: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12,
      label: '1. Alat Bantu'
    },
    p2: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12,
      label: '2. Cacat Tubuh'
    },
    p3: {
      widget: 'wradio',
      data: [
        { text: 'Mandiri', value: 'Mandiri' },
        { text: 'Dibantu', value: 'Dibantu' }
      ],
      col: 12,
      label: '3. ADL'
    },
    p4: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12,
      label: '4. Rawat jalan dalam 3 bulan'
    }
  },
  form6: {
    p1: {
      widget: 'wradio',
      data: [
        { text: 'Resiko Rendah', value: 'Resiko Rendah' },
        { text: 'Resiko Sedang', value: 'Resiko Sedang' },
        { text: 'Resiko Tinggi', value: 'Resiko Tinggi' }
      ],
      col: 12,
      label: 'Resiko Jatuh'
    }
  },
  form7: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'TFU' } },
      label: ' '
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'TBJ' } },
      label: ' '
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Letak' } },
      label: ' '
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Presentasi' } },
      label: ' '
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Penurunan' } },
      label: ' '
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Kontraksi/his' } },
      label: ' '
    },
    p7: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Kekuatan', suffix: 'Lamanya' } },
      label: ' '
    },
    p8: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Gerak Janin', suffix: 'kali/30 menit' } },
      label: ' '
    },
    p9: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'BJJ' } },
      label: ' '
    },
    p10: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Teratur/tidak' } },
      label: ' '
    },
    p11: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Tgl/Jam' } },
      label: ' '
    },
    p12: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Oleh' } },
      label: ' '
    },
    p13: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Portio' } },
      label: ' '
    },
    p14: {
      widget: 'wtext',
      data: null,
      col: 3,
      property: { attrs: { prefix: 'Pembukaan' } },
      label: ' '
    }
  },
  form8: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Inspekulo, oleh:'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Inspekulo, hasil:'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'CTG, oleh:'
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'CTG, hasil:'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'USG, oleh:'
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'USG, hasil:'
    },
    p7: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: 'Lab:'
    }
  },
  form9: {
    p1: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: ' '
    }
  },
  form10: {
    p1: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Pada ibu'
    },
    p2: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Pada janin'
    }
  },
  form11: {
    p1: {
      widget: 'wdatenormal',
      data: null,
      col: 6,
      label: 'Tanggal'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Penolong'
    }
  },
  form12: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '1. Lama Kala 1'
    },
    p2: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: '2. Partogram Melewati Garis Waspada'
    },
    p3: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: '3. Penatalaksanaan Yang Dilakukan'
    }
  },
  form13: {
    p1: {
      widget: 'wradio',
      data: [
        { text: 'Spontan', value: 'Spontan' },
        { text: 'Forceps', value: 'Forceps' },
        { text: 'Vakum', value: 'Vakum' },
        { text: 'SC, Indikasi', value: null, field: 'auto' }
      ],
      col: 6,
      label: '1.'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '2. Lama Kala 2'
    }
  },
  form14: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Jam'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Jenis Kelamin'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'A / S'
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 2,
      property: { attrs: { suffix: 'cm' } },
      label: 'BBL'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 2,
      property: { attrs: { suffix: 'cm' } },
      label: 'PB'
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 2,
      property: { attrs: { suffix: 'cm' } },
      label: 'LK'
    },
    p7: {
      widget: 'wtext',
      data: null,
      col: 2,
      property: { attrs: { suffix: 'cm' } },
      label: 'LD'
    },
    p8: {
      widget: 'wtext',
      data: null,
      col: 2,
      property: { attrs: { suffix: 'cm' } },
      label: 'LP'
    },
    p9: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Anus'
    },
    p10: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Cacat Bawaan'
    },
    p11: {
      widget: 'wradio',
      data: [
        { text: 'Mengeringkan', value: 'Mengeringkan' },
        { text: 'Menghangatkan', value: 'Menghangatkan' },
        { text: 'Bebaskan jalan nafas', value: 'Bebaskan jalan nafas' },
        { text: 'Rangsang taktil', value: 'Rangsang taktil' }
      ],
      col: 6,
      label: 'Resusitasi Awal'
    },
    p12: {
      widget: 'wradio',
      data: [
        { text: 'O2 (NC/NRM/Neopuff) liter', value: null, field: 'auto' },
        { text: 'Bagging', value: 'Bagging' }
      ],
      col: 6,
      label: 'Penatalaksaan'
    },
    p13: {
      widget: 'wradio',
      data: [
        { text: 'Inj vit K', value: 'Inj vit K' }
      ],
      col: 12,
      label: ' '
    },
    p14: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12,
      label: 'IMD'
    }
  },
  form15: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '1. Lama Kala III'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Jumlah Pendarahan'
    },
    p3: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12,
      label: '2. Pemberian Oksitosin 10 Unit IM'
    },
    p4: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12,
      label: '3. Peregangan Tali Pusat Terkendali'
    },
    p5: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12,
      label: '4. Massage Uteri'
    },
    p6: {
      widget: 'wradio',
      data: [
        { text: 'Spontan', value: 'Spontan' },
        { text: 'Manuver', value: 'Manuver' },
        { text: 'Lengkap', value: 'Lengkap' },
        { text: 'Tidak Lengkap', value: 'Tidak Lengkap' },
        { text: 'Placenta Lahir > 30 menit', value: 'Placenta Lahir > 30 menit' },
        { text: 'Kontraksi Iregular/Anatonia Uteri', value: 'Kontraksi Iregular/Anatonia Uteri' },
        { text: 'Sintosinon Drip', value: 'Sintosinon Drip' },
        { text: 'Metil Ergometrin 0,2 Mg Im/IV', value: 'Metil Ergometrin 0,2 Mg Im/IV' },
        { text: 'Kompresi Bimanual Internal', value: 'Kompresi Bimanual Internal' },
        { text: 'Kuret', value: 'Kuret' },
        { text: 'Eksplorasi', value: 'Eksplorasi' }
      ],
      col: 12,
      label: '5. Plasenta lahir jam'
    },
    p7: {
      widget: 'wradio',
      data: [
        { text: 'Episiotomi', value: 'Episiotomi' },
        { text: 'Laseri tingkat', value: 'Laseri tingkat' },
        { text: 'Utuh', value: 'Utuh' },
        { text: 'Hecting', value: 'Hecting' }
      ],
      col: 12,
      label: '6. Perineum'
    }
  },
  form16: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: ' '
    }
  },
  form17: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: ' '
    }
  },
  form18: {
    p1: {
      widget: 'wdatenormal',
      data: null,
      col: 4,
      label: 'Tanggal'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Jam'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Dokter'
    },
    p4: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: ' '
    }
  },
  form19: {
    p1: {
      widget: 'wradio',
      data: [
        { text: 'Laki-laki', value: 'Laki-laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 6,
      label: 'Jenis Kelamin'
    },
    p2: {
      widget: 'wradio',
      data: [
        { text: 'Hidup, Jam:', value: null, field: 'auto' },
        { text: 'Meninggal, Jam:', value: null, field: 'auto' }
      ],
      col: 6,
      label: 'Lahir'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Kelainan Koginetal'
    },
    p4: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Untuk Bayi Keadaan Jelek, Bayi Lahir Hidup Kemudian Meninggal'
    },
    p5: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Lahir Meninggal, Sebab Kelahiran Meninggal'
    }
  },
  form20: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Plasenta (Lengkap/Tidak Lengkap)'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Tali Pusat'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Luka Jalan Lahir (Episiotomi)'
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Luka Jalan Lahir (Ruptur Perinium Tingkat)'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Jahitan'
    }
  },
  form21: {
    p1: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Pendarahan (kala III, kala IV)'
    }
  },
  form22: {
    p1: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'TD', suffix: 'mmHg', type: 'number' } }
    },
    p2: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'N', suffix: 'x/mnt', type: 'number' } }
    },
    p3: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'RR', suffix: 'x/mnt', type: 'number' } }
    },
    p4: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'S', type: 'number' } }
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Kontraksi'
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Pendarahan'
    }
  }
}
