export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  hari: {
    p1: {
      label: 'Hari',
      widget: 'wtext',
      data: null,
      col: 12
    }
  },
  tanggal: {
    p1: {
      label: 'Tanggal',
      widget: 'wdatenormal',
      data: null,
      col: 12
    }
  },
  form1: [
    {
      masalah_keperawatan: {
        label: ' ',
        widget: 'wtextarea',
        col: 12,
        data: []
      },
      implementasi_keperawatan: {
        label: ' ',
        widget: 'wtextarea',
        col: 12,
        data: []
      },
      p1: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      s1: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      m1: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p2: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      s2: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      m2: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p3: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      s3: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      m3: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p4: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      s4: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      m4: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p5: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      s5: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      m5: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      p6: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      s6: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      },
      m6: {
        label: ' ',
        widget: 'wtext',
        col: 12,
        data: []
      }
    }
  ]
}
