<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">Grafik Bayi</h3>
    <v-row justify="center">
      <v-col cols="12">
        <v-card color="deep-purple lighten-1" dark>
          <v-card-text>
            <v-row class="mt-8 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider class="mt-3 mb-3" />
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form1"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form1[i] = e)"
              :value="data.form1[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <v-row class="mt-0 mb-0">
          <div style="overflow-x: scroll">
            <table border="1">
              <tbody>
              <tr>
                <td colspan="2">Tanggal</td>
                <td colspan="4" v-for="(v, i) in master.form2.tanggal" :key="i">
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form2.tanggal[i] = e)"
                    :value="data.form2.tanggal[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td>Berat</td>
                <td>Suhu</td>
                <td v-for="item in ['',12,16,20]" class="text-center" :key="'a'+item">{{item}}</td>
                <td v-for="item in [8,12,16,20]" class="text-center" :key="'b'+item">{{item}}</td>
                <td v-for="item in [8,12,16,20]" class="text-center" :key="'c'+item">{{item}}</td>
                <td v-for="item in [8,12,16,20]" class="text-center" :key="'d'+item">{{item}}</td>
                <td v-for="item in [8,12,16,20]" class="text-center" :key="'e'+item">{{item}}</td>
                <td v-for="item in [8,12,16,20]" class="text-center" :key="'f'+item">{{item}}</td>
                <td v-for="item in [8,12,16,20]" class="text-center" :key="'g'+item">{{item}}</td>
                <td v-for="item in [8,12,16,20]" class="text-center" :key="'h'+item">{{item}}</td>
                <td v-for="item in [8,12,16,20]" class="text-center" :key="'i'+item">{{item}}</td>
                <td v-for="item in [8,12,16,20]" class="text-center" :key="'j'+item">{{item}}</td>
              </tr>
              <tr v-for="(x,y) in 35" :key="y">
                <td>
                  <a v-if="x === 2">-5000</a>
                  <a v-if="x === 6">-4500</a>
                  <a v-if="x === 10">-4000</a>
                  <a v-if="x === 14">-3500</a>
                  <a v-if="x === 18">-3000</a>
                  <a v-if="x === 22">-2500</a>
                  <a v-if="x === 26">-2000</a>
                  <a v-if="x === 30">-1500</a>
                  <a v-if="x === 34">-1000</a>
                </td>
                <td>
                  <a v-if="x === 2">42</a>
                  <a v-if="x === 6">41</a>
                  <a v-if="x === 10">40</a>
                  <a v-if="x === 14">39</a>
                  <a v-if="x === 18">38</a>
                  <a v-if="x === 22">37</a>
                  <a v-if="x === 26">36</a>
                  <a v-if="x === 30">35</a>
                </td>
                <td v-for="(v, i) in master.form2[x]" :key="i">
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form2[x][i] = e)"
                    :value="data.form2[x][i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">Makanan/Minuman</td>
                <td colspan="4" v-for="(v, i) in master.form2.makanan_minuman" :key="i">
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form2.makanan_minuman[i] = e)"
                    :value="data.form2.makanan_minuman[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">Defaksi</td>
                <td colspan="4" v-for="(v, i) in master.form2.defaksi" :key="i">
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form2.defaksi[i] = e)"
                    :value="data.form2.defaksi[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">Miksi</td>
                <td colspan="4" v-for="(v, i) in master.form2.miksi" :key="i">
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form2.miksi[i] = e)"
                    :value="data.form2.miksi[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">Tidur</td>
                <td colspan="4" v-for="(v, i) in master.form2.tidur" :key="i">
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form2.tidur[i] = e)"
                    :value="data.form2.tidur[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">Tangis</td>
                <td colspan="4" v-for="(v, i) in master.form2.tangis" :key="i">
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form2.tangis[i] = e)"
                    :value="data.form2.tangis[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">Napas</td>
                <td colspan="4" v-for="(v, i) in master.form2.napas" :key="i">
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form2.napas[i] = e)"
                    :value="data.form2.napas[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">Catatan (Obat-obatan)</td>
                <td colspan="4" v-for="(v, i) in master.form2.ctt_obat" :key="i">
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form2.ctt_obat[i] = e)"
                    :value="data.form2.ctt_obat[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/grafik-bayi'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      ayam: '',
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          tanggal_lahir: '',
          pukul: ''
        },
        form2: {
          tanggal: {
            tanggal1: '',
            tanggal2: '',
            tanggal3: '',
            tanggal4: '',
            tanggal5: '',
            tanggal6: '',
            tanggal7: '',
            tanggal8: '',
            tanggal9: '',
            tanggal10: ''
          },
          1: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          2: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          3: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          4: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          5: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          6: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          7: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          8: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          9: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          10: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          11: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          12: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          13: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          14: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          15: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          16: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          17: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          18: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          19: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          20: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          21: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          22: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          23: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          24: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          25: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          26: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          27: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          28: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          29: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          30: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          31: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          32: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          33: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          34: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          35: {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
            f: '',
            g: '',
            h: '',
            i: '',
            j: '',
            k: '',
            l: '',
            m: '',
            n: '',
            o: '',
            p: '',
            q: '',
            r: '',
            s: '',
            t: '',
            u: '',
            v: '',
            w: '',
            x: '',
            y: '',
            z: '',
            aa: '',
            ab: '',
            ac: '',
            ad: '',
            ae: '',
            af: '',
            ag: '',
            ah: '',
            ai: '',
            aj: '',
            ak: '',
            al: '',
            am: '',
            an: ''
          },
          makanan_minuman: {
            tanggal1: '',
            tanggal2: '',
            tanggal3: '',
            tanggal4: '',
            tanggal5: '',
            tanggal6: '',
            tanggal7: '',
            tanggal8: '',
            tanggal9: '',
            tanggal10: ''
          },
          defaksi: {
            tanggal1: '',
            tanggal2: '',
            tanggal3: '',
            tanggal4: '',
            tanggal5: '',
            tanggal6: '',
            tanggal7: '',
            tanggal8: '',
            tanggal9: '',
            tanggal10: ''
          },
          miksi: {
            tanggal1: '',
            tanggal2: '',
            tanggal3: '',
            tanggal4: '',
            tanggal5: '',
            tanggal6: '',
            tanggal7: '',
            tanggal8: '',
            tanggal9: '',
            tanggal10: ''
          },
          tidur: {
            tanggal1: '',
            tanggal2: '',
            tanggal3: '',
            tanggal4: '',
            tanggal5: '',
            tanggal6: '',
            tanggal7: '',
            tanggal8: '',
            tanggal9: '',
            tanggal10: ''
          },
          tangis: {
            tanggal1: '',
            tanggal2: '',
            tanggal3: '',
            tanggal4: '',
            tanggal5: '',
            tanggal6: '',
            tanggal7: '',
            tanggal8: '',
            tanggal9: '',
            tanggal10: ''
          },
          napas: {
            tanggal1: '',
            tanggal2: '',
            tanggal3: '',
            tanggal4: '',
            tanggal5: '',
            tanggal6: '',
            tanggal7: '',
            tanggal8: '',
            tanggal9: '',
            tanggal10: ''
          },
          ctt_obat: {
            tanggal1: '',
            tanggal2: '',
            tanggal3: '',
            tanggal4: '',
            tanggal5: '',
            tanggal6: '',
            tanggal7: '',
            tanggal8: '',
            tanggal9: '',
            tanggal10: ''
          }
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
    }
  },
  methods: {
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Populasi Khusus'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
          } else {
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Populasi Khusus'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style>

.float-group {
  position: fixed;
  bottom: 50%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

</style>
