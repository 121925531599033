export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    dignosa_medis: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Diagnosa Medis'
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Ruangan'
    }
  },
  form2: {
    tanggal: {
      widget: 'wdatenormal',
      data: null,
      col: 6,
      label: 'Tanggal Masuk RS'
    },
    jam: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Jam Masuk RS'
    },
    alasan_mrs: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: 'Alasan MRS'
    },
    tanggal_pemulangan: {
      widget: 'wdatenormal',
      data: null,
      col: 6,
      label: 'Tanggal Perencanaan Pemulangan'
    },
    jam_pemulangan: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Jam Perencanaan Pemulangan'
    },
    estimasi_tanggal_pemulangan: {
      widget: 'wdatenormal',
      data: null,
      col: 6,
      label: 'Estimasi tanggal pemulangan pasien'
    },
    nama_perawat: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Nama Perawat'
    }
  },
  form3: {
    textkiri: {
      widget: 'wlabelkiri',
      label: 'Pengaruh rawat inap terhadap :',
      data: [],
      col: 12
    },
    a1: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 4,
      label: 'a. Pasian dan keluarga pasien'
    },
    b1: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 4,
      label: 'b. Pekerjaan'
    },
    c1: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 4,
      label: 'c. Keuangan'
    },
    a2: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: '2. Antisipasi terhadap masalah saat pulang'
    },
    b2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Penjelasan Nomor 2'
    },
    a3: {
      widget: 'wradio',
      data: [
        { text: 'Minum Obat', value: 'Minum Obat' },
        { text: 'Makan', value: 'Makan' },
        { text: 'Menyiapkan makanan', value: 'Menyiapkan makanan' },
        { text: 'Edukasi kesehatan', value: 'Edukasi kesehatan' },
        { text: 'Mandi', value: 'Mandi' },
        { text: 'Diet', value: 'Diet' },
        { text: 'Berpakaian', value: 'Berpakaian' },
        { text: 'Transportasi', value: 'Transportasi' }
      ],
      col: 12,
      label: '3. Bantuan diperlukan dalam hal'
    },
    a4: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '4. Adakah yang membantu keperluan tersebut diatas'
    },
    a5: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan orang yang akan merawat', value: null, field: 'auto' }
      ],
      col: 6,
      label: '5. Apakah pasien hidup/tinggal sendiri setelah keluar dari rumah sakit?'
    },
    a6: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '6. Apakah pasien menggunakan peralatan medis di rumah setelah keluar rumah sakit (cateter, NGT, double lumen, oksigen)?'
    },
    a7: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '7. Apakah pasien memerlukan alat bantu setelah keluar dari rumah sakit (tongkat, kursi roda, walker dll)?'
    },
    a8: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '8. Apakah memerlukan bantuan/perawatan khusus di rumah setelah keluar rumah sakit (home care, home visit)?'
    },
    a9: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '9. Apakah pasien bermasalah dalam memenuhi kebutuhan pribadinya setelah keluar dari rumah sakit (makan, minum, toilet, dll)?'
    },
    a10: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '10. Apakah pasien memiliki nyeri kronis dan kelelahan setelah keluar dari rumah sakit?'
    },
    a11: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '11. Apakah pasien dan keluarga memerlukan edukasi kesehatan setelah keluar dari rumah sakit (obat-obat, nyeri, diit, mencari pertolongan, follow up, dll)?'
    },
    a12: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Jelaskan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '12. Apakah pasien dan keluarga memerlukan keterampilan khusus setelah keluar dari rumah sakit (perawatan luka, injeksi, perawatan bayi, dll)?'
    }
  }
}
