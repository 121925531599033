<template>
  <v-container fluid>
    <a href="#test0" id="test0"></a>
    <h3 class="mb-2 mt-3">RENCANA ASUHAN KEPERAWATAN BAYI / NEONATUS</h3>
    <v-row justify="center">
      <v-col cols="12" lg="10" sm="10" md="10">
        <v-card color="deep-purple lighten-1" class="mt-2" dark>
          <v-card-text>
            <v-row class="mt-8 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="12" sm="12" md="12">
        <v-tabs
          class="mb-3"
          centered
          stacked>
          <v-tab @click="tabKanan = 0">Hal 1</v-tab>
          <v-tab @click="tabKanan = 1">Hal 2</v-tab>
        </v-tabs>
        <div :hidden="tabKanan !== 0">
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
            v-for="(v, i) in master.form1"
               :md="v.col ? v.col : 12"
               :lg="v.col ? v.col : 12"
               :sm="v.col ? v.col : 12"
               cols="12"
               class="mt-0 mb-0 pt-0 pb-3"
               :key="'a'+ i"
            >

            <smart-widget
                :comp="v.widget"
                :data="v.data"
                :sync-value="(e) => (data.form1[i] = e)"
                vclass="c-text-field"
                :value="data.form1[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
            />
            </v-col>
          </v-row>
          <div class="font-weight-bold text-center">DIAGNOSA</div>
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form2"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'b'+ i"
              >

              <smart-widget
                 :comp="v.widget"
                 :data="v.data"
                 :sync-value="(e) => (data.form2[i] = e)"
                 vclass="c-text-field"
                 :value="data.form2[i]"
                 :property="v.property"
                 :label-size="v.labelSize"
                 :input-size="v.inputSize"
                 :label="v.label ? v.label : i.replaceAll('_', ' ')"
                 :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form3"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'c'+ i"
              >

              <smart-widget
                 :comp="v.widget"
                 :data="v.data"
                 :sync-value="(e) => (data.form3[i] = e)"
                 vclass="c-text-field"
                 :value="data.form3[i]"
                 :property="v.property"
                 :label-size="v.labelSize"
                 :input-size="v.inputSize"
                 :label="v.label ? v.label : i.replaceAll('_', ' ')"
                 :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form4"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'d'+ i"
              >

              <smart-widget
                 :comp="v.widget"
                 :data="v.data"
                 :sync-value="(e) => (data.form4[i] = e)"
                 vclass="c-text-field"
                 :value="data.form4[i]"
                 :property="v.property"
                 :label-size="v.labelSize"
                 :input-size="v.inputSize"
                 :label="v.label ? v.label : i.replaceAll('_', ' ')"
                 :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form5"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'e'+ i"
              >

              <smart-widget
                 :comp="v.widget"
                 :data="v.data"
                 :sync-value="(e) => (data.form5[i] = e)"
                 vclass="c-text-field"
                 :value="data.form5[i]"
                 :property="v.property"
                 :label-size="v.labelSize"
                 :input-size="v.inputSize"
                 :label="v.label ? v.label : i.replaceAll('_', ' ')"
                 :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form6"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'f'+ i"
              >

              <smart-widget
                 :comp="v.widget"
                 :data="v.data"
                 :sync-value="(e) => (data.form6[i] = e)"
                 vclass="c-text-field"
                 :value="data.form6[i]"
                 :property="v.property"
                 :label-size="v.labelSize"
                 :input-size="v.inputSize"
                 :label="v.label ? v.label : i.replaceAll('_', ' ')"
                 :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form7"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'g'+ i"
              >

              <smart-widget
                 :comp="v.widget"
                 :data="v.data"
                 :sync-value="(e) => (data.form7[i] = e)"
                 vclass="c-text-field"
                 :value="data.form7[i]"
                 :property="v.property"
                 :label-size="v.labelSize"
                 :input-size="v.inputSize"
                 :label="v.label ? v.label : i.replaceAll('_', ' ')"
                 :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div :hidden="tabKanan !== 1">
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 m-1 mb-0">
            <v-col
            v-for="(v, i) in master.form8"
               :md="v.col ? v.col : 12"
               :lg="v.col ? v.col : 12"
               :sm="v.col ? v.col : 12"
               cols="12"
               class="mt-0 mb-0 pt-0 pb-3"
               :key="'h'+ i"
            >

            <smart-widget
                :comp="v.widget"
                :data="v.data"
                :sync-value="(e) => (data.form8[i] = e)"
                vclass="c-text-field"
                :value="data.form8[i]"
                :property="v.property"
                :label-size="v.labelSize"
                :input-size="v.inputSize"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
            />
            </v-col>
          </v-row>
          <div class="font-weight-bold text-center">DIAGNOSA</div>
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form9"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'i'+ i"
              >

              <smart-widget
                 :comp="v.widget"
                 :data="v.data"
                 :sync-value="(e) => (data.form9[i] = e)"
                 vclass="c-text-field"
                 :value="data.form9[i]"
                 :property="v.property"
                 :label-size="v.labelSize"
                 :input-size="v.inputSize"
                 :label="v.label ? v.label : i.replaceAll('_', ' ')"
                 :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row class="mt-0 m-1 mb-0">
            <v-col
              v-for="(v, i) in master.form10"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="'j'+ i"
              >

              <smart-widget
                 :comp="v.widget"
                 :data="v.data"
                 :sync-value="(e) => (data.form10[i] = e)"
                 vclass="c-text-field"
                 :value="data.form10[i]"
                 :property="v.property"
                 :label-size="v.labelSize"
                 :input-size="v.inputSize"
                 :label="v.label ? v.label : i.replaceAll('_', ' ')"
                 :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" medium class="btn-block">
          Simpan
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
// import { use } from 'vue/types/umd'
// import { apiKedua } from '../plugins/supports'
// import { apiKedua } from '../plugins/supports'
// import { isEmpty, jsonPrettier } from '@/plugins/supports'
import Master from '../../data/rencana-asuhan-keperawatan-bayi-neonatus'
import SmartWidget from '@/components/SmartWidget.vue'
import Dialog from '@/components/Dialog'
// import SekalaNyeri from '@/components/SekalaNyeri.vue'
export default {
  components: {
    DialogConfirm: Dialog,
    // SekalaNyeri,
    SmartWidget
    // DialogConfirm: Dialog,
    // Soap,
  },
  data () {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      item_ke: null,
      url_emr: 'http://emr.rs-syafira.com/',
      url: '',
      site: '',
      history: [],
      patient_name: '',
      tabKiri: '',
      tabKanan: 0,
      search: '',
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          rencana_keperawatan_tgl: ''
        },
        form2: {
          reaksi_yang_timbul: '',
          data_penunjang: '',
          data_subyektif: '',
          data_obyektif: ''
        },
        form3: {
          kriteria_hasil: '',
          nic_mandiri: '',
          nic_kolaborasi: ''
        },
        form5: {
          rencana_keperawatan_tgl: ''
        },
        form6: {
          resiko_tinggi_infeksi: '',
          data_penunjang: '',
          data_klg_mengatakan: '',
          nic_data_obyektif: ''
        },
        form7: {
          noc_kriteria: '',
          nic_mandiri2: '',
          nic_kolaborasi2: ''
        },
        form8: {
          rencana_keperawatan_tgl3: ''
        },
        form9: {
          reaksi_yang_timbul: '',
          data_penunjang: '',
          data_subyektif: '',
          data_obyektif: ''
        },
        form10: {
          kriteria_hasil: '',
          nic_mandiri: '',
          nic_kolaborasi: ''
        },
        form11: {
          rencana_keperawatan_tgl4: ''
        },
        form12: {
          resiko_tinggi_pola_termoregulasi: '',
          data_penunjang: '',
          data_subyektif: '',
          data_obyektif: ''
        },
        form13: {
          kriteria_hasil: '',
          nic_mandiri: '',
          nic_kolaborasi: ''
        }
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  computed: {
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      setTimeout(() => {
        // this.getAssemen(this.$route.query.folio_id)
      }, 100)
    }
    setTimeout(() => {
      // this.getAssemen(this.$route.query.folio_id)
    }, 100)
  },
  methods: {
    setTab () {
      console.log(this.tabKanan + ' TAB KE BERAPA')
      if (this.tabKanan === 1 || this.tabKanan === 2) {
        this.tabKiri = 2
      } else {
        this.tabKiri = 0
      }
      setTimeout(() => {
        // this.getAssemen(this.$route.query.folio_id)
      }, 100)
    },
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Awal Medis Rawat Inap Neonatus'
      }
      // eslint-disable-next-line no-undef
      // $.post(
      //   baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
      //   data,
      //   (res) => {
      //     // eslint-disable-next-line no-unused-vars
      //     const { con, msg, results } = res
      //     if (con) {
      //     } else {
      //     }
      //   },
      //   'JSON'
      // )
      localStorage.setItem('asesmeninap', JSON.stringify(data))
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    }
    // getAssemen (folioId) {
    //   var hmm2 = this
    //   var hmm = localStorage.getItem('asesmeninap')
    //   hmm = JSON.parse(hmm)
    //   this.data = hmm.data
    //   setTimeout(() => {
    //     Object.entries(hmm.data.form7).forEach(entry => {
    //       const [key, value] = entry
    //       hmm2.data.form7[key] = value
    //       console.log(hmm2.data.form7[key])
    //     })
    //   }, 3000)
    //   // var type = 'Asesmen Keperawatan Rawat Inap'
    //   // eslint-disable-next-line no-undef
    //   // $.get(
    //   //   baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
    //   //   { folio_id: folioId, jenis: type },
    //   //   (res) => {
    //   //     // eslint-disable-next-line no-unused-vars
    //   //     const { con, msg, results } = res
    //   //     if (con) {
    //   //       console.log(this.data)
    //   //       this.data = results
    //   //       this.data.form7 = results.form7
    //   //       // this.data.form6 = results.form6
    //   //       // this.data.demografi.jenis_kelamin = {
    //   //       //   value: results.jenis_kelamin,
    //   //       //   text: results.jenis_kelamin
    //   //       // }
    //   //     } else {
    //   //     }
    //   //   },
    //   //   'JSON'
    //   // )
    // }
  }
}
</script>

<style>
.v-input--radio-group .v-input__slot {
  margin-bottom: 0;
}
.v-messages {
  display: none;
}

.float-group {
  position: fixed;
  bottom: 50%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 100px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.text-capitalize {
  vertical-align:top;
}
</style>
