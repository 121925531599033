export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  formkesadaran: {
    p1: {
      label: 'Kesadaran Pasien',
      widget: 'wradio',
      data: [
        { text: 'Baik', value: 'Baik' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    }
  },
  formtanggal: {
    p1: {
      label: ' ',
      widget: 'wdatenormal',
      data: [],
      col: 12
    }
  },
  formwaktu: {
    p1: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form1: {
    p1: [
      {
        label: 'Dibawah 3 tahun',
        value: 4
      },
      {
        label: '3 -7 tahun',
        value: 3
      },
      {
        label: '8-13 tahun',
        value: 2
      },
      {
        label: '>13 tahun',
        value: 1
      }
    ],
    p2: [
      {
        label: 'Laki-laki',
        value: 2
      },
      {
        label: 'Perempuan',
        value: 1
      }
    ],
    p3: [
      {
        label: 'Kelainan neurologi',
        value: 4
      },
      {
        label: 'Perubahan dalam oksigen (masalah saluran nafas, dehidrasi, anemia, anoreksia, sinkop/sakit kepala)',
        value: 3
      },
      {
        label: 'Kelainan psikis/perilaku',
        value: 2
      },
      {
        label: 'Diagnosa lain',
        value: 1
      }
    ],
    p4: [
      {
        label: 'Tidak sadar akan keterbatasan',
        value: 3
      },
      {
        label: 'Lupa keterbatasan',
        value: 2
      },
      {
        label: 'Mengetahui keterbatasan',
        value: 1
      }
    ],
    p5: [
      {
        label: 'Riwayat jatuh dari tempat tidur saat bayi-anak',
        value: 4
      },
      {
        label: 'Pasien menggunakan alat bantu atau box/mebel',
        value: 3
      },
      {
        label: 'Pasien berada ditempat tidur',
        value: 2
      },
      {
        label: 'Diluar ruang rawat',
        value: 1
      }
    ],
    p6: [
      {
        label: 'Dalam 24 jam',
        value: 3
      },
      {
        label: 'Dalam 48 jam riwayat jatuh',
        value: 2
      },
      {
        label: '>48 jam',
        value: 1
      }
    ],
    p7: [
      {
        label: 'Bermacam-macam obat yang digunakan: obat sedatif (kecuali pasien PICU yang menggunakan sedasi dan paralisis), hipnotok, barbiturat, fenotiazin, anti depresan, laksansia/diuretika, narkotika',
        value: 3
      },
      {
        label: 'Salah satu dari pengobatan di atas',
        value: 2
      },
      {
        label: 'Pengobatan lain',
        value: 1
      }
    ]
  },
  penilaian_resiko_jatuh: {
    p1: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'RR', value: 'RR' },
        { text: 'RS', value: 'RS' },
        { text: 'RT', value: 'RT' }
      ],
      col: 12
    }
  },
  pencegahan: [
    {
      label: 'Orientasi lingkungan',
      key: 'p1'
    },
    {
      label: 'Pastikan anak dirawwat di tempat tidur yang sesuai, anak <2 tahun dirawat di box atau tempat tidur khusus anak',
      key: 'p2'
    },
    {
      label: 'Pastikan bel mudah di jangkau',
      key: 'p3'
    },
    {
      label: 'Posisikan tempat tidur pada posisi rendah dan roda terkunci',
      key: 'p4'
    },
    {
      label: 'Naikkan pagar pengaman tempat tidur',
      key: 'p5'
    },
    {
      label: 'Amankan dan awasi anak yang membutuhkan perhatian khusus (mis: gangguan perkembangan mental, anak yang mendapat obat penenang/ anastesi umum)',
      key: 'p6'
    },
    {
      label: 'Informasikan resiko sedang/tinggi jatuh pasien',
      key: 'p7'
    },
    {
      label: 'Pasangkan gelang/stiker Fall Risk (stiker warna kuning) sebagai tanda resiko jatuh',
      key: 'p8'
    },
    {
      label: 'Pasang tanda resiko jatuh warna kuning pada bed pasien',
      key: 'p9'
    },
    {
      label: 'Pasien didekatkan di dekat nurse station',
      key: 'p10'
    },
    {
      label: 'Aktifkan alarm bed pasien',
      key: 'p11'
    },
    {
      label: 'Pasang pagar pengaman',
      key: 'p12'
    },
    {
      label: 'Lakukan pengkajian ualng 1x setiap shift',
      key: 'p13'
    },
    {
      label: 'Kunjungi dan monitor setiap 1 jam',
      key: 'p14'
    },
    {
      label: 'Lakukan pemasangan restrain (pengikat) pada pasien yang mengalami gangguan penurunan kesadaran dan gangguan mobilitas',
      key: 'p15'
    },
    {
      label: 'Libatkan keluarga pasien untuk selalu',
      key: 'p16'
    }
  ]
}
