export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  formkesadaran: {
    p1: {
      label: 'Kesadaran Pasien',
      widget: 'wradio',
      data: [
        { text: 'Baik', value: 'Baik' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    }
  },
  formtanggal: {
    p1: {
      label: ' ',
      widget: 'wdatenormal',
      data: [],
      col: 12
    }
  },
  formwaktu: {
    p1: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form1: {
    p1: [
      {
        label: 'Tidak',
        value: 0
      },
      {
        label: 'Ya',
        value: 25
      }
    ],
    p2: [
      {
        label: 'Tidak',
        value: 0
      },
      {
        label: 'Ya',
        value: 15
      }
    ],
    p3: [
      {
        label: 'Alat bantu jalan: Bedrest/dibantu perawat/kursi roda/tidak ada',
        value: 0
      },
      {
        label: 'Alat penopang/tongkat/walker',
        value: 15
      },
      {
        label: 'Berpegangan pada benda-benda di sekitar',
        value: 30
      }
    ],
    p4: [
      {
        label: 'Tidak',
        value: 0
      },
      {
        label: 'Ya',
        value: 20
      }
    ],
    p5: [
      {
        label: 'Norml/bed rest/immoblile',
        value: 0
      },
      {
        label: 'Lemah (tidak bertenaga)',
        value: 10
      },
      {
        label: 'Gangguan/tidak normal (pincang atau diseret)',
        value: 20
      }
    ],
    p6: [
      {
        label: 'Orientasi baik',
        value: 3
      },
      {
        label: 'Sering lupa akan keterbatasan yang dimiliki',
        value: 2
      }
    ]
  },
  penilaian_resiko_jatuh: {
    p1: {
      label: ' ',
      widget: 'wradio',
      data: [
        { text: 'RR', value: 'RR' },
        { text: 'RS', value: 'RS' },
        { text: 'RT', value: 'RT' }
      ],
      col: 12
    }
  },
  pencegahan: [
    {
      label: 'Orientasi lingkungan',
      key: 'p1'
    },
    {
      label: 'Pastikan bel mudah di jangkau',
      key: 'p2'
    },
    {
      label: 'Posisikan tempat tidur pada posisi rendah dan roda terkunci',
      key: 'p3'
    },
    {
      label: 'Naikkan pagar pengaman tempat tidur',
      key: 'p4'
    },
    {
      label: 'Tepatkan alat bantu jalan yang mudah dijangkau',
      key: 'p5'
    },
    {
      label: 'Kaji kebutuhan toilet pasien',
      key: 'p6'
    },
    {
      label: 'Beri edukasi pasien',
      key: 'p7'
    },
    {
      label: 'Informasikan resiko sedang/tinggi jatuh pasien',
      key: 'p8'
    },
    {
      label: 'Pasangkan gelang/stiker Fall Risk (stiker warna kuning) sebagai tanda resiko jatuh',
      key: 'p9'
    },
    {
      label: 'Aktifkan alarm bed pasien',
      key: 'p10'
    },
    {
      label: 'Pasang pagar pengaman dan Pasang tanda resiko jatuh warna kuning pada bed pasien',
      key: 'p11'
    },
    {
      label: 'Beri penerangan yang cukup pada malam hari',
      key: 'p12'
    },
    {
      label: 'Lakukan pengkajian ulang 1x setiap shift',
      key: 'p13'
    },
    {
      label: 'Kunjungi dan monitor setiap 1 jam',
      key: 'p14'
    },
    {
      label: 'Lakukan pemasangan restrain (pengikat) pada pasien yang mengalami gangguan penurunan kesadaran dan gangguan mobilitas',
      key: 'p15'
    }
  ]
}
